import { getLatestSubscribers } from 'services/apiHandlers/Dashboard/Analytics';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { listSegments, subscribers, view } from 'services/constant/routes';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import { z } from 'zod';
import moment from 'moment';

const getSubscribersDataSchema = z.object({
  created_at: z.string(),
  deleted_at: z.null(),
  email_address: z.string(),
  email_content_format: z.string(),
  id: z.number(),
  ip_address: z.null(),
  last_blast_click_created_at: z.null(),
  last_blast_view_created_at: z.null(),
  opt_status: z.string(),
  owner_id: z.number(),
  person_database_id: z.number(),
  soft_bounce_count: z.number(),
  source_id: z.null(),
  source_type: z.null(),
  subscribed_at: z.string(),
  tracking_hash: z.string(),
  unsubscribed_at: z.null(),
  updated_at: z.string(),
  user_agent: z.null(),
});

const getSubscribersResponseSchema = z.object({
  data: z.array(getSubscribersDataSchema),
  status: z.number(),
});
type getSubscribersResponse = z.infer<typeof getSubscribersResponseSchema>;
const GetSubscribersDataSchema = z.array(getSubscribersDataSchema);

type GetSubscribersDataType = z.infer<typeof GetSubscribersDataSchema>;

const LatestSubscribers = () => {
  const [latestSubscribers, setLatestSubscribers] =
    useState<GetSubscribersDataType>([]);
  const getLatestSubscribersData = async () => {
    try {
      const getSubscribersResponse =
        (await getLatestSubscribers()) as getSubscribersResponse;
      if (getSubscribersResponse.status === 200) {
        setLatestSubscribers(getSubscribersResponse.data);
      }
    } catch (error) {
      console.log('error is: ', error);
    }
  };

  useEffect(() => {
    getLatestSubscribersData();
  }, []);

  return (
    <ErrorBoundary>
      <div className="rounded-lg shadow-lg overflow-hidden shadow-gray-200 h-[463px] dark:shadow-none bg-white dark:bg-slate-800">
        <div className="cardHeader">
          <h4 className="text-base text-darkText font-medium dark:text-white">
            Latest Subscribers
          </h4>
        </div>

        <div>
          {latestSubscribers?.map((subscriber) => {
            return (
              <div
                key={subscriber.id}
                className="p-4 flex justify-between items-center"
              >
                <div className="flex items-center">
                  <div className="w-6 h-6 rounded-full overflow-hidden">
                    <img
                      src="https://themesbrand.com/velzon/html/galaxy/assets/images/nft/img-01.jpg"
                      alt="top performing workflows"
                    />
                  </div>
                  <div className="ml-3 w-40">
                    <Link
                      to={`${listSegments}/${subscribers}/${view}/${subscriber.id}`}
                    >
                      <h6 className="text-sm text-darkText">
                        {subscriber.email_address}
                      </h6>
                      <p className="text-xs text-secondary">
                        {subscriber.tracking_hash}
                      </p>
                    </Link>
                  </div>
                </div>
                <p
                  className={`px-2 py-0.5 rounded-md bg-${
                    subscriber.opt_status === 'active' ? 'success' : 'danger'
                  } text-${
                    subscriber.opt_status === 'active' ? 'success' : 'danger'
                  } text-sm font-medium`}
                >
                  {subscriber.opt_status}
                </p>
                <p className="text-sm text-secondary text-end">
                  {moment(subscriber.subscribed_at).format('DD MMMM,YYYY')}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default LatestSubscribers;
