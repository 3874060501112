import Health from 'assets/Images/svg-images/health.svg';
import Plus from 'assets/Images/svg-images/plus.svg';
import { FC } from 'react';
import { Link} from 'react-router-dom';
import { createCampaign } from 'services/constant/routes';
import React from "react"

const Header: FC = () => {


  return (
    <div className="flex items-center relative mt-0.5 mb-4">
      <div className="sm:flex items-center w-full">
        <div className="flex items-center sm:w-auto w-full sm:mb-0 mb-3">
          <span>
            <img
              className="w-5 h-5 dark:invert dark:brightness-0"
              src={Health}
              alt="Health"
            />
          </span>
          <button className="flex items-center">
            <h2 className="sm:text-xl text-xs sm:leading-5 leading-3 text-black-700 dark:text-white font-medium sm:ml-2.5 ml-0.5">
              Blasts Campaigns
            </h2>
          </button>
        </div>
      </div>
      <Link
        to={`${createCampaign}?tab=campaign`}
        className="sm:mt-0 mt-5 lg:relative absolute right-0 lg:w-full"
      >
        <button className="sm:text-sm text-xs font-medium leading-5 text-white flex items-center ml-auto rounded-md py-3 sm:pl-4 px-2 sm:pr-3.5 font-inter bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105">
          <img className="sm:mr-3 mr-1" src={Plus} alt="Plus" />
          Create Campaign
        </button>
      </Link>
    </div>
  );
};
export default Header;
