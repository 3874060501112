import { FC, useContext, useEffect, useRef, useState } from 'react';
import Connector from './Connector';
import TimeClock from 'assets/Images/Workflow/time-clock.png';
import { WorkflowAction, WorkflowCondition } from './index.type';
import { renderBranchNodes } from './BranchNodes';
import { WorkflowContext } from 'store/WorkflowContext';
import React from 'react';


interface Props {
  workflow: WorkflowAction | WorkflowCondition;
  onAddBlock: (id: string, direction?: string) => void;
}

const countTotalNodes = (
  workflow: WorkflowAction | WorkflowCondition | undefined
) => {
  if (workflow) {
    let count = 0;
    if ('trueNode' in workflow && workflow.trueNode) {
      if ('conditionGroup' in workflow.trueNode) {
        count += 1 + countTotalNodes(workflow.trueNode);
      } else {
        count += countTotalNodes(workflow.trueNode);
      }
    }
    if ('falseNode' in workflow && workflow.falseNode) {
      if ('conditionGroup' in workflow.falseNode) {
        count += 1 + countTotalNodes(workflow.falseNode);
      } else {
        count += countTotalNodes(workflow.falseNode);
      }
    }
    if ('nextNode' in workflow && workflow.nextNode) {
      if ('conditionGroup' in workflow.nextNode) {
        count += 1 + countTotalNodes(workflow.nextNode);
      } else {
        count += countTotalNodes(workflow.nextNode);
      }
    }
    return count;
  }
  return 0;
};

const countNodes = (
  workflow: WorkflowAction | WorkflowCondition | undefined,
  id: string
) => {
  if (workflow) {
    if (workflow.id === id) {
      return countTotalNodes(workflow);
    }
    let count = 0;
    if ('trueNode' in workflow && workflow.trueNode) {
      const trueNodeCount = countNodes(workflow.trueNode, id);
      if (trueNodeCount) {
        count += trueNodeCount;
      }
    }
    if ('falseNode' in workflow && workflow.falseNode) {
      const falseNodeCount = countNodes(workflow.falseNode, id);
      if (falseNodeCount) {
        count += falseNodeCount;
      }
    }
    if ('nextNode' in workflow && workflow.nextNode) {
      const nextNodeCount = countNodes(workflow.nextNode, id);
      if (nextNodeCount) {
        count += nextNodeCount;
      }
    }
    return count;
  }
  return 0;
};

const LeftNode: FC<Props> = ({ workflow, onAddBlock }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(116);
  const workflowCtx = useContext(WorkflowContext);
  useEffect(() => {
    const nodes = countNodes(workflowCtx?.workflow?.workflow, workflow.id);
    setWidth(nodes === 0 ? 116 : (nodes * 116) + 116);
  }, [workflowCtx?.workflow]);
  
  return (
    <div ref={divRef} style={{ width: `${width}px` }}>
      <span className="border-l-2 border-t-2 border-gray-800 h-10 block rounded-tl-lg relative">
        <span className="py-1 px-1.5 text-xs font-medium leading-3 text-[#00bb94] rounded-[10px] bg-[#ecf8f6] absolute -bottom-4 -left-4">
          Yes
        </span>
      </span>
      <span className="border-r-2 border-gray-800 h-14 block w-0.5 mr-auto"></span>
      <div style={{ marginLeft: `-${width}px` }}>
        <div
          onClick={() => onAddBlock(workflow.id, 'left')}
          className="group w-52 mx-auto flex justify-center items-center text-13 font-medium leading-3 text-black-300 px-4 py-4 rounded-lg border border-gray-100 boxShadow bg-gray-400 hover:bg-workflowHover cursor-pointer ease-in duration-300"
        >
          <img className="mr-2" src={TimeClock} alt="Clock" />
          <span className='ease-in duration-300'>Add Block</span>
        </div>
        {'trueNode' in workflow && (
          <>
            {workflow.trueNode && <Connector />}
            {renderBranchNodes(workflow.trueNode, onAddBlock)}
          </>
        )}
      </div>
    </div>
  );
};
export default LeftNode;
