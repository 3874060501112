import React, { useEffect, useState, useMemo, useContext } from 'react';
import EditBlue from 'assets/Images/Common/edit-blue.png';
import Form from 'components/DashboardReports/Body/Common/Form';
import Table from 'components/Table';
import styled from 'styled-components';
import {
  exportFilter,
  getFilterSubscribers,
} from 'services/apiHandlers/List-Segments/Filter';
import { z } from 'zod';
import { TableColumn } from 'react-data-table-component';
import momentTimezone from 'moment-timezone';
import { Link, useParams } from 'react-router-dom';
import { filters, listSegments } from 'services/constant/routes';
import { UserContext } from 'store/UserContext';


const StyledWrapper = styled.div`
  .allWorkFlows div[data-column-id='1'] {
    min-width: 25.3%;
    width: 25.3%;
    max-width: 25.3%;
    font-size: 14px;
    font-weight: 500;
    border-right: 1px solid rgb(229 231 235);
    overflow: unset;
  }

  .allWorkFlows div[data-column-id='1'] div {
    width: 100%;
    min-width: 100%;
    border: unset;
    justify-content: space-between;
  }

  .allWorkFlows div[data-column-id='1'] div div {
    width: auto;
    min-width: auto;
  }

  .allWorkFlows div[data-column-id='2'] {
    min-width: 13%;
    width: 13%;
    max-width: 13%;
    font-size: 13px;
    font-weight: 500;
    color: #757575;
    overflow: unset;
  }

  .allWorkFlows div[data-column-id='2'] div {
    width: 100%;
    min-width: 100%;
    border: unset;
    justify-content: space-between;
  }

  .allWorkFlows div[data-column-id='2'] div div {
    width: auto;
    min-width: auto;
  }

  .allWorkFlows div[data-column-id='3'] {
    min-width: 13%;
    width: 13%;
    max-width: 13%;
    font-size: 13px;
    font-weight: 500;
    color: #757575;
    overflow: unset;
  }

  .allWorkFlows div[data-column-id='3'] div {
    width: 100%;
    min-width: 100%;
    border: unset;
    justify-content: space-between;
  }

  .allWorkFlows div[data-column-id='3'] div div {
    width: auto;
    min-width: auto;
  }

  .allWorkFlows div[data-column-id='4'] {
    min-width: 10.4%;
    width: 10.4%;
    max-width: 10.4%;
    font-size: 13px;
    font-weight: 500;
    color: #757575;
    overflow: unset;
  }

  .allWorkFlows div[data-column-id='4'] div {
    width: 100%;
    min-width: 100%;
    border: unset;
    justify-content: space-between;
  }

  .allWorkFlows div[data-column-id='4'] div div {
    width: auto;
    min-width: auto;
  }

  .allWorkFlows div[data-column-id='5'] {
    min-width: 10.4%;
    width: 10.4%;
    max-width: 10.4%;
    font-size: 13px;
    font-weight: 500;
    color: #757575;
    overflow: unset;
  }

  .allWorkFlows div[data-column-id='5'] div {
    width: 100%;
    min-width: 100%;
    border: unset;
    justify-content: space-between;
  }

  .allWorkFlows div[data-column-id='5'] div div {
    width: auto;
    min-width: auto;
  }

  .allWorkFlows div[data-column-id='6'] {
    min-width: 13.3%;
    width: 13.3%;
    max-width: 13.3%;
    font-size: 13px;
    font-weight: 500;
    color: #757575;
    border-left: 1px solid rgb(229 231 235);
    overflow: unset;
  }

  .allWorkFlows div[data-column-id='6'] div {
    width: 100%;
    min-width: 100%;
    border: unset;
    justify-content: space-between;
  }

  .allWorkFlows div[data-column-id='6'] div div {
    width: auto;
    min-width: auto;
  }

  .allWorkFlows div[data-column-id='7'] {
    min-width: 14.6%;
    width: 14.6%;
    max-width: 14.6%;
    font-size: 13px;
    font-weight: 500;
    color: #757575;
    border-left: 1px solid rgb(229 231 235);
    overflow: unset;
  }

  @media (max-width: 1024px) {
    .allWorkFlows div[data-column-id='1'] {
      min-width: 21%;
      width: 21%;
      max-width: 21%;
    }

    .allWorkFlows div[data-column-id='2'] {
      min-width: 15%;
      width: 15%;
      max-width: 15%;
    }

    .allWorkFlows div[data-column-id='4'] {
      min-width: 12%;
      width: 12%;
      max-width: 12%;
    }

    .allWorkFlows div[data-column-id='5'] {
      min-width: 12%;
      width: 12%;
      max-width: 12%;
    }

    .allWorkFlows div[data-column-id='6'] {
      min-width: 130px;
      width: 130px;
      max-width: 130px;
    }

    .allWorkFlows div[data-column-id='7'] {
      min-width: 200px;
      width: 200px;
      max-width: 200px;
    }
  }
`;

const subscriberDataSchema = z.object({
  person: z.object({
    created_at: z.string(),
    deleted_at: z.string().nullable(),
    email_address: z.string(),
    email_content_format: z.string(),
    id: z.number(),
    ip_address: z.string().nullable(),
    last_blast_click_created_at: z.string().nullable(),
    last_blast_view_created_at: z.string(),
    opt_status: z.string(),
    owner_name: z.string(),
    owner_id: z.number(),
    person_database_id: z.number(),
    soft_bounce_count: z.number(),
    source_id: z.string(),
    source_type: z.string(),
    subscribed_at: z.string(),
    tracking_hash: z.string(),
    unsubscribed_at: z.string(),
    updated_at: z.string(),
    user_agent: z.string().nullable(),
  }),
});

const getFilterResponseSchema = z.object({
  data: z.object({
    count: z.number(),
    subscriber: z.array(subscriberDataSchema),
  }),
  status: z.number(),
});

type GetFilterResponse = z.infer<typeof getFilterResponseSchema>;
type SubscriberData = z.infer<typeof subscriberDataSchema>;

const FilterSubscribers = () => {
  const [subscribersData, setSubscribersData] = useState<Array<SubscriberData>>(
    []
  );
  const [filteredSubscribers, setFilteredSubscribers] = useState<
    Array<SubscriberData>
  >([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [totalRecords, setTotalRecords] = useState<number>();
  const userCtx = useContext(UserContext);
  const {id} = useParams();
  const filterId = parseInt(id || ''); 


  const getSubscribers = async (page: number) => {
    setLoading(true);
    try {
      const getFilterSubscribersResponse = (await getFilterSubscribers(
        filterId,
        page
      )) as GetFilterResponse;
      if (getFilterSubscribersResponse.status === 200) {
        setSubscribersData(getFilterSubscribersResponse.data.subscriber);
        setFilteredSubscribers(getFilterSubscribersResponse.data.subscriber);
        setTotalRecords(getFilterSubscribersResponse.data.count);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false);
  };

  const handlePageChange = (page: number) => {
    getSubscribers(page);
  };

  const columns: TableColumn<SubscriberData>[] = useMemo(
    () => [
      {
        name: 'Email-Address',
        cell: (row) => {
          return (
            <Link
              className="flex items-center overflow-auto w-full min-w-[269px] w-[269px] py-2"
              to={
                listSegments +
                `?tab=subscriber&filterId=${row.person.id.toString()}`
              }
            >
              {row.person.email_address}
            </Link>
          );
        },
      },
      {
        name: 'Status',
        cell: (row) => {
          return (
            <p className="text-13 text-black-400 dark:text-white py-4">
              {row.person.opt_status}
            </p>
          );
        },
      },
      {
        name: 'Subscriber Date',
        cell: (row) => {
          return (
            <div>
              <p className="text-13 text-black-400 dark:text-white py-4 inline-block">
                {momentTimezone
                  .tz(
                    row.person.subscribed_at,
                    userCtx?.usersData?.timeZone || ''
                  )
                  .format('MMMM D, YYYY [at] hh:mm A')}
              </p>
            </div>
          );
        },
      },
      {
        name: 'Owner',
        cell: (row) => {
          return (
            <div>
              <p className="text-13 text-black-400 dark:text-white py-4">
                {row.person.owner_name}
              </p>
            </div>
          );
        },
        omit: !userCtx?.currentUserData?.franchise_enabled,
      },
    ],
    []
  );

  useEffect(() => {
    getSubscribers(1);
  }, []);

  useEffect(() => {
    const filteredSubscribers =
      searchQuery.length > 0
        ? subscribersData.filter((subscriber) =>
            subscriber.person.email_address
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          )
        : subscribersData;
    setFilteredSubscribers(filteredSubscribers);
  }, [searchQuery]);

  return (
    <div>
      <div  className="text-right mt-2">
        <Link
          to={`${listSegments}/${filters}/${filterId}`}
          className="text-sm font-medium leading-5 p-2 text-primary inline-block flex items-center sm:ml-auto dark:text-white"
        >
          <img
            className="mr-1 dark:invert dark:brightness-0"
            src={EditBlue}
            alt="Edit"
          />
          Edit Filter
        </Link>
      </div>
      <Form
        heading="All Subscribers"
        setSearchQuery={(query: string) => setSearchQuery(query)}
        hideFilter={true}
        onSelectExport={() => exportFilter(filterId)}
      />
      <StyledWrapper>
        <div className="grid grid-cols-1 gap-4 font-inter pt-3 overflow-hidden rounded-xl allWorkFlows">
          <Table
            className="rounded-t-2xl scrollbar-hide overflow-auto"
            data={filteredSubscribers}
            columns={columns}
            progressPending={loading}
            paginationServer
            paginationTotalRows={totalRecords}
            onChangePage={handlePageChange}
          />
        </div>
      </StyledWrapper>
    </div>
  );
};
export default FilterSubscribers;
