import React, { useEffect, useState} from 'react';
import { getAllWorkflows } from 'services/apiHandlers/Dashboard/Analytics';
import { z } from 'zod';
import { WorkflowSchema } from 'components/Workflow/Body/Workflows/index.type';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getWorkflowTransactionReports } from 'services/apiHandlers/Workflows/Workflow';
import Header from './Header';
import ReportsTable from './ReportsTable';

const workflowReportsDataSchema = z.object({
  name: z.string(),
  monetaryValue: z.number(),
  bounces: z.number(),
  clicks: z.number(),
  complaints: z.number(),
  delivered: z.number(),
  failed: z.number(),
  skipped: z.number(),
  inactiveSkipped: z.number(),
  unsubscribeSkipped: z.number(),
  totalSent: z.number(),
  uniqueClicks: z.number(),
  uniqueConversions: z.number(),
  uniquePeople: z.number(),
  uniqueViews: z.number(),
  unsubscribes: z.number(),
  views: z.number(),
  dateTime: z.number(),
});

const workflowResponseDataSchema = z.object({
  data: z.array(workflowReportsDataSchema),
  status: z.number(),
});

const AllWorkflowResponseSchema = z.object({
  data: z.object({ triggers: z.array(WorkflowSchema) }),
  status: z.number(),
});

const optionsSchema = z.object({ name: z.string(), value: z.string() });

type Options = z.infer<typeof optionsSchema>;
type WorkflowResponse = z.infer<typeof AllWorkflowResponseSchema>;
export type WorkflowType = z.infer<typeof WorkflowSchema>;
type WorkflowReportsResponse = z.infer<typeof workflowResponseDataSchema>;
export type WorkflowReportsData = z.infer<typeof workflowReportsDataSchema>;

const WorkflowReports = () => {
  const [workflows, setWorkflows] = useState<Array<WorkflowType>>([]);
  const [selectedWorkflowData, setSelectedWorkflowData] =
    useState<WorkflowType>();
  const [workflowOptions, setWorkflowOptions] = useState<Array<Options>>([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState<string>('');
  const [workflowsLoading, setWorkflowsLoading] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<string>(
    moment().format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string>(
    moment().subtract(1, 'month').format('YYYY-MM-DD')
  );
  const [workflowReports, setWorkflowReports] = useState<
    Array<WorkflowReportsData>
  >([]);
  const [reportsLoading, setReportsLoading] = useState<boolean>(true);
  const { id } = useParams();

  const onWorkflowSelect = (id: string) => {
    const filteredWorkflow = workflows.filter((workflow) => workflow.id === id);
    setSelectedWorkflow(filteredWorkflow[0].name || '');
    setSelectedWorkflowData(filteredWorkflow[0]);
    setStartDate(moment().format('YYYY-MM-DD'));
    setEndDate(moment().subtract(1, 'month').format('YYYY-MM-DD'));
  };

  const getWorkflowReports = async (startDate: string, endDate: string) => {
    setReportsLoading(true);
    try {
      if (selectedWorkflowData?.id) {
        const getWorkflowReportResponse = (await getWorkflowTransactionReports(
          selectedWorkflowData?.id || '',
          startDate,
          endDate
        )) as WorkflowReportsResponse;
        if (getWorkflowReportResponse.status === 200) {
          if (getWorkflowReportResponse.data.length > 0) {
            setWorkflowReports(getWorkflowReportResponse.data);
          }
        }
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setReportsLoading(false);
  };

  const getWorkflows = async () => {
    try {
      const getWorkflowsRequest = (await getAllWorkflows()) as WorkflowResponse;
      if (getWorkflowsRequest.status === 200) {
        setWorkflows(getWorkflowsRequest.data.triggers);

        const tempWorkflowOptions = getWorkflowsRequest.data.triggers.map(
          (trigger) => {
            return {
              name: trigger.name || '',
              value: trigger.id?.toString() || '',
            };
          }
        );

        setWorkflowOptions(tempWorkflowOptions);

        if (id) {
          const filteredWorkflow = getWorkflowsRequest.data.triggers.filter(
            (trigger) => trigger.id === id
          );
          setSelectedWorkflowData(filteredWorkflow[0]);
          setSelectedWorkflow(filteredWorkflow[0].name || '');
        } else {
          setSelectedWorkflowData(getWorkflowsRequest.data.triggers[0]);
          setSelectedWorkflow(tempWorkflowOptions[0].name);
        }
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setWorkflowsLoading(false);
  };

  useEffect(() => {
    getWorkflows();
  }, []);

  useEffect(() => {
    if (startDate !== '' && endDate !== '') {
      getWorkflowReports(
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD')
      );
    }
  }, [startDate, endDate, selectedWorkflowData]);

  return (
    <div>
      <div className="mt-6 ml-2">
        <Breadcrumbs />
      </div>
      <Header
        workflowsLoading={workflowsLoading}
        selectedWorkflowData={selectedWorkflowData}
        workflowOptions={workflowOptions}
        selectedWorkflow={selectedWorkflow}
        onWorkflowSelect={onWorkflowSelect}
      />
      <ReportsTable
        reportsLoading={reportsLoading}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        workflowReports={workflowReports}
      />
    </div>
  );
};
export default WorkflowReports;
