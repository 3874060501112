import React from 'react';
import BigArrow from 'assets/Images/Workflow/downarrow-big.png';
const Connector = () => {
  return (
    <div>
      <img className="mx-auto" src={BigArrow} alt="Arrow Down" />
    </div>
  );
};
export default Connector;
