import EditBlue from 'assets/Images/Common/edit-blue.png';
import Check from 'assets/Images/Filter/check-icon.png';
import { useEffect, useState } from 'react';
import {
  getFilterById,
  getFilterCount,
} from 'services/apiHandlers/List-Segments/Filter';
import ErrorBoundary from 'components/ErrorBoundary';
import { z } from 'zod';
import { getFilterDataSchema } from './Types';
import { Link, useParams } from 'react-router-dom';
import { listSegments, filters, matches } from 'services/constant/routes';
import moment from 'moment';
import React from 'react';

const getFilterResponseSchema = z.object({
  data: getFilterDataSchema,
  status: z.number(),
});

const RuleSchema = z.object({
  comparison_operator: z.string(),
  comparison_value: z.string(),
});

const RulesSchema = z.record(RuleSchema);

const getCountFilterResponseSchema = z.object({
  data: z.number(),
  status: z.number(),
});

type GetCountFilterResponse = z.infer<typeof getCountFilterResponseSchema>;
type EditRule = z.infer<typeof RulesSchema>;
type GetFilterResponse = z.infer<typeof getFilterResponseSchema>;
type FilterData = z.infer<typeof getFilterDataSchema>;

const ViewFilter = () => {
  const [filtersData, setFiltersData] = useState<FilterData>();
  const [filterCount, setFilterCount] = useState<number>();
  const { id } = useParams();
  const filterId = parseInt(id || '');

  const getFilterData = async () => {
    try {
      const getFiltersResponse = (await getFilterById(
        filterId.toString()
      )) as GetFilterResponse;
      if (getFiltersResponse.status === 200) {
        setFiltersData(getFiltersResponse.data);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const getRulesData = (data: FilterData) => {
    const filteredRules = data.segments.map((segment) => {
      return segment.rules;
    });
    const allRules = filteredRules.flat();

    const rules: EditRule = {};

    allRules.map((item) => {
      const ruleId = item.id.toString();

      rules[ruleId] = {
        comparison_operator: item.comparison_operators.selected,
        comparison_value: item.comparison_value,
      };
    });
    return rules;
  };

  const getCountHandler = async () => {
    if (filtersData) {
      try {
        const getCountFilterResponse = (await getFilterCount(
          filterId,
          filtersData.restored ? 'draft' : 'final',
          { rules: getRulesData(filtersData) }
        )) as GetCountFilterResponse;
        if (getCountFilterResponse.status === 200) {
          setFilterCount(getCountFilterResponse.data);
        }
      } catch (error) {
        console.log('error is : ', error);
      }
    }
  };

  useEffect(() => {
    getFilterData();
  }, []);

  return (
    <ErrorBoundary>
      <div className="md:px-6 px-3 py-5 font-inter bg-gray-400 h-screen dark:bg-black-400">
        <div className="md:px-1.5">
          <div className="flex justify-between items-center mb-4">
            <>
              <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black dark:text-white font-medium">
                {filtersData?.name}
              </h2>
              <p className="text-sm font-normal leading-4 text-black-400 dark:text-white mt-0.5">
                created about
                <span className="ml-1">
                  {moment
                    .duration(
                      moment().diff(
                        moment
                          .utc(filtersData?.created_at)
                          .local()
                          .format('YYYY-MM-DD HH:mm:ss')
                      )
                    )
                    .humanize()}
                </span>
                ago
              </p>
            </>
            <div>
              {Number.isInteger(filterCount) ? (
                <div>{filterCount}</div>
              ) : (
                <div className="flex sm:justify-center justify-end items-center sm:w-4/12 w-6/12">
                  <span className="w-5 h-5 rounded-full">
                    <img className="w-full" src={Check} alt="Check" />
                  </span>
                  <span
                    onClick={getCountHandler}
                    className="text-base font-medium leading-5 text-black-700 dark:text-white ml-2"
                  >
                    Count?
                  </span>
                </div>
              )}
              <Link
                to={`${listSegments}/${filters}/${matches}/${filterId}`}
                className="cursor-pointer"
              >
                View Checks
              </Link>
            </div>
          </div>
          <>
            {filtersData?.segments?.map((segment, index) => (
              <div key={segment.id}>
                <div className="md:px-6 px-3 pt-4 pb-6 bg-white rounded-lg boxShadow">
                  <h3 className="md:text-base text-sm font-gilroyBold leading-6 text-424242">
                    {segment.name}
                  </h3>
                  {segment?.rules?.map((rule, ruleIndex) => (
                    <div key={rule.id} className="flex items-center mt-1">
                      <p className="sm:text-sm text-[13px] font-normal leading-5 text-424242 mr-2">
                        is equal to {rule.comparison_value}
                      </p>
                      {segment.rules.length - 1 !== ruleIndex && (
                        <p className="sm:text-base text-sm font-normal leading-4 text-616161">
                          {segment.logical_operator}
                        </p>
                      )}
                    </div>
                  ))}
                </div>

                {filtersData.segments.length - 1 !== index && (
                  <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-ryzeoBlue text-center my-4">
                    {filtersData.logical_operator}
                  </h2>
                )}
              </div>
            ))}

            <div className="text-right mt-2">
              <Link
                to={`${listSegments}/${filters}/${filterId}`}
                className="text-sm font-medium leading-5 p-2 text-primary inline-block flex items-center sm:ml-auto dark:text-white"
                type="button"
              >
                <img
                  className="mr-1 dark:invert dark:brightness-0"
                  src={EditBlue}
                  alt="Edit"
                />
                Edit Filter
              </Link>
            </div>
          </>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default ViewFilter;
