import { FC, useEffect, useState } from 'react';
import Email from './Email';
import Insights from './Insights';
import { getcampaignEmails } from 'services/apiHandlers/Campaigns/Emails';
import { z } from 'zod';
import React from 'react';

const emailTriggerSchema = z.object({ name: z.string(), id: z.number() });

const campaignEmailSchema = z.object({
  id: z.number(),
  name: z.string(),
  created_at: z.string(),
  html_part_present: z.boolean(),
  html_part_blank: z.boolean(),
  include_event: z.boolean(),
  needs_widgetization: z.boolean(),
  widgetization_error: z.boolean(),
  new_editor_compatible: z.boolean(),
  text_part_present: z.boolean(),
  text_part_blank: z.boolean(),
  email_triggers: z.array(emailTriggerSchema),
});

const campaignResponseSchema = z.object({
  data: z.object({
    emails: z.array(campaignEmailSchema),
    readonly_mode: z.boolean(),
    total_entries: z.number(),
  }),
  status: z.number(),
});

const pageDataSchema = z.object({ page: z.number(), perPage: z.number() });

type PageData = z.infer<typeof pageDataSchema>;
type SinglecampaignEmail = z.infer<typeof campaignEmailSchema>;
type campaignResponse = z.infer<typeof campaignResponseSchema>;
interface Props {
  activeTab: string;
  filterValue: string;
  label: string | undefined;
}

const Body: FC<Props> = ({ activeTab, filterValue, label }) => {
  const [emails, setEmails] = useState<Array<SinglecampaignEmail>>([]);
  const [insights, setInsights] = useState<Array<SinglecampaignEmail>>([]);
  const [readonly, setReadonly] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [paginationData, setPaginationData] = useState<PageData>({
    page: 1,
    perPage: 10,
  });
  const [totalRecords, setTotalRecords] = useState<number>(0);


  const getData = async (
    searchedValue?: string,
    preventLoading?: boolean,
    label?: string
  ) => {
    const tempInsights: SinglecampaignEmail[] = [];
    const tempEmails: SinglecampaignEmail[] = [];
    try {
      if (!preventLoading) {
        setLoading(true);
      }
      const getEmailresponse = (await getcampaignEmails(
        paginationData,
        searchedValue,
        label
      )) as campaignResponse;
      if (getEmailresponse.status === 200) {
        getEmailresponse?.data?.emails?.map((data) => {
          if (data.html_part_present) {
            if (!data.include_event) {
              tempEmails.push(data);
            } else if (data.include_event) {
              tempInsights.push(data);
            }
          }
        });
        setTotalRecords(getEmailresponse.data.total_entries);
        setEmails(tempEmails);
        setInsights(tempInsights);
        setReadonly(getEmailresponse.data.readonly_mode);
      }
    } catch (error) {
      console.log('error is: ', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  },[]);

  useEffect(() => {
    getData(filterValue, true);
  },[filterValue]);

  useEffect(() => {
    if (label) {
      getData(undefined, true, label);
    }
  },[label]);

  useEffect(() => {
    getData(filterValue, true, label);
  },[paginationData]);

  return (
    <div className="md:px-6 px-3 py-5 font-inter bg-gray-400 dark:bg-slate-800 h-full">
      <div className="md:px-1.5  mb-16">
        {activeTab === 'email' ? (
          <Email
            emails={emails}
            readonly={readonly}
            loading={loading}
            total={totalRecords}
            onDelete={() => getData('', true)}
            onPageChange={(page: number) =>
              setPaginationData({ page: page, perPage: 10 })
            }
          />
        ) : (
          <Insights
            insights={insights}
            readonly={readonly}
            loading={loading}
            onDelete={() => getData('', true)}
          />
        )}
      </div>
    </div>
  );
};
export default Body;
