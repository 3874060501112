import { useContext, useEffect, useState } from 'react';
import ToggleSwitch from 'ToggleSwitch/ToggleSwitch';
import { useFormik, FormikValues } from 'formik';
import { CampaignContext } from 'store/CampaignContext';
import React from 'react';

const googleAnalytics = [
  { label: 'Campaign Source: *', name: 'utm_source' },
  { label: 'Campaign Medium: *', name: 'utm_medium' },
  { label: 'Campaign Content:', name: 'utm_content' },
  { label: 'Campaign Name:', name: 'utm_campaign' },
];

const GoogleAnalytics = () => {
  const [showAnalytics, setShowAnalytics] = useState<boolean>(false);
  const campaignCtx = useContext(CampaignContext);
  const formik: FormikValues = useFormik({
    initialValues: {
      utm_campaign: '',
      utm_medium: '',
      utm_content: '',
      utm_source: '',
    },
    onSubmit: () => console.log(),
  });

  useEffect(() => {
    if (campaignCtx?.campaignData) {
      campaignCtx?.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        ...formik.values,
        google_analytics: showAnalytics,
      });
    }
  }, [showAnalytics, formik.values]);

  useEffect(() => {
    if (campaignCtx?.campaignData) {
      formik.setFieldValue('utm_campaign', campaignCtx?.campaignData.subject);
      campaignCtx?.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        utm_campaign: campaignCtx?.campaignData.subject,
      });
    }
  }, [campaignCtx?.campaignData?.subject]);

  useEffect(() => {
    if (campaignCtx?.initialData && campaignCtx?.campaignData) {
      formik.setValues(campaignCtx.initialData.campaignData);
      campaignCtx?.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        utm_source: campaignCtx.initialData.campaignData.utm_source,
      });
      setShowAnalytics(campaignCtx.initialData.googleAnalytics)
    }
    }, [campaignCtx?.initialData]);
    
  return (
    <div className="lg:w-6/12 w-full sm:pl-5 mb-6">
      <div className="flex">
        <div className="max-w-md w-full">
          <p className="text-sm font-semibold leading-4 mb-2 text-black-400 dark:text-white">
            Integrate Google Analytics URLs
          </p>
          <p className="text-xs font-normal leading-4 text-black-300 dark:text-white mt-2 pr-1">
            Google Analytics Integration automatically modifies all links in
            your HTML email with campaign parameters.{' '}
            <span className="font-semibold">
              Your website must have Google Analytics Integrated.
            </span>
          </p>
          <p className="text-xs font-normal leading-4 text-black-300 dark:text-white mt-2 pr-1">
            Sample Link URL
            <span className="font-medium break-all">
              :
              http://www.example.com?utm_source=email_marketing_system&amp;utm_medium=email&amp;utm_content=%7B%7B%7Bblast_id%7D%7D%7D&amp;utm_campaign=photo
            </span>
          </p>
        </div>
        <div>
          <ToggleSwitch
            onChange={() => setShowAnalytics((prev) => !prev)}
            checked={showAnalytics}
          />
        </div>
      </div>
      {showAnalytics && (
        <div className="mt-4">
          {googleAnalytics.map((input) => (
            <div key={input.name} className="flex items-center mt-4">
              <label className="text-xs font-semibold leading-4 text-black-400 dark:text-white xl:w-40 lg:w-32 w-20">
                {input.label}
              </label>
              <input
                className="border border-gray-800 rounded-md w-full h-11 p-4 sm:w-64 w-8/12 dark:bg-slate-800 focus:outline-none dark:border-black-400 dark:text-white"
                type="text"
                id={input.name}
                name={formik[input.name]}
                value={formik.values[input.name]}
                onChange={formik.handleChange}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default GoogleAnalytics;
