import { FC } from 'react';
import Overview from './Overview';
import React from "react"

interface Props {
  activeTab: string;
}

const Body: FC<Props> = () => {

  return (
    <div className="md:px-6 px-3 py-5 font-inter bg-gray-400 dark:bg-slate-800 h-full ">
      <div className="md:px-1.5  mb-16">
        <Overview/>
      </div>
    </div>
  );
};
export default Body;
