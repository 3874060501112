import { useEffect, useState } from 'react';
import CopyPasteHtml from './CopyPasteHtml';
import ImportWebPage from './ImportWebPage';
import UploadFile from './UploadFile';
import { z } from 'zod';
import { getMailMergeOptions } from 'services/apiHandlers/Campaigns/Emails';
import React from 'react';

const getMergeOptionResponseSchema = z.object({
  data: z.any(),
  status: z.number(),
});
type getMergeOptionResponse = z.infer<typeof getMergeOptionResponseSchema>;

const showSectionSchema = z.object({
  showCopyPaste: z.boolean(),
  showImportWeb: z.boolean(),
  showUploadFile: z.boolean(),
});
type ShowSection = z.infer<typeof showSectionSchema>;

const OwnHtml = () => {
  const [showSection, setShowSection] = useState<ShowSection>({
    showCopyPaste: false,
    showImportWeb: false,
    showUploadFile: false,
  });
  const [groups, setGroups] = useState<Array<string>>([]);
  const [groupOptions, setGroupOptions] = useState<Array<Array<string>>>([]);

  const getData = async () => {
    try {
      const getMergeOptionsResponse =
        (await getMailMergeOptions()) as getMergeOptionResponse;
      const tempGroups: string[] = [];
      const tempOptions: string[][] = [];
      if (getMergeOptionsResponse.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getMergeOptionsResponse.data.map((options: any) => {
          tempGroups.push(options[0]);
          tempOptions.push(options[1]);
        });
      }
      setGroupOptions(tempOptions);
      setGroups(tempGroups);
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="overflow-auto pb-2">
      <p className="pl-7 text-sm font-normal leading-5 text-616161 mt-1">
        Copy-paste, Import from the web, or upload from a file
      </p>
      <div>
        <CopyPasteHtml
          onCopyShow={() => {
            setShowSection({ ...showSection, showCopyPaste: true });
          }}
          showContent={showSection.showCopyPaste}
          groups={groups}
          options={groupOptions}
        />
        <ImportWebPage
          showContent={showSection.showImportWeb}
          onWebShow={() =>
            setShowSection({ ...showSection, showImportWeb: true })
          }
        />
        <UploadFile
          showContent={showSection.showUploadFile}
          onUploadFile={() =>
            setShowSection({ ...showSection, showUploadFile: true })
          }
        />
      </div>
    </div>
  );
};
export default OwnHtml;
