import httpService from 'services/core/HttpService';
import {
  GET_campaign_EMAILS,
  GET_EMAIL,
  AUTOSAVE_EMAIL,
  SAVE_EMAIL,
  DELETE_EMAIL,
  TEST_EMAIL,
  GET_USERS,
  CREATE_NEW_EMAIL,
  COPY_PASTE_HTML,
  FETCH_HTML,
  UPLOAD_FILE,
  GET_TEMPLATE,
  GET_CURRENT_USER,
  GET_MAIL_MERGE_OPTIONS,
  CREATE_CAMPAIGN,
  VERIFY_EMAIL,
  RESEND_EMAIL_VERIFICATION,
  GET_NEW_CAMPAIGN_DATA,
  GET_POPULAR_TAGS,
  GET_EDITOR_IMAGES,
  DELETE_EDITOR_IMAGE,
  UPLOAD_EDITOR_IMAGE,
  CREATE_TRIGGER,
  GET_TEMPLATE_BY_ID,
} from 'services/constant/apiRoutes';
import {
  CreateCampaign,
  CreateTrigger,
} from 'components/NewCampaign/index.type';
import { z } from 'zod';

const sendTemplateDataSchema = z.object({
  html_length: z.string(),
  blank_template_id: z.number(),
  new_template_id: z.number(),
  new_template_type: z.string(),
  old_template_id: z.string(),
});
type SendTemplateData = z.infer<typeof sendTemplateDataSchema>;

const autoSaveDataSchema = z.object({
  draft: z.object({ html_part: z.string(), updated_at: z.string() }),
});
type AutosaveData = z.infer<typeof autoSaveDataSchema>;

const saveDataSchema = z.object({
  draft: z.object({
    html_part: z.string(),
    name: z.string(),
    tag_list: z.string(),
  }),
  options: z.object({ save_over: z.boolean() }),
  commit: z.string(),
});
type SaveData = z.infer<typeof saveDataSchema>;

const sendTestEmailSchema = z.object({
  to: z.string(),
  subject: z.string(),
  from: z.object({
    email_address: z.string(),
    name: z.string(),
  }),
  body: z.object({
    email_id: z.string(),
  }),
  email_draft_in_session: z.boolean(),
});
type SendTest = z.infer<typeof sendTestEmailSchema>;

const copyPasteHtmlDataSchema = z.object({
  copy_paste: z.string(),
  commit: z.string(),
});
type CopyPasteData = z.infer<typeof copyPasteHtmlDataSchema>;

const fetchHtmlSchema = z.object({ url: z.string(), commit: z.string() });
type FetchHtml = z.infer<typeof fetchHtmlSchema>;

const resendEmailDataSchema = z.object({
  from_email_address: z.string(),
});
type ResendEmail = z.infer<typeof resendEmailDataSchema>;

const pageDataSchema = z.object({ page: z.number(), perPage: z.number() });

type PageData = z.infer<typeof pageDataSchema>;

export const getcampaignEmails = (
  paginationData?: PageData,
  filter?: string,
  label?: string
) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(
        GET_campaign_EMAILS +
          `${
            paginationData
              ? `?page=${paginationData?.page}&per_page=${paginationData?.perPage}`
              : ''
          }` +
          `${
            label
              ? `&label=${label}`
              : `${filter ? `&filter_value=${filter}` : ''}`
          } `
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEmail = (id: string, force?: boolean) => {
  const campaignEmail = force ? `${GET_EMAIL + id}?force=true` : GET_EMAIL + id;

  return new Promise((resolve, reject) => {
    httpService
      .get(campaignEmail)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const autosaveEmail = (data: AutosaveData) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(AUTOSAVE_EMAIL, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const saveEmail = (data: SaveData) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(SAVE_EMAIL, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteEmail = (id: string) => {
  const deleteEmailUrl = DELETE_EMAIL + id;
  return new Promise((resolve, reject) => {
    httpService
      .delete(deleteEmailUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const sendTestEmail = (data: SendTest) => {
  const sendData = { blast: data };
  return new Promise((resolve, reject) => {
    httpService
      .post(TEST_EMAIL, sendData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUsers = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_USERS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createNewEmail = (newMail: boolean) => {
  const createNewMailUrl = newMail
    ? CREATE_NEW_EMAIL + '?force=true'
    : CREATE_NEW_EMAIL;
  return new Promise((resolve, reject) => {
    httpService
      .get(createNewMailUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const copyPasteHtmlHandler = (data: CopyPasteData) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(COPY_PASTE_HTML, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchHtml = (data: FetchHtml) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(FETCH_HTML, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadFile = (uploadedfile: File) => {
  const data = { file: uploadedfile };
  return new Promise((resolve, reject) => {
    httpService
      .post(UPLOAD_FILE, data, {
        headers: {
          'Content-Type': 'multipart/form-data; ',
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTemplate = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_TEMPLATE)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_CURRENT_USER)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getMailMergeOptions = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_MAIL_MERGE_OPTIONS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createCampaign = (data: CreateCampaign) => {
  const createData = { blast: data };
  return new Promise((resolve, reject) => {
    httpService
      .post(CREATE_CAMPAIGN, createData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const verifyEmail = (email: string) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(VERIFY_EMAIL + email)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const resendeEmailVerification = (data: ResendEmail) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(RESEND_EMAIL_VERIFICATION, data ,{headers: {Accept: "application/json"}})
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getNewCampaignData = (id: string) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_NEW_CAMPAIGN_DATA + id)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPopularTags = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_POPULAR_TAGS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEditorImages = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_EDITOR_IMAGES)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteEditorImage = (deleteImage: string[]) => {
  const data = { image: deleteImage[0], thumbnail: deleteImage[1] };
  return new Promise((resolve, reject) => {
    httpService
      .post(DELETE_EDITOR_IMAGE, data, {
        headers: {
          'Content-Type': 'multipart/form-data; ',
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadEditorImage = (uploadFile: File) => {
  const data = { uploads: uploadFile };
  return new Promise((resolve, reject) => {
    httpService
      .post(UPLOAD_EDITOR_IMAGE, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const sendTemplate = (data: SendTemplateData) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(GET_TEMPLATE, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createTrigger = (data: CreateTrigger) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(CREATE_TRIGGER, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTemplateById = (id: string) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_TEMPLATE_BY_ID + id)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}