import Tabs from 'components/Tabs/Tabs';
import { useEffect, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import Body from 'components/Integration/Body';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';

enum TabsEnum {
  CART = 'cart-integration',
  SCRIPTS = 'scripts',
  GOOGLE = 'google',
  MISCELLANEOUS = 'miscellaneous',
}

const tabsData = [
  { title: 'Cart Integration', searchQuery: TabsEnum.CART },
  { title: 'Scripts', searchQuery: TabsEnum.SCRIPTS },
  { title: 'Google Analytics', searchQuery: TabsEnum.GOOGLE },
  { title: 'Miscellaneous', searchQuery: TabsEnum.MISCELLANEOUS },
];

const Integration = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<string>(TabsEnum.CART);

  const onTabChangeHandler = (tabQuery: string) => {
    setSearchParams(createSearchParams({ tab: tabQuery }));
  };

  useEffect(() => {
    setActiveTab(searchParams.get('tab') || '');
    if (!searchParams.get('tab')) {
      setSearchParams(createSearchParams({ tab: TabsEnum.CART }));
    }
  }, [searchParams]);

  return (
    <ErrorBoundary>
        <div>
          <div className="md:px-6 px-3 pt-5 border-b dark:border-black-400 font-inter">
            <div className="md:px-1.5">
              <Breadcrumbs />
              <h2 className="sm:text-xl text-base leading-5 text-black-700 dark:text-white ml-2.5 font-medium">
                Integration
              </h2>
              <Tabs
                tabs={tabsData}
                activeTab={activeTab}
                onTabChange={onTabChangeHandler}
              />
            </div>
          </div>
          <Body activeTab={activeTab} />
        </div>
    </ErrorBoundary>
  );
};
export default Integration;
