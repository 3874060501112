import { FC } from 'react';
import 'chart.js/auto';
import {
  Chart as ChartJS,
  ChartDataset,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'store/ThemeContext';
import {z} from "zod";
import ErrorBoundary from 'components/ErrorBoundary';
import React from "react";

const chartStyleSchema = z.object({
  maxWidth: z.string().optional(),
  width: z.string().optional(),
  height: z.string().optional(),
  padding: z.string().optional(),
});

type chartStyle = z.infer<typeof chartStyleSchema>

interface Props {
  stacked?: boolean;
  chartLabels: string[] | undefined | (string | undefined)[] | undefined;
  datasets: ChartDataset<'bar', (number | [number, number] | null)[]>[];
  height?: number | string;
  style?: chartStyle;
}

const BarGraph: FC<Props> = ({
  chartLabels,
  datasets,
  stacked,
  height,
  style,
}) => {
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx?.theme;
  ChartJS.register(
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    Title
  );
  const labels = chartLabels;
  const options = {
    showTooltips: true,
    maintainAspectRatio: false,
    aspectRatio: 2.7,
    responsive: true,
    plugins: {
      title: {
        display: true,
      },
      legend: {
        margin: {
          top: 60,
        },
        display: true,
        position: 'bottom' as const,
        align: 'center' as const,
        labels: {
          usePointStyle: true,
          boxWidth: 100,
          color: `${theme === 'dark' ? 'white' : ''}`,
          padding: 20,
          textAlign: 'left' as const,
          //commented for future use for legend
          
          // generateLabels: function (chart: any) {
          //   const dataset = chart.data.datasets;
          //   //console.log('chart : ', dataset);
          //   return dataset.map((item: any, index: number) => {
          //     console.log("chart maps: " , item , index , item[index])
          //     return {
          //       text: `${item.label}`,
          //       fillStyle: '#8FDBC0',
          //       strokeStyle: '#8FDBC0',
          //       lineWidth: '1',
          //       align: 'center' as const,
          //       //hidden: isNaN(item),
          //     };
          //   });
          //  },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        stacked: stacked,
        categorySpacing: 0,
        ticks: {
          color: `${theme === 'dark' ? 'white' : ''}`,
          beginAtZero: true,
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        stacked: stacked,
        categorySpacing: 0,
        ticks: {
          color: `${theme === 'dark' ? 'white' : ''}`,
          beginAtZero: true,
        },
        grid: {
          display: true,
          drawOnChartArea: true,
        },
      },
    },
  };
  const data = {
    labels,
    datasets: datasets,
  };

  const StyledWrapper = styled.div``;
  return (
    <ErrorBoundary>
    <StyledWrapper className="h-full">
      <div className="h-full">
        {datasets && (
          <Bar
            redraw={true}
            height={height}
            options={options}
            data={data}
            style={style}
          />
        )}
      </div>
    </StyledWrapper>
    </ErrorBoundary>
  );
};
export default BarGraph;
