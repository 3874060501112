import { z } from 'zod';

const validateBigCommerceSchema = z.object({
  accessToken: z.string(),
  id: z.string().optional(),
  clientId: z.string(),
  shouldGrabOrderHistory: z.boolean(),
  storeHash: z.string(),
  uniqueIdConstraint: z.string(),
  _type: z.string(),
});
type validateBigCommerce = z.infer<typeof validateBigCommerceSchema>;

const validateMagentoSchema = z.object({
  apiKey: z.string(),
  id: z.string(),
  shouldGrabOrderHistory: z.boolean(),
  storeUrl: z.string(),
  uniqueIdConstraint: z.string(),
  userName: z.string(),
  _type: z.string(),
});

const validateMagento2Schema = z.object({
  adminPassword: z.string(),
  id: z.string(),
  shouldGrabOrderHistory: z.boolean(),
  storeUrl: z.string(),
  uniqueIdConstraint: z.string(),
  adminUser: z.string(),
  _type: z.string(),
});

const validateShopifySchema = z.object({
  APIkey: z.string(),
  domainUrl: z.string(),
  excludeProduct: z.string(),
  excludeProductsList: z.union([z.array(z.string()), z.tuple([])]),
  formAutoEmailCapture: z.boolean().optional(),
  id: z.string(),
  password: z.string(),
  publishEvents: z.boolean(),
  sendAnonymousEventsAfterIdentification: z.boolean().optional(),
  sharedSecret: z.string(),
  shouldGrabOrderHistory: z.boolean(),
  showExcludeProductSection: z.boolean(),
  storeName: z.string(),
  storeUrl: z.string(),
  uniqueIdConstraint: z.string(),
  _type: z.string(),
});

const validateBigCommerceLegacySchema = z.object({
  apiToken: z.string(),
  id: z.string().optional(),
  shouldGrabOrderHistory: z.boolean(),
  storeId: z.string(),
  storeUrl: z.string(),
  uniqueIdConstraint: z.string(),
  username: z.string(),
  _type: z.string(),
});
type validateBigCommerceLegacy = z.infer<
  typeof validateBigCommerceLegacySchema
>;

const validateWooCommerceSchema = z.object({
  id: z.string(),
  key: z.string(),
  secret: z.string(),
  shouldGrabOrderHistory: z.boolean(),
  storeDomain: z.string(),
  uniqueIdConstraint: z.string(),
  _type: z.string(),
});

const magentoSchema = z.object({
  storeUrl: z.string(),
  userName: z.string(),
  apiKey: z.string(),
  _type: z.string(),
});
type MagentoType = z.infer<typeof magentoSchema>;

const magento2Schema = z.object({
  storeUrl: z.string(),
  adminPassword: z.string(),
  adminUser: z.string(),
  _type: z.string(),
});
type Magento2Type = z.infer<typeof magento2Schema>;

const wooCommmerceSchema = z.object({
  storeDomain: z.string(),
  key: z.string(),
  secret: z.string(),
  _type: z.string(),
});
type wooCommerceType = z.infer<typeof wooCommmerceSchema>;

const shopifySchema = z.object({
  APIkey: z.string(),
  password: z.string(),
  storeUrl: z.string(),
  sharedSecret: z.string(),
  shouldGrabOrderHistory: z.boolean(),
  showExcludeProductSection: z.boolean(),
  uniqueIdConstraint: z.string(),
  formAutoEmailCapture: z.boolean(),
  sendAnonymousEventsAfterIdentification: z.boolean(),
  excludeProductsList: z.union([z.array(z.string()), z.tuple([])]),
  publishEvents: z.boolean(),
  _type: z.string(),
});
type shopifyType = z.infer<typeof shopifySchema>;

export type integration =
  | z.infer<typeof validateBigCommerceSchema>
  | z.infer<typeof validateMagentoSchema>
  | z.infer<typeof validateMagento2Schema>
  | z.infer<typeof validateShopifySchema>
  | z.infer<typeof validateBigCommerceLegacySchema>
  | z.infer<typeof validateWooCommerceSchema>;

const DefaultMagento: MagentoType = {
  storeUrl: '',
  userName: '',
  apiKey: '',
  _type: 'magento1',
};

const DefaultMagento2: Magento2Type = {
  storeUrl: '',
  adminPassword: '',
  adminUser: '',
  _type: 'magento2',
};

const DefaultWooCommerce: wooCommerceType = {
  storeDomain: '',
  key: '',
  secret: '',
  _type: 'woocommerce',
};

const DefaultShopify: shopifyType = {
  APIkey: '',
  password: '',
  storeUrl: '',
  sharedSecret: '',
  shouldGrabOrderHistory: true,
  showExcludeProductSection: true,
  uniqueIdConstraint: 'composite',
  formAutoEmailCapture: true,
  sendAnonymousEventsAfterIdentification: true,
  excludeProductsList: [],
  publishEvents: false,
  _type: 'shopify',
};

const DefaultBigCommerce: validateBigCommerce = {
  accessToken: '',
  clientId: '',
  shouldGrabOrderHistory: true,
  storeHash: '',
  uniqueIdConstraint: 'composite',
  _type: 'bigcommerce',
};

const DefaultBigCommerceLegacy: validateBigCommerceLegacy = {
  apiToken: '',
  shouldGrabOrderHistory: true,
  storeId: 'null',
  storeUrl: '',
  uniqueIdConstraint: 'composite',
  username: '',
  _type: 'bigcommerce_legacy',
};

export const cartOptions = [
  { name: 'Shopify', value: DefaultShopify },
  { name: 'Big Commerce', value: DefaultBigCommerce },
  { name: 'Big Commerce Legacy', value: DefaultBigCommerceLegacy },
  { name: 'Woo Commerce', value: DefaultWooCommerce },
  { name: 'Magento1', value: DefaultMagento },
  { name: 'Magento 2', value: DefaultMagento2 },
];
