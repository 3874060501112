import WorkflowTriggerData from './WorkflowTriggerData';
import HeaderInformation from './HeaderInformation';
import React from "react"

const Header = () => {
  return (
    <>
      <div className="mb-4 sm:mt-0 mt-7"></div>
      <HeaderInformation />
      <WorkflowTriggerData />
    </>
  );
};
export default Header;
