import { useOutsideClick } from 'app/hooks/useOutsideClick';
import Close from 'assets/Images/Workflow/close-Icon.png';
import { FC, useRef } from 'react';
import React from "react"
interface Props {
  onClose: () => void;
  children: JSX.Element;
  disabled?: boolean;
  onSave?: () => void;
  buttonTxt?: string;
  cancelButtonTxt?:string
}
const ModalWrapper: FC<Props> = ({
  onClose,
  children,
  disabled,
  onSave,
  buttonTxt,
  cancelButtonTxt
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useOutsideClick(modalRef, onClose);

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        ref={modalRef}
        className="w-full max-w-xl mx-auto scrollbar-hide h-[90vh] overflow-auto rounded-lg flex items-center justify-center"
      >
        <div className="relative">
          <img
            src={Close}
            onClick={() => onClose()}
            className="absolute top-0 right-0 mt-2 mr-2 border-none cursor-pointer dark:invert dark:brightness-0"
          />
        </div>
        <div className="bg-white rounded-lg w-full">
          <div className="bg-white rounded-t-lg p-5 dark:bg-black-400">
            {children}
          </div>
          <div className="border-t border-gray-200"></div>
          <div className="bg-white rounded-b-lg py-3 px-5 dark:bg-black-400">
            <div className="flex justify-end items-center">
              {
                <button
                  onClick={() => onClose()}
                  className="dark:bg-white text-sm font-medium leading-5 text-primary py-2.5 px-4 border-2 border-gray-200 rounded-md mr-4 ease-in duration-300 w-28 h-11 hover:scale-105 dark:hover:bg-primary"
                >
                  {cancelButtonTxt || "Cancel"}
                </button>
              }
              <button
                type="submit"
                disabled={disabled}
                onClick={() => onSave && onSave()}
                className={`text-sm font-medium leading-5 rounded-md px-4 py-2.5 w-28 h-11 ${
                  disabled
                    ? 'border border-gray-800 bg-gray-200 text-black-400'
                    : 'bg-btnPrimary hover:bg-primary text-white hover:scale-105 ease-in duration-300'
                }`}
              >
                {buttonTxt || 'Save'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalWrapper;
