import Dropdown from 'components/Dropdown/Dropdown';
import { useContext, useState } from 'react';
import { CampaignContext } from 'store/CampaignContext';
import React from "react"

const days = ['before', 'after', 'on'];
const dates = ['Subscriber Date', 'Last Clicked Date', 'Last Viewed Date'];
const repeats = ['does not repeat', 'repeats monthly', 'repeats yearly'];

interface DateType {
  [key: string]: number;
  ['Subscriber Date']: number;
  ['Last Clicked Date']: number;
  ['Last Viewed Date']: number;
}
interface FrequencyType {
  [key: string]: string;
  ['does not repeat']: string;
  ['repeats monthly']: string;
  ['repeats yearly']: string;
}

const selectedDate: DateType = {
  ['Subscriber Date']: -1,
  ['Last Clicked Date']: -3,
  ['Last Viewed Date']: -4,
};

const selectedFrequency: FrequencyType = {
  ['does not repeat']: 'does not repeat',
  ['repeats monthly']: 'monthly',
  ['repeats yearly']: 'yearly',
};

const ScheduleDays = () => {
  const [daysData, setDaysData] = useState({
    relativity: days[1],
    id: -1,
    frequency: repeats[0],
    offset: 0,
  });
  const campaignCtx = useContext(CampaignContext);

  const onSelectHandler = (value: string | number, name: string) => {
    let finalValue = value;
    if (name === 'id' && typeof finalValue === 'string') {
      finalValue = selectedDate[finalValue];
    }
    if (name === 'frequency' && typeof finalValue === 'string') {
      finalValue = selectedFrequency[finalValue];
    }
    setDaysData({ ...daysData, [name]: finalValue });
    if (
      campaignCtx?.campaignData?.subscriberField &&
      campaignCtx.campaignData
    ) {
      const tempCampaignData = {
        ...campaignCtx.campaignData,
        subscriberField: { ...daysData, [name]: finalValue },
      };

      campaignCtx?.onUpdateCampaignData(tempCampaignData);
    }
  };

  return (
    <div className="mb-4">
      <div className="flex flex-wrap items-center">
        {daysData.relativity === 'after' && (
          <>
            <input
              className="block w-28 h-11 text-13 py-3 border border-gray-800 dark:border-black-400 focus:outline-none dark:bg-slate-800 rounded-md px-4 font-medium leading-3 text-black-400 dark:text-white mt-4 mr-4"
              type="number"
              name="days"
              value={daysData.offset}
              onChange={(e) => onSelectHandler(e.target.value, 'offset')}
            />
            <p className="text-sm font-medium leading-4 text-black-400 dark:text-white mt-4 mr-4">
              Days
            </p>
          </>
        )}
        <div className="w-auto h-11 flex items-center relative mt-4 mr-4">
          <Dropdown
            options={days}
            onSelect={(e) => onSelectHandler(e, 'relativity')}
            value={daysData.relativity}
            disableOptions={["before"]}
          />
        </div>
        <div className="w-44 h-11 flex items-center relative mt-4 mr-4">
          <Dropdown
            options={dates}
            onSelect={(e) => onSelectHandler(e, 'id')}
            value={Object.keys(selectedDate).find(
              (key) => selectedDate[key] === daysData.id
            )}
          />
        </div>
        <p className="text-sm font-medium leading-4 text-black-400 dark:text-white mt-4 mr-4">
          and
        </p>
        <div className="w-44 h-11 flex items-center relative mt-4">
          <Dropdown
            options={repeats}
            onSelect={(e) => onSelectHandler(e, 'frequency')}
            value={Object.keys(selectedFrequency).find(
              (key) => selectedFrequency[key] === daysData.frequency
            )}
          />
        </div>
      </div>
    </div>
  );
};
export default ScheduleDays;
