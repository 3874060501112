import React, { FC, useEffect, useState } from 'react';
import {
  PaginationChangePage,
  PaginationChangeRowsPerPage,
} from 'react-data-table-component/dist/src/DataTable/types';
import uuid from 'react-uuid';

interface Props {
  rowsPerPage: number;
  rowCount: number;
  currentPage: number;
  onChangePage: PaginationChangePage;
  onChangeRowsPerPage: PaginationChangeRowsPerPage;
}

const Pagination: FC<Props> = ({
  rowsPerPage,
  rowCount,
  currentPage,
  onChangePage,
}) => {
  const [pages, setPages] = useState<Array<string>>([]);
  const [totalPages, setTotalPages] = useState<string>('');

  const nextPageHandler = () => {
    onChangePage(currentPage + 1, rowsPerPage);
  };

  const buttonClickHandler = (page: number) => {
    !isNaN(page) && onChangePage(page, rowsPerPage);
  };

  const previousPageHandler = () => {
    const tempPages = [...pages];
    onChangePage(currentPage - 1, rowsPerPage);
    setPages(tempPages);
  };

  const nextGreaterPageHandler = (allPages: string[]) => {
    setPages(allPages);
    const tempPages =
      parseInt(totalPages) - currentPage < 5
        ? [
            '1',
            '...',
            (parseInt(totalPages) - 5).toString(),
            (parseInt(totalPages) - 4).toString(),
            (parseInt(totalPages) - 3).toString(),
            (parseInt(totalPages) - 2).toString(),
            (parseInt(totalPages) - 1).toString(),
            totalPages,
          ]
        : [
            '1',
            '...',
            (currentPage - 2).toString(),
            (currentPage - 1).toString(),
            currentPage.toString(),
            (currentPage + 1).toString(),
            (currentPage + 2).toString(),
            '...',
            allPages.length.toString(),
          ];
    setPages(tempPages);
  };

  useEffect(() => {
    const page = Math.ceil(rowCount / rowsPerPage);
    const tempPage: number[] = [];
    Array(page)
      .fill(0)
      .map((_, index) => tempPage.push(index + 1));
    let allPages = tempPage.map(String);

    setTotalPages(allPages.length.toString());
    allPages =
      parseInt(totalPages) < 7
        ? (allPages = [...allPages])
        : currentPage < 5
        ? (allPages = [
            '1',
            '2',
            '3',
            '4',
            '5',
            '...',
            tempPage.length.toString(),
          ])
        : (allPages = [
            ...allPages.splice(0, currentPage),
            (currentPage + 1).toString(),
            (currentPage + 2).toString(),
            '...',
            tempPage.length.toString(),
          ]);
    currentPage < 5 ? setPages(allPages) : nextGreaterPageHandler(allPages);
  }, [totalPages, rowCount]);

  return (
    <div className="flex justify-between items-center px-4 bg-white rounded-b-lg pb-2 pt-6  dark:bg-black-400">
      <div>
        <p className="text-secondary font-medium text-sm">{`Showing ${currentPage} of ${totalPages}`}</p>
      </div>
      <div className="flex items-center text-">
        <button
          onClick={() => previousPageHandler()}
          className={`${
            currentPage === 1 ? 'cursor-not-allowed ' : ' '
          } h-8 w-8	mr-2.5  py-1 rounded-lg flex justify-center items-center border page-active dark:bg-white text-lightParagraphColor`}
          disabled={currentPage === 1}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 text-lightParagraphColor rotate-180"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            />
          </svg>
        </button>

        <div className="flex">
          {pages.map((page) => (
            <div
              key={uuid()}
              onClick={() => buttonClickHandler(parseInt(page))}
              className={`w-6 ${
                currentPage === parseInt(page) ? 'bg-primary border-primary text-white' : ''
              } cursor-pointer h-7 mr-2.5 rounded-md border  flex justify-center items-center text-xs leading-3 focus:text-white text-lightParagraphColor border-gray-800 page-active dark:text-white`}
            >
              {page}
            </div>
          ))}
        </div>
        <button
          onClick={() => nextPageHandler()}
          className={`${
            currentPage === parseInt(totalPages) ? 'cursor-not-allowed ' : ' '
          } h-8 w-8	mr-2.5 py-1 rounded-lg flex justify-center items-center border page-active dark:bg-white text-lightParagraphColor`}
          disabled={currentPage === parseInt(totalPages)}
          data-pw="next-page-button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 text-lightParagraphColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
export default Pagination;
