import Dropdown from 'components/Dropdown/Dropdown';
import { useEffect, useState } from 'react';
import WorkflowScriptForm from './WorkflowScriptForm';
import { getWorkflowScripts } from 'services/apiHandlers/Integration/Scripts';
import { z } from 'zod';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';

const workflowSchema = z.object({
  id: z.string(),
  javascript: z.string(),
  name: z.string(),
  domains: z.union([z.array(z.string()), z.string()]),
});
export type workflow = z.infer<typeof workflowSchema>;

const responseSchema = z.object({
  data: z.object({ workflowScripts: z.array(workflowSchema) }),
  status: z.number(),
});
type response = z.infer<typeof responseSchema>;

const WorkflowScript = () => {
  const [workflow, setWorkflow] = useState<string>('Select an item');
  const [workflows, setWorkflows] = useState<Array<string>>();
  const [workflowScripts, setWorkflowScripts] = useState<Array<workflow>>();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [editWorkflowData, setEditWorkflowData] = useState<workflow>();

  const getData = async () => {
    try {
      const getWorkflowScriptDataResponse =
        (await getWorkflowScripts()) as response;
      if (getWorkflowScriptDataResponse.status === 200) {
        setWorkflowScripts(getWorkflowScriptDataResponse.data.workflowScripts);
        const transformScripts: string[] = [];
        getWorkflowScriptDataResponse.data.workflowScripts.map((scripts) =>
          transformScripts.push(scripts.name)
        );
        setWorkflows(transformScripts);
      }
    } catch (error) {
      console.log('Workflow Netwrok Error: ', error);
    }
  };

  const editWorkflowHandler = (script: workflow) => {
    const temp = {
      ...script,
      domains: Array.isArray(script.domains)
        ? script.domains.join('\n')
        : script.domains,
    };
    setEditWorkflowData(temp);
  };

  const onWorkflowChangeHandler = (option: string) => {
    setWorkflow(option);
    setShowForm(true);
    workflowScripts?.map(
      (script) => script.name === option && editWorkflowHandler(script)
    );
  };

  const showFormHandler = () => {
    setEditWorkflowData(undefined);
    setWorkflow('Select an item');
    setShowForm(true);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <ErrorBoundary>
      <div className="lg:w-6/12 lg:pl-5 lg:mt-0 mt-8">
        <h3 className="text-base font-semibold leading-4 text-black-700 dark:text-white mb-3">
          Workflow Scripts
        </h3>
        <div>
          <Dropdown
            onSelect={onWorkflowChangeHandler}
            value={workflow}
            options={workflows}
            id={
              workflowScripts
                ? workflowScripts[workflowScripts?.length - 1]?.id
                : '0'
            }
          />
          <div className="w-full text-right">
            <div
              className="text-primary underline cursor-pointer text-xs font-medium mt-1 inline-block"
              onClick={showFormHandler}
            >
              Add workflow script
            </div>
          </div>
        </div>
        {showForm && (
          <WorkflowScriptForm
            getData={getData}
            setShowForm={setShowForm}
            editWorkflowData={editWorkflowData}
            setWorkflow={setWorkflow}
            setEditWorkflowData={setEditWorkflowData}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};
export default WorkflowScript;
