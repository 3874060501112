import React from "react";
interface Props {
  className?: string;
  onClick: () => void;
  src?: string;
  alt?: string;
  children?: JSX.Element;
}
const IconButton = ({ className, onClick, children }: Props) => {

  return (
    <button className={className} onClick={onClick}>
      {children}
    </button>
  );
};
export default IconButton;
