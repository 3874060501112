import React, { FC } from 'react';
import { ExpanderComponentProps } from 'react-data-table-component';
import { z } from 'zod';
import ProductTable from './ProductTable';

const resultDataSchema = z.object({
  accountId: z.number(),
  attributes: z.record(z.string()),
  id: z.string(),
  name: z.string(),
  timestamp: z.number(),
});
type ExpandableRowData = z.infer<typeof resultDataSchema>;

const EventLogAccordian: FC<ExpanderComponentProps<ExpandableRowData>> = ({
  data,
}) => {
  return (
    <div>
      <div className="md:flex block text-sm">
        {Object.entries(data.attributes).map(([key, value]) =>
          key !== 'products' ? (
            <div key={key + value} className="md:w-1/2 flex py-2">
              <p className="text-sm font-medium leading-5 text-212121 w-40">
                {key}
              </p>
              &nbsp;
              <p className="text-[13px] font-medium leading-5 text-616161 w-2/3">
                {typeof value === 'string' ? value : ''}
              </p>
            </div>
          ) : (
            <>
              <ProductTable key={key} data={value} />
            </>
          )
        )}
      </div>
    </div>
  );
};
export default EventLogAccordian;
