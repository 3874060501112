import { z } from 'zod';

export const RoiWeeklyResponseDataSchema = z.object({
  average_time_for_0th_purchase: z.number(),
  average_time_since_last_order: z.number(),
  blast_conversions: z.string(),
  blast_conversions_value: z.string(),
  break_even: z.string(),
  company: z.string(),
  conversions: z.string(),
  conversions_value: z.string(),
  email_click: z.string(),
  email_open: z.string(),
  email_recipients: z.string(),
  end_date: z.string(),
  monthly_fee: z.string(),
  new_buyers_count: z.string(),
  new_prospects: z.string(),
  orders: z.string(),
  profit: z.string(),
  repeated_buyers_count: z.string(),
  revenue: z.string(),
  roi: z.string(),
  shoppers_browsing: z.string(),
  start_date: z.string(),
  weekly_fee: z.string(),
});
const RoiWeeklyResponseSchema = z.object({
  data: RoiWeeklyResponseDataSchema,
  status: z.number(),
});
export type RoiWeeklyResponse = z.infer<typeof RoiWeeklyResponseSchema>;

const listDataSchema = z.object({
  deleted: z.boolean(),
  name: z.string(),
  id: z.number(),
});

const audienceDataSchema = z.object({
  name: z.string(),
  id: z.number(),
  included_list: z.array(listDataSchema),
  excluded_list: z.array(listDataSchema),
  segment: listDataSchema,
});

export const BlastSchema = z.object({
  blast: z.object({
    account_id: z.number(),
    audience: audienceDataSchema.optional(),
    blast_address_id: z.number(),
    bounced: z.number().optional(),
    cleaned_at: z.null(),
    clicks_count: z.number(),
    complaints_count: z.number(),
    completed_at: z.null(),
    created_at: z.string(),
    creator_id: z.number(),
    creator_type: z.null(),
    delivered: z.number().optional(),
    deleted_at: z.null(),
    email_id: z.number(),
    finder_id: z.number(),
    for_individual_owner: z.boolean(),
    forwards_count: z.number(),
    from_email_address: z.string(),
    from_name: z.string(),
    google_analytics: z.null(),
    hard_bounces_count: z.number(),
    host: z.string(),
    id: z.number(),
    include_social_bar: z.boolean(),
    intended_recipients: z.number(),
    is_test: z.boolean(),
    mailengine_id: z.number(),
    mailengine_version: z.null(),
    number_sent: z.number(),
    person_database_id: z.number(),
    piece_id: z.number(),
    priority: z.number(),
    reply_to_email_address: z.null(),
    reply_to_name: z.null(),
    schedule_method: z.string(),
    scheduled_for: z.string(),
    share_description: z.string(),
    share_report: z.string(),
    share_title: z.string(),
    soft_bounces_count: z.number(),
    social_views_count: z.number().optional(),
    social_count: z.number().optional(),
    status: z.string(),
    subject: z.string(),
    test_email_addresses: z.null(),
    timezone: z.string(),
    track_clicks: z.string(),
    track_forwards: z.string(),
    track_views: z.string(),
    tracking_hash: z.string(),
    trigger_id: z.null(),
    unique_clicks_count: z.number(),
    unique_complaints_count: z.number(),
    unique_views_count: z.number(),
    unsubscribes_count: z.number(),
    updated_at: z.string(),
    views_count: z.number(),
    whitelisting_completed_at: z.null(),
    whitelisting_scheduled_at: z.null(),
    date: z.string().optional(),
  }),
});
const BlastResponseSchema = z.object({
  data: z.object({ collection: z.array(BlastSchema) }),
  status: z.number(),
});

export type Blasts = z.infer<typeof BlastSchema>
export type BlastResponse = z.infer<typeof BlastResponseSchema>;

const SinglecampaignSchema = z.object({
  monetaryValue: z.number(),
  campaignName: z.string(),
  conversions: z.string().optional(),
  id: z.number().optional(),
  date: z.string().optional(),
  eventDate: z.string().optional(),
});

export type Singlecampaign = z.infer<typeof SinglecampaignSchema>;

const campaignsSchema = z.array(SinglecampaignSchema);
export type Campaigns = z.infer<typeof campaignsSchema>;

export const SingleWorkflowSchema = z.object({
  bounces: z.number(),
  clicks: z.number(),
  deliveries: z.number(),
  failures: z.number(),
  monetaryValue: z.number(),
  skips: z.number(),
  inactiveSkips: z.number(),
  unsubscribeSkips: z.number(),
  sends: z.number(),
  uniqueClicks: z.number(),
  uniqueConversions: z.number(),
  uniqueViews: z.number(),
  uniquePeople: z.number(),
  unsubscribes: z.number(),
  views: z.number(),
  workflowId: z.string(),
  workflowName: z.string(),
  date: z.string().optional(),
});
const StrategiesSchema = z.array(SingleWorkflowSchema);
export type Workflows = z.infer<typeof StrategiesSchema>;

const StrategyResponseSchema = z.object({
  data: z.object({ data: z.array(SingleWorkflowSchema) }),
  status: z.number(),
});
export type WorkflowResponse = z.infer<typeof StrategyResponseSchema>;

export const AllWorkflowDataSchema = z.object({
  accountId: z.number(),
  id: z.string(),
  initialConditionGroup: z.object({
    _type: z.string(),
    eventName: z.string(),
    conditions: z.array(z.number()),
  }),
  isEnabled: z.boolean(),
  isTestMode: z.boolean(),
  lastModified: z.number(),
  name: z.string(),
  runsOncePerPersonIdentifier: z.boolean(),
  workflow: z.object({
    id: z.string(),
    action: z.object({
      listIds: z.union([z.array(z.number()), z.undefined()]),
      _type: z.string(),
    }),
    _type: z.string(),
  }),
});

const AllWorkflowResponseSchema = z.object({
  data: z.object({ triggers: z.array(AllWorkflowDataSchema) }),
  status: z.number(),
});

export type AllWorkflowResponse = z.infer<typeof AllWorkflowResponseSchema>;
