export const sentEmailData = [
  {
    title: 'Sent in Workflows',
    sentMails: '400',
    id: '1',
    color: 'bg-yellow-400',
  },
  {
    title: 'Sent in Campaigns',
    sentMails: '300',
    id: '2',
    color: 'bg-green-300',
  },
  { title: 'Available', sentMails: '200', id: '3', color: 'bg-red-400' },
];

export const labels = ['Jan 1', 'Jan 7', 'Jan 14', 'Jan 28'];
export const datasets = [
  {
    barThickness: 16,
    barPercentage: 0.5,
    backgroundColor: '#2EA1F9',
    label: ' Revenue from campaigns',
    data: ['', '', '', ''],
  },
  {
    barThickness: 16,
    barPercentage: 0.5,
    backgroundColor: '#8FDBC0',
    label: ' Revenue from Workflows',
    data: ['', '', '', ''],
  },
];

export const columnLabels = ['Jan 1', 'Jan 7', 'Jan 14', 'Jan 28'];
export const columnData = [
  {
    label: ' Current Period',
    data: ['', '', '', ''],
    backgroundColor: '#2EA1F9',
    barPercentage: 1.0,
    barThickness: 16,
  },
  {
    label: ' Previous Period',
    data: ['', '', '', ''],
    backgroundColor: '#8FDBC0',
    barThickness: 16,
    barPercentage: 1.0,
  },
];

export const pieLabels = [
  'Sent in workflows',
  'Sent in campaigns',
  'Available',
];

export const pieData = [
  {
    data: [],
    backgroundColor: ['#FBB846', '#8FDBC0', '#2EA1F9'],
  },
];

export const LineChartData = [
  {
    label: ' Current Period',
    data: ['', '', ''],
    borderColor: '#FBB846',
    backgroundColor: '#FBB846',
  },
  {
    label: ' New Comer Series',
    data: ['', '', ''],
    borderColor: '#A292DA',
    backgroundColor: '#A292DA',
  },
];

export const LineChartCategories = ['Jan 1', 'Jan 7', 'Jan 14'];

export const ColoumnChartData = [
  {
    type: 'column',
    name: 'Current Period',
    data: [],
  },
  {
    type: 'column',
    name: 'Previous Period',
    data: [],
  },
];

export const ColoumnChartCategories = ['Jan 1', 'Jan 17', 'Jan 14', 'Jan 28'];

export const TotalOrders = {
  orderType: '------',
  orderValue: '-----',
  orderPercentage: '----',
  graphType: '----',
  graphCategories: LineChartCategories,
  graphData: LineChartData,
  graphHeight: 250,
  graphWidth: 450,
  graphStyle: { maxWidth: '450px', width: '100%', height: 'auto' },
  lastMonth: '----',
  last90Days: '----',
  last14Days: '----',
};

export const AverageOrder = {
  orderType: '-----',
  orderValue: '------',
  orderPercentage: '-----',
  graphType: '-----',
  graphCategories: columnLabels,
  graphData: columnData,
  graphStyle: { maxWidth: '450px', width: '100%',  },
  graphHeight: 167,
  lastMonth: '-----',
  last90Days: '------',
  last14Days: '------',
};

export const OnlineStore = {
  orderType: '-------',
  orderValue: '------',
  orderPercentage: '-------',
  graphCategories: LineChartCategories,
  graphData: LineChartData,
  graphType: '------',
  lastMonth: '------',
  last90Days: '------',
  last14Days: '-------',
  graphHeight: 250,
  graphWidth: 450,
  graphStyle: { maxWidth: '450px', width: '100%', height: 'auto' },
};

export const OpenRate = {
  orderType: 'Open Rate',
  orderValue: '450K',
  orderPercentage: '20%',
  graphType: 'Line',
  graphCategories: LineChartCategories,
  graphData: LineChartData,
  lastMonth: '$200K',
  last90Days: '$600K',
  last14Days: '$300K',
  graphHeight: 250,
  graphWidth: 450,
  graphStyle: { maxWidth: '450px', width: '100%', height: 'auto' },
};

export const OnlineStoreSession = {
  orderType: 'Online Store Sessions',
  orderValue: '$120',
  orderPercentage: '20%',
  graphType: 'Coloumn',
  graphCategories: columnLabels,
  graphData: columnData,
  graphStyle: { maxWidth: '450px', width: '100%', paddingTop: "1px"  },
  graphHeight: 167.9,
  lastMonth: '$100',
  last90Days: '$150',
  last14Days: '$190',
};

export const ReturningCustomer = {
  orderType: 'Returning Customer Rate',
  orderValue: '28%',
  orderPercentage: '20%',
  graphType: 'Line',
  graphCategories: LineChartCategories,
  graphData: LineChartData,
  lastMonth: '8%',
  last90Days: '11%',
  last14Days: '7%',
  graphHeight: 250,
  graphWidth: 450,
  graphStyle: { maxWidth: '450px', width: '100%', height: 'auto' },
};

export const SalesRyzeo = {
  orderType: 'Sales Attribured To Ryzeo',
  orderValue: '$120',
  orderPercentage: '20%',
  graphType: 'Coloumn',
  lastMonth: '$100',
  last90Days: '$150',
  last14Days: '$190',
  graphCategories: columnLabels,
  graphData: columnData,
  graphHeight: 225,
};

export const ProductsSold = {
  orderType: 'Top Products By Units Sold',
  orderValue: '$120',
  orderPercentage: '20%',
  graphType: 'Pie',
  graphData: pieData,
  graphCategories: pieLabels,
  lastMonth: '$100',
  last90Days: '$150',
  last14Days: '$190',
  graphHeight: '100%',
  graphWidth: '100%',
};


