import React, { FC } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  registerables,
  ChartDataset,
  ScatterDataPoint,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useContext } from 'react';
import { ThemeContext } from 'store/ThemeContext';
import { z } from 'zod';
import ErrorBoundary from 'components/ErrorBoundary';


const chartStyleSchema = z.object({
  maxWidth: z.string().optional(),
  width: z.string().optional(),
  height: z.string().optional(),
  padding: z.string().optional(),
});

type chartStyle = z.infer<typeof chartStyleSchema>;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ...registerables
);

interface Props {
  chartLabels: string[] | undefined;
  datasets: ChartDataset<'line', (number | ScatterDataPoint | null)[]>[];
  width?: number | string;
  height?: number | string;
  style?: chartStyle;
}

const LineGraph: FC<Props> = ({
  chartLabels,
  datasets,
  width,
  height,
  style,
}) => {
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx?.theme;
  let maxValue;

  const numberArray = datasets[0].data.map((data) => { if (typeof data === "string") { return parseFloat(data) } })

  if ( numberArray != undefined) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
      maxValue = (Math.max(...numberArray)) / 3;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    stacked: true,
    elements: {
      line: {
          tension: 0.5 
      }
    },
    plugins: {
      title: {
        display: true,
      },
      legend: {
        display: true,
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
          boxWidth: 6,
          color: `${theme === 'dark' ? 'white' : ''}`,
          padding: 20,

        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        categorySpacing: 0,
        ticks: {
          color: `${theme === 'dark' ? 'white' : ''}`,
          beginAtZero: true,
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        categorySpacing: 0,
        ticks: {
          color: `${theme === 'dark' ? 'white' : ''}`,
          beginAtZero: true,
          stepSize: Math.ceil( maxValue ? maxValue : 0),
        },
      },
    },
  };

  const labels = chartLabels;

  const data = {
    labels,
    datasets,
  };

  return (
    <ErrorBoundary>
      <Line
        width={width}
        height={height}
        options={options}
        data={data}
        style={style}
      />
    </ErrorBoundary>
  );
};
export default LineGraph;
