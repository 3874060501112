import CheckWhite from 'assets/Images/Campaign/check-white.png';
import React from "react"
import { FC } from 'react';
interface Props {
  completedSteps?: string[];
}
const statusSteps = ['1', '2', '3'];
const StatusBar: FC<Props> = ({ completedSteps }) => {
  return (
    <div className="w-full max-w-md mx-auto flex justify-center items-center">
      {statusSteps.map((step, index) => {
        return completedSteps && completedSteps.includes(step) ? (
          <div key={step}>
            <div className="w-7 h-7 relative -left-7 rounded-full bg-primary flex justify-center items-center">
              <img src={CheckWhite} />
            </div>
            {index < 2 && (
              <div className="h-0.5 sm:w-48 w-20 relative -top-3.5 border-b-1 bg-primary"></div>
            )}
          </div>
        ) : (
          <div key={step}>
            <div className={`${index === 0 ? '-left-7 ' : index ===2 ? 'left-7' : ''} w-7 h-7 relative rounded-full text-primary flex justify-center items-center border border-primary text-base font-normal leading-6`}>
              {step}
            </div>
            {index < 2 && (
              <div className={`${index === 1 ? "left-7" : ''} h-0.5 relative sm:w-48 -top-3.5 w-20 border-b-1 bg-[#cfcfcf]`}></div>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default StatusBar;
