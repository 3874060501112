import { useContext, useEffect} from 'react';
import { getSingleWorkflow } from 'services/apiHandlers/Workflows/Workflow';
import { WorkflowSchema } from './index.type';
import { z } from 'zod';
import Header from './WorkflowHeader';
import { WorkflowContext } from 'store/WorkflowContext';
import WorkflowPreview from './WorkflowPreview';
import CreateWorkflowHeader from './CreateWorkflowHeader';
import { useParams } from 'react-router-dom';
import React from "react"


const responseSchema = z.object({
  data: WorkflowSchema,
  status: z.number(),
});

type Response = z.infer<typeof responseSchema>;

const Workflow = () => {
  const workflowCtx = useContext(WorkflowContext);
  const { id } = useParams();

  const getData = async () => {
    try {
      if (id) {
        const response = (await getSingleWorkflow(id)) as Response;
        if (response.status === 200) {
          if (WorkflowSchema.safeParse(response.data)) {
            workflowCtx?.workflowChangeHandler(response.data);
          }
        }
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  useEffect(() => {
    getData()
  }, [id])
  
  return (
    <>
    <div className="md:px-6 px-3 py-5 font-inter bg-gray-400 dark:bg-slate-800 h-full">
      <div className="md:px-1.5  mb-16">
      <div className="body-content-right">
        {id ? <Header /> : <CreateWorkflowHeader />}
        <WorkflowPreview onSave={getData}/>
      </div>
    </div>
    </div>
    </>
  );
};
export default Workflow;
