import Table from 'components/Table';
import moment from 'moment-timezone';
import React, { FC, useContext, useMemo } from 'react';
import EventLogAccordian from './EventLogAccordian';
import { TableColumn } from 'react-data-table-component';
import { UserContext } from 'store/UserContext';
import { z } from 'zod';
import Loader from 'components/Loader';
import NoData from './NoData';

const resultDataSchema = z.object({
  accountId: z.number(),
  attributes: z.record(z.string()),
  id: z.string(),
  name: z.string(),
  timestamp: z.number(),
});

const filterDataSchema = z.object({
  startDate: z.string(),
  endDate: z.string(),
  searchValue: z.string(),
  selectedEvent: z.string(),
});

type FilterData = z.infer<typeof filterDataSchema>;
type ResultData = z.infer<typeof resultDataSchema>;

interface Props {
  events: ResultData[];
  loading: boolean;
  showMore: () => void;
  showMoreButton: boolean;
  moreLoading: boolean;
  filterData : FilterData
}

const AllEvents: FC<Props> = ({
  events,
  loading,
  showMore,
  showMoreButton,
  moreLoading,
  filterData
}) => {
  const userCtx = useContext(UserContext);

  const columns: TableColumn<ResultData>[] = useMemo(
    () => [
      {
        name: 'Subscriber',
        cell: (row) => {
          return (
            <td className="dark:text-white">
              <div>
                {typeof row.attributes.personIdentifier === 'string'
                  ? row.attributes.personIdentifier
                  : ''}
              </div>
            </td>
          );
        },
      },
      {
        name: 'Event',
        cell: (row) => {
          return (
            <td className="dark:text-white">
              <div>{row.name}</div>
            </td>
          );
        },
      },
      {
        name: 'Date',
        cell: (row) => {
          return (
            <td className="whitespace-nowrap py-3 pl-3 pr-3 text-13 sm:align-top align-middle  dark:text-white text-black-400 dark:text-white dark:text-white md:w-[400px] md:min-w-[400px] w-[200px] min-w-[200px] flex flex-wrap items-center ">
              <div className="flex items-center">
                {moment
                  .tz(row.timestamp, userCtx?.usersData?.timeZone || '')
                  .format('MMMM DD, YYYY hh:mm:ss A')}
              </div>
            </td>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      <div>
        <Table
          className="scrollbar-hide"
          data={events}
          columns={columns}
          pagination={false}
          expandableRows
          expandableRowsComponent={EventLogAccordian}
          progressPending={loading}
          expandOnRowClicked
          noDataComponent={<NoData filterData={filterData} />}
          // expandableRowsHideExpander
        />
        {showMoreButton && (
          <button
            disabled={loading || moreLoading}
            onClick={showMore}
            className={`${
              loading || moreLoading
                ? 'border border-gray-800 bg-gray-200 text-black-400'
                : 'leading-5 text-white bg-primary'
            } fs-13 font-medium  flex items-center ml-auto rounded-md py-3 sm:pl-4 px-2 sm:pr-3.5 font-inter  mt-5 ease-in duration-300 hover:bg-primaryHover hover:scale-105`}
          >
            {moreLoading && (
              <div className="">
                <Loader />
              </div>
            )}
            {loading
              ? 'Loading Logs...'
              : moreLoading
              ? 'Loading More ....'
              : 'Show More'}
          </button>
        )}
      </div>
    </div>
  );
};
export default AllEvents;
