import Edit from 'assets/Images/ListsegmentImages/edit-table-active.svg';
import SubscriberEvents from './SubscriberEvents';
import { FC, useContext, useEffect, useState } from 'react';
import xmlToJSON from 'utils/xmlToJSON';
import {
  getSubscriberById,
  getSubscriberMembershipById,
} from 'services/apiHandlers/List-Segments/Subscriber';
import { formatXmlJsonResponse } from 'utils/common';
import { getFields } from 'services/apiHandlers/List-Segments/Field';
import { Form } from './SubscribersForm';
import { getAllList } from 'services/apiHandlers/List-Segments/SegmentsList';
import { ListSchema } from 'components/List-Segments/Body/List/Types';
import { z } from 'zod';
import Skeleton from 'components/Skeleton/Skeleton';
import SubscriberTags from './SubscriberTags';
import { UserContext } from 'store/UserContext';
import { Link, useParams } from 'react-router-dom';
import { edit, listSegments, subscribers , listId } from 'services/constant/routes';
import React from 'react';

const ListResponseSchema = z.object({
  data: z.object({ lists: z.array(ListSchema) }),
  status: z.number(),
});
export type ListResponse = z.infer<typeof ListResponseSchema>;
type List = z.infer<typeof ListSchema>;

const loadingSchema = z.object({ fields: z.boolean(), lists: z.boolean() });
type Loading = z.infer<typeof loadingSchema>;

const ViewSubscriber: FC = () => {
  const [viewSubscriberData, setViewSubscriberData] =
    useState<Record<string, string>>();
  const [fields, setFields] = useState<Array<Form>>([]);
  const [filteredList, setFilteredList] = useState<Array<List>>();
  const [loading, setLoading] = useState<Loading>({
    fields: true,
    lists: true,
  });
  const userCtx = useContext(UserContext);
  const { id } = useParams();

  const getLists = async () => {
    try {
      const getListsResponse = (await getAllList()) as ListResponse;
      if (getListsResponse.status === 200) {
        setLoading({ ...loading, lists: false });
        return getListsResponse?.data?.lists;
      }
    } catch (error) {
      setLoading({ ...loading, lists: false });
      return [];
    }
  };

  const getSubscriberData = async () => {
    setLoading({ ...loading, fields: true });
    try {
      if (id) {
        /* eslint-disable */
        const getSubscriberDataresponse = await getSubscriberById(parseInt(id));
        const subscribedListResponse = await getSubscriberMembershipById(
          parseInt(id)
        );
        const subscribedListJson: any = xmlToJSON.parseString(
          subscribedListResponse.replace(
            '<?xml version="1.0" encoding="UTF-8"?>',
            ''
          )
        );

        const allLists = await getLists();

        const getFieldsResponse: any = await getFields();
        const data: any = xmlToJSON.parseString(getFieldsResponse.data);
        const fields = formatXmlJsonResponse(data?.fields[0]?.field);

        const formatedSubscribedList = formatXmlJsonResponse(
          subscribedListJson.memberships[0].membership
        );
        const preSelectLists = formatedSubscribedList.map((list: any) =>
          Number(list['list-id'])
        );

        const filteredList = allLists?.filter((list) =>
          preSelectLists.includes(list.id)
        );

        setFilteredList(filteredList || []);

        setViewSubscriberData(getSubscriberDataresponse);

        setFields(fields as any);
        /* eslint-enable  */
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading({ ...loading, fields: false });
  };

  useEffect(() => {
    getSubscriberData();
  }, []);

  return (
    <form action="#/" className="mt-6">
      <div className="md:flex">
        <div className="md:w-7/12 w-full md:pr-2.5">
          <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-212121 font-medium mb-4">
            View Subscriber
          </h2>
          <div className="md:px-6 px-3 pt-4 pb-6 bg-white rounded-lg boxShadow">
            <div className="flex justify-between items-center pt-2 mb-4">
              <h2 className="sm:text-xl text-base leading-5 text-424242 font-gilroySemibold">
                {viewSubscriberData && viewSubscriberData['email_address']}
              </h2>
              <div className="">
                <Link
                  to={`${listSegments}/${subscribers}/${edit}/${id}`}
                  className="mr-4 hover:text-[#3575d5] dark:text-white"
                >
                  <img
                    src={Edit}
                    alt="Edit"
                    className="inline mr-1.5 dark:invert dark:brightness-0"
                  />
                  <span className="text-[#3575d5]">Edit</span>
                </Link>
              </div>
            </div>
            <div className="flex">
              {loading.fields ? (
                <Skeleton columns={10} />
              ) : (
                <>
                  <div className="w-6/12 pr-2">
                    {fields
                      .slice(0, Math.ceil(fields.length / 2))
                      .map((field, index) => (
                        <div
                          className={`${index > 0 ? 'mt-6' : ''}`}
                          key={field.id}
                        >
                          <label className="text-xs font-semibold leading-3 text-[#9e9e9e] tracking-wide uppercase mb-2 block">
                            {field.name}
                          </label>
                          <p className="text-[13px] font-medium leading-[18px] text-616161">
                            {viewSubscriberData &&
                              viewSubscriberData[field.slug]}
                          </p>
                        </div>
                      ))}
                  </div>
                  <div className="w-6/12 pl-2">
                    {fields
                      .slice(Math.ceil(fields.length / 2), fields.length)
                      .map((field, index) => (
                        <div
                          className={`${index > 0 ? 'mt-6' : ''}`}
                          key={field.id}
                        >
                          <label className="text-xs font-semibold leading-3 text-[#9e9e9e] tracking-wide uppercase mb-2 block">
                            {field.name}
                          </label>
                          <p className="text-[13px] font-medium leading-[18px] text-616161">
                            {viewSubscriberData &&
                            !Array.isArray(viewSubscriberData[field.slug])
                              ? viewSubscriberData[field.slug]
                              : viewSubscriberData &&
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                viewSubscriberData[field.slug].join(' , ')}
                          </p>
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="md:w-5/12 w-full md:pl-2.5">
          <div className="md:h-9 h-6"></div>
          <div className="md:px-6 px-3 pt-4 pb-6 bg-white rounded-lg boxShadow">
            <label className="text-xs font-semibold leading-3 text-[#9e9e9e] tracking-wide uppercase mb-2 block">
              E-mail Format
            </label>
            <p className="text-[13px] font-medium leading-[18px] text-616161">
              <span className="font-bold">
                {viewSubscriberData?.email_content_format.toUpperCase()}{' '}
              </span>
              format messages
            </p>
          </div>
          <div className="md:px-6 px-3 py-4 bg-white rounded-lg boxShadow mt-4">
            <div className="">
              <label className="text-xs font-semibold leading-3 text-[#9e9e9e] tracking-wide uppercase mb-3 block">
                List Membership
              </label>
              {!filteredList ? (
                <Skeleton columns={5} />
              ) : filteredList?.length === 0 ? (
                <p className="text-[13px] font-medium leading-[18px] text-616161">
                  No List Found
                </p>
              ) : (
                <>
                  {filteredList?.map((list) => (
                    <Link
                      key={list.id}
                      className="text-[13px] font-medium leading-[18px] text-616161"
                      to={`${listSegments}/${subscribers}?${listId}=${list.id.toString()}`}
                    >
                      <div className="text-ryzeoBlue underline">
                        {list.name}
                      </div>
                    </Link>
                  ))}
                </>
              )}
            </div>
          </div>
          {userCtx?.currentUserData?.show_subscriber_events &&
            !loading.fields && (
              <>
                <SubscriberTags
                  email={
                    viewSubscriberData
                      ? viewSubscriberData['email_address']
                      : ''
                  }
                />
                <SubscriberEvents
                  email={
                    viewSubscriberData
                      ? viewSubscriberData['email_address']
                      : ''
                  }
                />
              </>
            )}
        </div>
      </div>
    </form>
  );
};
export default ViewSubscriber;
