import SegmentClose from 'assets/Images/Campaign/segment-close.png';
import { FilterResponseData } from 'components/List-Segments/Body/Filter/Filter';
import { useContext, useEffect, useRef, useState } from 'react';
import { getAllFilters } from 'services/apiHandlers/List-Segments/Filter';
import { getAllList } from 'services/apiHandlers/List-Segments/SegmentsList';
import CheckList from 'components/CheckList';
import Dropdown from 'components/Dropdown/valueDropdown';
import DownArrow from 'assets/Images/Common/down-arrow.png';
import ErrorBoundary from 'components/ErrorBoundary';
import { z } from 'zod';
import { CampaignContext } from 'store/CampaignContext';
import { FilterDataSchema } from 'components/List-Segments/Body/Filter/Types';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React from 'react';

const getListResponseDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  audience_id: z.number(),
});

const getListResponseSchema = z.object({
  data: z.object({
    lists: z.array(getListResponseDataSchema),
  }),
  status: z.number(),
});

type GetListResponse = z.infer<typeof getListResponseSchema>;
type ListType = z.infer<typeof getListResponseDataSchema>;

const listDataSchema = z.object({ name: z.string(), id: z.number() });
type ListData = z.infer<typeof listDataSchema>;

const options = z.object({
  name: z.string(),
  value: z.string(),
});

type option = z.infer<typeof options>;
type FilterData = z.infer<typeof FilterDataSchema>;

const ListSegments = () => {
  const [filterOptions, setFilterOptions] = useState<Array<option>>([]);
  const [list, setList] = useState<Array<ListType>>([]);
  const [showList, setShowList] = useState<boolean>(false);
  const [showExcludeList, setShowExcludeList] = useState<boolean>(false);
  const [subscribers, setSubscribers] = useState<Array<ListData>>([]);
  const [filters, setFilters] = useState<Array<FilterData>>([]);
  const [excludedSubscribers, setExcludedSubscribers] = useState<
    Array<ListData>
  >([]);
  const campaignCtx = useContext(CampaignContext);
  const [selectedFilter, setSelectedFilter] =
    useState<string>('Select a filter');
  const includeListRef = useRef<HTMLDivElement>(null);
  const excludeListRef = useRef<HTMLDivElement>(null);

  useOutsideClick(includeListRef, () => {
    setShowList(false);
  });

  useOutsideClick(excludeListRef, () => {
    setShowExcludeList(false);
  });

  const changeCampaignData = (name: string, value: number[] | string) => {
    if (campaignCtx?.campaignData) {
      campaignCtx?.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        [name]: value,
      });
    }
  };

  const getData = async () => {
    try {
      const getListsresponse = (await getAllList()) as GetListResponse;
      const filterResponse = (await getAllFilters()) as FilterResponseData;
      if (getListsresponse.status === 200) {
        setList(getListsresponse.data.lists);
      }
      if (filterResponse.status === 200) {
        const tempFilterOptions = filterResponse.data.filters.map((filter) => {
          return { name: filter.name, value: filter.id.toString() };
        });
        setFilterOptions(tempFilterOptions);
        setFilters(filterResponse.data.filters);
      }
    } catch (error) {
      console.log('error is: ', error);
    }
  };

  const removeSubscriberHandler = (id: number) => {
    let tempList = [...subscribers];
    tempList = tempList.filter((list) => list.id !== id);
    const excludeIds = tempList.map((list) => {
      return list.id;
    });
    changeCampaignData('include_lists', excludeIds);
    setSubscribers(tempList);
  };

  const excludeListHandler = (id: number) => {
    let tempList = [...excludedSubscribers];
    tempList = tempList.filter((list) => list.id !== id);
    const excludeIds = tempList.map((list) => {
      return list.id;
    });
    changeCampaignData('exclude_lists', excludeIds);
    setExcludedSubscribers(tempList);
  };

  const filterListSelectHandler = (id: string) => {
    const tempSelectedFilter = filters.filter(
      (filter) => filter.id.toString() === id
    );
    changeCampaignData('filter_id', id);
    setSelectedFilter(tempSelectedFilter[0].name);
  };

  const subscriberChangeHandler = (name: string, id: number) => {
    const tempSubscribers = [...subscribers, { name: name, id: id }];
    const excludeIds = tempSubscribers.map((list) => {
      return list.id;
    });
    changeCampaignData('include_lists', excludeIds);
    setSubscribers(tempSubscribers);
  };

  const excludeListChangeHandler = (name: string, id: number) => {
    const tempExcludedSubscribers = [
      ...excludedSubscribers,
      { name: name, id: id },
    ];
    const excludeIds = tempExcludedSubscribers.map((list) => {
      return list.id;
    });
    changeCampaignData('exclude_lists', excludeIds);
    setExcludedSubscribers(tempExcludedSubscribers);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ErrorBoundary>
      <div className="flex items-center pt-2 mb-4">
        <h1 className="sm:text-xl text-base sm:leading-5 leading-4 text-black-300 dark:text-white font-medium">
          Lists & Segments
        </h1>
        <a className="text-xs font-medium leading-3 text-primary ml-2 underline pt-0.5 cursor-pointer">
          Refresh
        </a>
      </div>
      <div className="bg-white rounded-lg pt-6 pb-8 sm:px-7 px-3 mb-10 dark:bg-slate-800 border border-gray-800 dark:border-black-400 shadow-lg shadow-gray-200 dark:shadow-none rounded-lg">
        <div className="sm:px-1">
          <div>
            <div className="md:flex">
              <div className="md:w-1/2">
                <div className="md:flex">
                  <p className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white md:w-24 md:mr-3 md:mb-0 mb-4 md:pt-4">
                    Send to
                  </p>
                  <div className="relative" ref={includeListRef}>
                    <div
                      className="xxl:w-full  xl:w-[21rem]  lg:w-56 md:w-40 w-full bg-white dark:bg-slate-800 border-gray-800 dark:border-black-400 border rounded-md dropdown-show-hide sm:h-11 h-9 overflow-y-auto flex items-center relative cursor-pointer px-4 justify-between text-13 text-black-400 dark:text-white"
                      onClick={() => setShowList((prev) => !prev)}
                    >
                      <p>
                        {subscribers.length > 0
                          ? `${subscribers.length} Subscribers Selected`
                          : 'All Subscriber'}
                      </p>
                      <span>
                        <img src={DownArrow} alt="Down Arrow" />
                      </span>
                    </div>

                    {showList && (
                      <div className="bg-white z-50 border border-gray-800 dark:bg-slate-800 dark:border-black-400 h-56 overflow-auto absolute w-full">
                        <CheckList
                          list={list}
                          onSelect={subscriberChangeHandler}
                          selectedList={subscribers}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap items-center rounded">
                  {subscribers.map((list) => (
                    <span
                      key={list.id}
                      className="flex items-center py-1.5 sm:px-3 px-2 rounded-full mr-4 sm:mb-0 mb-3 bg-gray-400 cursor-pointer mt-3"
                      onClick={() => removeSubscriberHandler(list.id)}
                    >
                      <span className="text-xs font-normal leading-4 text-black-400 mr-2">
                        {list.name}
                      </span>

                      <img className="w-2 h-2" src={SegmentClose} alt="Close" />
                    </span>
                  ))}
                </div>
                <div className="mt-8">
                  <div className="md:flex items-center">
                    <p className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white md:w-24 md:mr-3 md:mb-0 mb-4">
                      Apply Filters
                    </p>
                    <div>
                      <Dropdown
                        options={filterOptions}
                        onSelect={filterListSelectHandler}
                        value={selectedFilter}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-1/2 md:mt-0 mt-8">
                <div className="md:flex">
                  <p className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white md:w-24 md:mr-3 md:mb-0 mb-4 md:pt-4">
                    Exclude List
                  </p>
                  <div className="relative" ref={excludeListRef}>
                    <div
                      className="xxl:w-full  xl:w-[21rem]  lg:w-56 md:w-40 w-full bg-white dark:bg-slate-800 border-gray-800 dark:border-black-400 border rounded-md dropdown-show-hide sm:h-11 h-9 overflow-y-auto flex items-center relative cursor-pointer px-4 justify-between text-13 text-black-400 dark:text-white"
                      onClick={() => setShowExcludeList((prev) => !prev)}
                    >
                      <p>
                        {excludedSubscribers.length > 0
                          ? `${excludedSubscribers.length} Items Selected`
                          : 'None'}
                      </p>
                      <span>
                        <img src={DownArrow} alt="Down Arrow" />
                      </span>
                    </div>
                    {showExcludeList && (
                      <div className="bg-white z-50 border border-gray-800 dark:bg-slate-800 dark:border-black-400 h-56 overflow-auto absolute w-full">
                        <CheckList
                          list={list}
                          onSelect={excludeListChangeHandler}
                          selectedList={excludedSubscribers}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap items-center rounded">
                  {excludedSubscribers.map((list) => (
                    <span
                      key={list.id}
                      className="flex items-center py-1.5 sm:px-3 px-2 rounded-full mr-4 sm:mb-0 mb-3 bg-gray-400 cursor-pointer mt-3"
                    >
                      <span className="text-xs font-normal leading-4 text-black-400 mr-2">
                        {list.name}
                      </span>
                      <a onClick={() => excludeListHandler(list.id)}>
                        <img
                          className="w-2 h-2"
                          src={SegmentClose}
                          alt="Close"
                        />
                      </a>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default ListSegments;
