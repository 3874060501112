import {
  workflow,
  workflowReports,
  home,
  listSegments,
  create,
  logs,
  createCampaign,
  campaigns,
  emailTemplates,
  campaignReports,
  integration,
  subscribers,
  filters,
  segmentDefinition,
  upload,
  fields,
  edit,
  view,
  add,
  filter,
  matches,
  emailEditor,
  previewEmail,
  list,
} from 'services/constant/routes';
import BackImage from 'assets/Images/Header/back.png';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundary';

import React from 'react';

const Breadcrumbs = () => {
  const { id, idNested } = useParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const tab = searchParams.get('tab');
  const campaignId = searchParams.get('campaignId');
  const report = searchParams.get('report');
  const searchId = searchParams.get('id');
  const createEmail = searchParams.get('create');
  const tabUpper = tab?.charAt(0).toUpperCase().concat(tab?.slice(1));

  const BreadCrumbs = {
    [home]: [
      { title: 'Home', link: home },
      { title: tabUpper, link: `${home}${search}` },
    ],
    [campaignReports + `/${id}`]: [
      { title: 'Home', link: home },
      { title: 'Campaigns', link: campaigns },
      { title: 'Campaign Report', link: campaignReports },
    ],
    [campaigns]: [
      { title: 'Home', link: home },
      { title: 'Campaigns', link: campaigns },
    ],
    [createCampaign]: [
      { title: 'Home', link: home },
      {
        title: searchId ? 'Email Template' : tabUpper,
        link: searchId
          ? emailTemplates
          : tab === 'dashboard'
          ? home
          : tab === 'campaign'
          ? campaigns
          : home,
      },
      {
        title: searchId ? 'Edit Campaign' : 'Create Campaign',
        link: createCampaign,
      },
    ],
    [createCampaign + `/${id}`]: [
      { title: 'Home', link: home },
      {
        title: 'Email Template',
        link: `${emailTemplates}${search}`,
      },
      {
        title: tab === 'email' ? 'Email' : 'Edit',
        link:
          tab === 'email'
            ? `${emailTemplates}${search}`
            : `${emailEditor}/${edit}/${id}`,
      },
      {
        title: 'Edit Campaign',
        link: createCampaign,
      },
    ],
    [listSegments]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      { title: 'List', link: '' },
    ],
    [listSegments + `/${list}/${id}`]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      { title: 'List', link: listSegments },
      { title: 'Edit', link: `${listSegments}/${list}/${id}` },
    ],
    [listSegments + `/${list}/${create}`]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      { title: 'List', link: listSegments },
      { title: 'Create', link: `${listSegments}/${list}/${create}` },
    ],
    [listSegments + `/${list}/${create}`]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      { title: 'List', link: listSegments },
      { title: 'Create', link: `${listSegments}/${list}/${create}` },
    ],
    [listSegments + `/${list}/${add}/${id}`]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      { title: 'List', link: listSegments },
      { title: 'Add', link: `${listSegments}/${list}/${add}/${id}` },
    ],
    [listSegments + `/${list}/${add}/${id}/${filter}`]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      { title: 'List', link: listSegments },
      { title: 'Add', link: `${listSegments}/${list}/${add}/${id}` },
      {
        title: 'Filter',
        link: `${listSegments}/${list}/${add}/${id}/${filter}`,
      },
    ],
    [listSegments + `/${subscribers}`]: [
      { title: 'Home', link: home },
      report || campaignId
        ? { title: 'Campaigns', link: campaigns }
        : { title: null, link: listSegments },
      {
        title: report || campaignId ? 'Campaign Reports' : 'List & Segments',
        link:
          report || campaignId
            ? `${campaignReports}/${campaignId}`
            : listSegments,
      },
      { title: 'Subscribers', link: `${listSegments}/${subscribers}` },
    ],
    [listSegments + `/${subscribers}/${upload}`]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      { title: 'Subscribers', link: `${listSegments}/${subscribers}` },
      { title: 'Upload', link: `${listSegments}/${subscribers}/${upload}` },
    ],
    [listSegments + `/${subscribers}/${upload}/${create}`]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      { title: 'Subscribers', link: `${listSegments}/${subscribers}` },
      { title: 'Upload', link: `${listSegments}/${subscribers}/${upload}` },
      {
        title: 'Create',
        link: `${listSegments}/${subscribers}/${upload}/${create}`,
      },
    ],
    [listSegments + `/${subscribers}/${fields}`]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      { title: 'Subscribers', link: `${listSegments}/${subscribers}` },
      { title: 'Fields', link: `${listSegments}/${subscribers}/${fields}` },
    ],
    [listSegments + `/${subscribers}/${fields}/${edit}/${id}`]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      { title: 'Subscribers', link: `${listSegments}/${subscribers}` },
      { title: 'Fields', link: `${listSegments}/${subscribers}/${fields}` },
      {
        title: 'Edit',
        link: `${listSegments}/${subscribers}/${fields}/${edit}/${id}`,
      },
    ],
    [listSegments + `/${subscribers}/${edit}/${id}`]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      { title: 'Subscribers', link: `${listSegments}/${subscribers}` },
      { title: 'Edit', link: `${listSegments}/${subscribers}/${edit}/${id}` },
    ],
    [listSegments + `/${subscribers}/${view}/${id}`]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      { title: 'Subscribers', link: `${listSegments}/${subscribers}` },
      { title: 'View', link: `${listSegments}/${subscribers}/${view}/${id}` },
    ],
    [listSegments + `/${filters}`]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      { title: 'Filters', link: `${listSegments}/${filters}` },
    ],
    [listSegments + `/${filters}/${create}`]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      { title: 'Filters', link: `${listSegments}/${filters}` },
      { title: 'Create', link: `${listSegments}/${filters}/${create}` },
    ],
    [listSegments + `/${filters}/${id}`]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      { title: 'Filters', link: `${listSegments}/${filters}` },
      { title: 'Edit', link: `${listSegments}/${filters}/${id}` },
    ],
    [listSegments + `/${filters}/${matches}/${id}`]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      { title: 'Filters', link: `${listSegments}/${filters}` },
      { title: 'View', link: `${listSegments}/${subscribers}/${view}/${id}` },
      { title: 'Matches', link: `${listSegments}/${filters}/${matches}/${id}` },
    ],
    [listSegments + `/${filters}/${view}/${id}`]: [
      { title: 'Home', link: home },
      {
        title: report || campaignId ? 'Campaigns' : 'List & Segments',
        link: report || campaignId ? campaigns : listSegments,
      },
      report || campaignId
        ? { title: 'Campaign Report', link: `${campaignReports}/${campaignId}` }
        : { title: null, link: listSegments },
      !report || !campaignId
        ? { title: 'Filter', link: `${listSegments}/${filters}` }
        : { title: null, link: `${campaignReports}/${campaignId}` },
      !report || !campaignId
        ? { title: 'View', link: `${listSegments}/${filters}/${view}/${id}` }
        : {
            title: 'View Filter',
            link: `${listSegments}/${filters}/${view}/${id}`,
          },
    ],
    [listSegments + `/${segmentDefinition}`]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      {
        title: 'Segment Definition',
        link: `${listSegments}/${segmentDefinition}`,
      },
    ],
    [listSegments + `/${segmentDefinition}/${create}`]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      {
        title: 'Segment Definition',
        link: `${listSegments}/${segmentDefinition}`,
      },
      {
        title: 'Create',
        link: `${listSegments}/${segmentDefinition}/${create}`,
      },
    ],
    [listSegments + `/${segmentDefinition}/${id}`]: [
      { title: 'Home', link: home },
      { title: 'List & Segments', link: listSegments },
      {
        title: 'Segment Definition',
        link: `${listSegments}/${segmentDefinition}`,
      },
      { title: 'Edit', link: `${listSegments}/${segmentDefinition}/${id}` },
    ],
    [integration]: [
      { title: 'Home', link: home },
      { title: 'Integration', link: integration },
      { title: tabUpper, link: '' },
    ],
    [emailTemplates]: [
      { title: 'Home', link: home },
      { title: 'Email Templates', link: emailTemplates },
      { title: tabUpper, link: '' },
    ],
    [emailEditor]: [
      { title: 'Home', link: home },
      { title: 'Email Templates', link: emailTemplates },
      createEmail
        ? { title: 'Create', link: `${emailEditor}/${create}` }
        : { title: null, link: emailTemplates },
      { title: 'Editor', link: emailEditor },
    ],
    [emailEditor + `/${create}`]: [
      { title: 'Home', link: home },
      { title: 'Email Templates', link: emailTemplates },
      { title: 'Create', link: `${emailEditor}/${create}` },
    ],
    [emailEditor + `/${edit}/${id}`]: [
      { title: 'Home', link: home },
      { title: 'Email Templates', link: emailTemplates },
      { title: 'Edit', link: `${emailEditor}/${edit}/${id}` },
    ],
    [previewEmail]: [
      { title: 'Home', link: home },
      { title: 'Email Templates', link: emailTemplates },
      { title: 'Preview Email', link: previewEmail },
    ],
    [workflowReports + `/${id}`]: [
      { title: 'Home', link: home },
      { title: 'Workflow', link: workflow },
      { title: 'Workflow Reports', link: `${workflowReports}/${id}` },
    ],
    [workflow]: [
      { title: 'Home', link: home },
      { title: 'Workflows', link: workflow },
    ],
    [workflow + `/${create}`]: [
      { title: 'Home', link: home },
      { title: 'Workflows', link: workflow },
      { title: 'Create Workflow', link: `${workflow}/${create}` },
    ],
    [workflow + `/${id}`]: [
      { title: 'Home', link: home },
      { title: 'Workflows', link: workflow },
      { title: 'Edit Workflow', link: `${workflow}/${id}` },
    ],
    [workflow + `/${logs}` + `/${id}`]: [
      { title: 'Home', link: home },
      { title: 'Workflows', link: workflow },
      { title: 'Workflow Logs', link: `${workflow}/${logs}/${id}` },
    ],
    [workflow + `/${logs}` + `/${id}` + `/${idNested}`]: [
      { title: 'Home', link: home },
      { title: 'Workflow', link: workflow },
      { title: 'Workflow Logs', link: `${workflow}/${logs}/${id}` },
      {
        title: 'Execution Logs',
        link: `${workflow}/${logs}/${id}/${idNested}`,
      },
    ],
  };

  return (
    <ErrorBoundary>
      <div className="mb-6">
        <ul className="pl-0 mb-0 flex items-center">
          {BreadCrumbs[pathname] && (
            <span
              data-pw="back-button"
              onClick={() =>
                navigate(
                  BreadCrumbs[pathname].length > 2 &&
                    (BreadCrumbs[pathname][2]?.link !== '' ||
                      (tab !== null &&
                        tab !== 'cart-integration' &&
                        tab !== 'email'))
                    ? BreadCrumbs[pathname][BreadCrumbs[pathname].length - 2]
                        ?.link
                    : BreadCrumbs[pathname][0]?.link
                )
              }
              className="mr-1.5 cursor-pointer"
            >
              <img className="w-4 h-4" src={BackImage} alt="List Icon" />
            </span>
          )}
          {BreadCrumbs[pathname]?.map((item, index) => (
            <li key={item.title + item.link} className="flex items-center">
              <Link
                className="flex items-center"
                to={item.link ? item.link : ''}
              >
                <button
                  type="button"
                  disabled={index === BreadCrumbs[pathname]?.length - 1}
                  className={`${
                    index === BreadCrumbs[pathname]?.length - 1 &&
                    BreadCrumbs[pathname].length > 1
                      ? 'text-gray-800'
                      : 'text-darkParagraphColor'
                  } sm:text-sm text-11 font-medium leading-3 dark:text-white mr-1`}
                >
                  {item.title}
                  {index !== BreadCrumbs[pathname]?.length - 1 &&
                    item.title !== null &&
                    ' / '}
                </button>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </ErrorBoundary>
  );
};
export default Breadcrumbs;
