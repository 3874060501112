import React, { FC, useMemo } from 'react';
import DatePicker from 'components/Datepicker';
import { calculatePercentage } from 'utils/common';
import { TableColumn } from 'react-data-table-component';
import view from 'assets/Images/Dashboard/view.png';
import Table from 'components/Table';
import { WorkflowReportsData } from '.';

interface Props {
  startDate: string;
  endDate: string;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
  reportsLoading: boolean;
  workflowReports: WorkflowReportsData[];
}

const ReportsTable: FC<Props> = ({
  startDate,
  endDate,
  setEndDate,
  setStartDate,
  reportsLoading,
  workflowReports,
}) => {
  const columns: TableColumn<WorkflowReportsData>[] = useMemo(
    () => [
      {
        name: 'Names',
        cell: (row) => {
          return (
            <td className="whitespace-nowrap align-top py-3 pr-3 text-13 font-medium text-black-400 dark:text-white xl:border-r">
              {row.name}
            </td>
          );
        },
        center: true,
      },

      {
        name: 'Recipients',
        cell: (row) => {
          return (
            <td className="whitespace-nowrap py-3 text-13 text-black-400 dark:text-white align-top text-center table-cell w-full">
              {row.uniquePeople}
            </td>
          );
        },
        center: true,
      },
      {
        name: 'Converted',
        cell: (row) => {
          return (
            <td className="whitespace-nowrap sm:align-top align-middle py-3 text-center text-13 text-black-400 dark:text-white table-cell w-full">
              {row.uniqueConversions}
            </td>
          );
        },
        center: true,
      },
      {
        name: 'Delivered',
        cell: (row) => {
          return (
            <td className="whitespace-nowrap py-3 text-13 text-black-400 dark:text-white align-top text-center table-cell w-full">
              <div>
                {Number.isNaN(calculatePercentage(row.delivered, row.totalSent))
                  ? 0
                  : calculatePercentage(row.delivered, row.totalSent).toFixed(
                      2
                    )}
                %
              </div>
            </td>
          );
        },
        center: true,
      },
      {
        name: 'Opened',
        cell: (row) => {
          return (
            <td className="whitespace-nowrap sm:align-top align-middle py-3 text-center text-13 text-black-400 dark:text-white table-cell w-full">
              <div>
                {Number.isNaN(calculatePercentage(row.uniqueViews, row.views))
                  ? 0
                  : calculatePercentage(row.uniqueViews, row.views).toFixed(2)}
                %
              </div>
            </td>
          );
        },
        center: true,
      },
      {
        name: 'Clicked',
        cell: (row) => {
          return (
            <td className="whitespace-nowrap sm:align-top align-middle py-3 text-center text-13 text-black-400 dark:text-white table-cell w-full">
              <div>
                {Number.isNaN(calculatePercentage(row.uniqueClicks, row.clicks))
                  ? 0
                  : calculatePercentage(row.uniqueClicks, row.clicks).toFixed(
                      2
                    )}
                %
              </div>
            </td>
          );
        },
        center: true,
      },

      {
        name: 'Unsubscribed',
        cell: (row) => {
          return (
            <td className="whitespace-nowrap sm:align-top align-middle py-3 text-center text-13 text-black-400 dark:text-white table-cell w-full">
              <span>
                {Number.isNaN(
                  calculatePercentage(row.unsubscribes, row.totalSent)
                )
                  ? 0
                  : calculatePercentage(
                      row.unsubscribes,
                      row.totalSent
                    ).toFixed(2)}
                %
              </span>
            </td>
          );
        },
        center: true,
      },
      {
        name: 'Actions',
        cell: () => {
          return (
            <div className="flex items-center mr-4 group cursor-pointer">
              <img
                src={view}
                alt="view"
                className="inline mr-1.5 dark:invert dark:brightness-0"
              />
              <span className="group-hover:text-primary dark:text-white">
                View Details
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="md:px-6 px-3 pt-5 font-inter bg-[#f6f7f9] h-screen">
      <div className="md:px-1.5">
        <div className="pt-6">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg leading-5 text-212121 font-medium">
                Action report
              </h3>
              <p className="text-sm font-normal leading-4 text-757575 mt-2">
                A summary of transactions which occurred within the selected
                date range.
              </p>
            </div>
            <div className="w-56">
              <div className="mt-3 rounded relative border border-9e9e9e">
                <>
                  <div className="relative w-1/2 bg-white dark:bg-slate-800 border-gray-200 dark:border-black-400 border rounded py-1.5 px-3">
                    <DatePicker
                      date={startDate}
                      onDateChange={(date) => {
                        setStartDate(date);
                      }}
                    />
                  </div>
                  <p className="text-xs font-normal leading-5 text-primary text-center my-1.5 mx-3 dark:text-white">
                    through
                  </p>
                  <div className="relative w-1/2 bg-white dark:bg-slate-800 border-gray-200 dark:border-black-400 border rounded py-1.5 px-3">
                    <DatePicker
                      date={endDate}
                      onDateChange={(date) => {
                        setEndDate(date);
                      }}
                    />
                  </div>
                </>
              </div>
            </div>
          </div>
          <div id="allWorkflows" className="mt-6 flex flex-col">
            <div className="-my-2 px-1 overflow-x-auto">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="grid grid-cols-1 gap-4 font-inter pt-3">
                  <Table
                    className="scrollbar-hide"
                    data={workflowReports}
                    columns={columns}
                    progressPending={reportsLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReportsTable;
