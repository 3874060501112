import React, { FC, useEffect } from 'react';
import { getSubscriberTags } from 'services/apiHandlers/List-Segments/Subscriber';

interface Props{
 email : string
}

const SubscriberTags:FC<Props> = ({email}) => {
 console.log(email)
  const getTags = async () => {
    try {
      const getTagsResponse = await getSubscriberTags();
      console.log('get tag response is : ', getTagsResponse);
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  return (
    <div className="md:px-6 px-3 py-4 bg-white rounded-lg boxShadow mt-4">
      <div className="">
        <label className="text-xs font-semibold leading-3 text-[#9e9e9e] tracking-wide uppercase mb-3 block">
          Tags
        </label>
      </div>
    </div>
  );
};
export default SubscriberTags;
