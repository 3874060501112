import Dropdown from 'components/Dropdown/Dropdown';
import { useContext, useEffect, useState } from 'react';
import { CampaignContext } from 'store/CampaignContext';
import React from 'react';
import moment from 'moment';

const weekDays = [
  { name: 'Monday', value: 1 },
  { name: 'Tuesday', value: 2 },
  { name: 'Wednesday', value: 3 },
  { name: 'Thursday', value: 4 },
  { name: 'Friday', value: 5 },
  { name: 'Saturday', value: 6 },
  { name: 'Sunday', value: 7 },
];

const dateRepeats = [
  'does not repeat',
  'repeats daily',
  'repeats weekly',
  'repeats monthly',
  'repeats yearly',
];

interface SelectedFrequency {
  [key: string]: string[];
  ['does not repeat']: string[];
  ['repeats daily']: string[];
  ['repeats weekly']: string[];
  ['repeats monthly']: string[];
  ['repeats yearly']: string[];
}

const selectedFrequency: SelectedFrequency = {
  ['does not repeat']: ['does not repeat', 'does not repeat'],
  ['repeats daily']: ['days', 'daily'],
  ['repeats weekly']: ['week', 'weekly'],
  ['repeats monthly']: ['month', 'monthly'],
  ['repeats yearly']: ['year', 'yearly'],
};

const ScheduleDate = () => {
  const [days, setDays] = useState<Array<string>>([]);
  const [months, setMonths] = useState<Array<string>>([]);
  const [years, setYears] = useState<Array<string>>([]);
  const [date, setDate] = useState({
    day: moment().format('D'),
    month: moment().format('MMMM'),
    year: moment().format('YYYY'),
    frequency: 'does not repeat',
    interval: '1',
    days_of_week: [0, 0, 0, 0, 0, 0, 0],
  });
  const campaignCtx = useContext(CampaignContext);

  const onSelectHandler = (value: string | number | number[], name: string) => {
    if (campaignCtx?.campaignData?.schedule_later && campaignCtx.campaignData) {
      let finalValue = value;
      if (name === 'month') {
        finalValue = moment(value, 'MMMM').format('M');
      }

      if (name === 'frequency' && typeof finalValue === 'string') {
        finalValue = selectedFrequency[finalValue][1];
      }

      campaignCtx?.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        schedule_later: {
          ...campaignCtx.campaignData.schedule_later,
          [name]: finalValue,
        },
      });
    }
    setDate({ ...date, [name]: value });
  };

  useEffect(() => {
    const days = Array.from(Array(31).keys());
    days.shift();
    const scheduleDays = days.map(String);
    const nextFiveYears = Array.from(
      { length: 6 },
      (_, i) => moment().year() + i
    );

    setDays(scheduleDays);
    setMonths(moment.months());
    setYears(nextFiveYears.map(String));
  }, []);

  return (
    <div className="mt-2">
      <div>
        <label className="radio-btn block relative text-13 font-normal leading-3 text-black-400 flex items-center"></label>
        <div className="flex flex-wrap items-center">
          <div className="w-fit-content h-11 flex items-center relative mt-4 mr-4">
            <Dropdown
              options={months}
              onSelect={(value) => onSelectHandler(value, 'month')}
              value={date.month.toString()}
            />
          </div>
          <div className="w-fit-content h-11 flex items-center relative mt-4 mr-4">
            <Dropdown
              options={days}
              onSelect={(value) => onSelectHandler(value, 'day')}
              value={date.day.toString()}
            />
          </div>
          <div className="w-fit-content h-11 flex items-center relative mr-4 mt-4">
            <Dropdown
              options={years}
              onSelect={(value) => onSelectHandler(value, 'year')}
              value={date.year.toString()}
            />
          </div>
          <p className="text-sm font-medium leading-4 text-black-400 dark:text-white mr-4 mt-4">
            and
          </p>
          <div className="w-auto h-11 flex items-center relative mt-4">
            <Dropdown
              options={dateRepeats}
              onSelect={(value) => onSelectHandler(value, 'frequency')}
              value={date.frequency}
            />
          </div>
          {date.frequency !== 'does not repeat' && (
            <div>
              <p className="text-sm font-medium leading-4 text-black-400 dark:text-white mr-4 ml-4">
                every
              </p>
              <input
                className="border border-gray-800 rounded text-13 font-medium leading-3 text-black-400 w-full h-11 p-4 sm:w-64 w-8/12 dark:bg-slate-800 dark:border-black-400 focus:outline-none dark:text-white ml-4 mt-4"
                type="number"
                name="trigger"
                value={date.interval}
                onChange={(e) => onSelectHandler(e.target.value, 'interval')}
              />
              <p className="text-sm font-medium leading-4 text-black-400 dark:text-white mr-4 mt-4 ml-4">
                {selectedFrequency[date.frequency][0]}
              </p>
            </div>
          )}
        </div>

        {date.frequency === 'repeats weekly' && (
          <div className="flex wrap">
            {weekDays.map((week, index) => (
              <div key={week.value} className="flex items-center mt-4 mr-4">
                <input
                  className="w-[18px] h-[18px] rounded-sm accent-blue-600 mr-2"
                  type="checkbox"
                  id={week.value.toString()}
                  name="resume-mon"
                  value={week.value}
                  checked={date.days_of_week.includes(week.value)}
                  onChange={(e) => {
                    const tempWeekDays = date.days_of_week;
                    if (e.target.checked) {
                      tempWeekDays[index] = parseInt(e.target.value);
                    } else {
                      tempWeekDays[index] = 0;
                    }
                    onSelectHandler(tempWeekDays, 'days_of_week');
                  }}
                />
                <label
                  htmlFor={week.value.toString()}
                  className="font-sm font-normal leading-4 text-black-400 dark:text-white"
                >
                  {week.name}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default ScheduleDate;
