import { z } from 'zod';

const RuleSchema = z.object({
  operator: z.string(),
  value: z.string().nullable(),
});

export const BlockSchema = z.object({
  field_name: z.string().nullable(),
  field_source: z.string().nullable(),
  field_type: z.string().nullable(),
  logic_operator: z.string().nullable(),
  rules: z.object({ rule: z.union([z.array(RuleSchema), RuleSchema]) }),
});
export type Block = z.infer<typeof BlockSchema>;

export const FilterDataSchema = z.object({
  'audience-id': z.string(),
  blocks: z.object({ block: z.union([z.array(BlockSchema), BlockSchema]) }),
  'created-at': z.string(),
  description: z.string().nullable(),
  id: z.number(),
  'logic-operator': z.string(),
  name: z.string(),
  updated_at: z.string(),
  used_by_trigger: z.union([z.string(), z.null()]),
  mutable: z.string(),
});

export const FilterDataTypeSchema = z.object({
  audience_id: z.union([z.string(), z.undefined()]),
  blocks: z.union([
    z.object({ block: z.union([z.array(BlockSchema), BlockSchema]) }),
    z.string(),
  ]),
  created_at: z.union([z.string(), z.undefined()]),
  description: z.string().nullable(),
  id: z.number(),
  logic_operator: z.union([z.string(), z.undefined()]),
  name: z.string(),
  updated_at: z.string(),
  used_by_trigger: z.string().nullable(),
  mutable: z.boolean().nullable(),
});

export const ruleDataSchema = z.object({
  class: z.string().optional(),
  comparison_value: z.string(),
  data_type: z
    .object({
      table: z.object({
        name: z.string(),
        ruby_class: z.string(),
        default_options: z.array(z.string()),
      }),
      modifiable: z.boolean(),
    })
    .optional(),
  id: z.number(),
  logical_operator: z.string().optional(),
  comparison_operators: z.object({
    options: z.array(z.array(z.string())),
    selected: z.string(),
  }),
  comparison_operator: z.string().optional(),
  comparison_column_option_id: z.string().nullable().optional(),
  created_at: z.string().optional(),
  position: z.number().optional(),
  segment_block_id: z.number().optional(),
  updated_at: z.string().optional(),
});

export const segmentDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  rules: z.array(ruleDataSchema),
  type_label: z.string(),
  logical_operator: z.string(),
});

export const getFilterDataSchema = z.object({
  description: z.string(),
  fields: z.array(z.any()),
  logical_operator: z.string(),
  name: z.string(),
  segments: z.array(segmentDataSchema),
  created_at: z.string(),
  restored: z.boolean()
});
