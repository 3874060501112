import User from 'assets/Images/Workflow/user.png';
import { getEmailSenders } from 'services/apiHandlers/Integration/Miscellaneous';
import Dropdown from 'components/Dropdown/ActionDropdown';
import {
  forwardRef,
  ForwardRefRenderFunction,
  RefObject,
  useEffect,
  useState,
} from 'react';
import { z } from 'zod';
import { SendEmailData } from '../index.type';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';
import React from 'react';

interface Props {
  emailData: SendEmailData | undefined;
  formIsValid: (valid: boolean) => void;
  senderRef: RefObject<HTMLDivElement>;
  onSenderDataChanged: (data: SenderData) => void;
  onFormTouch: () => void;
}

const senderDataSchema = z.object({
  fromEmailName: z.string(),
  fromEmailAddress: z.string(),
  replyToEmailAddress: z.string(),
  senderId: z.string(),
});
type SenderData = z.infer<typeof senderDataSchema>;

const dataSchema = z.object({
  accountId: z.number().optional(),
  email: z.string().optional(),
  id: z.string().optional(),
  links: z
    .object({ triggers: z.union([z.array(z.string()), z.tuple([])]) })
    .optional(),
  name: z.string().optional(),
  replyTo: z.string().optional(),
});
type data = z.infer<typeof dataSchema>;

const responseSchema = z.object({
  data: z.array(dataSchema),
  status: z.number(),
});
type response = z.infer<typeof responseSchema>;

const emailSenderForm = [
  { name: 'fromEmailName', placeholder: 'Name', label: 'From Email Name' },
  {
    name: 'fromEmailAddress',
    placeholder: 'Email',
    label: 'From Email Address',
  },
  {
    name: 'replyToEmailAddress',
    placeholder: 'Reply to',
    label: 'Reply to Email Address',
  },
];

const Sender: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { emailData, formIsValid, senderRef, onSenderDataChanged, onFormTouch },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref
) => {
  const [emailSenderData, setEmailSenderData] = useState<Array<data>>([]);
  const [customSelected, setCustomSelected] = useState<boolean>(true);
  const [selectedSender, setSelectedSender] = useState<string>();
  const [senderId, setSenderId] = useState<string>('');

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      fromEmailName: '',
      fromEmailAddress: '',
      replyToEmailAddress: '',
    },
    validationSchema: Yup.object({
      fromEmailName: Yup.string().required('Required'),
      fromEmailAddress: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      console.log('values', values);
    },
  });

  const getData = async () => {
    const getEmailSenderResponse = (await getEmailSenders()) as response;
    if (getEmailSenderResponse.status === 200) {
      const tempEmailData = [...getEmailSenderResponse.data];
      tempEmailData.unshift({ email: 'Custom' });
      if (emailData?.senderId) {
        tempEmailData.map((email) => {
          if (email.id === emailData.senderId) {
            setSelectedSender(email.name);
            setSenderId(emailData.senderId || '');
          }
        });
      }
      setEmailSenderData(tempEmailData);
    }
  };

  useEffect(() => {
    if (emailData) {
      const senderData = {
        fromEmailName: emailData?.fromEmailName,
        fromEmailAddress: emailData?.fromEmailAddress,
        replyToEmailAddress: emailData?.replyToEmailAddress,
      };
      formik.setValues(senderData);
      formIsValid(true);
    }

    getData();
  }, [emailData]);

  useEffect(() => {
    if (formik.dirty) {
      onFormTouch();
    }
    if (!formik.isValid) {
      formIsValid(false);
    } else {
      formIsValid(true);
    }
    onSenderDataChanged(formik.values);
  }, [
    formik.values,
    formik.errors,
    formik.isValid,
    formik.touched,
    emailSenderData,
  ]);

  return (
    <div ref={senderRef}>
      <div className="flex items-center my-6">
        <span className="mr-1.5 dark:invert dark:brightness-0">
          <img src={User} alt="User" />
        </span>
        <p className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium dark:text-white">
          Choose your email sender
        </p>
      </div>
      <div className="z-10 bg-white dark:bg-slate-800 border-gray-200 dark:border-black-400 border rounded-md dropdown-show-hide relative shadow-sm shadow-gray-200 sm:h-11 h-9 dark:bg-slate-800 dark:text-white">
        <Dropdown
          options={emailSenderData}
          getData={getData}
          workflow={true}
          senderId={senderId}
          onSelect={(select, id) => {
            onFormTouch();
            if (select) {
              formik.setValues({});
              onSenderDataChanged({
                ...formik.initialValues,
                senderId: '',
              });
          
            } else {
              formIsValid(true);
              const selectedSenderData = emailSenderData.filter(
                (senderData) => {
                  return id === senderData.id;
                }
              );

              onSenderDataChanged({
                fromEmailName: selectedSenderData[0].name || '',
                fromEmailAddress: selectedSenderData[0].email || '',
                replyToEmailAddress: selectedSenderData[0].replyTo || '',
                senderId: id || '',
              });
            }
            setCustomSelected(select);
          }}
          selectedSender={selectedSender}
        />
      </div>
      {customSelected &&
        emailSenderForm.map((input) => (
          <div key={input.name}>
            <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 block my-4 dark:text-white">
              {input.label}
            </label>
            <div className="shadow-sm shadow-gray-200 dark:shadow-none rounded-md">
              <input
                className="text-13 font-medium leading-3 text-gray-700 py-2 rounded-md px-4 bg-white border border-gray-600 focus:outline-none focus:border-primary sm:h-11 h-9 w-full dark:bg-slate-800 dark:text-white"
                type="text"
                name={input.name}
                value={formik.values[input.name]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
        ))}
    </div>
  );
};
export default forwardRef(Sender);
