export const home = '/';
export const login = '/login';
export const listSegments = '/list&segments';
export const createSegment = '/create-segment';
export const integration = '/integrations';
export const campaigns = '/campaigns';
export const emailTemplates = '/email-templates';
export const createCampaign = '/create-campaign';
export const emailEditor = '/email';
export const workflow = '/workflows';
export const previewEmail = '/preview-email';
export const workflowReports = '/workflow-reports';
export const storeReports = 'store_report';
export const activity = 'activity';
export const calendar = 'calendar';
export const matches = 'matches';
export const add = 'add';
export const filter = 'filter';
export const campaignReports = '/campaign-reports';
export const create = 'create';
export const edit = 'edit';
export const id = ':id';
export const nestedId = ':idNested';
export const logs = 'logs';
export const subscribers = 'subscribers';
export const filters = 'filters';
export const segmentDefinition = 'segment-definition';
export const view = 'view';
export const revenues = '/revenues';
export const eventLogs = '/event-logs';
export const upload = 'upload';
export const fields = 'fields';
export const listId = 'list_id';
export const list = 'list';
export const profile = 'profile';
export const report = 'report';
export const campaignId = 'campaignId';
export const reports = 'reports';
export const tab = 'tab';
