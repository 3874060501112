import Reach from 'components/Campaigns/Body/Overview/Reach';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { ChartDataset } from 'chart.js';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSingleCampaign } from 'services/apiHandlers/Dashboard/Analytics';
import { BlastSchema } from 'components/DashboardReports/Types';
import { z } from 'zod';
import Header from './Header';
import BarGraph from 'components/Graph/BarGraph';

const BlastResponseSchema = z.object({ data: BlastSchema, status: z.number() });
type BlastResponse = z.infer<typeof BlastResponseSchema>;
type Blast = z.infer<typeof BlastSchema>;

const CampaignReports = () => {
  const { id } = useParams();
  const [blastData, setBlastData] = useState<Blast>();
  const [campaignsLoading, setCampaignsLoading] = useState<boolean>(true);

  const getCampiagn = async (id: string) => {
    setCampaignsLoading(true);
    try {
      const getSingleCampiagnResponse = (await getSingleCampaign(
        id
      )) as BlastResponse;
      if (getSingleCampiagnResponse.status === 200) {
        setBlastData(getSingleCampiagnResponse.data);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setCampaignsLoading(false);
  };

  useEffect(() => {
    getCampiagn(id || '');
  }, []);
  return (
    <div>
      <div className="mt-6 ml-2">
        <Breadcrumbs />
      </div>
      <Header blastData={blastData} campaignsLoading={campaignsLoading} />
      <div className="mt-6 ml-4">
        <Reach blastData={blastData} />
      </div>
      <div>
        <BarGraph
          height={167}
          chartLabels={[
            'Total Emails Sent',
            'Opens',
            'Clicks',
            'Deliverability',
          ]}
          datasets={
            [
              {
                label: 'Email Reports',
                data: [
                  blastData?.blast.delivered,
                  blastData?.blast.unique_views_count,
                  blastData?.blast.unique_clicks_count,
                  blastData?.blast.bounced,
                ],
                backgroundColor: '#2EA1F9',
                barPercentage: 1,
                barThickness: 25,
              },
            ] as ChartDataset<'bar', (number | [number, number] | null)[]>[]
          }
          style={{ maxWidth: '450px', width: '100%' }}
        />
      </div>
    </div>
  );
};
export default CampaignReports;
