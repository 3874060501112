import ConnectedServices from './ConnectedServices';
import EmailEvents from './EmailEvents';
import ConversionPeriod from './ConversionPeriod';
import WorkflowActions from './WorkflowActions';
import ConversionEvent from './ConversionEvent';
import EmailSender from './EmailSender';
import ErrorBoundary from 'components/ErrorBoundary';
import React from "react";
const Miscellaneous = () => {
  return (
    <ErrorBoundary>
      <div id="otherconversion_content" className="tabcontent">
        <div className="md:px-6 px-3 pt-4 pb-6 rounded bg-white border border-gray-800 dark:border-black-400 dark:bg-slate-800">
          <div>
            <div className="lg:flex flex-wrap">
              <div className="lg:w-6/12 lg:pr-5">
                <ConnectedServices />
                <ConversionPeriod />
                <ConversionEvent />
              </div>
              <div className="lg:w-6/12 lg:pl-5 lg:mt-0 mt-8">
                <EmailEvents />
                <WorkflowActions />
                <EmailSender />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default Miscellaneous;
