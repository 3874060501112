import Dropdown from 'components/Dropdown/valueDropdown';
import { FC, useContext, useEffect, useState } from 'react';
import { Categories, Collections } from './EmailCreationSteps';
import { z } from 'zod';
import { html } from 'utils/html';
import { EmailContext } from 'store/EmailContext';
import Thumbnail from 'assets/Images/Campaign/thumbnail.gif';
import { Toastify } from 'App';
import {
  copyPasteHtmlHandler,
  sendTemplate,
} from 'services/apiHandlers/Campaigns/Emails';
import { EmailDataResponse } from '.';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { create, emailEditor } from 'services/constant/routes';

const options = z.object({
  name: z.string(),
  value: z.string(),
});
type option = z.infer<typeof options>;

const emailDataSchema = z.object({
  account_id: z.number(),
  agreed_to_canspam_address_for_html: z.number().nullable(),
  agreed_to_canspam_address_for_text: z.number().nullable(),
  content_type: z.string(),
  created_at: z.string(),
  creator_id: z.number(),
  creator_type: z.string(),
  css: z.string(),
  description: z.string().nullable(),
  html_part: z.string(),
  id: z.number(),
  name: z.string(),
  process: z.string(),
  save_type: z.string(),
  size_in_bytes: z.number(),
  source_email_id: z.number(),
  subject: z.string().nullable(),
  tag_list: z.union([z.array(z.string()), z.tuple([])]),
  template_id: z.number(),
  template_name: z.string(),
  template_category_name: z.string(),
  template_type: z.string(),
  text_part: z.string(),
  updated_at: z.string(),
  widgetization_error: z.string().nullable(),
});

const submitEmailResponseSchema = z.object({
  status: z.number(),
  data: z.object({
    draft: z.object({ email: emailDataSchema }),
    redirect_to: z.string(),
  }),
});
export type SubmitEmailResponse = z.infer<typeof submitEmailResponseSchema>;

interface Props {
  categories: Categories[];
  collections: Collections[];
}

const Template: FC<Props> = ({ categories, collections }) => {
  const [categoryOptions, setCategoryOptions] = useState<Array<option>>([]);
  const [selectedCategory, setSelectedCategory] =
    useState<string>('- Featured -');
  const [templateCollections, setTemplateCollections] =
    useState<Array<Collections>>();
  const [checkedTemplate, setCheckedTemplate] = useState<Array<boolean>>([]);
  const [templateImages, setTemplateImages] = useState<Array<string>>([]);
  const [loadedHtml, setLoadedHtml] = useState<string>();
  const [buildChecked, setBuildChecked] = useState<boolean>(false);
  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [templateId, setTemplateId] = useState<number>(0);
  const navigate = useNavigate();
  const { REACT_APP_BASE_URL } = process.env;
  const URL = REACT_APP_BASE_URL;
  const emailCtx = useContext(EmailContext);

  const getImageSrc = (url: string) => {
    const img = new Image();
    img.src = url;
    if (img.onerror) {
      return `${URL}/images/icons/thumbnail.png`;
    } else {
      return url;
    }
  };

  const categoryChangeHandler = (value: string) => {
    setFormIsValid(false);
    const tempSelectedCategory = categories.filter(
      (category) =>
        category.prebuilt_template_category.library_id.toString() === value
    );

    const tempCollections = collections.filter(
      (collection) =>
        collection.prebuilt_template.library_id.toString() === value
    );

    const tempImages = tempCollections.map((collection) => {
      return getImageSrc(
        `${URL}/images/templates/${collection.prebuilt_template.id}/thumbnail.gif`
      );
    });

    setTemplateImages(tempImages);
    setTemplateCollections(tempCollections);
    setCheckedTemplate(new Array(tempCollections.length).fill(false));
    setSelectedCategory(
      tempSelectedCategory.length == 0
        ? '- Featured -'
        : tempSelectedCategory[0].prebuilt_template_category.name
    );
  };

  const loadTemplateHandler = async () => {
    if (loadedHtml) {
      if (buildChecked) {
        const data = {
          copy_paste: loadedHtml,
          commit: 'Submit',
        };
        try {
          const submitHtmlResponse = (await copyPasteHtmlHandler(
            data
          )) as SubmitEmailResponse;
          if (submitHtmlResponse.status === 200) {
            emailCtx?.htmlSubmitHandler(loadedHtml);
            navigate(`${emailEditor}?${create}=true`);
          }
        } catch (error) {
          Toastify('Sending Template Failed', 'error', 'custom2');
        }
      } else {
        const templateData = {
          html_length: '',
          blank_template_id: 12,
          new_template_id: templateId,
          new_template_type: 'PrebuiltTemplate',
          old_template_id: '',
        };
        try {
          const sendTemplateResponse = (await sendTemplate(
            templateData
          )) as EmailDataResponse;
          if (sendTemplateResponse.status === 200) {
            emailCtx?.htmlSubmitHandler(loadedHtml);
            emailCtx?.templateInfoHandler({
              name: sendTemplateResponse.data.draft.email.template_name,
              type: sendTemplateResponse.data.draft.email.template_category_name,
            });
            navigate(`${emailEditor}?${create}=true`);
          }
        } catch (error) {
          Toastify('Sending Template Failed', 'error', 'custom1');
        }
      }
    }
  };

  useEffect(() => {
    const tempCategories = categories.map((category) => {
      return {
        name: category.prebuilt_template_category.name,
        value: category.prebuilt_template_category.library_id.toString(),
      };
    });
    setCategoryOptions(tempCategories);
  }, [categories, checkedTemplate]);

  return (
    <div>
      <p className="pl-7 text-sm font-normal leading-5 text-616161 mt-1">
        Customize a professionally designed e-mail template
      </p>
      <div className="ml-7 p-2 flex items-center mt-3 md:w-8/12 bg-f6f7f9">
        <div className="sm:flex items-center w-full">
          <label className="text-sm font-normal leading-4 text-212121 mr-2">
            Filter by Category:
          </label>
          <div className="sm:w-56 relative border border-bdbdbd sm:mt-0 mt-2 rounded-md">
            <Dropdown
              options={[
                { name: '- Featured -', value: '' },
                ...categoryOptions,
              ]}
              onSelect={categoryChangeHandler}
              value={selectedCategory}
            />
          </div>
        </div>
      </div>
      <div className="mt-4 ml-7 w-48 mr-auto pl-4">
        {templateCollections && templateCollections?.length > 0 ? (
          templateCollections.map((collections, index) => (
            <div key={collections.prebuilt_template.id}>
              <div className="border p-0.5">
                <img src={templateImages[index]} />
              </div>
              <div className="flex items-center mt-4">
                <input
                  className=""
                  type="radio"
                  name="radio"
                  id={collections.prebuilt_template.id.toString()}
                  checked={checkedTemplate[index]}
                  onChange={(event) => {
                    setTemplateId(collections.prebuilt_template.id);
                    setFormIsValid(true);
                    setBuildChecked(false);
                    if (event.target.checked) {
                      const newCheckedTemplate = checkedTemplate.map(
                        (_, idx) => {
                          return idx === index ? true : false;
                        }
                      );
                      setCheckedTemplate(newCheckedTemplate);
                      setLoadedHtml(collections.prebuilt_template.html_part);
                    }
                  }}
                />
                <label
                  htmlFor={collections.prebuilt_template.id.toString()}
                  className="radio-btn block pl-4 relative fs-13 font-normal leading-3 text-616161"
                >
                  {collections.prebuilt_template.name}
                </label>
              </div>
            </div>
          ))
        ) : (
          <div>
            <div className="border p-0.5">
              <img src={Thumbnail} />
            </div>
            <div className="flex items-center mt-4">
              <input
                className=""
                type="radio"
                name="radio"
                id="build"
                checked={buildChecked}
                onChange={(event) => {
                  setFormIsValid(true);
                  setBuildChecked(event.target.checked);
                  setLoadedHtml(html);
                }}
              />
              <label
                htmlFor="build"
                className="radio-btn block pl-4 relative fs-13 font-normal leading-3 text-616161"
              >
                Build Your Own
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="mt-5">
        <div className="py-1.5 px-2 bg-f7f4ff rounded mt-4">
          <p className="text-xs font-normal leading-5 text-616161">
            Showing <strong>1-1</strong> of <strong>1</strong>
          </p>
        </div>
        <div className="text-right mt-3">
          <button
            className={`${
              !formIsValid
                ? 'border border-gray-800 bg-gray-200 text-black-400'
                : 'text-white bg-btnPrimary ease-in-in duration-300 hover:bg-primary hover:scale-105'
            } text-sm font-medium leading-5 flex justify-center items-center ml-auto rounded-md py-1.5 px-5 font-inter h-11 w-28`}
            type="button"
            disabled={!formIsValid}
            onClick={loadTemplateHandler}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};
export default Template;
