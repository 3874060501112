import { useState, createContext } from 'react';
import { z } from 'zod';
import React from 'react';
import moment from 'moment';

const RoiWeeklyResponseDataSchema = z.object({
  average_time_for_0th_purchase: z.number(),
  average_time_since_last_order: z.number(),
  blast_conversions: z.string(),
  blast_conversions_value: z.string(),
  break_even: z.string(),
  company: z.string(),
  conversions: z.string(),
  conversions_value: z.string(),
  email_click: z.string(),
  email_open: z.string(),
  email_recipients: z.string(),
  end_date: z.string(),
  monthly_fee: z.string(),
  new_buyers_count: z.string(),
  new_prospects: z.string(),
  orders: z.string(),
  profit: z.string(),
  repeated_buyers_count: z.string(),
  revenue: z.string(),
  roi: z.string(),
  shoppers_browsing: z.string(),
  start_date: z.string(),
  weekly_fee: z.string(),
});
export type RoiWeeklyData = z.infer<typeof RoiWeeklyResponseDataSchema>;

const SingleCampaignSchema = z.object({
  monetaryValue: z.number(),
  campaignName: z.string(),
  conversions: z.string().optional(),
  id: z.number().optional(),
  date: z.string().optional(),
  eventDate: z.string().optional(),
});

const CampaignsSchema = z.array(SingleCampaignSchema);

type Campaigns = z.infer<typeof CampaignsSchema>;

const SingleWorkflowSchema = z.object({
  bounces: z.number(),
  clicks: z.number(),
  deliveries: z.number(),
  failures: z.number(),
  monetaryValue: z.number(),
  skips: z.number(),
  inactiveSkips: z.number(),
  unsubscribeSkips: z.number(),
  sends: z.number(),
  uniqueClicks: z.number(),
  uniqueConversions: z.number(),
  uniqueViews: z.number(),
  uniquePeople: z.number(),
  unsubscribes: z.number(),
  views: z.number(),
  workflowId: z.string(),
  workflowName: z.string(),
  date: z.string().optional(),
});
const StrategiesSchema = z.array(SingleWorkflowSchema);
type Workflows = z.infer<typeof StrategiesSchema>;

const StoreReportDataSchema = z.object({
  startDate: z.union([z.string(), z.date()]),
  endDate: z.union([z.string(), z.date()]),
});

export type StoreReportData = z.infer<typeof StoreReportDataSchema>;

interface ContextType {
  storeReportDataHandler: (name: string, value: number | string) => void;
  storeReportData: StoreReportData;
  workflows: Workflows;
  campaigns: Campaigns;
  loading: boolean;
  workflowChangeHanlder: (workflows: Workflows) => void;
  campaignChangeHandler: (campaigns: Campaigns) => void;
  loadingStateHandler: (loadingState: boolean) => void;
  weeklyRoiData: RoiWeeklyData | undefined;
  weeklyRoiDataHandler: (data: RoiWeeklyData) => void;
  orderDates: number[];
  orderDateChangeHandler: (orders: number[]) => void;
}
export const DashboardContext = createContext<ContextType | null>(null);
type Props = {
  children: JSX.Element;
};

export const DashboardContextProvider = ({ children }: Props) => {
  const [weeklyRoiData, setWeeklyRoiData] = useState<RoiWeeklyData>();
  const [workflows, setWorkflows] = useState<Workflows>([]);
  const [campaigns, setCampaigns] = useState<Campaigns>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [orderDates, setOrderDates] = useState<Array<number>>([]);

  const [storeReportData, setStoreReportData] = useState<StoreReportData>({
    startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  });
  const storeReportDataHandler = (name: string, value: number | string) => {
    setStoreReportData({ ...storeReportData, [name]: value });
  };
  const workflowChangeHanlder = (workflows: Workflows) => {
    setWorkflows(workflows);
  };
  const campaignChangeHandler = (campaigns: Campaigns) => {
    setCampaigns(campaigns);
  };
  const weeklyRoiDataHandler = (data: RoiWeeklyData) => {
    setWeeklyRoiData(data);
  };
  const loadingStateHandler = (loadingState: boolean) => {
    setLoading(loadingState);
  };
  const orderDateChangeHandler = (orders: number[]) => {
    setOrderDates(orders);
  };

  return (
    <DashboardContext.Provider
      value={{
        storeReportDataHandler,
        storeReportData,
        workflows,
        campaigns,
        loading,
        workflowChangeHanlder,
        campaignChangeHandler,
        loadingStateHandler,
        weeklyRoiData,
        weeklyRoiDataHandler,
        orderDates,
        orderDateChangeHandler,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
