import { FC, useContext } from 'react';
import { useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import Table from 'components/Table';
import { tableSort } from 'utils/common';
import CampaignNames from 'components/CompaignColumns/CompaignNames';
import CampaignStatus from 'components/CompaignColumns/CompaignStatus';
import CampaignActions from 'components/CompaignColumns/CompaignActions';
import { z } from 'zod';
import styled from 'styled-components';
import React from 'react';
import { UserContext } from 'store/UserContext';
import moment from 'moment-timezone';

const StyleWrapper = styled.div`
  .rdt_TableRow {
    height: 50px;
  }

  .rdt_TableRow .rdt_TableCell {
    border-right: 1px solid #e0e0e0;
  }

  .rdt_TableRow .rdt_TableCell:last-child {
    border-right: 0;
  }

  .rdt_TableHeadRow .rdt_TableCol {
    border-right: 1px solid #e0e0e0;
  }
  .rdt_TableHeadRow .rdt_TableCol:last-child {
    border-right: 0;
  }
  .rdt_TableHeadRow .rdt_TableCol:nth-child(3) {
    max-width: 20%;
  }
  .rdt_TableRow .rdt_TableCell:nth-child(3) {
    max-width: 20%;
  }
`;
const StyledComponent = styled.div`
  [data-column-id='2'] {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
  }
`;

const emailTriggerSchema = z.object({ name: z.string(), id: z.number() });

const campaignEmailSchema = z.object({
  id: z.number(),
  name: z.string(),
  created_at: z.string(),
  html_part_present: z.boolean(),
  html_part_blank: z.boolean(),
  include_event: z.boolean(),
  needs_widgetization: z.boolean(),
  widgetization_error: z.boolean(),
  new_editor_compatible: z.boolean(),
  text_part_present: z.boolean(),
  text_part_blank: z.boolean(),
  email_triggers: z.array(emailTriggerSchema),
});
type SinglecampaignEmail = z.infer<typeof campaignEmailSchema>;

interface Props {
  emails: SinglecampaignEmail[];
  readonly: boolean;
  loading: boolean;
  onDelete: () => void;
  total: number;
  onPageChange: (page: number) => void;
}

const Email: FC<Props> = ({
  emails,
  readonly,
  loading,
  onDelete,
  total,
  onPageChange,
}) => {
  const [actionTooltipOpen, setActionTooltipOpen] = useState<boolean>(false);
  const userCtx = useContext(UserContext);

  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  const campaignSort = (
    rowA: SinglecampaignEmail,
    rowB: SinglecampaignEmail
  ) => {
    return tableSort(rowA.name, rowB.name);
  };

  const columns: TableColumn<SinglecampaignEmail>[] = useMemo(
    () => [
      {
        name: 'Name',
        cell: (row, index) => {
          return <CampaignNames row={row} index={index} />;
        },
        sortable: true,
        sortFunction: campaignSort,
      },
      {
        name: 'Created At',
        cell: (row) => {
          return (
            <div className="">
              <div className="mb-1.5 dark:text-white">
                {moment
                  .tz(row.created_at, userCtx?.usersData?.timeZone || '')
                  .format('MMMM D, YYYY [at] h:mm A')}
              </div>
            </div>
          );
        },
      },
      {
        name: 'Status',
        cell: (row) => {
          return <CampaignStatus row={row} readonly={readonly} />;
        },
      },
      {
        name: 'Actions',
        omit: !!readonly,
        cell: (row: SinglecampaignEmail) => {
          return (
            <CampaignActions
              row={row}
              actionTooltipOpen={actionTooltipOpen}
              setActionTooltipOpen={setActionTooltipOpen}
              onDelete={onDelete}
            />
          );
        },
      },
    ],
    [readonly, actionTooltipOpen]
  );

  return (
    <StyleWrapper>
      <StyledComponent>
        <Table
          onRowMouseLeave={() => {
            setActionTooltipOpen(false);
          }}
          pagination
          paginationTotalRows={total}
          paginationServer
          onChangePage={handlePageChange}
          className="scrollbar-hide"
          data={emails}
          columns={columns}
          progressPending={loading}
        />
      </StyledComponent>
    </StyleWrapper>
  );
};
export default Email;
