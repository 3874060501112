import { useContext, useEffect, useState } from 'react';
import TopPerformingWorkflows from 'components/Dashboard/Body/TopPerformingWorkflows';
import TopPerformingCampaigns from 'components/Dashboard/Body/TopPerformingCampaigns';
import SentEmails from 'components/DashboardReports/Body/Analytics/SentEmails';
import { Outlet } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { DashboardContext } from 'store/DashboardContext';
import { RoiWeeklyResponse } from 'components/DashboardReports/Types';
import { getWeeklyRoiReport } from 'services/apiHandlers/Dashboard/Analytics';
import moment from 'moment';
import Calendar from 'components/Dashboard/Body/Calendar';
import TotalOrder from 'components/Dashboard/Body/Orders/TotalOrders';
import AverageRevenue from 'components/Dashboard/Body/Orders/AverageRevenue';
import Revenue from 'components/Dashboard/Body/Orders/Revenue';
import AverageOpenRate from 'components/Dashboard/Body/EmailPerformanceStats/AverageOpenRate';
import AverageClickRate from 'components/Dashboard/Body/EmailPerformanceStats/AverageClickRate';
import AverageDeliverability from 'components/Dashboard/Body/EmailPerformanceStats/AverageDeliverability';
import AverageConversionRate from 'components/Dashboard/Body/EmailPerformanceStats/AverageConversionRate';
import LatestSubscribers from 'components/Dashboard/Body/LatestSubscribers';
import Revenues from 'components/Revenues-Orders';
import {
  getBlastByStatus,
  getBlastMonetaryValue,
} from 'services/apiHandlers/Dashboard/Analytics';
import { BlastResponse, Campaigns } from 'components/DashboardReports/Types';
import AllWokflows from './AllWorkflows';
import EventsLogs from 'components/Dashboard/Body/EventsLogs';
import { getOrderRevenueReport } from 'services/apiHandlers/Dashboard/StoreReport';
import { storeReportOrder } from 'components/DashboardReports/Body/Store-Report/Types';
import {
  calculatePercentageDifference,
  convertStringToWholeNumber,
} from 'utils/common';
import { z } from 'zod';
import React from 'react';

const DateSchema = z.object({ date: z.string(), total: z.number() });
const OrderSchema = z.object({
  metric: z.string(),
  dates: z.array(DateSchema),
});
const OrderResponseSchema = z.object({
  data: z.array(OrderSchema),
  status: z.number(),
});
type OrderResponse = z.infer<typeof OrderResponseSchema>;

const BlastValueSchema = z.object({
  monetaryValue: z.number().optional(),
  campaignName: z.string().optional(),
  conversions: z.number().optional(),
  id: z.number().optional(),
  date: z.string().optional(),
  uniqueConversion: z.string().optional(),
  eventDate: z.string().optional(),
});

type BlastValues = z.infer<typeof BlastValueSchema>;

const BlastMonetaryValueResponseDataSchema = z.object({
  blastId: z.number(),
  conversions: z.number(),
  monetaryValue: z.number(),
});

const BlastMonetaryValueResponseSchema = z.object({
  data: z.array(BlastMonetaryValueResponseDataSchema),
  status: z.number(),
});

type BlastMonetaryValueResponse = z.infer<
  typeof BlastMonetaryValueResponseSchema
>;

const Dashboard = () => {
  const [orders, setOrders] = useState<storeReportOrder>();
  const [averageRevenue, setAverageRevenue] = useState<storeReportOrder>();
  const [revenue, setRevenue] = useState<storeReportOrder>();

  const dashboardCtx = useContext(DashboardContext);

  const getData = async () => {
    dashboardCtx?.loadingStateHandler(true);
    const currentMonthDate = moment().format('YYYY-MM-DD');
    const previousSixMonthDate = moment()
      .subtract(5, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');

    const previousYearDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
    const previousYearSixMonthsDate = moment(previousYearDate)
      .subtract(5, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');

    const labels: string[] = [];
    const tempOrderLabels: string[] = [];
    const tempCurrentYearOrderData: string[] = [];
    const tempCurrentOrderData: string[] = [];
    const tempAverageRevenueLabels: string[] = [];
    const tempPreviousYearAverageRevenueData: string[] = [];
    const tempCurrentYearAverageRevenueData: string[] = [];
    const tempRevenueLabels: string[] = [];
    const tempPreviousYearRevenueData: string[] = [];
    const tempCurrentYearRevenueData: string[] = [];

    try {
      let tempBlastValues: BlastValues[] = [];

      const getBlastResponse = (await getBlastByStatus(
        dashboardCtx?.storeReportData.startDate.toString(),
        dashboardCtx?.storeReportData.endDate.toString()
      )) as BlastResponse;

      if (getBlastResponse.status === 200) {
        tempBlastValues = getBlastResponse.data.collection.map((data) => ({
          id: data.blast.id,
          campaignName: data.blast.subject,
          date: moment(data.blast.created_at).format('MMM D'),
          eventDate: data.blast.created_at,
        }));

        const getBlastMonetaryValueResponse = (await getBlastMonetaryValue(
          tempBlastValues.map((data) => data.id).join(',')
        )) as BlastMonetaryValueResponse;

        if (getBlastMonetaryValueResponse.status === 200) {
          tempBlastValues = getBlastMonetaryValueResponse.data.map(
            (data, index) => ({
              monetaryValue: data.monetaryValue,
              campaignName: tempBlastValues[index].campaignName,
              date: tempBlastValues[index].date,
              conversions: data.conversions,
              eventDate: tempBlastValues[index].eventDate,
              id: tempBlastValues[index].id,
            })
          );
        }
        dashboardCtx?.campaignChangeHandler(tempBlastValues as Campaigns);
      }

      const getCurrentOrderResponse = (await getOrderRevenueReport(
        previousSixMonthDate,
        currentMonthDate
      )) as OrderResponse;

      const getPreviousOrderResponse = (await getOrderRevenueReport(
        previousYearSixMonthsDate,
        previousYearDate
      )) as OrderResponse;

      if (getCurrentOrderResponse.status === 200) {
        getCurrentOrderResponse.data.map((data) =>
          data.dates.map(
            (date) => (
              data.metric === 'Orders' &&
                tempOrderLabels.push(
                  moment(date.date, 'MMMM YYYY').format('MMM')
                ),
              data.metric === 'Orders' &&
                tempCurrentOrderData.push(date.total.toString()),
              data.metric === 'Average Revenue' &&
                tempAverageRevenueLabels.push(
                  moment(date.date, 'MMMM YYYY').format('MMM')
                ),
              data.metric === 'Average Revenue' &&
                tempPreviousYearAverageRevenueData.push(date.total.toString()),
              data.metric === 'Revenue' &&
                tempRevenueLabels.push(
                  moment(date.date, 'MMMM YYYY').format('MMM')
                ),
              data.metric === 'Revenue' &&
                tempPreviousYearRevenueData.push(date.total.toString()),
              data.metric === 'Revenue' &&
                labels.push(moment(date.date, 'MMMM YYYY').format('MMM'))
            )
          )
        );
      }

      if (getPreviousOrderResponse.status === 200) {
        getPreviousOrderResponse.data.map((data) =>
          data.dates.map(
            (date) => (
              data.metric === 'Orders' &&
                tempOrderLabels.push(
                  moment(date.date, 'MMMM YYYY').format('MMM')
                ),
              data.metric === 'Orders' &&
                tempCurrentYearOrderData.push(date.total.toString()),
              data.metric === 'Average Revenue' &&
                tempAverageRevenueLabels.push(
                  moment(date.date, 'MMMM YYYY').format('MMM')
                ),
              data.metric === 'Average Revenue' &&
                tempCurrentYearAverageRevenueData.push(date.total.toString()),
              data.metric === 'Revenue' &&
                tempRevenueLabels.push(
                  moment(date.date, 'MMMM YYYY').format('MMM')
                ),
              data.metric === 'Revenue' &&
                tempCurrentYearRevenueData.push(date.total.toString())
            )
          )
        );
      }

      const averageRevenueData = [
        {
          label: ' Current Period',
          data: tempPreviousYearAverageRevenueData,
          backgroundColor: '#2EA1F9',
          barPercentage: 1.0,
          barThickness: 25,
        },
        {
          label: ' Previous Period',
          data: tempCurrentYearAverageRevenueData,
          backgroundColor: '#8FDBC0',
          barPercentage: 1.0,
          barThickness: 25,
        },
      ];

      const revenueData = [
        {
          label: ' Current Period',
          data: tempPreviousYearRevenueData,
          borderColor: '#FBB846',
          backgroundColor: '#FBB846',
        },
        {
          label: ' Previous Period',
          data: tempCurrentYearRevenueData,
          borderColor: '#A292DA',
          backgroundColor: '#A292DA',
        },
      ];

      const OrdersData = [
        {
          label: '  Current Period',
          data: tempCurrentOrderData,
          borderColor: '#FBB846',
          backgroundColor: '#FBB846',
        },
        {
          label: '  Previous Period',
          data: tempCurrentYearOrderData,
          borderColor: '#A292DA',
          backgroundColor: '#A292DA',
        },
      ];

      const currentTotalOrders = tempCurrentOrderData.reduce(
        (accumulator, orderData) => {
          return accumulator + parseInt(orderData);
        },
        0
      );

      const previousTotalOrders = tempCurrentYearOrderData.reduce(
        (accumulator, orderData) => {
          return accumulator + parseInt(orderData);
        },
        0
      );

      const ordersPercenatge = calculatePercentageDifference(
        previousTotalOrders,
        currentTotalOrders
      );

      const lastThreeMonthOrders = tempCurrentOrderData
        .slice(3, tempCurrentOrderData.length)
        .reduce((accumulator, orderData) => {
          return accumulator + parseInt(orderData);
        }, 0);

      const currentOrderLastThreeMonths = tempCurrentOrderData.reduce(
        (accumulator, orderData) => {
          return accumulator + parseInt(orderData);
        },
        0
      );

      const totalOrders = {
        orderType: 'Total Orders',
        orderValue: currentTotalOrders.toString(),
        orderPercentage: ordersPercenatge.toString(),
        graphType: 'Line',
        graphCategories: labels,
        graphData: OrdersData,
        graphHeight: 280,
        graphWidth: 450,
        graphStyle: { maxWidth: '450px', width: '100%', height: 'auto' },
        lastMonth: tempCurrentOrderData[tempCurrentOrderData.length - 1],
        last90Days: lastThreeMonthOrders.toString(),
        last14Days: currentOrderLastThreeMonths.toString(),
      };

      const totalAverageRevenue = tempPreviousYearAverageRevenueData.reduce(
        (accumulator, averageRevenue) => {
          return accumulator + parseInt(averageRevenue);
        },
        0
      );

      const lastThreeMonthAverageRevenue = Math.floor(
        tempPreviousYearAverageRevenueData
          .slice(3, tempCurrentOrderData.length)
          .reduce((accumulator, averageRevenue) => {
            return accumulator + parseInt(averageRevenue);
          }, 0)
      );

      const previousAverageRevenues = tempCurrentYearAverageRevenueData.reduce(
        (accumulator, averageRevenue) => {
          return accumulator + parseFloat(averageRevenue);
        },
        0
      );

      const averageRevenuePercentage = calculatePercentageDifference(
        previousAverageRevenues,
        totalAverageRevenue
      );

      const lastSixMonthAverageRevenue = Math.floor(
        tempPreviousYearAverageRevenueData.reduce(
          (accumulator, averageRevenueData) => {
            return accumulator + parseInt(averageRevenueData);
          },
          0
        )
      );

      const lastAverageMonth = convertStringToWholeNumber(
        tempPreviousYearAverageRevenueData[
          tempPreviousYearAverageRevenueData.length - 1
        ]
      );

      const AverageRevenue = {
        orderType: 'Average Revenue',
        orderValue: `$${totalAverageRevenue.toString()}`,
        orderPercentage: averageRevenuePercentage.toString(),
        graphType: 'Coloumn',
        graphCategories: labels,
        graphData: averageRevenueData,
        graphStyle: { maxWidth: '450px', width: '100%' },
        graphHeight: 186,
        lastMonth: `$${lastAverageMonth.toString()}`,
        last90Days: `$${lastThreeMonthAverageRevenue}`,
        last14Days: `$${lastSixMonthAverageRevenue}`,
      };

      const totalRevenue = tempPreviousYearRevenueData.reduce(
        (accumulator, revenue) => {
          return accumulator + parseInt(revenue);
        },
        0
      );

      const lastThreeMonthRevenue = Math.floor(
        tempPreviousYearRevenueData
          .slice(3, tempPreviousYearRevenueData.length)
          .reduce((accumulator, revenue) => {
            return accumulator + parseInt(revenue);
          }, 0)
      );

      const previousYearsRevenue = tempCurrentYearRevenueData.reduce(
        (accumulator, revenue) => {
          return accumulator + parseInt(revenue);
        },
        0
      );

      const totalRevenuePercentage = calculatePercentageDifference(
        previousYearsRevenue,
        totalRevenue
      );

      const lastSixMonthRevenue = Math.floor(
        tempPreviousYearRevenueData.reduce((accumulator, revenueData) => {
          return accumulator + parseInt(revenueData);
        }, 0)
      );

      const revenue = {
        orderType: 'Revenue',
        orderValue: `$${totalRevenue.toString()}`,
        orderPercentage: totalRevenuePercentage.toString(),
        graphType: 'Line',
        graphCategories: labels,
        graphData: revenueData,
        graphHeight: 280,
        graphWidth: 450,
        graphStyle: { maxWidth: '450px', width: '100%', height: 'auto' },
        lastMonth: `$${convertStringToWholeNumber(
          tempPreviousYearRevenueData[tempPreviousYearRevenueData.length - 1]
        )}`,
        last90Days: `$${lastThreeMonthRevenue.toString()}`,
        last14Days: `$${lastSixMonthRevenue}`,
      };

      const getWeeklyRoiResponse =
        (await getWeeklyRoiReport()) as RoiWeeklyResponse;

      if (getWeeklyRoiResponse.status === 200) {
        dashboardCtx?.weeklyRoiDataHandler(getWeeklyRoiResponse.data);
      }
      setOrders(totalOrders);
      setAverageRevenue(AverageRevenue);
      setRevenue(revenue);
    } catch (error) {
      console.log('error is : ', error);
    }
    dashboardCtx?.loadingStateHandler(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
    <div className="pt-5 bg-gray-300 dark:bg-slate-800 h-full">
        <div className="ml-2 p-4">
            <Breadcrumbs />
            <Outlet />
            <div className="flex 2xl:flex-row flex-col gap-4 w-full ">
                <div className="2xl:w-[70%] w-full">
                    <div className="flex md:flex-nowrap flex-wrap justify-between gap-4 mb-4">
                        <div className="md:w-full sm:w-[48%] w-full">
                            <AverageOpenRate />
                        </div>
                        <div className="md:w-full sm:w-[48%] w-full">
                            <AverageClickRate />
                        </div>
                        <div className="md:w-full sm:w-[48%] w-full">
                            <AverageDeliverability />
                        </div>
                        <div className="md:w-full sm:w-[48%] w-full">    
                            <AverageConversionRate />
                        </div>
                    </div>
                    <div className="flex md:flex-row flex-col gap-4 ">
                        <div className="2xl:w-[66%] lg:w-[70%] w-full mb-4">
                            <Revenues />
                        </div>
                        <div className="mb-4">
                            <LatestSubscribers />
                        </div>
                    </div>
                    
                   
                </div>
                <div className="2xl:w-[30%] 2xl:block flex md:flex-row flex-col mb-4 h-fit justify-between">
                    <div className="mb-4 2xl:w-full md:w-[32.3%]">
                        <SentEmails />
                    </div>
                    <div className="mb-5 2xl:w-full md:w-[32.3%]">
                            <TopPerformingCampaigns />
                    </div>
                    <div className=" mb-5 md:w-[32.3%] block 2xl:hidden">
                        <TopPerformingWorkflows />
                     </div>
                   
                </div>
                
            </div>
            <div className='flex md:flex-row flex-col gap-4 mb-4 w-full'>
              
                    <div className="2xl:w-[22.5%] w-[33%]">
                            <TotalOrder orders={orders}/>
                            </div>
                       <div className="2xl:w-[22.5%] w-[33%]">
                            <AverageRevenue averageRevenue={averageRevenue}/>
                        </div>
                        <div className="2xl:w-[22.5%] w-[33%]">

                            <Revenue revenue={revenue}/>
                        </div>
                        
                        <div className="w-[30%] 2xl:block hidden">
                        <TopPerformingWorkflows />
                     </div>
                     </div>
            <div className="flex 2xl:flex-row flex-col lg:flex-nowrap gap-4 mb-4">
                <div className="2xl:w-1/2 w-full border border-soild bg-white rounded-md ">
                    <AllWokflows/>
                </div>
                <div className="flex gap-4 2xl:w-1/2 w-full md:flex-row flex-col">
                    <div className="gap-4 md:w-1/2 w-full">
                        <Calendar />
                    </div>
                    <div className="gap-4 relative md:w-1/2 w-full border border-soild bg-white rounded-md">
                        <EventsLogs/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>

  );
};
export default Dashboard;
