import { FC, useContext, useEffect, useState } from 'react';
import { DashboardContext } from 'store/DashboardContext';
import {
  calculateNumberFromPercentage,
  calculatePercentage,
  convertStringToWholeNumber,
} from 'utils/common';
import React from 'react';
import { z } from 'zod';


const EmailDataSchema = z.object({
  title: z.string(),
  percent: z.string(),
  openings: z.string().optional(),
  id: z.string(),
});
type EmailData = z.infer<typeof EmailDataSchema>;

const EmailPerformanceStats: FC = () => {
  const [emailPerformanceData, setEmailPerformanceData] = useState<
    Array<EmailData>
  >([]);
  const dashboardCtx = useContext(DashboardContext);

  useEffect(() => {
    setEmailPerformanceData([
      {
        title: 'Average Open Rate',
        percent: `${
          dashboardCtx?.weeklyRoiData?.email_open
            ? convertStringToWholeNumber(dashboardCtx.weeklyRoiData.email_open)
            : 0
        }%`,
        openings: `${
          dashboardCtx?.weeklyRoiData?.email_open
            ? calculateNumberFromPercentage(
                parseFloat(dashboardCtx?.weeklyRoiData?.email_open),
                parseFloat(dashboardCtx?.weeklyRoiData?.email_recipients)
              )
            : 0
        } unique opens`,
        id: '1',
      },
      {
        title: 'Average Click Rate',
        percent: `${
          dashboardCtx?.weeklyRoiData?.email_click
            ? convertStringToWholeNumber(dashboardCtx.weeklyRoiData.email_click)
            : 0
        }%`,
        openings: `${
          dashboardCtx?.weeklyRoiData?.email_click
            ? calculateNumberFromPercentage(
                parseFloat(dashboardCtx?.weeklyRoiData?.email_click),
                parseFloat(dashboardCtx?.weeklyRoiData?.email_recipients)
              )
            : 0
        } unique clicks`,
        id: '2',
      },
      {
        title: 'Average Deliverability',
        percent: `${
          dashboardCtx?.weeklyRoiData?.email_recipients
            ? dashboardCtx?.weeklyRoiData?.email_recipients
            : 0
        }`,
        id: '3',
      },
      {
        title: 'Average Conversion Rate',
        percent: `${
          dashboardCtx?.weeklyRoiData?.conversions
            ? calculatePercentage(
                parseInt(dashboardCtx?.weeklyRoiData?.conversions),
                parseInt(dashboardCtx?.weeklyRoiData?.email_recipients)
              )
            : 0
        }%`,
        openings: `${
          dashboardCtx?.weeklyRoiData?.conversions
            ? dashboardCtx?.weeklyRoiData?.conversions
            : 0
        } contacts converted`,
        id: '4',
      },
    ]);
  }, [dashboardCtx?.weeklyRoiData]);
  return (
    <div className="email-performance-left w-full md:w-6/12 md:pr-2.5">
      <div className="rounded-lg shadow-lg shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800">
        <div className="flex flex-wrap">
          {emailPerformanceData.map((email) => (
            <div
              key={email.id}
            >
              <div>
                <p className="tracking-wide text-secondary dark:text-white text-sm leading-4 px-2">
                  {email.title}
                </p>
              </div>
              <div className="mt-3">
                <h3 className="font-medium leading-7 text-3xl text-lightParagraphColor dark:text-white">
                  {email.percent}
                </h3>
              </div>
              <div className="mt-3">
                <p className="text-lightParagraphColor dark:text-white text-13 font-medium leading-4 px-2">
                  {email.openings}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default EmailPerformanceStats;
