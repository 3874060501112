import TabItem from 'components/Tabs/TabItem';
import { z } from 'zod';
import React from "react"

const TabSchema = z.object({
  src: z.string().optional(),
  alt: z.string().optional(),
  title: z.string(),
  link: z.string().optional(),
  active: z.string().optional(),
  whiteSrc: z.string().optional(),
  activeSrc: z.string().optional(),
  searchQuery: z.string().optional(),
});
type Tab = z.infer<typeof TabSchema>;

interface Prop {
  tabs: Tab[];
  activeTab?: string;
  onTabChange?: (tabQuery: string) => void;
}

const Tabs = ({ tabs, activeTab, onTabChange }: Prop) => {
    
  return (
    <div className="flex flex-wrap items-center pt-5">
      {tabs.map((item: Tab) => (
        
        <TabItem
          key={item.title}  
          onClick={() =>
            onTabChange && item.searchQuery && onTabChange(item.searchQuery)
           }
           active={item.searchQuery === activeTab}
          tabItem = {{
            title: item.title,
            link: item.link,
            src: item.src,
            alt: item.alt,
            active: item.active
          }}
        />
      ))}
    </div>
  );
};
export default Tabs;
