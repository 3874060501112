import { Link, useLocation } from "react-router-dom";
import React from "react"
interface Props {
  tabItem?: tabItem;
  onClick?: () => void;
  active?: boolean;
}
interface tabItem {
  title: string;
  link?: string;
  src?: string;
  alt?: string;
  active?: string
}
const TabItem = ({
  tabItem,
  onClick,
  active,
}: Props) => {

  const location = useLocation();
  const tabs = location.pathname.split('/');

  const isActive =  !tabItem?.link ? active : (tabs[2]) ? tabs[2].match(tabItem?.active || '') : tabs[1].match(tabItem?.active || '');         

  return (
    <div onClick={tabItem?.link ? ()=>{''} : onClick} className="pt-4">
      <Link to={tabItem?.link ? tabItem.link : ''}
        className={`text-sm font-medium leading-3 text-darkParagraphColor dark:text-white sm:px-6 px-2 pb-4 hover:text-primary hover:border-b-2 border-primary dark:hover:border-white group hover:-mb-0.5 ${
          isActive
            ? ' border-b-2 border-primary dark:border-white text-primary -mb-0.5'
            : ''
        } flex justify-center items-center group`}
      >
        <span className="w-[18px] h-[18px] block mr-2 pt-px">
          <img
            className={`dark:invert dark:brightness-0  ${
              isActive ? ' ' : ''
            }`}
            src={tabItem?.src}
            alt={tabItem?.alt}
          />
        </span>
        <div className={`${isActive ? 'dark:text-white text-primary' : ''}`}>{tabItem?.title}</div>
      </Link>
    </div>
  );
};
export default TabItem;
