import Cart from './Cart';
import Script from './Scripts';
import GoogleAnalytics from './Google-Analytics';
import Miscellaneous from './Miscellaneous';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
interface Props {
  activeTab: string;
}

const Body: React.FC<Props> = ({ activeTab }: Props) => {

  return (
    <ErrorBoundary>
      <div className="md:px-6 px-3 py-7 bg-gray-400 dark:bg-slate-800 font-inter min-h-screen h-full">
        <div className="md:px-1.5">
          {activeTab === 'cart-integration' && <Cart />}
          {activeTab === 'scripts' && <Script />}
          {activeTab === 'google' && <GoogleAnalytics />}
          {activeTab === 'miscellaneous' && <Miscellaneous />}
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default Body;
