import React, { FC } from 'react';
import { z } from 'zod';
import moment from 'moment';

const filterDataSchema = z.object({
  startDate: z.string(),
  endDate: z.string(),
  searchValue: z.string(),
  selectedEvent: z.string(),
});

type FilterData = z.infer<typeof filterDataSchema>;

interface Props {
  filterData: FilterData;
}

const NoData: FC<Props> = ({ filterData }) => {
  return (
    <div>
      <div>
        {`There isn't any history for events ${
          filterData.searchValue.length > 0 ||
          filterData.selectedEvent !== 'All Events'
            ? 'with this filter'
            : ''
        } from ${moment
          .utc(filterData.startDate)
          .format('MMM D, YYYY')} through ${moment
          .utc(filterData.endDate)
          .format('MMM D, YYYY')}.`}
      </div>
      <div>{`Try using ${
        filterData.searchValue.length > 0 ||
        filterData.selectedEvent !== 'All Events'
          ? 'a less specific filter or'
          : ''
      } a different date range.`}</div>
    </div>
  );
};
export default NoData;
