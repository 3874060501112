import AllCampaigns from './AllCampaigns';
import React from 'react';

const Overview = () => {
  return (
    <div>
      <AllCampaigns />
    </div>
  );
};
export default Overview;
