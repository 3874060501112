import React from 'react';
const Header = () => {
  return (
    <div className="md:px-6 px-3 pt-5 bg-white dark:bg-slate-800 border-b border-gray-800 dark:border-black-400 font-inter">
      <div>
        <div className="flex items-center relative mb-4">
          <div className="sm:flex items-center w-full">
            <div className="flex items-center">
              <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black dark:text-white font-medium ml-2.5 mr-3">
                Event Logs
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
