import * as Yup from 'yup';
import { useFormik } from 'formik';
import { z } from 'zod';
import ErrorBoundary from 'components/ErrorBoundary';
import { createFilter } from 'services/apiHandlers/List-Segments/Filter';
import { Toastify } from 'App';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { filters, listSegments } from 'services/constant/routes';

const createFilterResponseSchema = z.object({
  data: z.object({ id: z.number() }),
  status : z.number()
});

type CreateFilterResponse = z.infer<typeof createFilterResponseSchema>;

const AddFilterForm = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name Cannot Be Empty'),
    }),
    onSubmit: async (values) => {
      try {
        const createFilterResponse = (await createFilter({
          name: values.name,
          description: values.description,
        })) as CreateFilterResponse;
        if(createFilterResponse.status === 200){
          Toastify("Filter Created Sucessfully " , "success" , "create1")
          navigate(`${listSegments}/${filters}/${createFilterResponse.data.id}`);
        }
      } catch (error) {
        Toastify("Filter Creation Failed" , "error" , "create2")
        console.log('error is : ', error);
      }
    },
  });

  return (
    <ErrorBoundary>
      <form onSubmit={formik.handleSubmit}>
        <div className="md:flex">
          <div className="md:w-7/12 md:pr-3">
            <div className="flex justify-between items-center">
              <h2 className="sm:text-xl text-base font-medium leading-5 text-black-300 dark:text-white">
                Create New Filter
              </h2>
              <div className="flex items-center">
                <Link
                to={`${listSegments}/${filters}`}
                  className="sm:text-13 text-xs py-2.5 px-4 h-11 w-28 font-medium leading-5 text-primary hover:text-white border-2 border-primary rounded-md bg-white mr-3 ease-in duration-300 hover:bg-primaryHover hover:scale-105"
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="sm:text-13 text-xs font-medium leading-5 text-white py-2.5 px-4 h-11 w-28 rounded-md bg-btnPrimary ease-in-in duration-300 hover:bg-primary hover:scale-105"
                >
                  Create
                </button>
              </div>
            </div>
            <div className="mt-4 py-6 sm:px-6 px-4 bg-white dark:bg-slate-800 border border-gray-800 dark:border-black-400 shadow-lg shadow-gray-200 dark:shadow-none rounded-lg">
              <div>
                <div>
                  <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white inline-block inline-block mb-3">
                    Name
                  </label>
                  <input
                    className="w-full p-4 text-13 font-medium leading-3 dark:bg-slate-800 border border-gray-800 dark:border-black-400 rounded-md text-black-400 dark:text-white sm:h-11 h-9 focus:outline-none"
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.name ? (
                    <div
                      data-pw="filter-required"
                      className="text-xs leading-4 text-red-400 mt-1.5"
                    >
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
                <div className="mt-8">
                  <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white inline-block inline-block mb-3">
                    Description
                  </label>
                  <input
                    className="w-full p-4 text-13 font-medium leading-3 dark:bg-slate-800 border border-gray-800 dark:border-black-400 rounded-md text-black-400 dark:text-white sm:h-11 h-9 focus:outline-none"
                    type="text"
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-5/12 md:pl-3 md:mt-0 mt-6">
            <div className="md:h-10"></div>
            <div className="mt-4 py-6 sm:px-6 px-4 bg-white dark:bg-slate-800 border border-gray-800 dark:border-black-400 shadow-lg shadow-gray-200 dark:shadow-none rounded-lg">
              <div>
                <p className="text-base font-normal leading-5 text-black-400 dark:text-white dark:text-white">
                  Once you name your new filter, you can start adding rules.
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ErrorBoundary>
  );
};
export default AddFilterForm;
