import React , { FC } from 'react';
import { Tooltip } from 'react-tooltip';
// import 'react-tooltip/dist/react-tooltip.css';
import {z} from "zod";

const tooltipSchema = z.object({
  backgroundColor: z.string(),
  border: z.string(),
  zIndex: z.string(),
});
export type TooltipStyles = z.infer<typeof tooltipSchema>

interface Props {
  children: JSX.Element;
  tooltipOpen: boolean;
  anchorId: string;
  // styles: TooltipStyles
}

const campaignTooltip: FC<Props> = ({ children, tooltipOpen, anchorId }) => {
  return (
    <Tooltip
      isOpen={tooltipOpen}
      anchorId={anchorId}
    >
      {children}
    </Tooltip>
  );
};
export default campaignTooltip