import SegmentDefinitionHeader from './SegmentDefinitonHeader';
import Table from 'components/Table';
import SegmentAction from './SegmentAction';
import { useEffect, useMemo, useState } from 'react';
import { getAllSegments } from 'services/apiHandlers/List-Segments/SegmentsList';
import { TableColumn } from 'react-data-table-component';
import { tableSort } from 'utils/common';
import { z } from 'zod';
import React from 'react';

export const SegmentSchema = z.object({
  segment_json: z.string(),
  elastic_json: z.string(),
  segment_name: z.string(),
  subscriber_count: z.number(),
  status: z.string(),
});
export type Segment = z.infer<typeof SegmentSchema>;

const SegmentDefinition = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [segments, setSegments] = useState<Array<Segment>>([]);


  const getData = async (toggled?: boolean) => {
    if (!toggled) {
      setLoading(true);
    }
    try {
      const getAllSegmentsResponse = await getAllSegments();
      setSegments(getAllSegmentsResponse);
    } catch (error) {
      console.log('error is: ', error);
    }
    setLoading(false);
  };

  const segmentNameSort = (rowA: Segment, rowB: Segment) => {
    return tableSort(rowA.segment_name, rowB.segment_name);
  };

  const segmentMemberSort = (rowA: Segment, rowB: Segment) => {
    return tableSort(rowA.subscriber_count, rowB.subscriber_count);
  };

  const columns: TableColumn<Segment>[] = useMemo(
    () => [
      {
        name: 'Sr.No',
        cell: (row, index) => {
          return <td className="dark:text-white">{index + 1}</td>;
        },
      },
      {
        name: 'Segments',
        cell: (row) => {
          return <td className="dark:text-white">{row.segment_name}</td>;
        },
        sortable: true,
        sortFunction: segmentNameSort,
      },
      {
        name: 'Members',
        cell: (row) => {
          return <td className="dark:text-white">{row.subscriber_count}</td>;
        },
        sortable: true,
        sortFunction: segmentMemberSort,
      },
      {
        name: 'Actions',
        cell: (row, index) => {
          return (
            <td className="whitespace-nowrap py-3 pl-3 pr-3 text-13 sm:align-top align-middle text-black-400 dark:text-white md:w-[400px] md:min-w-[400px] w-[200px] min-w-[200px] flex flex-wrap items-center bg-white dark:bg-slate-800">
              <SegmentAction
                index={index}
                row_name={row.segment_name}
                status={row.status}
                getData={(toggled) => getData(toggled)}
              />
            </td>
          );
        },
      },
    ],
    []
  ); 

  useEffect(() => {
    
    getData();
  }, []);

  return (
    <>
          <SegmentDefinitionHeader />
          <Table
            className="scrollbar-hide"
            data={segments}
            columns={columns}
            progressPending={loading}
          />
    </>
  );
};
export default SegmentDefinition;
