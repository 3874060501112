import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataset,
} from 'chart.js';
import { FC } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useContext } from 'react';
import { ThemeContext } from 'store/ThemeContext';
import ErrorBoundary from 'components/ErrorBoundary';
import React from "react";


ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  labels: string[] | undefined;
  datasets: ChartDataset<'doughnut', number[]>[];
  height: string | number;
  width: string | number | undefined;
}

const PieGraph: FC<Props> = ({ labels, datasets, height, width }) => {
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx?.theme;

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      arc: {
        borderWidth: 10,        
      }
    },    
    tooltips: {
      enabled: false
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
        rtl: false,
        labels: {
          usePointStyle: true,
          boxWidth: 6,
          textAlign: 'left' as const,
          color: `${theme === 'dark' ? 'white' : ''}`,
          font: {
            size: 13, 
          },
          padding: 20
        }
      },
      
    },
  };
  return (
  <ErrorBoundary>
    <div className="2xl:h-52 w-auto relative 2xl:top-0 -top-2 ">
      <Doughnut height={height} width={width} data={data} options={options} />
    </div>
    </ErrorBoundary>
  );
};
export default PieGraph;





