import Table from 'components/Table';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { getSubscriberEvents } from 'services/apiHandlers/List-Segments/Subscriber';
import { z } from 'zod';
import moment from 'moment';
import SubscriberEventAccordian from './SubscriberEventAccordian';
import Loader from 'components/Loader';

interface Props{
 email : string
}

export const eventResultDataSchema = z.object({
  accountId: z.number(),
  attributes: z.object({
    anonymousId: z.string(),
    hostname: z.string(),
    ip: z.string(),
    ipCity: z.string(),
    ipCountry: z.string(),
    ipState: z.string(),
    path: z.string(),
    personIdentifier: z.string(),
    referrer: z.string(),
    referrerHostname: z.string(),
    timestampOffset: z.string(),
    title: z.string(),
    url: z.string(),
    userAgent: z.string(),
  }),
  id: z.string(),
  name: z.string(),
  timestamp: z.number(),
});

const getSubscriberEventResponseSchema = z.object({
  data: z.object({
    next_start_token: z.string(),
    result: z.array(eventResultDataSchema),
    totalCount: z.string(),
  }),
  status: z.number(),
});

type EventResultData = z.infer<typeof eventResultDataSchema>;
type GetSubscriberEventResponse = z.infer<
  typeof getSubscriberEventResponseSchema
>;

const SubscriberEvents :FC<Props> = ({email}) => {
  const [subscriberEvents, setSubscriberEvents] = useState<
    Array<EventResultData>
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [startToken, setStartToken] = useState<string>('');
  const [showMore, setShowMore] = useState<boolean>(true);

  const columns: TableColumn<EventResultData>[] = useMemo(
    () => [
      {
        name: 'Subscribers',
        cell: () => {
          return (
            <div className="cursor-pointer text-sm font-medium text-primary hover:text-blue-700 mt-1 flex gap-3 dark:text-white">
              <div>{email}</div>
            </div>
          );
        },
      },
      {
        name: 'Event',
        cell: (row: EventResultData) => (
          <div className="py-0.5 px-2.5 text-black ">{row.name}</div>
        ),
      },
      {
        name: 'Date',
        cell: (row) => (
          <div className="py-0.5 px-2.5  text-black ">
            {moment(row.timestamp).format('MMMM DD, YYYY hh:mm:ss A')}
          </div>
        ),
      },
    ],
    []
  );

  const getEvents = async (startToken?: string) => {
    setLoading(true);
    try {
      const getSubscriberEventResponse = (await getSubscriberEvents(
        email,
        startToken
      )) as GetSubscriberEventResponse;
      if (getSubscriberEventResponse.status === 200) {
        if (getSubscriberEventResponse.data.result.length === 0) {
          setSubscriberEvents(subscriberEvents);
          setShowMore(false);
        } else {
          setSubscriberEvents(getSubscriberEventResponse.data.result);
          setStartToken(getSubscriberEventResponse.data.next_start_token);
        }
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div className="md:px-6 px-3 py-5 font-inter bg-f6f7f9 min-h-screen">
      <div className="md:px-1.5">
        <div className="md:px-6 px-3 py-4 bg-white rounded-lg boxShadow">
          <h2 className="text-base sm:leading-5 leading-3 text-212121 font-medium my-3">
            Subscriber Events
          </h2>
          <h1 className="text-base text-base sm:leading-5 leading-3 text-ryzeoBlue font-medium my-3">
            {email}
          </h1>
        </div>
        <Table
          data={subscriberEvents}
          columns={columns}
          expandableRows
          expandableRowsComponent={SubscriberEventAccordian}
          progressPending={loading}
          expandOnRowClicked
          // expandableRowsHideExpander
          pagination={false}
        />
        {showMore && (
          <button
            disabled={loading}
            onClick={() => {
              getEvents(startToken);
            }}
            className="fs-13 font-medium leading-5 text-white flex items-center ml-auto rounded-md py-3 sm:pl-4 px-2 sm:pr-3.5 font-inter bg-primary mt-5 ease-in duration-300 hover:bg-primaryHover hover:scale-105"
          >
            {loading && (
              <div className="">
                <Loader />
              </div>
            )}
            {loading ? 'Loading' : 'Show More'}
          </button>
        )}
      </div>
    </div>
  );
};
export default SubscriberEvents;
