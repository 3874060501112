import SubjectForm from './SubjectForm';
import EmailTemplates from './EmailTemplates';
import Sender from './Sender';
import TagBlast from './TagBlast';
import Reply from './Reply';
import {  FC, useState } from 'react';
import React from "react"

interface Props{
  onEmailChange: (id:string) => void
}

const Forms :FC<Props> = ({onEmailChange}) => {
  const [hideReply, setHideReply] = useState<boolean>(false);
  return (
    <div className="bg-white rounded-lg pt-6 pb-8 sm:px-7 px-3 mb-10 dark:bg-slate-800 border border-gray-800 dark:border-black-400 shadow-lg shadow-gray-200 dark:shadow-none rounded-lg">
      <div>
        <div className="md:flex flex-wrap">
          <div className="md:w-6/12 w-full md:pr-4 sm:mb-0 mb-8 left">
            <SubjectForm />
            <div>
              <Sender hideReplySection={(show) => setHideReply(show)} />
              <TagBlast />
            </div>
          </div>

          <div className="md:w-6/12 w-full md:pl-4 sm:mb-0 mb-8 md:mt-0 mt-8 right">
            <EmailTemplates onEmailChange={(id:string) => onEmailChange(id) }/>
            {!hideReply && <Reply />}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Forms;
