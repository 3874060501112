import ErrorBoundary from 'components/ErrorBoundary';
import { Link, Outlet } from 'react-router-dom';
import React from 'react';
import { create, listSegments, segmentDefinition } from 'services/constant/routes';

const SegmentDefinitionHeader = () => {
  return (
    <ErrorBoundary>
      <div className="flex justify-between items-center relative">
        <h1 className="sm:text-xl text-base font-medium leading-5 lg:w-52 md:w-56 w-48 text-black-300 dark:text-white">
          All Lists & Segments
        </h1>
        <Link
          to= {`${listSegments}/${segmentDefinition}/${create}`}
          className="sm:text-sm text-10 font-medium leading-5 text-white flex items-center ml-auto rounded-md sm:py-3 py-2 sm:pl-4 sm:pr-3.5 px-1 font-inter bg-primary group dark:hover:bg-white dark:hover:text-black-400 dark:text-white dark:hover:bg-white ease-in duration-300 hover:bg-primaryHover hover:scale-105"
        >
          Create New Segment
        </Link>
      </div>
      <Outlet />
    </ErrorBoundary>
  );
};
export default SegmentDefinitionHeader;
