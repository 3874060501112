import {   useRef, useState } from 'react';
import { z } from 'zod';
import DownArrow from 'assets/Images/Common/down-arrow.png';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React from "react";


const options = z.object({
  name: z.string(),
  value: z.string(),
});

type option = z.infer<typeof options>;
interface Props {
  options: Array<option>;
  onSelect: (e: string) => void;
  value: string;
  className?: string;
}
const Dropdown = ({
  value,
  options,
  onSelect,
}: Props) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideClick(dropdownRef, () => setShowDropdown(false))
  return (
    <div
    ref={dropdownRef}
    onClick={() =>  setShowDropdown((prev) => !prev)}
    className="shadow-sm shadow-gray-200 dark:shadow-none rounded-md w-full relative"
  >
    <div
      className={`${
        showDropdown ? 'rounded-b-none border-primary dark:border-white' : ''
      } bg-white text-sm font-medium leading-4 text-primary dark:text-white dark:text-white flex items-center justify-between sm:h-11 py-2 px-4 focus:outline-none border-0 rounded-md dark:bg-slate-800 dark:border-black-400 cursor-pointer`}
    >
      {value}
      <span>
        <img
          className="dark:brightness-0 dark:invert"
          src={DownArrow}
          alt="Arrow"
        />
      </span>
    </div>
      {<div className="max-h-40 overflow-auto bg-white dark:bg-slate-800 border-t-0 rounded-b border-gray-200 dark:border-black-400 shadow-sm shadow-gray-200 scrollbar-hide cursor-pointer absolute w-full z-50">
        {showDropdown && options?.map((opt) => (
          <div
            key={opt.value + opt.name}
            className="p-3 text-sm block w-full text-left leading-4 text-black-400 dark:text-white dark:hover:bg-white hover:bg-dropdownHover dark:text-white dark:hover:text-black-400 border border-dropdownHover"
            onClick={() => onSelect(opt.value)}
          >
            {opt.name}
          </div>
        ))}
      </div>}
      </div>
    
    
  );
};
export default Dropdown;
