import { useEffect, useState } from 'react';
import { getAllFilters } from 'services/apiHandlers/List-Segments/Filter';
import { FilterResponseData } from '../Filter/Filter';
import {
  addMemberUsingFilter,
  getSingleList,
} from 'services/apiHandlers/List-Segments/SegmentsList';
import xmlToJSON from 'utils/xmlToJSON';
import { formatXmlJsonResponse } from 'utils/common';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { Toastify } from 'App';
import Dropdown from 'components/Dropdown/valueDropdown';
import React from 'react';
import { listSegments, filters as filterTab, view } from 'services/constant/routes';

export const filterOptionSchema = z.object({
  name: z.string(),
  value: z.string(),
});
type FilterOptions = z.infer<typeof filterOptionSchema>;

const responseStatusSchema = z.object({ status: z.number() });
type ResponseStatus = z.infer<typeof responseStatusSchema>;



const UploadFilter = () => {
  const [filters, setFilters] = useState<Array<FilterOptions>>([]);
  const [filterName, setFilterName] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState<FilterOptions>({
    name: '',
    value: '',
  });
  const {id} = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      /* eslint-disable */
      const getListsResponse: any = await getSingleList(id);
      const data: any = xmlToJSON.parseString(getListsResponse.data);
      const filter: any = formatXmlJsonResponse(data.list);
      /* eslint-enable  */
      setFilterName(filter[0].name);
      const getAllFiltersresponse =
        (await getAllFilters()) as FilterResponseData;
      if (getAllFiltersresponse.status === 200) {
        const tempFilters = getAllFiltersresponse.data.filters.map((data) => {
          return { name: data.name, value: data.id.toString() };
        });
        setSelectedFilter({
          name: getAllFiltersresponse.data.filters[0].name,
          value: getAllFiltersresponse.data.filters[0].id.toString(),
        });

        setFilters(tempFilters);
      }
    } catch (error) {
      console.log('error is: ', error);
    }
  };

  const applyFilterHandler = async () => {
    setLoading(true);
    try {
      const addMemberResponse = (await addMemberUsingFilter(
        selectedFilter.value,
        id
      )) as ResponseStatus;
      if (addMemberResponse.status === 200) {
        Toastify('Filter Added Successfully', 'success', 'upload1');
      }
    } catch (error) {
      Toastify('Adding Filter Failed', 'error', 'upload2');
    }
    setLoading(false);
  };

  const filterChangeHandler = (id: string) => {
    const changedFilter = filters.filter((filter) => filter.value === id);
    setSelectedFilter(changedFilter[0]);
  };

  const viewFilterHandler = () => {
    const id = selectedFilter.value;
    searchParams.set('filter', id);
    setSearchParams(searchParams);
    navigate(`${listSegments}/${filterTab}/${view}/${id}`);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="lg:w-8/12 w-full md:pr-2.5">
      <div className="flex justify-between">
        <h1 className="sm:text-xl text-base font-medium leading-5 text-black-300 dark:text-white mr-2.5">
          Add List Members by Filter
        </h1>
        <div>
          <button
            type="button"
            onClick={applyFilterHandler}
            disabled={loading}
            className={`${
              loading
                ? 'border-gray-800 bg-gray-200 text-black-400'
                : 'text-white bg-primary'
            } flex items-center text-13 font-medium leading-5  rounded-md h-11 w-28 px-4 py-2.5 ease-in duration-300 hover:bg-primaryHover hover:scale-105 justify-center`}
          >
            Apply Filter
          </button>
        </div>
      </div>
      <div className="mt-4">
        <div>
          <div className="sm:px-6 px-4 sm:py-4 py-2.5 border dark:border-black-400 rounded-lg shadow-lg shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800">
            <div className="flex">
              <p className="text-xs font-semibold leading-3 tracking-wide uppercase text-gray-500 dark:text-white w-28">
                Name
              </p>
              <p className="text-13 font-medium leading-3 text-black-400 dark:text-white">
                {filterName}
              </p>
            </div>
            <div className="sm:flex items-center mt-5">
              <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-gray-500 dark:text-white w-28">
                Filter
              </label>
              <div className="sm:mt-0 mt-4 sm:w-9/12 w-full">
                <div className="flex items-center">
                  <div className="border border-gray-800 dark:border-black-400 flex items-center rounded-md relative lg:w-4/6 md:w-3/6 w-4/6 sm:h-11 h-9">
                    <Dropdown
                      options={filters}
                      value={
                        selectedFilter.name === ''
                          ? filters[0]?.name
                          : selectedFilter.name
                      }
                      onSelect={(value) => filterChangeHandler(value)}
                    />
                  </div>
                  <p
                    onClick={viewFilterHandler}
                    className="lg:w-2/6 md:w-3/6 w-2/6 pl-2.5"
                  >
                    <a className="text-sm font-normal leading-4 text-primary underline cursor-pointer">
                      View Filter
                    </a>
                  </p>
                </div>
                <p className="text-13 font-medium leading-4 text-black-400 dark:text-white mt-1">
                  Double-check your filter to ensure the correct subscribers are
                  matched.
                </p>
              </div>
            </div>
          </div>
        </div>
        <p className="text-sm font-medium leading-5 text-black-700  mt-2 text-right">
          Warning: list modifications from the applied filter cannot be undone.
        </p>
      </div>
    </div>
  );
};
export default UploadFilter;
