import ListSegmentHeader from './ListSegmentHeader';
import { TableColumn } from 'react-data-table-component';
import Table from 'components/Table';
import { getLists,getFilterList } from 'services/apiHandlers/List-Segments/SegmentsList';
import { useEffect, useMemo, useState } from 'react';
import ListActions from './ListActions';
import { z } from 'zod';
import { tableSort } from 'utils/common';
import ErrorBoundary from 'components/ErrorBoundary';
import { Link } from 'react-router-dom';
import { listSegments, subscribers, listId } from 'services/constant/routes';
import styled from 'styled-components';
import React from 'react';

const StyleWrapper = styled.div`
  .rdt_TableRow {
    height: 50px;
  }

  .rdt_TableRow .rdt_TableCell {
    border-right: 1px solid #e0e0e0;
  }

  .rdt_TableRow .rdt_TableCell:last-child {
    border-right: 0;
  }

  .rdt_TableHeadRow .rdt_TableCol {
    border-right: 1px solid #e0e0e0;
  }
  .rdt_TableHeadRow .rdt_TableCol:last-child {
    border-right: 0;
  }
`;

const collectionSchema = z.object({
  active_people_count: z.number(),
  audience_id: z.number(),
  channel_list: z.boolean(),
  checked_by_default: z.boolean().nullable(),
  created_at: z.string(),
  deleted_at: z.string().nullable(),
  description: z.string(),
  id: z.number(),
  inactive_people_count: z.number(),
  mutable: z.boolean(),
  name: z.string(),
  pending_people_count: z.number(),
  pretty_status: z.string(),
  public: z.string(),
  status: z.string(),
  trigger_name: z.string(),
  unsubscribed_people_count: z.number(),
  updated_at: z.string(),
});

const ListResponseSchema = z.object({
  data: z.object({
    current_page: z.number(),
    per_page: z.number(),
    total_count: z.number(),
    collection: z.object({ lists: z.array(collectionSchema) }),
  }),
  status: z.number(),
});
export type ListResponse = z.infer<typeof ListResponseSchema>;
export type List = z.infer<typeof collectionSchema>;

const ListSegment = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [list, setList] = useState<Array<List>>([]);
  const [filteredList, setFilteredList] = useState<Array<List>>(list);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalEntries, setTotalEntries] = useState<number>(0);
  const [filteredEntries, setFilteredEntries] = useState<number>(0);

  const getData = async (preventLoading?: boolean) => {
    if (!preventLoading) {
      setLoading(true);
    }
    try {
      const getListsResponse = (await getLists(currentPage)) as ListResponse;
      if (getListsResponse.status === 200) {
        setList(getListsResponse?.data?.collection?.lists);
        setTotalEntries(getListsResponse?.data?.total_count);
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  const getFilterData = async (preventLoading?: boolean) => {
    if (!preventLoading) {
      setLoading(true);
    }
    try {
      const getFilterListsResponse = (await getFilterList(searchQuery,currentPage)) as ListResponse;
      if (getFilterListsResponse.status === 200) {
        setFilteredList(getFilterListsResponse?.data?.collection?.lists);
        setFilteredEntries(getFilterListsResponse?.data?.total_count);
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  const listSort = (rowA: List, rowB: List) => {
    return tableSort(rowA.name, rowB.name);
  };

  const listMemberSort = (rowA: List, rowB: List) => {
    return tableSort(
      rowA.active_people_count +
        rowA.inactive_people_count +
        rowA.pending_people_count +
        rowA.unsubscribed_people_count,
      rowB.active_people_count +
        rowB.inactive_people_count +
        rowB.pending_people_count +
        rowB.unsubscribed_people_count
    );
  };

  const columns: TableColumn<List>[] = useMemo(
    () => [
      {
        name: 'List Name',
        cell: (row, index) => {
          return (
            <div className="dark:text-white">
              <Link
                data-pw={`${index === 0 ? 'list-name' : ''}`}
                className="cursor-pointer text-sm font-medium text-primary hover:underline hover:text-blue-700 mt-1 flex gap-3 dark:text-white"
                to={`${listSegments}/${subscribers}?${listId}=${row.id}`}
              >
                {row.name}
              </Link>
              <div
                data-pw={`${index === 0 ? 'list-description' : ''}`}
                className="mt-1"
              >
                {row.description}
              </div>
            </div>
          );
        },
        sortable: true,
        sortFunction: listSort,
      },
      {
        name: 'Members',
        cell: (row) => {
          return (
            <div className="dark:text-white">
              <div>
                {row.active_people_count +
                  row.inactive_people_count +
                  row.pending_people_count +
                  row.unsubscribed_people_count +
                  ' '}
                Members
              </div>
            </div>
          );
        },
        sortFunction: listMemberSort,
      },
      {
        name: 'Actions',
        cell: (row, index) => {
          return (
            <ListActions
              rowData={row}
              index={index}
              getData={() => getData(true)}
            />
          );
        },
      },
    ],
    []
  );


  useEffect(() => {
    getData(false);
  }, []);

  useEffect(()=>{
    setFilteredEntries(totalEntries);
    const timeoutId = setTimeout(() => {
      getFilterData(false);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    }
  },[searchQuery])

  useEffect(() => {
    getData();
    getFilterData();
  }, [currentPage]);

  return (
    <ErrorBoundary>
      <div>
        <ListSegmentHeader onSearch={setSearchQuery} />
        <StyleWrapper>
          {
            <Table
              className="scrollbar-hide"
              data={filteredList}
              columns={columns}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={filteredEntries}
              onChangePage={setCurrentPage}
            />
          }
        </StyleWrapper>
      </div>
    </ErrorBoundary>
  );
};
export default ListSegment;
