import httpService from 'services/core/HttpService';
import {
  GET_SENDERS,
  CREATE_SENDERS,
  GET_BLASTS,
  CREATE_BLASTS,
  GET_MAIL_MERGE,
  GET_ALL_STATUS_BLASTS,
  DELETE_SENDER,
} from 'services/constant/apiRoutes';
import { z } from 'zod';

const PageDataSchema = z.object({
  page: z.number(),
  per_page: z.number(),
});

const createBlastDataSchema = z.object({ tag: z.string() });

const createSenderDataSchema = z.object({
  city: z.string(),
  country: z.string(),
  from_email_address: z.string(),
  from_name: z.string(),
  postal_code: z.string(),
  state: z.string(),
  street_address_1: z.string(),
  street_address_2: z.string(),
});

export type Sender = z.infer<typeof createSenderDataSchema>;
export type createBlastData = z.infer<typeof createBlastDataSchema>;
export type PageData = z.infer<typeof PageDataSchema>;

export const getAllSenders = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_SENDERS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createSender = (data: Sender) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(CREATE_SENDERS, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getBlasts = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_BLASTS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createBlasts = (data: createBlastData) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(CREATE_BLASTS, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getMailMerge = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_MAIL_MERGE)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllBlasts = (pageData: PageData) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(
        GET_ALL_STATUS_BLASTS +
          `?page=${pageData.page}&per_page=${pageData.per_page}`
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteSender = (id: string) => {
  return new Promise((resolve, reject) => {
    httpService
      .delete(DELETE_SENDER + id)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
