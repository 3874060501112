import {
  CREATE_INTEGRATION,
  VALIDATE_INTEGRATION,
  GET_INTEGRATIONS,
} from 'services/constant/apiRoutes';
import httpService from 'services/core/HttpService';
import { z } from 'zod';

const validateBigCommerceSchema = z.object({
  accessToken: z.string(),
  id: z.string().optional(),
  clientId: z.string(),
  shouldGrabOrderHistory: z.boolean(),
  storeHash: z.string(),
  uniqueIdConstraint: z.string(),
  _type: z.string(),
});
export type validateBigCommerce = z.infer<typeof validateBigCommerceSchema>;

const validateMagentoSchema = z.object({
  apiKey: z.string(),
  id: z.string(),
  shouldGrabOrderHistory: z.boolean(),
  storeUrl: z.string(),
  uniqueIdConstraint: z.string(),
  userName: z.string(),
  _type: z.string(),
});
export type validateMagento = z.infer<typeof validateMagentoSchema>;

const validateMagento2Schema = z.object({
  adminPassword: z.string(),
  id: z.string(),
  shouldGrabOrderHistory: z.boolean(),
  storeUrl: z.string(),
  uniqueIdConstraint: z.string(),
  adminUser: z.string(),
  _type: z.string(),
});
export type validate2Magento = z.infer<typeof validateMagento2Schema>;

const validateShopifySchema = z.object({
  APIkey: z.string(),
  domainUrl: z.string(),
  excludeProduct: z.string(),
  excludeProductsList: z.union([z.array(z.string()), z.tuple([])]),
  formAutoEmailCapture: z.boolean().optional(),
  id: z.string(),
  password: z.string(),
  publishEvents: z.boolean(),
  sendAnonymousEventsAfterIdentification: z.boolean().optional(),
  sharedSecret: z.string(),
  shouldGrabOrderHistory: z.boolean(),
  showExcludeProductSection: z.boolean(),
  storeName: z.string(),
  storeUrl: z.string(),
  uniqueIdConstraint: z.string(),
  _type: z.string(),
});
export type validateShopify = z.infer<typeof validateShopifySchema>;

const validateBigCommerceLegacySchema = z.object({
  apiToken: z.string(),
  id: z.string().optional(),
  shouldGrabOrderHistory: z.boolean(),
  storeId: z.string(),
  storeUrl: z.string(),
  uniqueIdConstraint: z.string(),
  username: z.string(),
  _type: z.string(),
});
export type validateBigCommerceLegacy = z.infer<
  typeof validateBigCommerceLegacySchema
>;

const validateWooCommerceSchema = z.object({
  id: z.string(),
  key: z.string(),
  secret: z.string(),
  shouldGrabOrderHistory: z.boolean(),
  storeDomain: z.string(),
  uniqueIdConstraint: z.string(),
  _type: z.string(),
});
export type validateWooCommerce = z.infer<typeof validateWooCommerceSchema>;

const validateResponseSchema = z.object({
  status: z.number(),
});
export type validateResponse = z.infer<typeof validateResponseSchema>;

export const magentoSchema = z.object({
  storeUrl: z.string(),
  userName: z.string(),
  apiKey: z.string(),
  _type: z.string(),
});
export type Magento = z.infer<typeof magentoSchema>;

export const magento2Schema = z.object({
  storeUrl: z.string(),
  adminPassword: z.string(),
  adminUser: z.string(),
  _type: z.string(),
});
export type Magento2Type = z.infer<typeof magento2Schema>;

export const wooCommmerceSchema = z.object({
  storeDomain: z.string(),
  key: z.string(),
  secret: z.string(),
  _type: z.string(),
});
export type wooCommerceType = z.infer<typeof wooCommmerceSchema>;

export const shopifySchema = z.object({
  APIkey: z.string(),
  password: z.string(),
  storeUrl: z.string(),
  sharedSecret: z.string(),
  shouldGrabOrderHistory: z.boolean(),
  showExcludeProductSection: z.boolean(),
  uniqueIdConstraint: z.string(),
  formAutoEmailCapture: z.boolean(),
  sendAnonymousEventsAfterIdentification: z.boolean(),
  excludeProductsList: z.union([z.array(z.string()), z.tuple([])]),
  publishEvents: z.boolean(),
  _type: z.string(),
});
export type shopifyType = z.infer<typeof shopifySchema>;

const integrationDataSchema = z.object({
  shopify: validateShopifySchema,
  magento1: validateMagentoSchema,
  magento2: validateMagento2Schema,
  bigcommerce: validateBigCommerceSchema,
  bigcommerce_legacy: validateBigCommerceLegacySchema,
  woocommerce: validateWooCommerceSchema,
});
export type IntegrationData = z.infer<typeof integrationDataSchema>;

export type integration =
  | z.infer<typeof validateBigCommerceSchema>
  | z.infer<typeof validateMagentoSchema>
  | z.infer<typeof validateMagento2Schema>
  | z.infer<typeof validateShopifySchema>
  | z.infer<typeof validateBigCommerceLegacySchema>
  | z.infer<typeof validateWooCommerceSchema>;


export const validateIntegration = (data: integration) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(VALIDATE_INTEGRATION, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createIntegration = (data: integration) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(CREATE_INTEGRATION, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getIntegrations = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_INTEGRATIONS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
