import { FC, useContext, useEffect, useState } from 'react';
import averageClickRate from '../../../../assets/Images/Dashboard/average-click-rate.svg';
import EmailPerformance from './EmailPerformance';
import { DashboardContext } from 'store/DashboardContext';
import {
  calculateNumberFromPercentage,
  convertStringToWholeNumber,
} from 'utils/common';
import React from 'react';
import { z } from 'zod';


const EmailDataSchema = z.object({
  title: z.string(),
  percent: z.string(),
  openings: z.string().optional(),
  id: z.string(),
});
type EmailData = z.infer<typeof EmailDataSchema>;

const AverageClickRate: FC = () => {
  const [emailPerformanceData, setEmailPerformanceData] = useState<
    Array<EmailData>
  >([]);
  const dashboardCtx = useContext(DashboardContext);

  useEffect(() => {
    setEmailPerformanceData([
    
      {
        title: 'Average Click Rate',
        percent: `${
          dashboardCtx?.weeklyRoiData?.email_click
            ? convertStringToWholeNumber(dashboardCtx.weeklyRoiData.email_click)
            : 0
        }%`,
        openings: `${
          dashboardCtx?.weeklyRoiData?.email_click
            ? calculateNumberFromPercentage(
                parseFloat(dashboardCtx?.weeklyRoiData?.email_click),
                parseFloat(dashboardCtx?.weeklyRoiData?.email_recipients)
              )
            : 0
        }`,
        //unique clicks
        id: '1',
      },
   
     
    ]);
  }, [dashboardCtx?.weeklyRoiData]);
  return (
    <EmailPerformance emailPerformance={emailPerformanceData} image={averageClickRate}/>
  );
};
export default AverageClickRate;
