import { FC } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import { campaigns} from 'services/constant/routes';

interface Props {
	heading1: string;
	heading2?: string;
	children?: JSX.Element;
	type?: string;
}

const StoreTable: FC<Props> = ({ heading1, heading2, children, type }) => {
  
  return (
    <div className="rounded-lg shadow-lg overflow-hidden shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800 storeTable">
		<div className="cardHeader">
			<h4 className="text-base text-darkText font-medium dark:text-white">
				{heading1}
			</h4>
			{heading2 && (
				<div className='group rounded py-1 px-2 bg-cardBadge hover:bg-primary cursor-pointer ease-in-in duration-300'>
					<Link
						to={type === "campaigns" ? campaigns : '/?tab=store_report' }
						className="flex items-center text-primary group-hover:text-white dark:text-white text-xxs"
					>
						{heading2}
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 w-3 h-3 text-primary group-hover:text-white">
							<path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
						</svg>

					</Link>
				</div>
			)}
		</div>
      {children}
    </div>
  );
};

export default StoreTable;
