import { EditedSubscriber } from 'components/List-Segments/Body/Subscribers/SubscribersForm';
import httpService from 'services/core/HttpService';
import {
  CREATE_SUBSCRIBER,
  GET_SUBSCRIBER_BY_ID,
  GET_SUBSCRIBER_MEMBERSHIP,
  GET_SUBSCRIBER_BY_LIST_ID,
  GET_SUBSCRIBERS,
  GET_SUBSCRIBER_EVENTS,
  GET_SUBSCRIBER_TAGS,
  GET_EDIT_SUBSCRIBER
} from 'services/constant/apiRoutes';
import { z } from 'zod';
import axios from 'services/core/HttpService';

const PageDataSchema = z.object({
  page: z.number(),
  per_page: z.number(),
  list_id: z.number().nullable().optional(),
  opt_status: z.string().optional(),
  email_address: z.string().optional(),
});
export type PageData = z.infer<typeof PageDataSchema>;

export const getSubscribers = async (pageData: PageData) => {
  const search =
    pageData.email_address && pageData.email_address.length > 0
      ? `&search=${pageData.email_address}`
      : '';
  const list = pageData.list_id ? `&list=${pageData.list_id}` : '';
  const status =
    pageData.opt_status &&
    pageData.opt_status.toLowerCase() !== 'select' &&
    typeof pageData.opt_status !== 'undefined'
      ? `&opt_status=${pageData.opt_status}`
      : '';

  const URL =
    GET_SUBSCRIBERS +
    `page=${pageData.page}&per_page=${pageData.per_page}${search}${list}${status}`;
  return new Promise((resolve, reject) => {
    httpService
      .get(URL)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const xmlConfig = {
  headers: { 'Content-Type': 'text/xml' },
};

export const createSubscriber = async (
  data: Record<string, string | string[] |  number[]>,
  selectedList: Array<number>,
) => {

  const createSubscriberApi = CREATE_SUBSCRIBER + '.xml';

  let xmlData = '';
  let xmlList = '';
  for (const key in data) {
    xmlData += `<${key}>${data[key]}</${key}>`;
    }
  if (selectedList.length > 0) {
    xmlList = '<lists type="array">';
    selectedList.forEach((list) => {
      xmlList += `<list>${list}</list>`;
    });
    xmlList += '</lists>';
  }
  const subscriberXml = `<person> ${xmlData} ${xmlList} 
   </person>`;

  try {
    const response = await httpService.post(createSubscriberApi, subscriberXml, xmlConfig);

    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSubscriberById = async (id: number | string) => {
  try {
    const response = await httpService.get(GET_SUBSCRIBER_BY_ID + id);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const editSubscriber= async (id: number, data: EditedSubscriber,
  ) => {
  console.log("id in number isa : " , data)
  return new Promise((resolve, reject) => {
    httpService
      .put(GET_EDIT_SUBSCRIBER + id, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSubscriberMembershipById = async (id: number) => {
  try {
    const membershipUrl = GET_SUBSCRIBER_MEMBERSHIP.replace(
      '{{ID}}',
      id.toString()
    );
    const response = await httpService.get(membershipUrl);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSubscriberByListId = async (id: number, pageData: PageData) => {
  try {
    const Url =
      GET_SUBSCRIBER_BY_LIST_ID.replace('{{ID}}', id.toString()) +
      `page=${pageData.page}&per_page=${pageData.per_page}`;

    const response = await httpService.get(Url);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSubscriberEvents = (email: string, token?: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        GET_SUBSCRIBER_EVENTS.replace('{{MAIL}}', email) +
          `${token ? `&startToken=${token}` : ''}`
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSubscriberTags = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(GET_SUBSCRIBER_TAGS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
