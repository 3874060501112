import { FC, useContext } from 'react';
import TimeClock from 'assets/Images/Workflow/time-clock.png';
import Swap from 'assets/Images/Workflow/swap.png';
import { WorkflowAction, WorkflowCondition } from './index.type';
import {
  getActionName,
  workflowActionType,
  getConditionGroupName,
} from './WorkflowPreview';
import Node from './Node';
import Connector from './Connector';
import LeftNode from './LeftNode';
import RightNode from './RightNode';
import { WorkflowContext } from 'store/WorkflowContext';
import { renderNodes } from './BranchNodes';
import React from "react"

interface Props {
  workflow: WorkflowAction | WorkflowCondition | undefined;
  onAddBlock: (id: string, direction?: string) => void;
}

const NextNode: FC<Props> = ({ workflow, onAddBlock }) => {
  const workflowCtx = useContext(WorkflowContext);

  const replaceBranchNode = (
    workflow: WorkflowCondition | WorkflowAction | undefined,
    id: string
  ) => {
    const updatedWorkflow = JSON.parse(JSON.stringify(workflow));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const findAndReplaceNode = (node: any) => {
      if (node.id === id) {
        const tempTrueNode = node.trueNode;
        const tempFalseNode = node.falseNode;
        node.trueNode = tempFalseNode;
        node.falseNode = tempTrueNode;
        return true;
      }
      if (node.trueNode && findAndReplaceNode(node.trueNode)) return true;
      if (node.falseNode && findAndReplaceNode(node.falseNode)) return true;
      if (node.nextNode && findAndReplaceNode(node.nextNode)) return true;
      return false;
    };
    findAndReplaceNode(updatedWorkflow);
    return updatedWorkflow;
  };

  const replaceBranchHandler = (id: string) => {
    const updatedWorkflow = replaceBranchNode(
      workflowCtx?.workflow?.workflow,
      id
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    workflowCtx?.workflowChangeHandler({
      ...workflowCtx.workflow,
      workflow: updatedWorkflow,
    });
  };

  return workflow ? (
    <div>
      {'action' in workflow && (
        <div>
          <Node
            actionNode={true}
            nodeName={
              workflow._type === 'SendEmailActionNode' ||
              workflow._type === 'DelayNode'
                ? getActionName(workflow)
                : workflowActionType[workflow._type || '']
            }
            workflow={workflow}
          />
          <Connector />
          <div
            onClick={() => onAddBlock(workflow.id, '')}
            className="group w-52 mx-auto flex justify-center items-center text-13 font-medium leading-3 text-black-300 px-4 py-4 rounded-lg border border-gray-100 boxShadow bg-gray-400 hover:bg-workflowHover cursor-pointer ease-in duration-300"
          >
            <img className="mr-2" src={TimeClock} alt="Clock" />
            <span className='ease-in duration-300'>Add Block</span>
          </div>
          {workflow.nextNode && (
            <>
              <Connector />
              {renderNodes(workflow, false, onAddBlock)}
            </>
          )}
        </div>
      )}
      {'conditionGroup' in workflow && (
        <div>
          <Node
            nodeName={getConditionGroupName(workflow)}
            conditionNode={true}
            workflow={workflow}
          />
          <div className="mx-auto flex flex-col items-center relative">
            <span className="border-r-2 border-gray-800 relative h-14 block w-0.5 mx-auto"></span>
            {(workflow.trueNode || workflow.falseNode) && (
              <span
                onClick={() => replaceBranchHandler(workflow.id)}
                className="absolute top-4 bg-white border border-gray-100 rounded px-2"
              >
                <img src={Swap} />
              </span>
            )}
            <div
              className="flex justify-center relative w-full"
              style={{ minWidth: '250px', width: 'auto' }}
            >
              <LeftNode
                workflow={workflow}
                onAddBlock={(id: string, direction?: string) =>
                  onAddBlock(id, direction)
                }
              />

              <RightNode
                workflow={workflow}
                onAddBlock={(id: string, direction?: string) =>
                  onAddBlock(id, direction)
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};
export default NextNode;
