import { FC, useContext, useEffect, useState } from 'react';
import { DashboardContext } from 'store/DashboardContext';
import { topStrategies } from 'utils/common';
import { Workflows } from 'components/DashboardReports/Body/Analytics/Types';
import { Link } from 'react-router-dom';
import {
  workflowReports,
  reports,
  tab,
  storeReports,
} from 'services/constant/routes';
import { getWorkflowReports } from 'services/apiHandlers/Dashboard/Analytics';
import { WorkflowResponse } from 'components/DashboardReports/Types';
import React from 'react';
import { UserContext } from 'store/UserContext';
import moment from 'moment';

const TopPerformingWorkflows: FC = () => {
  const [workflows, setWorkflows] = useState<Workflows>([]);
  const dashboardCtx = useContext(DashboardContext);
  const userCtx = useContext(UserContext);

  const getData = async () => {
    dashboardCtx?.loadingStateHandler(true);
    try {
      const workflowReportResponse = (await getWorkflowReports(
        dashboardCtx?.storeReportData.startDate.toString(),
        dashboardCtx?.storeReportData.endDate.toString()
      )) as WorkflowResponse;
      if (workflowReportResponse.status === 200) {
        dashboardCtx?.workflowChangeHanlder(workflowReportResponse.data.data);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    dashboardCtx?.loadingStateHandler(false);
  };

  const getWorlkflowsDates = (id: string) => {
    const date = userCtx?.workflows?.filter((workflow) => workflow.id === id);
    if (date) {
      return date[0].lastTriggered
        ? moment(date[0].lastTriggered).format('DD MMMM, YYYY')
        : '-';
    }
    return '';
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const tempWorkflows = topStrategies(
      dashboardCtx?.workflows ? dashboardCtx.workflows : [],
      3,
      'monetaryValue'
    );

    dashboardCtx?.workflows && setWorkflows(tempWorkflows as Workflows);
  }, [dashboardCtx?.workflows, dashboardCtx?.campaigns, dashboardCtx?.loading]);

  return (
    <div className="rounded-lg 2xl:h-[290px] shadow-lg overflow-hidden shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800">
      <div className="cardHeader !py-5 flex-wrap">
        <h4 className="lg:text-base text-sm text-darkText font-medium dark:text-white">
          Top Performing Worklflows
        </h4>
        <div className="group rounded py-1 px-2 bg-cardBadge hover:bg-primary cursor-pointer ease-in-in duration-300">
          <Link
            to={`${reports}?${tab}=${storeReports}`}
            className="flex items-center text-primary group-hover:text-white dark:text-white text-xxs"
          >
            View All
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="ml-1 w-3 h-3 text-primary group-hover:text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </Link>
        </div>
      </div>
      <div className="">
        <ul>
          {workflows?.length > 0 ? (
            workflows?.map((workflow) => {
              return (
                <li
                  key={workflow.workflowId}
                  className="flex items-center justify-between p-4 hover:bg-listBg border-b border-slate-300"
                >
                  <div className="flex items-center">
                    <div className="w-6 h-6 rounded-full overflow-hidden">
                      <img
                        src="https://themesbrand.com/velzon/html/galaxy/assets/images/nft/img-01.jpg"
                        alt="top performing workflows"
                      />
                    </div>
                    <div className="ml-3">
                      <Link to={`${workflowReports}/${workflow.workflowId}`}>
                        <h6 className="text-sm text-darkText">
                          {workflow.workflowName}
                        </h6>
                        <p className="text-xs text-secondary">
                          {getWorlkflowsDates(workflow.workflowId)}
                        </p>
                      </Link>
                    </div>
                  </div>
                  <div className="">
                    <h6>{workflow.monetaryValue}</h6>
                  </div>
                </li>
              );
            })
          ) : (
            <div>No Workflows Found</div>
          )}
        </ul>
      </div>
    </div>
  );
};
export default TopPerformingWorkflows;
