import { useEffect, useState, FC } from 'react';
import * as Yup from 'yup';
import {
  createIntegration,
  validateIntegration,
} from 'services/apiHandlers/Integration/CartIntegration';
import { useFormik, FormikValues } from 'formik';
import Description from './Description';
import uuid from 'react-uuid';
import { Toastify } from 'App';
import { z } from 'zod';
import React from "react";

const validateWooCommerceSchema = z.object({
  id: z.string(),
  key: z.string(),
  secret: z.string(),
  shouldGrabOrderHistory: z.boolean(),
  storeDomain: z.string(),
  uniqueIdConstraint: z.string(),
  _type: z.string(),
});
type validateWooCommerce = z.infer<typeof validateWooCommerceSchema>;

const validateResponseSchema = z.object({
  status: z.number(),
});
type validateResponse = z.infer<typeof validateResponseSchema>;

interface Props {
  removeWooCommerce: () => void;
  getData: () => void;
  data?: validateWooCommerce | undefined;
}

const wooCommerceData = [
  { name: 'storeDomain', placeholder: 'Store Domain' },
  { name: 'key', placeholder: 'Consumer Key' },
  { name: 'secret', placeholder: 'Consumer Secret' },
];

const WooCommerceForm: FC<Props> = ({ removeWooCommerce, data, getData }) => {
  const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false);
  const [isValidData, setIsValidData] = useState<boolean>(false);

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      storeDomain: '',
      key: '',
      secret: '',
    },
    validationSchema: Yup.object({
      storeDomain: Yup.string().required('Required'),
      key: Yup.string().required('Required'),
      secret: Yup.string().required('Required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = {
        ...values,
        id: uuid(),
        shouldGrabOrderHistory: true,
        uniqueIdConstraint: 'composite',
        _type: 'woocommerce',
      };
      try {
        const createWooCommerce = (await createIntegration(
          data
        )) as validateResponse;
        if (createWooCommerce.status === 200) {
          setIsValidData(false);
          setInvalidCredentials(false);
          getData();
          Toastify(
            'Woo Commerce Created Successfullly',
            'success',
            'wooCommerce1'
          );
          resetForm();
        }
      } catch (error) {
        Toastify('Woo Commerce Creation Failed', 'error', 'wooCommerce2');
      }
    },
  });

  const checkValidation = async () => {
    if (
      formik.values.key.length > 0 &&
      formik.values.secret.length > 0 &&
      formik.values.storeDomain.length > 0
    ) {
      const data = {
        ...formik.values,
        id: '01aa8028-4cc3-4263-954f-f446f6929fd6',
        shouldGrabOrderHistory: true,
        uniqueIdConstraint: 'composite',
        _type: 'woocommerce',
      };
      try {
        const validateWooCommerceResponse = (await validateIntegration(
          data
        )) as validateResponse;
        if (validateWooCommerceResponse.status === 200) {
          setIsValidData(true);
        } else {
          setInvalidCredentials(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const checkInputValidation = setTimeout(() => {
      checkValidation();
    }, 3000);
    return () => clearTimeout(checkInputValidation);
  }, [formik.values]);

  useEffect(() => {
    data ? formik.setValues(data) : formik.setValues(formik.initialValues);
  }, []);
  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className="bg-white mt-6 p-4 bg-[#f5f5f5] border dark:border-black-400 rounded dark:bg-slate-800"
      >
        <h4 className="text-13 font-semibold leading-4 text-black-700 dark:text-white mb-3">
          Woo Commerce
        </h4>
        <Description title="Woo Commerce" />
        {wooCommerceData.map((input) => (
          <div key={input.placeholder}>
            <input
              className="w-full focus:border-primary dark:focus:border-white placeholder:text-gray-500 focus:outline-none text-13 font-medium leading-3 text-gray-700 dark:text-white py-2 rounded px-4 bg-white dark:bg-slate-800 border border-gray-800 dark:border-black-400 sm:h-11 h-9 mt-4"
              type="text"
              placeholder={input.placeholder}
              name={input.name}
              value={formik.values[input.name]}
              onChange={formik.handleChange}
            />
          </div>
        ))}
        <div className="flex flex-wrap items-center mt-4">
          <button
            type="submit"
            className={` ${
              !isValidData
                ? 'bg-gray-800 text-black-400 dark:text-white font-nomral '
                : 'bg-primary text-white '
            }text-13 font-medium leading-5 rounded py-2 px-4 dark:hover:bg-white dark:hover:text-black-400 dark:text-white mr-4 mt-4`}
            disabled={!isValidData}
          >
            Save
          </button>
          <button
            type="button"
            className="text-13 font-medium leading-5 rounded py-2 px-4 text-white bg-[#B31E33] dark:hover:bg-white dark:hover:text-[#b31e33] mr-4 mt-4"
            onClick={removeWooCommerce}
          >
            Remove
          </button>
          <a
            
            className="text-sm leading-4 text-[#cb5b57] dark:text-white mt-4"
          >
            {invalidCredentials
              ? 'invalid Credentials'
              : !isValidData
              ? 'All fields are required'
              : ''}
          </a>
        </div>
      </form>
    </div>
  );
};
export default WooCommerceForm;
