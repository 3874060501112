import { DashboardContext } from 'store/DashboardContext';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { z } from 'zod';
import { calendar, reports, tab } from 'services/constant/routes';
import React from 'react';



const CalendarSchema = z.object({
  title: z.string(),
  start: z.string(),
  id: z.number(),
  day: z.string(),
  createdThisWeek: z.boolean(),
});
type CalendarEvent = z.infer<typeof CalendarSchema>;

const Days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const Calendar = () => {
  const [events, setEvents] = useState<Array<CalendarEvent>>();
  const dashboardCtx = useContext(DashboardContext);
 

  useEffect(() => {
    const compaignEvents = dashboardCtx?.campaigns.map((campaign) => ({
      title: campaign.campaignName,
      start: campaign.eventDate,
      id: campaign.id,
      day: moment(campaign.eventDate).format('ddd'),
      createdThisWeek: moment(campaign.eventDate).isSame(moment(), 'week'),
    }));
    
    setEvents(compaignEvents as CalendarEvent[]);
  }, [dashboardCtx?.campaigns]);
 

  return (
    <div className="rounded-lg shadow-lg overflow-hidden shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800">
      <div className="cardHeader">
        <h4 className="text-base text-darkText font-medium dark:text-white">
          This Week Calendar
        </h4>
      </div>
      <div>
        <div className="bg-listBg flex items-center justify-between font-semibold text-sm text-darkText">
          <div className="">
            <h4 className='w-3/12 font-medium text-darkText p-4'>Days</h4>
          </div>
          <div className="w-9/12 pl-4 border-l flex items-center h-[52px] relative -left-[1px]">
            <h4 className='font-medium text-darkText'>Event Triggers on</h4>
          </div>
        </div>
        <div>
          {Days.map((day) => (
            <div
              key={day}
              className="flex items-center justify-between text-xs border-b "
            >
              <div className="w-3/12 border-r px-4 h-[54px] flex items-center">
                <h4>{day}</h4>
              </div>
              <div className="w-9/12 px-4 flex justify-between">
                <div className='flex flex-wrap gap-1'>
                {events
                  ?.filter((event) => event.day === day && event.createdThisWeek)
                  .slice(0, 3)
                  .map((list) => (
                    <span
                      key={list.id}
                      className="bg-blue-50 text-primary px-1 mr-2"
                    >
                      {list.title}
                    </span>
                  ))}
                {events?.filter((event) => event.day === day && event.createdThisWeek).length === 0 && (
                  <span className="bg-blue-50 text-primary px-1 mr-2">
                    No Event Occured
                  </span>
                )}
                {events?.filter((event) => event.day === day && event.createdThisWeek) &&
                  events?.filter((event) => event.day === day && event.createdThisWeek).length > 3 && (
                    <Link
                      to={`${reports}?${tab}=${calendar}`}
                      className="bg-pink-100 rounded text-pink-400 px-1 py-0.5"
                    >
                      ShowMore
                    </Link>
                   
                  )}
              </div>
            </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Calendar;


