import { FC, useContext } from 'react';
import Analytics from './Body/Analytics';
import StoreReport from './Body/Store-Report';
import Activity from './Body/Activity';
import Calendar from './Body/Calendar';
import {
  getWorkflowReports,
  getBlastByStatus,
  getBlastMonetaryValue,
  getWeeklyRoiReport,
  getAllWorkflows,
} from 'services/apiHandlers/Dashboard/Analytics';
import { useEffect } from 'react';
import { DashboardContext } from 'store/DashboardContext';
import {
  RoiWeeklyResponse,
  BlastResponse,
  WorkflowResponse,
  AllWorkflowResponse,
  Campaigns,
} from './Types';
import { z } from 'zod';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import moment from 'moment';

const BlastValueSchema = z.object({
  monetaryValue: z.number().optional(),
  campaignName: z.string().optional(),
  conversions: z.number().optional(),
  id: z.number().optional(),
  date: z.string().optional(),
  uniqueConversion: z.string().optional(),
  eventDate: z.string().optional(),
});

type BlastValues = z.infer<typeof BlastValueSchema>;

const BlastMonetaryValueResponseDataSchema = z.object({
  blastId: z.number(),
  conversions: z.number(),
  monetaryValue: z.number(),
});

const BlastMonetaryValueResponseSchema = z.object({
  data: z.array(BlastMonetaryValueResponseDataSchema),
  status: z.number(),
});

type BlastMonetaryValueResponse = z.infer<
  typeof BlastMonetaryValueResponseSchema
>;

interface Props {
  activeTab: string;
}

const Body: FC<Props> = ({ activeTab }) => {
  const dashboardCtx = useContext(DashboardContext);

  const getData = async () => {
    dashboardCtx?.loadingStateHandler(true);
    try {
      let tempBlastValues: BlastValues[] = [];
      const getBlastResponse = (await getBlastByStatus(
        dashboardCtx?.storeReportData.startDate.toString(),
        dashboardCtx?.storeReportData.endDate.toString()
      )) as BlastResponse;
      const workflowReportResponse = (await getWorkflowReports(
        dashboardCtx?.storeReportData.startDate.toString(),
        dashboardCtx?.storeReportData.endDate.toString()
      )) as WorkflowResponse;

      const getWeeklyRoiResponse =
        (await getWeeklyRoiReport()) as RoiWeeklyResponse;

      if (workflowReportResponse.status === 200) {
        dashboardCtx?.workflowChangeHanlder(workflowReportResponse.data.data);
      }

      const workflowResponse = (await getAllWorkflows()) as AllWorkflowResponse;

      if (workflowResponse.status === 200) {
        const tempTriggers = workflowResponse.data.triggers.map((data) => {
          return data.lastModified;
        });

        dashboardCtx?.orderDateChangeHandler(tempTriggers);
      }

      if (getWeeklyRoiResponse.status === 200) {
        dashboardCtx?.weeklyRoiDataHandler(getWeeklyRoiResponse.data);
      }

      if (getBlastResponse.status === 200) {
        
        tempBlastValues = getBlastResponse.data.collection.map((data) => ({
          id: data.blast.id,
          campaignName: data.blast.subject,
          date: moment(data.blast.created_at).format('MMM D'),
          eventDate: data.blast.created_at,
        }));
        const getBlastMonetaryValueResponse = (await getBlastMonetaryValue(
          tempBlastValues.map((data) => data.id).join(',')
        )) as BlastMonetaryValueResponse;

        if (getBlastMonetaryValueResponse.status === 200) {
          tempBlastValues = getBlastMonetaryValueResponse.data.map(
            (data, index) => ({
              monetaryValue: data.monetaryValue,
              campaignName: tempBlastValues[index].campaignName,
              date: tempBlastValues[index].date,
              conversions: data.conversions,
              eventDate: tempBlastValues[index].eventDate,
              id: tempBlastValues[index].id,
            })
          );
        }
        dashboardCtx?.campaignChangeHandler(tempBlastValues as Campaigns);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    dashboardCtx?.loadingStateHandler(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <ErrorBoundary>
      {activeTab === 'analytics' && <Analytics />}
      {activeTab === 'store_report' && <StoreReport />}
      {activeTab === 'activity' && <Activity />}
      {activeTab === 'calendar' && <Calendar />}
    </ErrorBoundary>
  );
};
export default Body;
