import { useFormik } from 'formik';
import ScheduleDays from './ScheduleDays';
import ScheduleDate from './ScheduleDate';
import ScheduleTime from './ScheduleTime';
import { useContext, useEffect, useState } from 'react';
import { CampaignContext } from 'store/CampaignContext';
import React from "react";
import moment from 'moment';

const defaultSchedule = {
  day: moment().format('D'),
  hour: '15',
  meridian: 'AM',
  minute: '0',
  month: moment().format('M'),
  year: moment().format('YYYY'),
  frequency: "does not repeat",
  interval: "1",
  days_of_week: [],
};

const defaultSubsriber = {
  relativity: 'after',
  id: -1,
  frequency: 'does not repeat',
  offset: 0,
};

const Schedule = () => {
  const campaignCtx = useContext(CampaignContext);
  const [showError, setShowError] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: { schedule: 'now', trigger: '' },
    onSubmit: () => console.log(),
  });

  useEffect(() => {
    if (campaignCtx?.campaignData) {
      campaignCtx?.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        schedule_type: formik.values.schedule,
        ...(formik.values.schedule !== 'now'
          ? {
              schedule_later: defaultSchedule,
              subscriberField: defaultSubsriber,
            }
          : {}),
        triggerName: formik.values.trigger,
      });

      if (
        campaignCtx?.campaignFormErrors.triggerError &&
        formik.touched.trigger &&
        formik.values.trigger.length === 0
      ) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    }
  }, [formik.values]);

  useEffect(() => {
    if (campaignCtx?.campaignFormErrors.triggerError) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [campaignCtx?.campaignFormErrors]);

  return (
    <>
      <div className="flex justify-between items-center pt-2 mb-4">
        <h1 className="sm:text-xl text-base leading-5 text-black-700 dark:text-white ml-2.5 font-medium">
          Schedule based on
        </h1>
      </div>
      <div className="bg-white rounded-lg pt-6 pb-8 sm:px-7 px-3 mb-10 dark:bg-slate-800 border border-gray-800 dark:border-black-400 shadow-lg shadow-gray-200 dark:shadow-none rounded-lg">
        <div className="sm:px-1">
          <div>
            <div>
              <div>
                <label className="block relative text-13 font-normal leading-3 text-black-400 dark:text-white mb-4 flex items-center">
                  <input
                    className="w-4 h-4 text-primary bg-gray-100 border-gray-300 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 mr-2 accent-blue-600"
                    type="radio"
                    name="schedule"
                    value="now"
                    checked={formik.values.schedule === 'now'}
                    onChange={formik.handleChange}
                  />
                  Now
                </label>
                <label className="block relative text-13 font-normal leading-3 text-black-400 dark:text-white mb-4 flex items-center">
                  <input
                    className="w-4 h-4 text-primary bg-gray-100 border-gray-300 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600 mr-2 accent-blue-600"
                    type="radio"
                    name="schedule"
                    value="subscriber"
                    onChange={formik.handleChange}
                  />
                  Subscribers Field
                </label>

                <label className="block relative text-13 font-normal leading-3 text-black-400 dark:text-white mb-4 flex items-center">
                  <input
                    className="w-4 h-4 text-primary bg-gray-100 border-gray-300 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600 mr-2 accent-blue-600"
                    type="radio"
                    name="schedule"
                    value="later"
                    onChange={formik.handleChange}
                  />
                  Specific Date
                </label>
              </div>
            </div>
            {formik.values.schedule !== 'now' && (
              <label className="block relative text-13 font-normal leading-3 text-black-400 dark:text-white mb-4 flex items-center">
                <p className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white md:w-24 md:mr-3 md:mb-0 mb-4">
                  Trigger Name
                </p>
                <input
                  className="border border-gray-800 rounded-md text-13 font-medium leading-3 text-black-400 w-full h-11 p-4 sm:w-64 w-8/12 dark:bg-slate-800 dark:border-black-400 focus:outline-none dark:text-white ml-4"
                  type="text"
                  name="trigger"
                  value={formik.values.trigger}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {showError && (
                  <div className="text-xs leading-4 text-red-400 mt-1.5">
                    Select a Trigger to continue
                  </div>
                )}
              </label>
            )}
            {formik.values.schedule === 'subscriber' && <ScheduleDays />}
            {formik.values.schedule === 'later' && <ScheduleDate />}
            {formik.values.schedule !== 'now' && <ScheduleTime />}
          </div>
        </div>
      </div>
    </>
  );
};
export default Schedule;
