import { FC, useContext, useEffect, useRef, useState } from 'react';
import {
  updateWorkflow,
  createWorkflow,
  resetExecutions,
} from 'services/apiHandlers/Workflows/Workflow';
import { Toastify } from 'App';
import * as Yup from 'yup';
import Dropdown from 'components/Dropdown/Dropdown';
import { getEventNames } from 'services/apiHandlers/Workflows/Workflow';
import { z } from 'zod';
import DatePicker from 'components/Datepicker';
import { WorkflowContext } from 'store/WorkflowContext';
import { useFormik, FormikValues } from 'formik';
import { SaveWorkflowType, WorkflowSchema } from '../index.type';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React from 'react';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { workflow } from 'services/constant/routes';

interface Props {
  onClose: () => void;
  onSave: () => void;
}

const responseSchema = z.object({
  status: z.number(),
  data: z.array(z.string()),
});
type Response = z.infer<typeof responseSchema>;

const createWorkfloResponseSchema = z.object({
  status: z.number(),
  data: WorkflowSchema,
});
type CreateWorkflowResponse = z.infer<typeof createWorkfloResponseSchema>;

const eventExtraOptions = [
  'insights_email_send',
  'insights_email_view',
  'insights_email_click',
  'ss_email_sent',
  'ss_added_to_list',
  'ss_unsubscribed',
  'ss_email_viewed',
  'ss_email_link_clicked',
  'ss_form_signup',
];

const statusOptions = [
  'Disabled',
  "Test Mode (doesn't execute actions)",
  'Live',
];

const propertiesToDelete = [
  'initialConditionGroup',
  'workflow',
  'accountId',
  'id',
  'lastTriggered',
  'lastModified',
];

const SaveModal: FC<Props> = ({ onClose, onSave }) => {
  const { pathname } = useLocation();
  const [options, setOptions] = useState<Array<string>>([]);
  const [savedData, setSavedData] = useState<SaveWorkflowType>({
    defaultConversionReportingEvent: 'Select an item',
    isEnabled: false,
    isTestMode: false,
    name: '',
    expiresAt: 0,
    runsOncePerPersonIdentifier: false,
  });

  const [selectedDate, setSelectedDate] = useState<string>(
    moment().format('MMMM D, YYYY')
  );
  const [expireChecked, setExpireChecked] = useState<boolean>(false);
  const [resetExecution, setResetExecution] = useState<boolean>(false);
  const workflowCtx = useContext(WorkflowContext);
  const modalRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useOutsideClick(modalRef, onClose);

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      const data = {
        ...(savedData.expiresAt && savedData.expiresAt > 0
          ? { expiresAt: savedData.expiresAt }
          : {}),
        ...(savedData.defaultConversionReportingEvent !== 'Select an item'
          ? {
              defaultConversionReportingEvent:
                savedData.defaultConversionReportingEvent,
            }
          : {}),
        ...(savedData.isTestMode ? { isTestMode: savedData.isTestMode } : {}),
        isEnabled: savedData.isEnabled || false,
        name: values.name,
        workflow: workflowCtx?.workflow?.workflow,
        runsOncePerPersonIdentifier:
          savedData.runsOncePerPersonIdentifier || false,
        initialConditionGroup: workflowCtx?.workflow?.initialConditionGroup,
      };
      try {
        if (pathname.includes('create') || workflowCtx?.copyWorkflow) {
          const createWorkflowResponse = (await createWorkflow(
            data
          )) as CreateWorkflowResponse;
          if (createWorkflowResponse.status === 201) {
            Toastify('Workflow Created Successfully', 'success', 'modal2');
            if (resetExecution) {
              await resetExecutions(createWorkflowResponse.data.id);
            }
            workflowCtx?.onChangeWorkflowId(
              createWorkflowResponse.data.id || ''
            );
            navigate(`${workflow}/${createWorkflowResponse.data.id}`);
          }
        } else {
          const response = (await updateWorkflow(
            data,
            workflowCtx?.workflow?.id || ''
          )) as CreateWorkflowResponse;
          if (response.status === 200) {
            onSave();
            Toastify('Workflow Updated Successfully', 'success', 'header1');
          }
        }
      } catch (error) {
        Toastify('Updating Workflow Failed', 'error', 'header2');
      }
      onClose();
    },
  });

  const getData = async () => {
    try {
      const response = (await getEventNames()) as Response;
      if (response.status === 200) {
        setOptions([...response.data, ...eventExtraOptions]);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const inputChangeHandler = (
    name: string,
    value: string | boolean | number
  ) => {
    setSavedData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const checkStatus = (
    enabled: boolean | undefined,
    testMode: boolean | undefined
  ) => {
    if (enabled && testMode) {
      return "Test Mode (doesn't execute actions)";
    } else if (enabled) {
      return 'Live';
    } else {
      return 'Disabled';
    }
  };

  const statusChangeHandler = (value: string) => {
    let enabled = false,
      testMode = false;
    if (value === 'Disabled') {
      enabled = false;
    } else if (value === 'Live') {
      enabled = true;
    } else {
      enabled = true;
      testMode = true;
    }
    setSavedData({ ...savedData, isEnabled: enabled, isTestMode: testMode });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const tempWorkflow: SaveWorkflowType = { ...workflowCtx?.workflow };
    if (tempWorkflow) {
      for (const prop of propertiesToDelete) {
        delete tempWorkflow[prop as keyof SaveWorkflowType];
      }

      formik.setFieldValue('name', tempWorkflow.name);
      if (tempWorkflow.expiresAt && tempWorkflow?.expiresAt > 0) {
        setExpireChecked(true);
        setSelectedDate(moment(tempWorkflow.expiresAt).format('MMMM D, YYYY'));
      }
      setSavedData(tempWorkflow);
    }
  }, [workflowCtx?.workflow]);

  useEffect(() => {
    inputChangeHandler('expiresAt', moment(selectedDate, 'MMMM DD, YYYY').valueOf());
  }, [selectedDate]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="w-full max-w-[600px] m-auto p-5 fixed top-0 left-0 right-0 z-50 h-[90vh]"
    >
      <div
        ref={modalRef}
        className="bg-white py-5 rounded-lg dark:bg-black-400"
      >
        <div className="bg-white px-4 dark:bg-black-400">
          <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium mb-5 dark:text-white">
            Save Workflow
          </h2>
          <div className="h-[65vh] my-auto overflow-y-auto pr-3">
            <div>
              <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 block my-4 dark:text-white">
                Name
              </label>

              <div className="relative items-center sm:h-11 h-9 bg-white rounded">
                <input
                  className="text-13 font-medium leading-3 text-gray-500 py-2 px-3 w-full h-full border rounded border-gray-800 focus:outline-none focus:border-primary placeholder:text-gray-700 shadow-sm shadow-gray-200 dark:bg-slate-800 dark:text-white dark:hover:text-white"
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>

              {formik.touched.name && Object.keys(formik.errors).length > 0 && (
                <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4">
                  <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
                    Name Cannot be Empty
                  </p>
                </div>
              )}
            </div>
            <div>
              <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 block my-4 dark:text-white">
                Default conversion reporting event
              </label>
              <div className="z-11 bg-white dark:bg-slate-800 rounded dropdown-show-hide relative sm:h-11 h-9 ">
                <Dropdown
                  options={options}
                  value={
                    savedData.defaultConversionReportingEvent ||
                    'Select an item'
                  }
                  onSelect={(value) =>
                    inputChangeHandler('defaultConversionReportingEvent', value)
                  }
                />
              </div>
            </div>
            <div>
              <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 block my-4 dark:text-white">
                Status
              </label>
              <div className="z-10 bg-white dark:bg-slate-800 border-gray-200 dark:border-black-400 border rounded dropdown-show-hide relative sm:h-11 h-9 w-40">
                <Dropdown
                  options={statusOptions}
                  value={checkStatus(savedData.isEnabled, savedData.isTestMode)}
                  onSelect={(value) => statusChangeHandler(value)}
                />
              </div>
            </div>
            <div className="flex items-center mt-6">
              <input
                className="w-[18px] h-[18px] rounded-sm accent-blue-600 mr-2"
                type="checkbox"
                id="subscriber"
                name="subscriber"
                checked={savedData.runsOncePerPersonIdentifier}
                onChange={(e) =>
                  inputChangeHandler(
                    'runsOncePerPersonIdentifier',
                    e.target.checked
                  )
                }
              />
              <label
                htmlFor="subscriber"
                className="font-sm font-normal leading-4 text-black-400 dark:text-white"
              >
                Run once per subscriber
              </label>
            </div>
            <div className="flex items-center mt-6">
              <input
                className="w-[18px] h-[18px] rounded-sm accent-blue-600 mr-2"
                type="checkbox"
                id="expire"
                name="expire"
                checked={expireChecked}
                onChange={(e) => {
                  setExpireChecked(e.target.checked);
                }}
              />
              <label
                htmlFor="expire"
                className="font-sm font-normal leading-4 text-black-400 dark:text-white"
              >
                Expires
              </label>
            </div>
            {expireChecked && (
              <div className="relative w-1/2 flex items-center text-black-400 bg-white dark:bg-slate-800 border-gray-800 dark:border-black-400 border rounded py-1.5 px-3 sm:h-11 h-9 mt-4">
                <DatePicker
                  date={selectedDate}
                  onDateChange={(date) => {
                    date === ''
                      ? setSelectedDate(date)
                      : setSelectedDate(moment(date).format('MMMM D, YYYY'));
                  }}
                />
              </div>
            )}
            <div className="flex items-center mt-4">
              <input
                className="w-[18px] h-[18px] rounded-sm accent-blue-600 mr-2"
                type="checkbox"
                id="reset"
                name="reset"
                checked={resetExecution}
                onChange={(e) => setResetExecution(e.target.checked)}
              />
              <label
                htmlFor="reset"
                className="font-sm font-normal leading-4 text-black-400 dark:text-white"
              >
                Reset workflow history (0 executions)
              </label>
            </div>
            <p className="mt-2 dark:text-white">
              Upon Save, delayed subscribers are removed and execution history
              is reset.
            </p>
          </div>
        </div>
        <div className="border-t my-6 border-black-400 dark:border-white"></div>
        <div className="px-5">
          <div className="flex justify-end items-center">
            <button
              type="submit"
              className="flex items-center text-13 font-medium leading-5 text-white rounded bg-primary px-4 py-2.5 mr-7"
              disabled={Object.keys(formik.errors).length > 0}
            >
              Save
            </button>
            <button
              type="button"
              className="flex items-center text-13 font-medium leading-5 text-black-400 py-2 px-3 bg-white rounded border"
              onClick={() => onClose()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
export default SaveModal;
