import SentEmails from './SentEmails';
import EmailPerformanceStats from './EmailPerformanceStats';
import React from "react";

const EmailPerformance = () => {
  return (
    <div className="mt-11 ">
      <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black dark:text-white font-medium">
        Email Performance
      </h2>
      <div>
        <div className="flex md:flex-row flex-col font-inter mt-4">
          <div className="w-full">
            <div>
              <div className="flex justify-between md:flex-row flex-col">
                <EmailPerformanceStats />
                <SentEmails />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmailPerformance;
