import CheckWhite from 'assets/Images/Campaign/check-white.png';
import { FC, useContext, useEffect, useState } from 'react';
import StatusBar from 'components/StatusBar';
import { EmailContext } from 'store/EmailContext';
import React from 'react';
interface Props {
  draftUpdate: string;
  onYes: () => void;
  onNo: () => void;
}
const CreateHtmlHeader: FC<Props> = ({ draftUpdate, onYes, onNo }) => {
  const emailCtx = useContext(EmailContext);
  const [showDraft, setShowDraft] = useState<boolean>(true);

  useEffect(() => {
    if (emailCtx?.htmlSubmitted) {
      setShowDraft(false);
    } else {
      setShowDraft(true);
    }
  }, [emailCtx?.htmlSubmitted]);
  
  
  return (
    <div className="font-inter">
      <div>
        <StatusBar completedSteps={['1']} />
        <div className="my-4">
          <h1 className="sm:text-xl text-lg font-medium leading-5">
            <span className="text-757575">Step 1b:</span>
            <span className="text-primary">HTML version</span>
          </h1>
        </div>
        <div className="bg-white rounded-lg boxShadow">
          {showDraft && (
            <div className="flex">
              <div className="w-7 h-7 rounded-full bg-primary flex justify-center items-center">
                <img src={CheckWhite} />
              </div>
              <div className="pl-2">
                <p className="text-sm font-medium leading-5 text-black-400 my-1">
                  {`We restored your unsaved draft from ${draftUpdate}. Is that okay?`}
                </p>
                <div className="flex sm:flex-row flex-col items-center mt-2 mb-4">
                  <button
                    onClick={onYes}
                    className="text-[13px] h-10 font-medium leading-5 rounded-md py-1.5 px-4 text-white bg-primary hover:bg-primaryHover ease-in duration-300 mr-2 hover:scale-105"
                  >
                    Yes! Thank You!
                  </button>
                  <span className="text-sm font-medium leading-5 text-616161 mr-2">
                    or
                  </span>
                  <div
                    onClick={onNo}
                    className="text-sm font-medium leading-4 text-primary hover:text-blue-700 ease-in duration-300 text-center cursor-pointer"
                  >
                    {"No thanks, I'd rather start from scratch"}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          {emailCtx?.templateInfo?.name && (
            <p className="text-[13px] font-medium leading-5 my-3 text-757575 dark:text-white">
              You are currently editing the
              <b className="text-616161 font-semibold mr-1 ml-1 dark:text-white">
                {emailCtx.templateInfo?.name}
              </b>
              template.
              {emailCtx.templateInfo.type && <b>under {emailCtx.templateInfo.type} category</b>}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
export default CreateHtmlHeader;
