import React, { useEffect, useState } from 'react';
import {
  addBlock,
  editFilter,
  getFilterById,
  getFilterCount,
  updateFilter,
} from 'services/apiHandlers/List-Segments/Filter';
import {
  getFilterDataSchema,
  ruleDataSchema,
  segmentDataSchema,
} from './Types';
import Segments from './Segments';
import Check from 'assets/Images/Filter/check-icon.png';
import GroupDropdown from 'components/Dropdown/GroupDropdown';
import { z } from 'zod';
import { Toastify } from 'App';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { filters, listSegments, matches } from 'services/constant/routes';
import moment from 'moment';

const getFilterResponseSchema = z.object({
  data: getFilterDataSchema,
  status: z.number(),
});

const addBlockResponseDataSchema = z.object({
  class_name: z.string(),
  column_id: z.number().nullable(),
  column_name: z.string(),
  created_at: z.string(),
  id: z.number(),
  logic_operator: z.string(),
  position: z.number(),
  rule: z.object({ segment_rule: ruleDataSchema }),
  segment_id: z.number(),
  updated_at: z.string(),
});

const addBlockResponseSchema = z.object({
  data: z.object({ segment_block: addBlockResponseDataSchema }),
  status: z.number(),
});

const editFilterResponseSchema = z.object({ status: z.number() });

const RuleSchema = z.object({
  comparison_operator: z.string(),
  comparison_value: z.string(),
});

const RulesSchema = z.record(RuleSchema);

const getCountFilterResponseSchema = z.object({
  data: z.number(),
  status: z.number(),
});

const updateFilterResponseSchema = z.object({ status: z.number() });

type UpdateFilterResponse = z.infer<typeof updateFilterResponseSchema>;
type GetCountFilterResponse = z.infer<typeof getCountFilterResponseSchema>;
type EditFilterResponse = z.infer<typeof editFilterResponseSchema>;
type EditRule = z.infer<typeof RulesSchema>;
type AddBlockResponse = z.infer<typeof addBlockResponseSchema>;
type RuleData = z.infer<typeof ruleDataSchema>;
type FilterData = z.infer<typeof getFilterDataSchema>;
type GetFilterResponse = z.infer<typeof getFilterResponseSchema>;
type SegmentData = z.infer<typeof segmentDataSchema>;

const EditFilters = () => {
  const [filtersData, setFiltersData] = useState<FilterData>();
  const [groups, setGroups] = useState<Array<string>>([]);
  const [groupOptions, setGroupOptions] = useState<Array<Array<string>>>([]);
  const [selectedField, setSelectedField] = useState<string>('');
  const [showRestore, setShowRestore] = useState<boolean>(false);
  const [restoreFilter, setRestoreFilter] = useState<boolean>(false);
  const [filterCount, setFilterCount] = useState<number>();
  const [formTouched, setFormTouched] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const idEdit = parseInt(id || '');

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name Cannot Be Empty'),
    }),
    onSubmit: async (values) => {
      const data = {
        name: values.name,
        description: values.description,
      };
      try {
        const updateFilterResponse = (await updateFilter(
          idEdit,
          data
        )) as UpdateFilterResponse;
        if (updateFilterResponse.status === 200) {
          Toastify(
            'Filter Information Updated Successfully',
            'success',
            'edit4'
          );
        }
      } catch (error) {
        Toastify('Error While Updating Filter', 'error', 'edit3');
      }
    },
  });

  const getFilterData = async (id: number) => {
    try {
      const getFiltersResponse = (await getFilterById(
        id.toString()
      )) as GetFilterResponse;
      if (getFiltersResponse.status === 200) {
        setFiltersData(getFiltersResponse.data);
        setShowRestore(getFiltersResponse.data.restored);
        setRestoreFilter(getFiltersResponse.data.restored);
        const tempGroups: string[] = [];
        const tempOptions: string[][] = [];
        getFiltersResponse.data.fields.map((options) => {
          tempGroups.push(options[0]);
          tempOptions.push(options[1]);
        });
        setGroupOptions(tempOptions);
        setGroups(tempGroups);
        setSelectedField(tempOptions[0][0][1]);
        formik.setFieldValue('name', getFiltersResponse.data.name);
        formik.setFieldValue(
          'description',
          getFiltersResponse.data.description
        );
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const getSegmentName = () => {
    if (selectedField.includes('Person')) {
      const filteredOption = groupOptions[0].filter((option) =>
        option[1].includes(selectedField.split(',')[1])
      );
      return filteredOption[0][0];
    } else {
      const filteredOption = groupOptions[1].filter((option) =>
        option[1].includes(selectedField.split(',')[1])
      );
      return filteredOption[0][0];
    }
  };

  const addBlockHandler = async () => {
    setShowRestore(false);
    try {
      const addBlockResponse = (await addBlock(
        idEdit,
        selectedField
      )) as AddBlockResponse;
      if (addBlockResponse.status === 200) {
        const addSegment: SegmentData = {
          id: addBlockResponse.data.segment_block.id,
          name: getSegmentName(),
          rules: [addBlockResponse.data.segment_block.rule.segment_rule],
          type_label: selectedField.includes('Person') ? 'subscriber' : 'owner',
          logical_operator: addBlockResponse.data.segment_block.logic_operator,
        };
        if (filtersData) {
          setFiltersData({
            ...filtersData,
            segments: [...filtersData.segments, addSegment],
          });
        }
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const onBlockSwap = () => {
    setShowRestore(false);
    if (filtersData) {
      setFiltersData({
        ...filtersData,
        logical_operator:
          filtersData?.logical_operator === 'AND' ? 'OR' : 'AND',
      });
    }
  };

  const onSwapRule = (id: number) => {
    setShowRestore(false);
    const tempFilterData = { ...filtersData };
    const filteredSegments = tempFilterData?.segments?.map((segment) => {
      if (segment.id === id) {
        return {
          ...segment,
          logical_operator: segment.logical_operator === 'AND' ? 'OR' : 'AND',
        };
      } else {
        return segment;
      }
    });
    if (filtersData && filteredSegments) {
      setFiltersData({ ...filtersData, segments: filteredSegments });
    }
  };

  const onRuleRemove = (id: number, ruleId: number) => {
    setShowRestore(false);
    const tempFilterData = { ...filtersData };
    const filteredSegments = tempFilterData?.segments?.map((segment) => {
      if (segment.id === id) {
        return {
          ...segment,
          rules: segment.rules.filter((rule) => rule.id !== ruleId),
        };
      } else {
        return segment;
      }
    });
    if (filtersData && filteredSegments) {
      setFiltersData({
        ...filtersData,
        segments: filteredSegments.filter(
          (segment) => segment.rules.length > 0
        ),
      });
    }
  };

  const onAddRule = (id: number, rule: RuleData) => {
    setShowRestore(false);
    const tempFilterData = { ...filtersData };
    const filteredSegments = tempFilterData?.segments?.map((segment) => {
      if (segment.id === id) {
        return {
          ...segment,
          rules: [...segment.rules, rule],
        };
      } else {
        return segment;
      }
    });
    if (filtersData && filteredSegments) {
      setFiltersData({ ...filtersData, segments: filteredSegments });
    }
  };

  const onOperatorChange = (
    id: number,
    ruleId: number,
    value: string,
    field: string
  ) => {
    setShowRestore(false);
    if (filtersData) {
      const updatedData = { ...filtersData };
      const segment = updatedData?.segments?.find((seg) => seg.id === id);

      if (segment) {
        const rule = segment.rules.find((r) => r.id === ruleId);

        if (rule) {
          if (field === 'operator') {
            rule.comparison_operators.selected = value;
          } else {
            rule.comparison_value = value;
          }
        }
      }
      setFiltersData(updatedData);
    }
  };

  const getRulesData = (data: FilterData) => {
    const filteredRules = data.segments.map((segment) => {
      return segment.rules;
    });
    const allRules = filteredRules.flat();

    const rules: EditRule = {};

    allRules.map((item) => {
      const ruleId = item.id.toString();

      rules[ruleId] = {
        comparison_operator: item.comparison_operators.selected,
        comparison_value: item.comparison_value,
      };
    });
    return rules;
  };

  const getCountHandler = async () => {
    setShowRestore(false);
    if (filtersData) {
      try {
        const getCountFilterResponse = (await getFilterCount(
          idEdit,
          restoreFilter ? 'draft' : 'final',
          { rules: getRulesData(filtersData) }
        )) as GetCountFilterResponse;
        if (getCountFilterResponse.status === 200) {
          setFilterCount(getCountFilterResponse.data);
        }
      } catch (error) {
        console.log('error is : ', error);
      }
    }
  };

  const editFilterHandler = async () => {
    setShowRestore(false);
    try {
      if (formik.isValid) {
        if (filtersData) {
          const editFilterResponse = (await editFilter(idEdit, {
            rules: getRulesData(filtersData),
          })) as EditFilterResponse;
          if (
            editFilterResponse.status === 200 ||
            editFilterResponse.status === 201
          ) {
            Toastify('Filter Edited Sucessfully', 'success', 'edit1');
            navigate(`${listSegments}/${filters}`);
          }
        }
      }
    } catch (error) {
      Toastify('Editing Filter Failed', 'error', 'edit2');
      console.log('error is : ', error);
    }
  };

  useEffect(() => {
    getFilterData(idEdit);
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="w-full">
        <div className="flex flex-wrap justify-between items-center">
          <div className="sm:w-4/12 w-6/12">
            <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black dark:text-white font-medium flex items-center">
              <input
                className="text-xs font-medium text-gray-700 dark:text-white ml-1.5"
                value={formik.values.name}
                onChange={(e) => {
                  setFormTouched(true);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                name="name"
              />
              {!formik.isValid && (
                <div className="text-xs leading-4 text-red-400 mt-1.5">
                  {formik.errors.name}
                </div>
              )}
              <input
                className="text-xs font-medium text-gray-700 dark:text-white ml-1.5"
                value={formik.values.description}
                onChange={(e) => {
                  setFormTouched(true);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                name="description"
              />
              {formTouched && (
                <button
                  type="submit"
                  className="sm:text-13 text-xs font-medium leading-5 text-white sm:py-2.5 py-1.5 sm:px-4 px-3 h-11 rounded-md bg-btnPrimary ease-in-in duration-300 hover:bg-primary hover:scale-105"
                >
                  Update
                </button>
              )}
            </h2>
            <p className="text-sm font-normal leading-4 text-black-400 dark:text-white dark:text-white mt-0.5">
              created about
              {
                <span className="ml-1 mr-1">
                  {moment
                    .duration(
                      moment().diff(
                        moment
                          .utc(filtersData?.created_at)
                          .local()
                          .format('YYYY-MM-DD HH:mm:ss')
                      )
                    )
                    .humanize()}
                </span>
              }
              ago
            </p>
            {showRestore && (
              <p className='className="text-sm font-normal leading-4 text-black-400 dark:text-white dark:text-white mt-0.5"'>
                Restore Unsaved Filter Changes
              </p>
            )}
          </div>
          <div>
            {Number.isInteger(filterCount) ? (
              <div>{filterCount}</div>
            ) : (
              <div className="flex sm:justify-center justify-end items-center sm:w-4/12 w-6/12">
                <span className="w-5 h-5 rounded-full">
                  <img className="w-full" src={Check} alt="Check" />
                </span>
                <span
                  onClick={getCountHandler}
                  className="text-base font-medium leading-5 text-black-700 dark:text-white ml-2"
                >
                  Count?
                </span>
              </div>
            )}
            <Link
              to={`${listSegments}/${filters}/${matches}/${idEdit}`}
              className="cursor-pointer"
            >
              View Checks
            </Link>
          </div>

          <div className="flex justify-end items-center sm:w-4/12 w-full sm:mt-0 mt-2.5">
            <Link
              to={`${listSegments}/${filters}`}
              // onClick={() => onChangeView('')}
              className="sm:text-13 text-xs py-2.5 px-4 h-11 w-28 font-medium leading-5 text-primary border-2 border-primary rounded-md bg-white hover:text-white ease-in duration-300 hover:bg-primary hover:scale-105 mr-3"
            >
              Cancel
            </Link>
            <button
              type="button"
              className="sm:text-13 text-xs font-medium leading-5 text-white sm:py-2.5 py-1.5 sm:px-4 px-3 h-11 rounded-md bg-btnPrimary ease-in-in duration-300 hover:bg-primary hover:scale-105"
              onClick={editFilterHandler}
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
      {filtersData?.segments?.map((segment, index) => (
        <Segments
          key={index}
          segment={segment}
          operator={
            filtersData.segments.length - 1 !== index
              ? filtersData?.logical_operator
              : ''
          }
          onBlockSwap={onBlockSwap}
          onSwapRule={onSwapRule}
          onRuleRemove={onRuleRemove}
          onAddRule={onAddRule}
          onOperatorChange={onOperatorChange}
        />
      ))}
      <div className="sm:px-6 px-4 sm:py-4 py-2.5 border dark:border-black-400 rounded-lg shadow-lg shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800 mt-6">
        <div>
          <label className="text-xs font-semibold leading-3 text-black-400 dark:text-white dark:text-white block">
            Add Database field
          </label>
          <div className="flex flex-wrap items-center mt-4">
            <div className="w-44 flex items-center rounded relative sm:h-11 h-20 mr-2">
              <GroupDropdown
                options={groupOptions}
                groups={groups}
                onSelect={(e) => setSelectedField(e.target.value)}
              />
            </div>
            <button
              onClick={addBlockHandler}
              type="button"
              className="text-13 font-medium leading-5 rounded-md py-1.5 px-4 text-white bg-primary dark:hover:bg-white dark:hover:text-black-400 dark:text-white sm:h-11 h-9 sm:ml-0 ml-2 ease-in duration-300 hover:bg-primaryHover hover:scale-105"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
export default EditFilters;
