import { ChartDataset } from 'chart.js';
import PieGraph from 'components/Graph/PieGraph';
import React, { useState, useEffect, useContext } from 'react';
import { getReportQouta } from 'services/apiHandlers/Dashboard/StoreReport';
import { z } from 'zod';
import { DashboardContext } from 'store/DashboardContext';
// import moment from 'moment';
const qoutaDataSchema = z.object({
  unlimited_quota: z.boolean().nullable(),
  quota: z.number(),
  used: z.number(),
  remaining: z.number(),
});
const getQoutaEmailResponseSchema = z.object({
  data: qoutaDataSchema,
  status: z.number(),
});
type QoutaEmailResponse = z.infer<typeof getQoutaEmailResponseSchema>;
const defaultPieData = [
  {
    data: [12, 19, 3],
    backgroundColor: ['#FBB846', '#8FDBC0', '#2EA1F9'],
  },
];
const SentEmails = () => {
  const [sentMails, setSentMails] = useState<string>('');
  const [pieLabels, setPieLabels] = useState<Array<string>>([]);
  const [pieData, setPieData] =
    useState<ChartDataset<'doughnut', number[]>[]>();
  const dashboardCtx = useContext(DashboardContext);
  const getSentEmailData = async () => {
    try {
      const getQoutaEmailResponse =
        (await getReportQouta()) as QoutaEmailResponse;
      if (getQoutaEmailResponse.status === 200) {
        setSentMails(getQoutaEmailResponse.data.used.toString());
        const totalWorkflows = dashboardCtx?.workflows.reduce(
          (accumulator, workflow) => {
            return accumulator + workflow.sends;
          },
          0
        );
        const totalCampaigns =
          getQoutaEmailResponse.data.used - (totalWorkflows || 0);
        if (getQoutaEmailResponse.data.unlimited_quota) {
          setPieLabels(['Sent in workflows', 'Sent in campaigns']);
          setPieData([
            {
              data: [totalWorkflows || 0, totalCampaigns],
              backgroundColor: ['#506fe47a', '#506fe4c9'],
            },
          ]);
        } else {
          setPieLabels([
            'Sent in workflows ',
            'Sent in campaigns',
            'Available',
          ]);
          setPieData([
            {
              data: [
                totalWorkflows || 0,
                totalCampaigns,
                getQoutaEmailResponse.data.remaining,
              ],
              backgroundColor: ['#506fe47a', '#506fe4c9', '#506FE4'],
              // borderWidth: 10,
            },
          ]);
        }
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };
  useEffect(() => {
    getSentEmailData();
  }, [dashboardCtx?.workflows]);
  return (
    <div className="">
      <div className="2xl:h-full h-[274px] rounded-lg shadow-lg shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800 2xl:py-8 xl:px-7 lg:px-5 px-3.5">
        <p className="text-sm 2xl:pt-0 pt-4 font-medium text-headingColor leading-4 tracking-wide mb-2.5 dark:text-white">
          Sent Emails
        </p>
        <div className="flex items-end">
          <h2 className="2xl:text-3xl text-xl 2xl:leading-10 dark:text-white font-medium text-lightParagraphColor">
            {sentMails}
          </h2>
        </div>
        <div>
          <div className={pieData ? '' : 'hidden'}>
            <PieGraph
              width={'100%'}
              height={'100%'}
              labels={pieLabels}
              datasets={pieData || defaultPieData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SentEmails;