import { getSubscriberSummary } from 'services/apiHandlers/Dashboard/Analytics';
import { useContext, useEffect, useState } from 'react';
import { DashboardContext } from 'store/DashboardContext';
import {z} from "zod";
import React from "react";
import SubscriberSVG from 'assets/Images/Dashboard/subscriber.svg';
import NewlyAddedSVG from 'assets/Images/Dashboard/newAdded.svg';
import UnsubscribeSVG from 'assets/Images/Dashboard/unsubscribe.svg';

const AudienceGrowthDataSchema = z.object({
  title: z.string(),
  growth: z.string(),
  id: z.string(),
  icon: z.string(),
  bgColor: z.string()
});
type AudienceGrowthData = z.infer<typeof AudienceGrowthDataSchema>;

const SubscriberSummaryDataSchema = z.object({
  total: z.number(),
  subscribed: z.number(),
  unsubscribed: z.number(),

});

const SubscriberResponseDataSchema = z.object({
  data: z.object({ data: SubscriberSummaryDataSchema }),
  status: z.number(),
});
type SubscriberResponse = z.infer<typeof SubscriberResponseDataSchema>;

const AudienceGrowth = () => {
  const dashboardCtx = useContext(DashboardContext);
  const [subscriberData, setSubscriberData] =
    useState<Array<AudienceGrowthData>>();
  
  const getData = async () => {
    const getSubscriberResponse = (await getSubscriberSummary(
      dashboardCtx?.storeReportData.startDate,
      dashboardCtx?.storeReportData.endDate
    )) as SubscriberResponse;
    if (getSubscriberResponse.status === 200) {
      setSubscriberData([
        {
          title: 'Total Subscribers',
          growth: getSubscriberResponse.data.data.total.toString(),
          id: '1',
          icon: SubscriberSVG,
          bgColor: "bg-green-100"
        },
        {
          title: 'Newly Added',
          growth: getSubscriberResponse.data.data.subscribed.toString(),
          id: '2',
          icon: NewlyAddedSVG,
          bgColor: "bg-yellow-100"

        },
        {
          title: 'Unsubscribed',
          growth: getSubscriberResponse.data.data.unsubscribed.toString(),
          id: '3',
          icon: UnsubscribeSVG,
          bgColor: "bg-cyan-100"

        },
      ]);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="mt-4">
      <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black dark:text-white font-medium">
        Audience Growth
      </h2>
      <div>
        <div className="grid md:grid-cols-3 grid-rows-1 gap-5 font-inter mt-4">
          {subscriberData &&
            subscriberData.map((audienceGrowth) => (
              <div key={audienceGrowth.id} className="w-full max-width-24 mb-4 hover:mt[-4px]">
                <div className="flex flex-col justify-center items-center text-center rounded-lg shadow-lg shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800  pb-6 lg:px-4 px-3.5 bg-white boxShadow">
                  <p className="text-sm my-4 w-full text-left text-headingColor dark:text-white font-medium leading-4">
                    {audienceGrowth.title}
                  </p>
                  
                  <div className="flex justify-between w-full items-center">
                    <h3 className="text-lightParagraphColor dark:text-white font-medium leading-7 text-3xl">
                      {audienceGrowth.growth}
                    </h3>
                    <img src={audienceGrowth.icon} alt='Audience Growth' className={`w-12 p-3 rounded-full ${audienceGrowth.bgColor}`} />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default AudienceGrowth;
