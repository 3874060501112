import Duplicate from 'assets/Images/Workflow/copy.png';
import Gallery from 'assets/Images/Campaign/gallery.png';
import CampaignDropdown from 'components/Dropdown/GroupDropdown';
import EmailAlert from 'components/Alert/EmailAlert';
import { useFormik, FormikValues } from 'formik';
import { FC, useContext, useState } from 'react';
import { html } from 'utils/html';
import { Toastify } from 'App';
import { copyPasteHtmlHandler } from 'services/apiHandlers/Campaigns/Emails';
import { z } from 'zod';
import { EmailContext } from 'store/EmailContext';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { emailEditor, create } from 'services/constant/routes';

const emailDataSchema = z.object({
  account_id: z.number(),
  agreed_to_canspam_address_for_html: z.number().nullable(),
  agreed_to_canspam_address_for_text: z.number().nullable(),
  content_type: z.string(),
  created_at: z.string(),
  creator_id: z.number(),
  creator_type: z.string(),
  css: z.string(),
  description: z.string().nullable(),
  html_part: z.string(),
  id: z.number(),
  name: z.string(),
  process: z.string(),
  save_type: z.string(),
  size_in_bytes: z.number(),
  source_email_id: z.number(),
  subject: z.string().nullable(),
  tag_list: z.union([z.array(z.string()), z.tuple([])]),
  template_id: z.number(),
  template_type: z.string(),
  text_part: z.string(),
  updated_at: z.string(),
  widgetization_error: z.string().nullable(),
});

const submitEmailResponseSchema = z.object({
  data: z.object({
    draft: z.object({ email: emailDataSchema }),
    redirect_to: z.string(),
  }),
  status: z.number(),
});
export type SubmitEmailResponse = z.infer<typeof submitEmailResponseSchema>;

interface Props {
  showContent: boolean;
  onCopyShow: () => void;
  groups: string[];
  options: string[][];
}

const CopyPasteHtml: FC<Props> = ({
  showContent,
  onCopyShow,
  groups,
  options,
}) => {
  const [showMergeMail, setShowMergeMail] = useState<boolean>(false);
  const [mergeMail, setMergeMail] = useState<string>('{{{email_address}}}');
  const emailCtx = useContext(EmailContext);
  const navigate = useNavigate();

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      mail: '',
    },
    onSubmit: async (values) => {
      console.log('values subject', values);
    },
  });

  const submitHtmlHandler = async () => {
    const data = {
      copy_paste: formik.values.mail,
      commit: 'Submit',
    };
    try {
      const submitHtmlResponse = (await copyPasteHtmlHandler(
        data
      )) as SubmitEmailResponse;
      if (submitHtmlResponse.status === 200) {
        navigate(`${emailEditor}?${create}=true`);

        emailCtx?.htmlSubmitHandler(
          submitHtmlResponse.data.draft.email.html_part || html
        );
      }
    } catch (error) {
      Toastify('Submitting Html Failed', 'error', 'html2');
    }
  };

  return (
    <div>
      <div
        onClick={onCopyShow}
        className="ml-7 p-2 flex items-center mt-3 md:w-8/12 bg-f6f7f9"
      >
        <img className="w-5" src={Duplicate} />
        <a className="text-sm font-medium leading-3 ml-2 text-primary hover:text-blue-700 ease-in duration-300">
          Copy/Paste HTML
        </a>
      </div>
      {showContent && (
        <div className="md:w-8/12 ml-7 border bg-white rounded mt-4">
          <div className="">
            <div className=" bg-gray-50 py-2.5 px-3">
              <div className="flex md:justify-start justify-between items-center">
                <div
                  onClick={() => setShowMergeMail(true)}
                  className="md:w-auto flex items-center text-xs font-medium text-primary mr-2 cursor-pointer"
                >
                  <span className="w-3 h-3 flex justify-center items-center inline-block rounded-full bg-primary text-white text-xs font-bold mr-1">
                    +
                  </span>
                  <span className="underline w-24">Add Mail Merge</span>
                </div>
                <CampaignDropdown
                  onSelect={(e) => {
                    setMergeMail(e.target.value);
                  }}
                  groups={groups}
                  options={options}
                />
                <a className="md:w-auto flex md:justify-start justify-end items-center text-xs font-medium text-primary xl:ml-20 md:ml-8">
                  <span className="w-4 h-4 mr-2">
                    <img src={Gallery} />
                  </span>
                  <span className="underline w-16">Add Image</span>
                </a>
              </div>
            </div>
            <textarea
              className="w-full p-3 focus:outline-0"
              name="mail"
              value={formik.values.mail}
              onChange={formik.handleChange}
            ></textarea>
            {showMergeMail && (
              <div>
                <div
                  onClick={() => setShowMergeMail(false)}
                  className="fixed left-0 top-0 w-full h-full z-10 bg-black-200 duration-300"
                ></div>
                <EmailAlert
                  cancelClicked={() => setShowMergeMail(false)}
                  createClicked={(mail) => {
                    formik.setFieldValue('mail', formik.values.mail + mail);
                    setShowMergeMail(false);
                  }}
                  mergeMail={mergeMail}
                />
              </div>
            )}
            <div className="text-right bg-gray-50 py-2 px-3">
              <button
                onClick={submitHtmlHandler}
                className="text-sm font-medium leading-5 rounded-md px-4 py-2.5 w-28 h-11 bg-btnPrimary hover:bg-primary text-white hover:scale-105 ease-in duration-300"
                type="button"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CopyPasteHtml;
