import TrackDelivery from "./TrackDelivery";
import GoogleAnalytics from "./GoogleAnalytics";
import React from "react"
const DeliveryOptions = () => {
  return (
    <>
      <h1 className="sm:text-xl text-base leading-5 text-black-700 dark:text-white ml-2.5 font-medium">
        Delivery Options
      </h1>
      <div className="sm:px-1">
        <div className="bg-white rounded-lg pt-6 pb-8 sm:px-7 px-3 mb-10 dark:bg-slate-800 border border-gray-800 dark:border-black-400 shadow-lg shadow-gray-200 dark:shadow-none rounded-lg">
          <div>
            <h3 className="text-base font-semibold leading-6 text-black-700 dark:text-white mb-1">
              Choose Delivery Options
            </h3>
            <p className="text-sm font-normal leading-4 text-black-300 dark:text-white">
              Enhance emails and blast reporting with tracking, social sharing,
              and analytics options.
            </p>
            <div className="lg:flex flex-wrap mt-7">
              <TrackDelivery />
              <GoogleAnalytics/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DeliveryOptions;
