import { useEffect, useState } from 'react';
import {
  getDefaultConversionPeriod,
  updateConversionPeriod,
} from 'services/apiHandlers/Integration/Miscellaneous';
import { z } from 'zod';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Toastify } from 'App';
import ErrorBoundary from 'components/ErrorBoundary';
import React from "react";

const responseSchema = z.object({
  data: z.object({ defaultConversionPeriod: z.number() }),
  status: z.number(),
});
type response = z.infer<typeof responseSchema>;

const updateConversionPeriodResponseSchema = z.object({ status: z.number() });
type updateConversionPeriodResponse = z.infer<
  typeof updateConversionPeriodResponseSchema
>;

const ConversionPeriod = () => {
  const [conversionPeriod, setConversionPeriod] = useState<number>();

  const getData = async () => {
    const getConversionPeriodResponse =
      (await getDefaultConversionPeriod()) as response;
    if (getConversionPeriodResponse.status === 200) {
      setConversionPeriod(
        getConversionPeriodResponse.data.defaultConversionPeriod
      );
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      conversionPeriod: conversionPeriod,
    },
    validationSchema: Yup.object({
      conversionPeriod: Yup.number().required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        const data = { defaultConversionPeriod: values.conversionPeriod };
        const updateConversionPeriodResponse = (await updateConversionPeriod(
          data
        )) as updateConversionPeriodResponse;
        if (updateConversionPeriodResponse.status === 200) {
          Toastify('Conversion Period Updated', 'success', 'period1');
        }
      } catch (error) {
        Toastify('Updating Conversion Period Failed', 'error', 'period2');
      }
    },
  });
  useEffect(() => {
    getData();
  }, []);
  return (
    <ErrorBoundary>
      <form
        onSubmit={formik.handleSubmit}
        className="p-4 bg-gray-150 dark:bg-slate-800 rounded border border-gray-800 dark:border-black-400 mt-8"
      >
        <h3 className="text-13 font-semibold leading-4  text-black-700 dark:text-white mb-2">
          Conversion Period
        </h3>
        <p className="text-xs leading-4 text-black-400 dark:text-white dark:text-white">
          Number of days <span className="font-semibold">(1-90)</span> to look
          back to attribute conversions to emails. Conversions are attributed to
          the latest email view or click occurring within:
        </p>
        <div className="flex items-center mt-3">
          <input
            className="border focus:border-primary dark:focus:border-white focus:outline-none border-gray-800 dark:border-black-400 dark:bg-slate-800 sm:h-11 h-9 p-4 text-13 font-medium leading-3 text-gray-700 placeholder:text-gray-500 dark:text-white dark:bg-slate-800 rounded-md w-full focus:border-primary dark:focus:border-white focus:outline-none md:w-7/12"
            type="Number"
            max="90"
            min="1"
            name="conversionPeriod"
            data-pw="conversionPeriod"
            id="conversion"
            value={formik.values.conversionPeriod}
            onChange={formik.handleChange}
          />
          <span className="text-13 font-normal leading-4 text-gray-700 dark:text-white ml-2.5">
            day(s)
          </span>
        </div>
        <p className="text-xs leading-4 text-black-400 dark:text-white dark:text-white mt-2.5">
          note: changes to the conversion period will impact new reporting data
          only
        </p>
        <div className="mt-4">
          <button
            type="submit"
            className="sm:py-2.5 py-1.5 sm:px-4 px-2 sm:text-13 text-xs font-medium leading-4 text-white rounded-md bg-btnPrimary ease-in-in duration-300 hover:bg-primary hover:scale-105"
            data-pw="update-conversion-period"
          >
            Update
          </button>
        </div>
        {formik.errors.conversionPeriod ? (
          <div data-pw="conversion-period-required" className="text-xs leading-4 text-red-400 mt-1.5">
            {formik.errors.conversionPeriod}
          </div>
        ) : null}
      </form>
    </ErrorBoundary>
  );
};
export default ConversionPeriod;
