import SideNote from './SideNote';
import { getGoogleSettings } from 'services/apiHandlers/Integration/GoogleAnalytics';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import ToggleSwitch from 'ToggleSwitch/ToggleSwitch';
import { useFormik, FormikValues } from 'formik';
import { updateGoogleAnalyticsSetting } from 'services/apiHandlers/Integration/GoogleAnalytics';
import { Toastify } from 'App';
import ErrorBoundary from 'components/ErrorBoundary';
import React from "react";

const responseStatusSchema = z.object({ status: z.number() });
type ResponseStatus = z.infer<typeof responseStatusSchema>;

const googleSettingsData = [
  { id: 1, title: 'campaign Source:', name: 'campaign' },
  { id: 2, title: 'campaign Medium:', name: 'medium' },
  { id: 3, title: 'campaign Content:', name: 'content' },
  { id: 4, title: 'campaign Name:', name: 'source' },
  { id: 5, title: 'Account ID (optional, to track opens):', name: 'accountId' },
];

const settingsSchema = z.object({
  campaign: z.string(),
  content: z.string(),
  medium: z.string(),
  source: z.string(),
  accountId: z.string(),
  enabled: z.boolean(),
});
type settings = z.infer<typeof settingsSchema>;
const responseSchema = z.object({
  data: settingsSchema,
  status: z.number(),
});
type response = z.infer<typeof responseSchema>;

const GoogleAnalytics = () => {
  const [settingsData, setSettingsData] = useState<settings | []>([]);
  const [showList, setShowList] = useState<boolean>(false);

  const getData = async () => {
    try {
      const getGoogleAnalyticsresponse =
        (await getGoogleSettings()) as response;
      if (getGoogleAnalyticsresponse.status === 200) {
        setSettingsData(getGoogleAnalyticsresponse.data);
      }
    } catch (error) {
      console.log('Getting data Failed', error);
    }
  };

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      campaign: '',
      medium: '',
      content: '',
      source: '',
      accountId: '',
    },

    onSubmit: async (values) => {
      const data = { ...values, enabled: showList ? true : false };
      try {
        const updateGoogleAnalyticsSettingResponse =
          (await updateGoogleAnalyticsSetting(data)) as ResponseStatus;
        if (updateGoogleAnalyticsSettingResponse.status === 200) {
          Toastify('Data Updated Successfully', 'success', 'google1');
        }
      } catch (error) {
        Toastify('Updating data failed', 'error', 'google2');
      }
    },
  });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    formik.setValues(settingsData);
  }, [settingsData]);

  return (
    <ErrorBoundary>
      <form
        id="GoogleAnalytics_content"
        className="tabcontent"
        onSubmit={formik.handleSubmit}
      >
        <div className="md:px-6 px-3 pt-4 pb-6 rounded bg-white dark:bg-slate-800 shadow-lg shadow-gray-200 dark:shadow-none border border-gray-800 dark:border-black-400 ">
          <div className="lg:flex">
            <div className="lg:w-6/12 lg:pr-5">
              <div>
                <div className="flex justify-between items-center">
                  <p className="text-13 font-semibold leading-4  text-black-700 dark:text-white mb-2">
                    Analytics
                  </p>
                  <ToggleSwitch
                    onChange={() => setShowList((prev) => !prev)}
                    checked={showList}
                  />
                </div>
                {showList &&
                  googleSettingsData.map((input) => (
                    <div data-pw="analytics-form" key={input.name} className="mt-5">
                      <>
                        <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white">
                          {input.title}
                        </label>
                        <input
                          className="w-full focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 font-medium leading-3 border border-gray-800 dark:border-black-400 rounded-md mt-2 text-black-400 dark:text-white dark:text-white bg-white dark:bg-slate-800 sm:h-11 h-9 placeholder:text-gray-500 dark:placeholder:text-white"
                          type="text"
                          placeholder={input.name}
                          name={input.name}
                          value={formik.values[input.name]}
                          onChange={formik.handleChange}
                        />
                      </>
                    </div>
                  ))}

                <div className="w-full mt-4">
                  <button
                    type="submit"
                    className={`${showList ? "text-white bg-primary " : "bg-gray-800 text-black-400 cursor-not-allowed font-nomral" } text-13 font-medium leading-5 rounded-md py-2.5 px-4 h-11 w-28 hover:text-white ease-in duration-300 hover:bg-primaryHover hover:scale-105`}
                    disabled={!showList}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
            <div className="lg:w-6/12 lg:pl-5 lg:mt-0 mt-8">
              <SideNote linkData={formik.values}/>
            </div>
          </div>
        </div>
      </form>
    </ErrorBoundary>
  );
};
export default GoogleAnalytics;
