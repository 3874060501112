import Delete from 'assets/Images/Common/delete-red.png';
import Send from 'assets/Images/Common/send.png';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import DeleteModal from 'components/Alert/DeleteModal';
import Mail from 'assets/Images/svg-images/mail.svg';
import { Toastify } from 'App';
import { deleteEmail } from 'services/apiHandlers/Campaigns/Emails';
import { createCampaign, emailTemplates } from 'services/constant/routes';
import SendTestModal from './SendTestModal';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import StatusBar from 'components/StatusBar';
import React from 'react';
import { EmailContext } from 'store/EmailContext';

const deleteEmailResponseSchema = z.object({ status: z.number() });
type DeleteEmailResponse = z.infer<typeof deleteEmailResponseSchema>;
interface Props {
  name: string;
  lastUpdated: string;
  id: string;
  loading: boolean;
  view?: string;
  draftUpdate?: string;
  onYes?: () => void;
  onNo?: () => void;
  showEmailModal?: () => void;
}

const Header: FC<Props> = ({
  name,
  lastUpdated,
  id,
  loading,
  view,
  draftUpdate,
  onYes,
  onNo,
}) => {
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const modalRef = useRef<HTMLDivElement>(null);
  const [draftUpdatedDate, setDraftUpdatedDate] = useState<string>('');
  const emailCtx = useContext(EmailContext);

  useOutsideClick(modalRef, () => setShowModal(false));

  const deleteEmailHandler = async (id: string) => {
    try {
      const deleteEmailResponse = (await deleteEmail(
        id
      )) as DeleteEmailResponse;
      if (deleteEmailResponse.status === 200) {
        Toastify('Email Deleted Successfully', 'success', 'actions1');
        navigate(emailTemplates);
      }
    } catch (error) {
      Toastify('Deleting Email Failed', 'error', 'actions2');
    }
  };

  const sendButtonClickHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (loading) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setDraftUpdatedDate(draftUpdate || '');
  }, [draftUpdate]);

  return (
    <div className="flex items-center relative">
      <div className="w-full relative">
        {view === 'edit' && (
          <h1 className="sm:text-xl text-lg font-medium leading-5 dark:text-white">
            Edit
          </h1>
        )}
        {view === 'emailSelected' && (
          <>
            <StatusBar completedSteps={['1']} />
            <div className="my-5">
              <h1 className="sm:text-xl text-lg font-medium leading-7">
                <span className="text-757575 dark:text-white">Step 1b:</span>
                <span className="text-primary">HTML version</span>
              </h1>
            </div>
          </>
        )}
        {draftUpdatedDate.length > 0 && view !== 'emailSelected' && (
          <div>
            <p className="text-sm font-medium leading-5 text-black-400 mb-3">
              {`We restored your unsaved draft from ${draftUpdatedDate}. Is that okay?`}
            </p>
            <div className="flex sm:flex-row flex-col items-center">
              <button
                onClick={onYes}
                className="text-[13px] h-10 font-medium leading-5 rounded-md py-1.5 px-4 text-white bg-primary hover:bg-primaryHover hover:scale-105 ease-in duration-300 mr-2"
              >
                Yes! Thank You!
              </button>
              <span className="text-sm font-medium leading-5 text-black-400 mr-2">
                or
              </span>
              <div
                onClick={onNo}
                className="text-sm font-medium leading-4 text-primary hover:text-blue-700 ease-in duration-300 text-center cursor-pointer"
              >
                {`No thanks, use the last saved version from ${lastUpdated}`}
              </div>
            </div>
          </div>
        )}
        <div className="mt-4 rounded-lg boxShadow">
          <div className="flex justify-between items-center my-2">
            <div>
              {view === 'edit' ||
                (pathname === '/preview-email' && (
                  <div>
                    <h3 className="sm:text-base text-sm font-medium leading-5 dark:text-white">
                      {name}
                    </h3>
                    <p className="text-xs font-medium leading-4 text-black-400 dark:text-white">
                      Last Updated at {lastUpdated}
                    </p>
                  </div>
                ))}
            </div>
            <div className="flex items-center">
              <div className="flex items-center">
                {view === 'edit' && (
                  <button
                    type="button"
                    className={`${
                      loading ? 'cursor-not-allowed' : 'cursor-pointer'
                    } flex items-center text-sm text-sm font-medium text-primary hover:text-blue-700 ease-in duration-300 font-medium leading-3 text-red-500 dark:text-white`}
                    onClick={() => setShowDeleteAlert(true)}
                    disabled={loading}
                  >
                    <span className="w-4 h-4 mr-1.5">
                      <img
                        className="w-full h-full dark:invert dark:brightness-0"
                        src={Delete}
                        alt="Delete"
                      />
                    </span>
                    Delete
                  </button>
                )}
                <Link
                  // type="button"
                  className={`${
                    loading ? 'cursor-not-allowed' : 'cursor-pointer'
                  } flex items-center text-sm font-medium leading-3 text-[#3575d5] ml-5 dark:text-white`}
                  // disabled={loading}
                  onClick={sendButtonClickHandler}
                  to={`${createCampaign}/${id}?tab=edit`}
                >
                  <span className="w-4 h-4 mr-1.5">
                    <img
                      className="w-full h-full dark:invert dark:brightness-0"
                      src={Send}
                    />
                  </span>
                  Send
                </Link>
                <div ref={modalRef}>
                  <button
                    className={`${
                      loading ? 'cursor-not-allowed' : 'cursor-pointer'
                    } flex items-center text-sm font-medium leading-3 text-[#3575d5] ml-5 dark:text-white cursor-pointer`}
                    disabled={loading}
                    onClick={() => !loading && setShowModal((prev) => !prev)}
                  >
                    <span className="w-4 h-4 mr-1.5">
                      <img
                        className="w-full h-full dark:invert dark:brightness-0"
                        src={Mail}
                      />
                    </span>
                    Send Test
                  </button>
                  <div>
                    {showModal && (
                      <div>
                        <SendTestModal onClose={() => setShowModal(false)} />
                      </div>
                    )}
                  </div>
                </div>
                {/* {view === 'edit' && (
                  <button
                    type="button"
                    className={`${
                      loading ? 'cursor-not-allowed' : 'cursor-pointer'
                    } flex items-center text-13 font-medium leading-3 text-gray-700 ml-5 dark:text-white`}
                    disabled={loading}
                  >
                    <span className="w-4 h-4 mr-1.5">
                      <img
                        className="w-full h-full dark:invert dark:brightness-0"
                        src={Edit}
                      />
                    </span>
                    Edit
                  </button>
                )} */}
              </div>
              {showDeleteAlert && (
                <div
                  onClick={() => setShowDeleteAlert(false)}
                  className="fixed left-0 top-0 w-full h-full z-10 bg-black-200 duration-300"
                >
                  <DeleteModal
                    onDelete={() => deleteEmailHandler(id)}
                    onCancel={() => setShowDeleteAlert(false)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              {emailCtx?.templateInfo?.name && view === 'edit' && (
                <p className="text-[13px] font-medium leading-5 my-3 text-757575 dark:text-white">
                  You are currently editing the
                  <b className="text-616161 font-semibold mr-1 ml-1 dark:text-white">
                    {emailCtx.templateInfo?.name}
                  </b>
                  template.
                  {emailCtx.templateInfo.type && (
                    <b>under {emailCtx.templateInfo.type} category</b>
                  )}
                </p>
              )}
            </div>

            {
              <p className="text-sm font-medium leading-5 my-3 text-757575 dark:text-white">
                Saved as
                <b className="text-616161 font-semibold"> {name} </b>
              </p>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
