import { useEffect, useMemo, useState } from 'react';
import messageRemove from 'assets/Images/Dashboard/message-remove.png';
import Form from '../Common/Form';
import { TableColumn } from 'react-data-table-component';
import Table from 'components/Table';
import {z} from "zod"
import React from "react"

const activitySchema = z.object({
  type: z.string(),
  status: z.string(),
  sentDate: z.string(),
  sentDay: z.string(),
});
type activity = z.infer<typeof activitySchema>;

const activitiesSchema = z.array(activitySchema);
type activities = z.infer<typeof activitiesSchema>;

const allActivities = [
  {
    type: 'James Perry',
    status: 'unscubsdcribed',
    sentDate: '06-01-2022',
    sentDay: '06-09-2020',
  },
  {
    type: 'Welcome Winters',
    status: 'Has sent',
    sentDate: '06-01-2022',
    sentDay: '06-09-2020',
  },
  {
    type: 'Welcome Home',
    status: 'Not sent',
    sentDate: '06-01-2022',
    sentDay: '06-09-2020',
  },
  {
    type: 'James Perry',
    status: 'unscubsdcribed',
    sentDate: '06-01-2022',
    sentDay: '06-09-2020',
  },
];

const Activity = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [activities, setActivities] = useState<activities>(allActivities);

  const columns: TableColumn<activity>[] = useMemo(
    () => [
      {
        name: 'Activity Type',
        cell: (row) => {
          return (
            <div className="dark:text-white flex items-center pt-3 pb-6">
              <span className="mr-2">
                <img src={messageRemove} alt="Remove Message"/>
              </span>
              <p className="text-13 font-medium leading-4 text-black-400">
                <strong>{row.type}</strong> {row.status}
              </p>
            </div>
          );
        },

        sortable: true,
      },
      {
        name: 'Sent on',
        cell: (row) => {
          return (
            <div className="dark:text-white pt-3 pb-6 hover:bg-gray-500">
              <div className="text-13 font-medium leading-4 mb-1.5 text-black-400">
                {row.sentDate}
              </div>
              <div className="text-xs font-medium leading-4 text-gray-700">
                {row.sentDay}
              </div>
            </div>
          );
        },
        sortable: true,
      },
    ],
    []
  );

  useEffect(() => {
    const transformProducts = [...activities];
    if (searchQuery) {
      transformProducts.filter((product) => product.type.includes(searchQuery));
    }
    setActivities(transformProducts);
  }, [searchQuery]);
  return (
    <div className="bg-gray-400 dark:bg-slate-900 font-inter py-7 md:px-6 px-3 pb-8">
    <div className='md:px-1.5'>
      <Form heading="All Activities" setSearchQuery={setSearchQuery} />
      <Table className="scrollbar-hide" data={activities} columns={columns} />
      </div>
    </div>
  );
};
export default Activity;
