import { useContext,FC } from 'react';
import {

  OnlineStore,
} from 'staticdata/Dashboard/Data';
import StoreReport from 'components/DashboardReports/Body/Store-Report/Report';
import { storeReportOrder } from 'components/DashboardReports/Body/Store-Report/Types';
import { DashboardContext } from 'store/DashboardContext';
import React from 'react';

interface Props {
  revenue: storeReportOrder | undefined;
}

const Revenue: FC<Props> = ({ revenue }) => {
  const dashboardCtx = useContext(DashboardContext);

  return (
    <div>
      <StoreReport
        data={revenue ? revenue : OnlineStore}
        loading={dashboardCtx?.loading}
      />
    </div>
  );
};
export default Revenue;
