import { useEffect, useState } from 'react';
import {
  getFrequencyLimit,
  updateWorkflowFrequency,
} from 'services/apiHandlers/Integration/Miscellaneous';
import { useFormik } from 'formik';
import { z } from 'zod';
import * as Yup from 'yup';
import { Toastify } from 'App';
import ErrorBoundary from 'components/ErrorBoundary';
import React from "react";

const WorkflowActions = () => {
  const [frequencyLimit, setFrequencyLimit] = useState<number>();

  const responseSchema = z.object({
    data: z.object({ frequencyLimit: z.number() }),
    status: z.number(),
  });
  type response = z.infer<typeof responseSchema>;

  const updateFrequencyLimitResponseSchema = z.object({ status: z.number() });
  type updateFrequencyLimitResponse = z.infer<
    typeof updateFrequencyLimitResponseSchema
  >;

  const getData = async () => {
    const getWorkflowActionsresponse = (await getFrequencyLimit()) as response;
    if (getWorkflowActionsresponse.status === 200) {
      setFrequencyLimit(getWorkflowActionsresponse.data.frequencyLimit);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      frequencyLimit: frequencyLimit,
    },
    validationSchema: Yup.object({
      frequencyLimit: Yup.number().required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        const updateFrequencyLimitResponse = (await updateWorkflowFrequency(
          values
        )) as updateFrequencyLimitResponse;
        if (updateFrequencyLimitResponse?.status === 200) {
          Toastify(
            'Workflow Actions Updated Successfully',
            'success',
            'workflow1'
          );
        }
      } catch (error) {
        Toastify('Updating Workflow Actions Failed', 'error', 'workflow2');
      }
    },
  });
  useEffect(() => {
    getData();
  }, []);
  return (
    <ErrorBoundary>
      <form
        onSubmit={formik.handleSubmit}
        className="p-4 bg-gray-150 dark:bg-slate-800 rounded border border-gray-800 dark:border-black-400 mt-8"
      >
        <h3 className="text-13 font-semibold leading-4  text-black-700 dark:text-white mb-2">
          Workflow Action Frequency Limits
        </h3>
        <p className="text-xs leading-4 text-black-400 dark:text-white dark:text-white">
          Number of hours between executing the same workflow action for the
          same person. Delays are never skipped. Set to 0 (Zero) if youd like to
          disable this.
        </p>
        <p className="text-xs leading-4 text-black-400 dark:text-white dark:text-white mt-2.5">
          Skip a workflow action if that person has done that action within:
        </p>
        <div className="flex items-center mt-3">
          <input
            className="text-13 focus:border-primary dark:focus:border-white focus:outline-none font-medium leading-3 text-gray-700 dark:text-white py-2 rounded-md px-4 focus:outline-none bg-white border border-gray-800 dark:border-black-400 md:w-7/12 sm:h-11 h-9 dark:bg-slate-800 dark:border-black-400"
            type="Number"
            name="frequencyLimit"
            data-pw="frequencyLimit"
            value={formik.values.frequencyLimit}
            onChange={formik.handleChange}
          />
          <span className="text-13 font-normal leading-4 text-gray-700 dark:text-white ml-2.5">
            hour(s)
          </span>
        </div>
        <div className="flex items-center mt-4">
          <button
            type="submit"
            className="text-13 font-medium leading-5 rounded-md py-2 px-4 text-white bg-primary mr-3 ease-in duration-300 hover:bg-primaryHover hover:scale-105"
            data-pw="update-workflow-actions"
          >
            Update
          </button>
          {formik.errors.frequencyLimit ? (
            <div className="text-xs leading-4 text-red-400 mt-1.5">
              {formik.errors.frequencyLimit}
            </div>
          ) : null}
        </div>
      </form>
    </ErrorBoundary>
  );
};
export default WorkflowActions;
