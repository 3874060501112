import upArrow from 'assets/Images/Dashboard/up-arrow.png';
import DownArrow from 'assets/Images/svg-images/down.svg';
import { ChartDataset, ScatterDataPoint } from 'chart.js';
import LineGraph from 'components/Graph/LineGraph';
import { FC } from 'react';
import PieGraph from 'components/Graph/PieGraph';
import BarGraph from 'components/Graph/BarGraph';
import StoreReportStats from 'components/DashboardReports/Body/Store-Report/StoreReportStats';
import { storeReportOrder } from './Types';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import CustomSkeleton from 'components/Skeleton/Skeleton';
import { useLocation } from 'react-router-dom';
import { home } from 'services/constant/routes';
interface Props {
	data: storeReportOrder;
	loading?: boolean;
}

const Order: FC<Props> = ({ data, loading }) => {
	const {pathname} = useLocation();
		return (
			<ErrorBoundary>
				<div className="md:mt-0 mt-5">
					
					<div className="rounded-lg shadow-lg overflow-hidden shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800">
						<div className='cardHeader'>
							<h4 className="text-base text-darkText font-medium dark:text-white">
								{data.orderType}
							</h4>
						</div>
						<div className="py-3 lg:px-3 px-3.5 rounded-t-lg bg-white dark:bg-slate-800">
							{pathname !== home && <div className="flex items-end">
								<h2 className="sm:text-3xl text-xl leading-10 font-medium text-black-700 dark:text-white">
									{data.orderValue}
								</h2>
								{data.orderPercentage !== 'Infinity' && (
									<div className="flex items-center ml-4 mb-2">
									<span>
										<img
										src={
											data.orderPercentage.includes('-') ? DownArrow : upArrow
										}
										alt="Arrow"
										/>
									</span>
									<span
										className={`${
										data.orderPercentage.includes('-')
											? 'text-red-400'
											: 'text-green-400'
										} text-15 font-gilroySemibold leading-4 ml-1 dark:text-white`}
									>
										{data.orderPercentage + '%'}
									</span>
									</div>
								)}
							</div>}
							{loading ? (
								<CustomSkeleton columns={5} />
							) : (
							<div>
								{data.graphType === 'Line' && (
									<LineGraph
										chartLabels={data.graphCategories}
										datasets={
										data.graphData as ChartDataset<
											'line',
											(number | ScatterDataPoint | null)[]
										>[]
										}
										width={data.graphWidth}
										height={data.graphHeight}
										style={data.graphStyle}
									/>
								)}
								{data.graphType === 'Coloumn' && (
									<BarGraph
										height={data.graphHeight }
										chartLabels={data.graphCategories}
										datasets={
										data.graphData as ChartDataset<
											'bar',
											(number | [number, number] | null)[]
										>[]
										}
										style={data.graphStyle}
									/>
								)}
								{data.graphType === 'Pie' && (
									<PieGraph
										labels={data.graphCategories}
										datasets={
										data.graphData as ChartDataset<'doughnut', number[]>[]
										}
										height={data.graphHeight}
										width={data.graphWidth}
									/>
								)}
							</div>
							)}
						</div>
						{pathname !== home && <StoreReportStats
							lastMonth={data.lastMonth}
							last14Days={data.last14Days}
							last90Days={data.last90Days}
						/>}
					</div>
				</div>
			</ErrorBoundary>
		);
	};
export default Order;
