import Form from 'components/DashboardReports/Body/Common/Form';
import { TableColumn } from 'react-data-table-component';
import Table from 'components/Table';

import { useEffect, useMemo, useState } from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import styled from 'styled-components';
import { z } from 'zod';
import {
  getAllBlasts as getBlastByStatus,
  getBlastMonetaryValue,
} from 'services/apiHandlers/Dashboard/Analytics';
import { getAllBlasts } from 'services/apiHandlers/Campaigns/CampaignInformation';
import { BlastSchema } from 'components/DashboardReports/Types';
import { calculatePercentage } from 'utils/common';
import moment from 'moment';
import React from 'react';
import { Link,} from 'react-router-dom';
import { campaignReports } from 'services/constant/routes';

const StyledWrapper = styled.div`
  .WQNxq {
    border-radius: 8px 8px 0 0;
  }
  .rdt_TableRow .rdt_TableCell {
    border-right: 1px solid #e0e0e0;
  }

  .rdt_TableRow .rdt_TableCell:last-child {
    border-right: 0;
  }

  .rdt_TableHeadRow .rdt_TableCol {
    border-right: 1px solid #e0e0e0;
  }
  .rdt_TableHeadRow .rdt_TableCol:last-child {
    border-right: 0;
  }
  .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
    max-width: 20%;
  }
  .rdt_TableRow .rdt_TableCell:nth-child(1) {
    max-width: 20%;
  }
  .rdt_TableHeadRow .rdt_TableCol:last-child {
    min-width: 155px;
  }
  .rdt_TableRow .rdt_TableCell:last-child {
    min-width: 155px;
  }
`;

const BlastMonetaryValueResponseData = z.object({
  blastId: z.number(),
  conversions: z.number(),
  monetaryValue: z.number(),
});

const getBlastMonetaryValueResponseSchema = z.object({
  data: z.array(BlastMonetaryValueResponseData),
  status: z.number(),
});

const campaignsSchema = z.object({
  collection: z.union([z.array(BlastSchema), z.tuple([])]),
  current_page: z.number(),
  per_page: z.number(),
  total_count: z.number(),
});

const getFilterCampaignsSchema = z.object({
  data: campaignsSchema,
  status: z.number(),
});

const BlastResponseSchema = z.object({
  data: z.object({
    collection: z.union([z.array(BlastSchema), z.tuple([])]),
    total_count: z.number(),
  }),
  status: z.number(),
});

const PageDataSchema = z.object({
  page: z.number(),
  per_page: z.number(),
});

type Blasts = z.infer<typeof BlastSchema>;
type GetBlastMonetaryValueResponse = z.infer<
  typeof getBlastMonetaryValueResponseSchema
>;
type BlastMonetaryData = z.infer<typeof BlastMonetaryValueResponseData>;
type GetFilterCampaigns = z.infer<typeof getFilterCampaignsSchema>;
type BlastResponse = z.infer<typeof BlastResponseSchema>;
type PageData = z.infer<typeof PageDataSchema>;

interface Status {
  [key: string]: string;
  Sent: string;
  Sending: string;
  Scheduled: string;
}

const campaignStatus: Status = {
  Sent: 'sent',
  Sending: 'sending',
  Scheduled: 'waiting',
};

const AllCampaigns = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [allCampaigns, setAllCampaigns] = useState<Array<Blasts>>([]);
  const [filteredCampaigns, setFilteredCampaigns] =
    useState<Array<Blasts>>(allCampaigns);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedFilter, setSelectedFilter] = useState<string>('Filter');
  const [blastMonetaryValues, setBlastMonetaryValues] = useState<
    Array<BlastMonetaryData>
  >([]);
  const [totalCampaigns, setTotalCampaigns] = useState<number>(0);

  const handlePageChange = (page: number) => {
    if (selectedFilter !== 'All' && selectedFilter !== 'Filter') {
      getFilteredCampaigns(campaignStatus[selectedFilter], {
        page: page,
        per_page: 15,
      });
    } else {
      getData({ page: page, per_page: 15 });
    }
  };

  const getData = async (paginationData: PageData) => {
    setLoading(true);
    try {
      const getBlastStatusResponse = (await getAllBlasts(
        paginationData
      )) as BlastResponse;
      if (getBlastStatusResponse.status === 200) {
        setAllCampaigns(getBlastStatusResponse.data.collection);
        setTotalCampaigns(getBlastStatusResponse.data.total_count);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false);
  };

  const getFilteredCampaigns = async (filter: string, pageData: PageData) => {
    setLoading(true);
    try {
      const getFilteredResponse = (await getBlastByStatus(filter, {
        page: pageData.page,
        per_page: pageData.per_page,
      })) as GetFilterCampaigns;
      if (getFilteredResponse.status === 200) {
        setAllCampaigns(getFilteredResponse.data.collection);
        setTotalCampaigns(getFilteredResponse.data.total_count);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false);
  };

  const checkSentConvertValue = (id: number, status: string) => {
    if (status === 'sent') {
      const filteredId = blastMonetaryValues.filter(
        (blast) => blast.blastId === id
      );
      return filteredId[0]?.conversions;
    } else {
      return '-';
    }
  };

  const getMonetaryValues = async (id: string) => {
    try {
      const getBlastMonetaryValuesResponse = (await getBlastMonetaryValue(
        id
      )) as GetBlastMonetaryValueResponse;
      if (getBlastMonetaryValuesResponse.status === 200) {
        setBlastMonetaryValues(getBlastMonetaryValuesResponse.data);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const campaignSort = (rowA: Blasts, rowB: Blasts) => {
    const a = rowA.blast.subject.toLowerCase();
    const b = rowB.blast.subject.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const columns: TableColumn<Blasts>[] = useMemo(
    () => [
      {
        name: 'ID',
        cell: (row) => {
          return (
            <Link
              to={`${campaignReports}/${row.blast.id}`}
              className="whitespace-break-spaces align-top py-3 pr-3 text-13 font-medium text-black-400 dark:text-white cursor-pointer"
            >
              {row.blast.id}
            </Link>
          );
        },
        center: true,
      },
      {
        name: 'Subject',
        cell: (row) => {
          return (
            <Link
              to={`${campaignReports}/${row.blast.id}`}
              className="whitespace-break-spaces py-3 text-13 text-black-400 dark:text-white align-top cursor-pointer"
            >
              {row.blast.subject}
            </Link>
          );
        },
        sortable: true,
        center: true,
        sortFunction: campaignSort,
      },
      {
        name: 'Status',
        cell: (row) => {
          return (
            <td className="whitespace-break-spaces py-3 text-center text-13 text-black-400 dark:text-white align-top table-cell w-full">
              <span
                className={`${
                  row.blast.status === 'sending'
                    ? 'text-[#ea7104] bg-[#f8eade]'
                    : row.blast.status === 'waiting'
                    ? 'text-primary bg-[#eaeef4]'
                    : 'text-green-600 bg-green-100'
                } text-xs font-medium leading-3  rounded-xl py-1 px-2.5 `}
              >
                {row.blast.status}
              </span>
            </td>
          );
        },
        center: true,
      },
      {
        name: 'Recipients',
        cell: (row) => {
          return (
            <td className="whitespace-break-spaces py-3 text-13 text-black-400 dark:text-white align-top text-center table-cell w-full">
              {row.blast.intended_recipients}
            </td>
          );
        },
        center: true,
      },
      {
        name: 'Delivered',
        cell: (row) => {
          return (
            <td className="whitespace-break-spaces py-3 text-13 text-black-400 dark:text-white align-top text-center table-cell w-full">
              <div>{row.blast.delivered || '-'}</div>
              <div className="text-xs text-gray-700 dark:text-white mt-1">
                {row.blast.delivered
                  ? calculatePercentage(
                      row.blast.delivered,
                      row.blast.number_sent
                    ) + '%'
                  : '-'}
              </div>
            </td>
          );
        },
        center: true,
      },
      {
        name: 'Bounced',
        cell: (row) => {
          return (
            <td className="whitespace-break-spaces py-3 text-13 text-black-400 dark:text-white text-center align-top table-cell w-full">
              <div>{row.blast.bounced || '-'}</div>
              <div className="text-xs text-gray-700 dark:text-white mt-1">
                {row.blast.bounced
                  ? calculatePercentage(
                      row.blast.bounced,
                      row.blast.number_sent
                    )
                  : '-'}
              </div>
            </td>
          );
        },
        center: true,
      },
      {
        name: 'Unique Viewers',
        cell: (row) => {
          return (
            <td className="whitespace-break-spaces py-3 text-black-400 dark:text-white align-top text-center table-cell w-full">
              <div>{row.blast.unique_views_count}</div>
              <div className="text-xs text-gray-700 dark:text-white mt-1">
                {row.blast.delivered
                  ? calculatePercentage(
                      row.blast.unique_views_count,
                      row.blast.delivered
                    ) + '%'
                  : '-'}
              </div>
            </td>
          );
        },
        center: true,
      },
      {
        name: 'Social Viewers',
        cell: (row) => {
          return (
            <td className="whitespace-break-spaces py-3 text-13 text-black-400 dark:text-white align-top text-center table-cell w-full">
              {row.blast.social_views_count || '-'}
            </td>
          );
        },
        center: true,
      },
      {
        name: 'Unique Clickers',
        cell: (row) => {
          return (
            <td className="whitespace-break-spaces sm:align-top align-middle py-3 text-center text-13 text-black-400 dark:text-white table-cell w-full">
              <div>{row.blast.unique_clicks_count}</div>
              <div className="text-xs text-gray-700 dark:text-white mt-1">
                {row.blast.delivered
                  ? calculatePercentage(
                      row.blast.unique_clicks_count,
                      row.blast.delivered
                    )
                  : '-'}
              </div>
            </td>
          );
        },
        center: true,
      },
      {
        name: 'Unsubscribes',
        cell: (row) => {
          return (
            <td className="whitespace-break-spaces sm:align-top align-middle py-3 text-center text-13 text-black-400 dark:text-white table-cell w-full">
              <div>{row.blast.unique_complaints_count}</div>
              <div className="mt-1">
                {row.blast.delivered
                  ? calculatePercentage(
                      row.blast.unique_complaints_count,
                      row.blast.delivered
                    )
                  : '_'}
              </div>
            </td>
          );
        },
        center: true,
      },
      {
        name: 'Socail Shares',
        cell: (row) => {
          return (
            <td className="whitespace-break-spaces sm:align-top align-middle py-3 text-center text-13 text-black-400 dark:text-white table-cell w-full">
              {row.blast.social_count || '-'}
            </td>
          );
        },
        center: true,
      },
      {
        name: 'Converted',
        cell: (row) => {
          return (
            <td className="whitespace-break-spaces sm:align-top align-middle py-3 text-center text-13 text-black-400 dark:text-white table-cell w-full">
              {checkSentConvertValue(row.blast.id, row.blast.status)}
            </td>
          );
        },
        center: true,
      },
      {
        name: 'Date',
        cell: (row) => {
          return (
            <td className="whitespace-break-spaces sm:align-top align-middle py-3 text-center text-13 text-black-400 dark:text-white table-cell w-full">
              <div>{moment(row.blast.created_at).format('MMM DD, YYYY')}</div>
            </td>
          );
        },
        center: true,
      },
      {
        name: 'Actions',
        cell: (row) => {
          return (
            <Link
              to={`${campaignReports}/${row.blast.id}`}
              className="whitespace-break-spaces sm:align-top align-middle py-3 text-center text-13 text-primary dark:text-white table-cell w-full cursor-pointer"
            >
              <div className="mr-4 flex justify-between items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>

                <span>View Details</span>
              </div>
            </Link>
          );
        },
        center: true,
      },
    ],
    [filteredCampaigns]
  );

  useEffect(() => {
    let tempCampaigns = [...allCampaigns];
    tempCampaigns = allCampaigns.filter((campaign) =>
      campaign.blast.subject.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredCampaigns(tempCampaigns);
  }, [searchQuery, allCampaigns]);

  useEffect(() => {
    const filteredCampaigns = allCampaigns.filter(
      (campaign) => campaign.blast.status === 'sent'
    );

    const filteredIds = filteredCampaigns.map((data) => {
      return data.blast.id;
    });
    getMonetaryValues(filteredIds.join(','));
  }, [allCampaigns]);

  useEffect(() => {
    getData({ page: 1, per_page: 15 });
  }, []);

  return (
    <ErrorBoundary>
      <Form
        heading="All Campaigns"
        setSearchQuery={setSearchQuery}
        showFilter={true}
        filterOptions={['All', 'Sent', 'Sending', 'Scheduled']}
        onFilterSelect={(filter: string) => {
          if (filter === 'All') {
            getData({ page: 1, per_page: 15 });
          } else {
            getFilteredCampaigns(campaignStatus[filter], {
              page: 1,
              per_page: 15,
            });
          }
          setSearchQuery('');
          setSelectedFilter(filter);
        }}
        searchQuery={searchQuery}
        selectedFilter={selectedFilter}
        hideForm={true}
      />
      <div className="grid grid-cols-1 gap-4 font-inter pt-3">
        <StyledWrapper>
          <Table
            className="scrollbar-hide"
            data={filteredCampaigns}
            columns={columns}
            progressPending={loading}
            paginationServer
            paginationTotalRows={totalCampaigns}
            paginationPerPage={15}
            onChangePage={handlePageChange}
          />
        </StyledWrapper>
      </div>
    </ErrorBoundary>
  );
};
export default AllCampaigns;
