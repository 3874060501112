import React , { FC, useContext } from 'react';
import { WorkflowAction, WorkflowCondition } from './index.type';
import { WorkflowContext } from 'store/WorkflowContext';
import NextNode from "./NextNode"

interface Props {
  onAddBlock: (id: string, direction?: string) => void;
}

export const renderBranchNodes = (
  workflow: WorkflowAction | WorkflowCondition | undefined,
  onAddBlock: (id: string, direction?: string) => void
) => {
  if (workflow) {
    if ('action' in workflow || 'conditionGroup' in workflow) {
      return <NextNode workflow={workflow} onAddBlock={onAddBlock} />;
    }
  }
};

export const renderNodes = (
  workflow: WorkflowAction | WorkflowCondition | undefined,
  firstRender = true,
  onAddBlock: (id: string, direction?: string) => void
) => {
  if (workflow) {
    if (firstRender) {
      return <NextNode workflow={workflow} onAddBlock={onAddBlock} />;
    } else if ('nextNode' in workflow) {
      return <NextNode workflow={workflow.nextNode} onAddBlock={onAddBlock} />;
    } else if ('trueNode' in workflow || 'falseNode' in workflow) {
      return (
        <>
          {workflow.trueNode && (
            <NextNode workflow={workflow.trueNode} onAddBlock={onAddBlock} />
          )}
          {workflow.falseNode && (
            <NextNode workflow={workflow.falseNode} onAddBlock={onAddBlock} />
          )}
        </>
      );
    }
  }
};

const BranchNodes: FC<Props> = ({ onAddBlock }) => {
  const workflowCtx = useContext(WorkflowContext);
  return (
    <div>
      {renderNodes(workflowCtx?.workflow?.workflow, true, onAddBlock)}
    </div>
  );
};
export default BranchNodes;
