import { useState } from 'react';
import Header from 'components/Header';
import SideBar from 'components/Sidebar';
import ErrorBoundary from 'components/ErrorBoundary';

import React from "react";

type Props = {
  children: JSX.Element;
};
const MainLayout: React.FC<Props> = ({ children }: Props) => {
  const [showSidebar, setShowsidebar] = useState(false);
  const [showFullSidebar, setShowFullSidebar] = useState<boolean>(true)
  const toggleSidebar = () => {
    setShowsidebar((prev) => !prev);
  };
  return (
    <ErrorBoundary>
      <div className="min-h-screen flex transition duration-200 dark:bg-gray-900">
        <SideBar toggleSidebar={toggleSidebar} showSidebar={showSidebar} showFullSidebar={showFullSidebar} setShowFullSidebar={setShowFullSidebar} />
        <div className={`${showFullSidebar ? "lg:w-[calc(100%-250px)] sm:w-[calc(100%-4rem)] lg:ml-[256px] sm:ml-16" : "lg:w-[calc(100%-80px)] sm:w-[calc(100%-4rem)] lg:ml-[80px] sm:ml-8"} w-full overflow-hidden`}>

          <Header toggleSidebar={toggleSidebar} />
          {children}
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default MainLayout;
