import MagnetoDescription from 'components/Integration/Body/Cart/Description/MagnetoDescription';
import WooCommerceDescription from 'components/Integration/Body/Cart/Description/WooCommerceDescription';
import BigCommerceDescription from 'components/Integration/Body/Cart/Description/BigCommerceDescription';
import { useEffect, useState } from 'react';
import React from 'react';
interface Props {
  title: string;
}
enum DESCRIPTION {
  MAGNETO = 'Magento 1',
  WOO_COMMERCE = 'Woo Commerce',
  BIG_COMMERCE = 'Big Commerce',
}
const Description: React.FC<Props> = ({ title }: Props) => {
  const [description, setDescription] = useState<string>(title);
  useEffect(() => {
    setDescription(title);
  }, [title]);
  return (
    <>
      {DESCRIPTION.MAGNETO === description && <MagnetoDescription />}
      {DESCRIPTION.WOO_COMMERCE === description && <WooCommerceDescription />}
      {DESCRIPTION.BIG_COMMERCE === description && <BigCommerceDescription />}
    </>
  );
};
export default Description;
