import React from "react"
const Loader = () => {
  return (
    <div className="flex mr-4 justify-center items-center ">
      <div className="animate-spin h-6 w-6 rounded-full border-4 border-gray-600 border-t-4 border-t-blue-600 ..."></div>
    </div>
  );
};

export default Loader;
 