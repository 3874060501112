import { FC } from 'react';
import { z } from 'zod';
import React from "react"

const listDataSchema = z.object({ name: z.string(), id: z.number() });
type ListData = z.infer<typeof listDataSchema>;

const listResponseDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  audience_id: z.number(),
});
type List = z.infer<typeof listResponseDataSchema>

interface Props {
  list: List[];
  onSelect: (name: string, id: number) => void;
  selectedList: ListData[];
}

const CheckList: FC<Props> = ({ list, onSelect, selectedList }) => {
  return (
    <div>
      {list.map((list) => (
        <div key={list.id}>
          {
            <div className="flex items-center py-1 px-3 cursor-pointer hover:bg-primaryHover hover:text-white text-black-700 dark:text-white dark:hover:bg-white dark:hover:text-black-400">
              <input
                className="mt-1"
                value={list.name}
                type="checkbox"
                checked={
                  selectedList.filter(
                    (selectedList) => selectedList.id === list.id
                  ).length > 0
                    ? true
                    : false
                }
                onChange={() => onSelect(list.name, list.id)}
                id={list.id.toString()}
              />
              <label
                htmlFor={list.id.toString()}
                className="checkBox relative cursor-pointer pl-2"
              >
                <span className="text-13 font-normal leading-3">
                  {list.name}
                </span>
              </label>
            </div>
          }
        </div>
      ))}
    </div>
  );
};
export default CheckList;
