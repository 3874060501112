import DeliveryOptions from '../SendEmail/DeliveryOptions';
import Mailer from '../SendEmail/Mailer';
import Sender from '../SendEmail/Sender';
import EmailContent from '../SendEmail/EmailContent';
import Tabs from '../SendEmail/Tabs';
import { SendEmailData } from '../index.type';
import { FC, useEffect, useRef, useState } from 'react';
import { z } from 'zod';
import uuid from 'react-uuid';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import React from 'react';
interface Props {
  emailData: SendEmailData | undefined;
  onClose: () => void;
  onSave: (data: SendEmailData) => void;
}

const senderDataSchema = z.object({
  fromEmailName: z.string(),
  fromEmailAddress: z.string(),
  replyToEmailAddress: z.string(),
});
type SenderData = z.infer<typeof senderDataSchema>;

const providerDataSchema = z.object({
  emailId: z.number().nullable(),
  subject: z.string(),
});
type ProviderData = z.infer<typeof providerDataSchema>;

const SendEmail: FC<Props> = ({ emailData, onClose, onSave }) => {
  const [activeTab, setActiveTab] = useState('Mailer');
  const [validForm, setValidForm] = useState({
    senderIsValid: false,
    contentIsValid: false,
  });
  const [ignoreStatus, setIgnoreStatus] = useState<boolean>(
    emailData?.ignoreOptStatus || false
  );
  const [sendEmailData, setSendEmailData] = useState<SendEmailData>({
    fromEmailName: '',
    fromEmailAddress: '',
    replyToEmailAddress: '',
    emailProviderId: uuid(),
    subject: '',
    senderId: '',
    ignoreOptStatus: emailData?.ignoreOptStatus || false,
    _type: 'SendEmailAction',
    emailId: null,
  });
  const [formsTouched, setFormsTouched] = useState<boolean>(false);
  const [data, setData] = useState<SendEmailData | undefined>();
  const [formIsValid, setFormIsValid] = useState<boolean>(true);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const mailerRef = useRef<HTMLDivElement>(null);
  const senderRef = useRef<HTMLDivElement>(null);
  const emailRef = useRef<HTMLDivElement>(null);
  const deliveryRef = useRef<HTMLDivElement>(null);

  const tabClickHandler = (tab: string) => {
    setActiveTab(tab);
    /* eslint-disable */
    if (tab === 'Mailer') {
      mailerRef.current!.scrollIntoView({ behavior: 'smooth' });
    }
    if (tab === 'Sender') {
      senderRef.current!.scrollIntoView({ behavior: 'smooth' });
    }
    if (tab === 'Content') {
      emailRef.current!.scrollIntoView({ behavior: 'smooth' });
    }
    if (tab === 'Options') {
      deliveryRef.current!.scrollIntoView({ behavior: 'smooth' });
    }
    /* eslint-enable */
  };

  const saveDataHandler = () => {
    if (
      !formsTouched ||
      !validForm.contentIsValid ||
      !validForm.senderIsValid
    ) {
      setFormIsValid(false);
    } else {
      if (sendEmailData.senderId === '') {
        delete sendEmailData.senderId;
      }
      onSave(sendEmailData);
      onClose();
    }
  };

  useEffect(() => {
    setData(emailData);
  }, [emailData]);

  useEffect(() => {
    if (validForm.contentIsValid && validForm.senderIsValid && formsTouched) {
      setFormIsValid(true);
    }
  }, [validForm]);

  return (
    <div>
      <ModalWrapper
        onClose={onClose}
        disabled={!formIsValid}
        onSave={saveDataHandler}
      >
        <>
          <div className="bg-white dark:bg-black-400">
            <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium mb-8 dark:text-white">
              Action
            </h2>
            <Tabs
              onTabClick={(tab) => tabClickHandler(tab)}
              activeTab={activeTab}
            />
            <div className="h-[65vh] my-auto overflow-y-auto px-5">
              <div>
                <Mailer mailRef={mailerRef} />
                <Sender
                  emailData={data}
                  formIsValid={(valid) => {
                    if (valid) {
                      setValidForm({ ...validForm, senderIsValid: valid });
                    }
                    setShowPreview(valid);
                  }}
                  onSenderDataChanged={(data: SenderData) => {
                    setSendEmailData({ ...sendEmailData, ...data });
                  }}
                  senderRef={senderRef}
                  onFormTouch={() => setFormsTouched(true)}
                />
                <EmailContent
                  emailData={data}
                  formIsValid={(valid) => {
                    if (valid) {
                      setValidForm({ ...validForm, contentIsValid: valid });
                    }
                  }}
                  emailRef={emailRef}
                  onDataChange={(data: ProviderData) =>
                    setSendEmailData({
                      ...sendEmailData,
                      ...data,
                    })
                  }
                  senderIsValid={showPreview}
                  onFormTouch={() => setFormsTouched(true)}
                />
                <DeliveryOptions
                  status={ignoreStatus}
                  deliveryRef={deliveryRef}
                  onChangeStatus={(status) => {
                    setSendEmailData({
                      ...sendEmailData,
                      ignoreOptStatus: status,
                    });
                    setIgnoreStatus(status);
                  }}
                />
              </div>
            </div>
          </div>
          {!formIsValid && (
            <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4">
              <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
                Correct the errors above
              </p>
            </div>
          )}
        </>
      </ModalWrapper>
    </div>
  );
};
export default SendEmail;
