import React, { FC } from 'react';
import Dropdown from 'components/Dropdown/SearchDropdown';
import DatePickerCalendar from 'components/Datepicker';
import Copy from 'assets/Images/Workflow/copy.png';

interface Props {
  selectedEvent: string;
  events: string[];
  onSelect: (value: string) => void;
  startDate: string;
  endDate: string;
  onStartDateChange: (date: string) => void;
  onEndDateChange: (date: string) => void;
  onApplyFilter: () => void;
  searchValue: string;
  onSearchValueChange: (search: string) => void;
  onApplySearch: () => void;
  activeTab: string;
  onDownloadLog: () => void
}

const EventFilters: FC<Props> = ({
  selectedEvent,
  onSelect,
  events,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onApplyFilter,
  searchValue,
  onSearchValueChange,
  onApplySearch,
  activeTab,
  onDownloadLog
}) => {
  return (
    <div>
      <div className="sm:ml-3 sm:w-auto sm:mt-0 mt-5 sm:left-32 left-7 sm:-top-2.5 top-1 flex justify-between items-center">
        <Dropdown value={selectedEvent} options={events} onSelect={onSelect} />
        <DatePickerCalendar date={startDate} onDateChange={onStartDateChange} />
        <div>Through</div>
        <DatePickerCalendar date={endDate} onDateChange={onEndDateChange} />
        <button
          onClick={onApplyFilter}
          className=" text-xs font-medium text-white  rounded-md py-3 sm:pl-4 sm:pr-3.5 font-inter bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105"
        >
          Apply Filter
        </button>
        {selectedEvent !== 'All Events' && (
          <div>
            <div>Mail Merge</div>
            <img className="w-5" src={Copy} />
          </div>
        )}
      </div>
      {activeTab === 'Event Logs' && (
        <div className="flex ml-10 justify-between items-center md:w-10/12 mt-10">
          <div className="xl:w-9/12 w-8/12 md:block hidden">
            <div className="relative flex items-center h-11 border rounded-md border-gray-200 bg-white dark:bg-slate-800 dark:border-black-400">
              <input
                className="text-13 px-2 font-medium focus:outline-none placeholder:text-gray-500 leading-3 text-gray-900 dark:text-white py-2 w-full dark:bg-slate-800 dark:placeholder:text-white"
                type="text"
                name="search"
                placeholder="Search Logs"
                value={searchValue}
                onChange={(e) => onSearchValueChange(e.target.value)}
              />
            </div>
          </div>
          <div className="w-32">
            <button
              onClick={onApplySearch}
              className="w-full rounded-md border-md text-sm font-medium leading-4 font-inter flex items-center lg:py-2.5 lg:px-2 md:p-1 px-2 py-1.5 text-white bg-primary hover:bg-primaryHover hover:scale-105 ease-in duration-300 shadow h-11"
            >
              Apply/Refresh
            </button>
          </div>
          {selectedEvent !== 'All Events' && activeTab==="Event Logs" && (
            <div className="w-32">
              <button
                onClick={onDownloadLog}
                className="w-full rounded-md border-md text-sm font-medium leading-4 font-inter flex items-center lg:py-2.5 lg:px-2 md:p-1 px-2 py-1.5 text-white bg-primary hover:bg-primaryHover hover:scale-105 ease-in duration-300 shadow h-11"
              >
                Download
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default EventFilters;
