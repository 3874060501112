import ErrorBoundary from 'components/ErrorBoundary';
import OnloadScript from './OnloadScript';
import WorkflowScript from './WorkflowScript';
import React from "react";
const Script = () => {
  return (
    <ErrorBoundary>
      <div className="sm:px-6 px-4 sm:py-4 py-2.5 border dark:border-black-400 rounded-lg shadow-lg shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800 lg:flex min-h-[300px]">
        <OnloadScript />
        <WorkflowScript />
      </div>
    </ErrorBoundary>
  );
};
export default Script;
