import axios from 'services/core/HttpService';
import {
  CREATE_SEGMENT,
  GET_SEGMENT,
  DELETE_SEGMENT,
  DISABLE_SEGMENT,
  GET_UPDATED_SEGMENT,
} from 'services/constant/apiRoutes';
import {z} from "zod";

const SegmentCreationSchema = z.object({
  elastic_json: z.string(),
  segment_json: z.string(),
  segment_name: z.string(),
  update: z.boolean(),
});
type SegmentCreation = z.infer<typeof SegmentCreationSchema>;

const SegmentDeletionSchema = z.object({ segment_name: z.string() });
type SegmentDeletion = z.infer<typeof SegmentDeletionSchema>;

const DisableSegmentSchema = z.object({
  segment_name: z.string(),
  status: z.string(),
});
type DisableSegment = z.infer<typeof DisableSegmentSchema>;

export const createSegmentHandler = (segmentData: SegmentCreation) => {
  return new Promise((resolve, reject) => {
    axios
      .post(CREATE_SEGMENT, segmentData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSingleSegment = (name: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(GET_SEGMENT + name)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteSegment = (segmentData: SegmentDeletion) => {
  return new Promise((resolve, reject) => {
    axios
      .post(DELETE_SEGMENT, segmentData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const toggleStatus = (segmentData: DisableSegment) => {
  return new Promise((resolve, reject) => {
    axios
      .post(DISABLE_SEGMENT, segmentData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUpdatedSegment = (name: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(GET_UPDATED_SEGMENT + name)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
