import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { previewEmail } from 'services/constant/routes';
import { z } from 'zod';
import React from "react"

const emailTriggerSchema = z.object({ name: z.string(), id: z.number() });

const campaignEmailSchema = z.object({
  id: z.number(),
  name: z.string(),
  created_at: z.string(),
  html_part_present: z.boolean(),
  html_part_blank: z.boolean(),
  include_event: z.boolean(),
  needs_widgetization: z.boolean(),
  widgetization_error: z.boolean(),
  new_editor_compatible: z.boolean(),
  text_part_present: z.boolean(),
  text_part_blank: z.boolean(),
  email_triggers: z.array(emailTriggerSchema),
});
type SingleCampaignEmail = z.infer<typeof campaignEmailSchema>;

interface Props {
  row: SingleCampaignEmail;
  readonly: boolean | undefined;
}

const CampaignStatus: FC<Props> = ({ row, readonly }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  return (
    <div className="flex justify-between items-center ">
      {!row.widgetization_error &&
        !row.needs_widgetization &&
        !row.html_part_blank &&
        !readonly && (
          <a
            target="popup"
            onClick={() => {
              searchParams.set('id', row.id.toString());
              setSearchParams(searchParams);
              window.open(
                `${previewEmail}?id=${row.id}`,
                `html${uuid()}`,
                `width=800,height=${window.screen.availHeight}`
              );
            }}
            href={`${previewEmail}/${row.id}?tab=${tab}`}
            className="block text-xs cursor-pointer leading-3 text-white py-1 px-2.5 bg-primary rounded-full"
          >
            HTML
          </a>
        )}
      
    </div>
  );
};
export default CampaignStatus;
