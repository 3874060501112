import { FC, useEffect, useState } from 'react';
import { UpdateScoreData } from '../index.type';
import { useFormik, FormikValues } from 'formik';
import Dropdown from 'components/Dropdown/SearchDropdown';
import { getFields } from 'services/apiHandlers/List-Segments/Field';
import xmlToJSON from 'utils/xmlToJSON';
import * as Yup from 'yup';
import { formatXmlJsonResponse } from 'utils/common';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import React from 'react';

interface Props {
  data: UpdateScoreData | undefined;
  onClose: () => void;
  onSave: (data: UpdateScoreData) => void;
}

const defaultFields = [
  'First Name',
  'Last Name',
  'Title',
  'Company',
  'Address1',
  'Address2',
  'City',
  'State/Province',
  'Postal Code',
  'Country',
  'Phone Number',
];

const UpdateScore: FC<Props> = ({ data, onClose, onSave }) => {
  const [fields, setFields] = useState<Array<string>>([]);
  const [selectedField, setSelectedField] = useState<string>('Select an Item');
  const [formIsValid, setFormIsValid] = useState<boolean>(true);

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      scoreChange: 0,
    },
    validationSchema: Yup.object({
      scoreChange: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      onSave({
        scoreChange: values.scoreChange.toString(),
        subscriberFieldName: selectedField,
        _type: 'UpdateScoreAction',
      });
      onClose();
    },
  });

  const getData = async () => {
    /* eslint-disable */
    const getFieldsResponse: any = await getFields();
    const data: any = xmlToJSON.parseString(getFieldsResponse.data);
    const fields = formatXmlJsonResponse(data?.fields[0]?.field);
    const tempFields = fields.filter((field) => {
      return (
        field['data-type'] === 'Text Field' &&
        !defaultFields.includes(field.name)
      );
    });
    const filteredFields = tempFields.map((data) => {
      return data.name;
    });
    setFields(filteredFields as string[]);
    /* eslint-enable */
  };

  useEffect(() => {
    getData();
    if (data) {
      formik.setValues({ scoreChange: data.scoreChange });
      setSelectedField(data.subscriberFieldName);
    }
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <ModalWrapper
        onClose={onClose}
        disabled={!formIsValid}
        onSave={() => {
          if (selectedField === 'Select an Item') {
            setFormIsValid(false);
          }
        }}
      >
        <div className="bg-white dark:bg-black-400">
          <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium mb-8 dark:text-white">
            Update Score
          </h2>
          <div>
            <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 block my-4">
              Select Field
            </label>
            <div className="flex items-center gap-3">
              <div className="z-10 bg-white dark:bg-slate-800 border-gray-200 dark:border-black-400 border rounded-lg dropdown-show-hide relative sm:h-11 h-9 w-1/2">
                <Dropdown
                  options={fields}
                  value={selectedField}
                  onSelect={(value) => {
                    setFormIsValid(true);
                    setSelectedField(value);
                  }}
                />
              </div>
              <div className="w-1/2">
                <input
                  className="text-13 font-medium leading-3 text-gray-700 py-2 rounded px-4 bg-white border border-gray-800 focus:outline-none focus:border-primary sm:h-11 h-9 md:w-7/12 dark:bg-slate-800 dark:text-white"
                  type="number"
                  name="scoreChange"
                  value={formik.values.scoreChange}
                  onChange={formik.handleChange}
                  min={0}
                />
              </div>
            </div>
            {!formIsValid && (
              <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4">
                <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
                  Select any field to continue
                </p>
              </div>
            )}
          </div>
        </div>
      </ModalWrapper>
    </form>
  );
};
export default UpdateScore;
