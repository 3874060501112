import React, { useEffect, useState } from 'react';
import { Form } from './SubscribersForm';
import Dropdown from 'components/Dropdown/Dropdown';
import { getFieldOptions } from 'services/apiHandlers/List-Segments/Field';
import DatePicker from 'components/Datepicker';
import { z } from 'zod';

const fieldOptionDataSchema = z.object({
  person_attribute_column_option: z.object({
    account_id: z.number(),
    code: z.number().nullable(),
    created_at: z.string(),
    custom_question: z.string().nullable(),
    id: z.number(),
    name: z.string(),
    person_attribute_column_id: z.number(),
    position: z.number(),
    updated_at: z.string(),
  }),
});

const getFieldOptionResponseSchema = z.object({
  data: z.array(fieldOptionDataSchema),
  status: z.number(),
});

type GetFieldOptionsResponse = z.infer<typeof getFieldOptionResponseSchema>;
interface Props {
  fieldData: Record<string, string | string[]>;
  className: string;
  parentClass: string;
  field: Form;
  onFieldValueChange: (name: string, value: string, check?: boolean) => void;
}
const Input: React.FC<Props> = ({
  className,
  parentClass,
  fieldData,
  field,
  onFieldValueChange,
}) => {
  const [options, setOptions] = useState<Array<string>>([]);
  const getFieldOptionsHandler = async (id: number) => {
    try {
      const getFieldOptionsResponse = (await getFieldOptions(
        id || 0
      )) as GetFieldOptionsResponse;
      if (getFieldOptionsResponse.status === 200) {
        const options = getFieldOptionsResponse.data.map((option) => {
          return option.person_attribute_column_option.name;
        });
        setOptions(options);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const getSelectValue = (field: string | string[]) => {
    if (typeof field === 'string') {
      if (field === '') {
        return options[0];
      } else {
        return field;
      }
    } else {
      ('');
    }
  };

  const fieldName = field?.slug.includes('_')
    ? field?.slug.split('_').join('-')
    : field?.slug;

  useEffect(() => {
    if (
      field['data-type'] === 'Select' ||
      field['data-type'] === 'Checkbox' ||
      field['data-type'] === 'Radio'
    ) {
      getFieldOptionsHandler(field.id);
    }
  }, []);

  switch (field?.['data-type']) {
    case 'Text Field':
      return (
        <>
          <div className={parentClass}>
            <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white inline-block inline-block">
              {field.name}
            </label>
            <input
              className={className}
              type={field?.['data-type']}
              name={fieldName}
              value={fieldData[field?.slug]}
              onChange={(e) => onFieldValueChange(field.slug, e.target.value)}
            />
          </div>
        </>
      );
    case 'Select':
      return (
        <>
          <div className={parentClass}>
            <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white inline-block inline-block">
              {fieldName}
            </label>
            <div className="flex items-center relative mt-2">
              <Dropdown
                options={
                  options.length === 0
                    ? ['No Options Available For this Field']
                    : options
                }
                value={
                  !getSelectValue(fieldData[field.slug]) ||
                  getSelectValue(fieldData[field.slug]) === ''
                    ? 'Select an Item'
                    : getSelectValue(fieldData[field.slug])
                }
                disableOptions={['No Options Available For this Field']}
                onSelect={(value) => onFieldValueChange(field.slug, value)}
              />
            </div>
          </div>
        </>
      );
    case 'Text Area':
      return (
        <>
          <div className={parentClass}>
            <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white inline-block inline-block">
              {fieldName}
            </label>
            <textarea
              className={className}
              name={fieldName}
              value={fieldData[field?.slug]}
              onChange={(e) => onFieldValueChange(field.slug, e.target.value)}
            />
          </div>
        </>
      );
    case 'Date/Time':
      return (
        <div className="relative">
          <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white inline-block inline-block">
            {fieldName}
          </label>
          <DatePicker
            date={(fieldData[field.slug] as string) || ''}
            onDateChange={(date: string) => {
              onFieldValueChange(field.slug, date);
            }}
            showTime={true}
          />
        </div>
      );
    case 'Time':
      return (
        <div className="relative">
          <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white inline-block inline-block">
            {fieldName}
          </label>
          <DatePicker
            date={(fieldData[field.slug] as string) || ''}
            onDateChange={(date: string) =>
              onFieldValueChange(field.slug, date)
            }
            showTime={true}
          />
        </div>
      );
    case 'Date':
      return (
        <div className="relative">
          <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white inline-block inline-block">
            {fieldName}
          </label>
          <DatePicker
            date={(fieldData[field.slug] as string) || ''}
            onDateChange={(date: string) =>
              onFieldValueChange(field.slug, date)
            }
            showTime={true}
          />
        </div>
      );
    case 'Radio':
      return (
        <>
          <div className={parentClass}>
            <div>{field?.name}</div>
            {options.map((option) => (
              <div key={option}>
                <input
                  id={option}
                  type="radio"
                  checked={option === fieldData[field?.slug]}
                  name={fieldName}
                  onChange={() => onFieldValueChange(field.slug, option)}
                  className="w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor={option}>{option}</label>
              </div>
            ))}
          </div>
        </>
      );
    case 'Checkbox':
      return (
        <>
          <div className={parentClass}>
            <div>{field?.name}</div>
            {options.map((option) => (
              <div key={option}>
                <input
                  id={option}
                  type="checkbox"
                  value={option}
                  checked={fieldData[field?.slug]?.includes(option)}
                  name={fieldName}
                  onChange={() => onFieldValueChange(field.slug, option,true)}
                  className="w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor={option}>{option}</label>
              </div>
            ))}
          </div>
        </>
      );
    case 'Numeric':
      return (
        <>
          <div className={parentClass}>
            <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white inline-block inline-block">
              {field?.name}
            </label>
            <input
              className={className}
              type="number"
              name={fieldName}
              value={fieldData[field?.slug]}
              onChange={(e) => onFieldValueChange(field.slug, e.target.value)}
            />
          </div>
        </>
      );
    default:
      return <div>Un-Supported Field</div>;
  }
};
export default Input;
