import {z} from "zod";

export const senderSchema = z.object({
    blast_id: z.number().nullable(),
    city: z.string(),
    confirmed: z.string(),
    country: z.string(),
    created_at: z.string(),
    from_email_address: z.string(),
    from_name: z.string(),
    id: z.number(),
    postal_code: z.string(),
    state: z.string().nullable(),
    street_address_1: z.string(),
    street_address_2: z.string().nullable(),
    updated_at: z.string(),
  });