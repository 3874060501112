import Orders from './Orders';
import EmailPerformance from './EmailPerformance';
import AudienceGrowth from './AudienceGrowth';
import { FC} from 'react';
import Revenue from './Revenue';
import AnalyticsTable from './AnalyticsTable';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';

const Analytics: FC = () => {
  return (
    <ErrorBoundary>
      <div className="bg-contentBg dark:bg-slate-900 font-inter py-7 md:px-6 px-3 pb-8">
        <div className="md:px-1.5">
          <div>
            <AudienceGrowth />
            <h2 className="sm:text-xl text-base sm:leading-5 leading-3 mt-8 text-black dark:text-white font-medium">
              Top Performing Strategies
            </h2>
            <AnalyticsTable />
            <Revenue />
            <Orders />
            <EmailPerformance />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default Analytics;
