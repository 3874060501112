import { FC, useContext, useEffect, useState } from 'react';
import { DashboardContext } from 'store/DashboardContext';
import { topStrategies } from 'utils/common';
import { Link } from 'react-router-dom';
import { campaignReports, campaigns as campaign } from 'services/constant/routes';
import {  Campaigns } from 'components/DashboardReports/Types';
import React from 'react';


const AnalyticsTable: FC = () => {
  const [campaigns, setCampaigns] = useState<Campaigns>([]);
  const dashboardCtx = useContext(DashboardContext);

  useEffect(() => {

    const tempCampaigns = topStrategies(
      dashboardCtx?.campaigns ? dashboardCtx.campaigns : [],
      3,
      'monetaryValue'
    );

    dashboardCtx?.campaigns && setCampaigns(tempCampaigns as Campaigns);
  }, [dashboardCtx?.workflows, dashboardCtx?.campaigns, dashboardCtx?.loading]);

  return (
    <div className="rounded-lg shadow-lg overflow-hidden shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800">
      <div className="cardHeader">
        <h4 className="lg:text-base text-sm text-darkText font-medium dark:text-white">
          Top Campaigns
        </h4>
        <div className="group rounded py-1 px-2 bg-cardBadge hover:bg-primary cursor-pointer ease-in-in duration-300">
          <Link
            to={campaign}
            className="flex items-center text-primary group-hover:text-white dark:text-white text-xxs"
          >
            View All
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="ml-1 w-3 h-3 text-primary group-hover:text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </Link>
        </div>
      </div>
      <div className="2xl:pb-5">
        <ul>
          {campaigns?.length > 0 ? (
            campaigns?.map((campaign) => {
              return (
                <li
                  key={campaign.id}
                  className="flex items-center justify-between p-4"
                >
                  <div className="flex items-center">
                    <div className="w-6 h-6 rounded-full overflow-hidden">
                      <img
                        src="https://themesbrand.com/velzon/html/galaxy/assets/images/nft/img-01.jpg"
                        alt="top performing workflows"
                      />
                    </div>
                    <div className="ml-3">
                      <Link to={`${campaignReports}/${campaign.id}`}>
                        <h6 className="text-sm text-darkText">
                          {campaign.campaignName}
                        </h6>
                        <p className="text-xs text-secondary">{campaign.date}</p>
                      </Link>
                    </div>
                  </div>
                  <div>
                    <img src="https://i.ibb.co/2vbPCdJ/down.png" alt="down" />
                  </div>
                  <div className="">
                    <h6>{campaign.monetaryValue}</h6>
                  </div>
                </li>
              );
            })
          ) : (
            <div>No Campaigns Found</div>
          )}
        </ul>
      </div>
    </div>
  );
};
export default AnalyticsTable;
