import Health from 'assets/Images/svg-images/health.svg';
import React from "react"
const HeaderData = () => {
  return (
    <div className="flex items-center relative mb-4">
      <div className="sm:flex items-center w-full">
        <div className="flex items-center">
          <span>
            <img
              className="dark:invert dark:brightness-0"
              src={Health}
              alt="Health"
            />
          </span>
          <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black dark:text-white font-medium ml-2.5 mr-3">
            Workflows
          </h2>
        </div>
      </div>
    </div>
  );
};
export default HeaderData;
