import ErrorBoundary from 'components/ErrorBoundary';
import { WorkflowContextProvider } from 'store/WorkflowContext';
import Header from 'components/Workflow/Header';
import { Outlet } from 'react-router-dom';
import React from 'react';

const Workflows = () => {
  return (
    <ErrorBoundary>
      <WorkflowContextProvider>
        <>
          <Header />
          <Outlet />
        </>
      </WorkflowContextProvider>
    </ErrorBoundary>
  );
};
export default Workflows;
