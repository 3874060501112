import Duplicate from 'assets/Images/Workflow/copy.png';
import Fetch from 'assets/Images/Campaign/fetch.png';
import { useFormik, FormikValues } from 'formik';
import { FC, useContext, useState } from 'react';
import { fetchHtml } from 'services/apiHandlers/Campaigns/Emails';
import { SubmitEmailResponse } from './CopyPasteHtml';
import { EmailContext } from 'store/EmailContext';
import React from 'react';
import { edit, emailEditor,create } from 'services/constant/routes';
import { useNavigate } from 'react-router-dom';

interface Props {
  showContent: boolean;
  onWebShow: () => void;
}
const ImportWebPage: FC<Props> = ({ showContent, onWebShow }) => {
  const [fetchingError, setFetchingError] = useState<string>();
  const emailCtx = useContext(EmailContext);
  const navigate = useNavigate();

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      fetch: '',
    },
    onSubmit: async (values) => {
      const data = {
        url: values.fetch,
        commit: 'Fetch',
      };
      try {
        const fetchHtmlResponse = (await fetchHtml(
          data
        )) as SubmitEmailResponse;
        if (fetchHtmlResponse.status === 200) {
          navigate(
            `${emailEditor}/${edit}/${fetchHtmlResponse.data.draft.email.id}?${create}=true`
          );
          emailCtx?.htmlSubmitHandler(
            fetchHtmlResponse.data.draft.email.html_part
          );
        }
      } catch (error) {
        setFetchingError('Error: Unable to fetch URL');
      }
    },
  });

  return (
    <div>
      <div
        onClick={onWebShow}
        className="ml-7 p-2 flex items-center mt-3 md:w-8/12 bg-f6f7f9"
      >
        <img className="w-5" src={Duplicate} />
        <a className="text-sm font-medium leading-3 ml-2 text-primary hover:text-blue-700 ease-in duration-300">
          Import from Web page
        </a>
      </div>
      {showContent && (
        <div className="md:w-8/12 ml-7 mt-4 flex items-center">
          <span className="mr-3 w-5 h-5">
            <img src={Fetch} />
          </span>
          <input
            className="border rounded-md sm:w-6/12 w-8/12 h-11 py-1 px-2 text-base font-medium leading-4 placeholder:text-gray-500"
            type="text"
            name="fetch"
            value={formik.values.fetch}
            onChange={formik.handleChange}
            placeholder="http://"
          />
          <button
            type="button"
            onClick={() => formik.handleSubmit()}
            disabled={formik.values.fetch.length === 0}
            className={` ${
              formik.values.fetch.length === 0
                ? 'border border-gray-800 bg-gray-200 text-black-400'
                : 'text-white bg-primary hover:bg-primaryHover'
            } py-1 px-3 w-28 h-11 sm:ml-6 ml-3 rounded-md text-sm font-normal leading-4 placeholder:text-gray-500 ease-in duration-300 hover:scale-105`}
          >
            Fetch
          </button>
        </div>
      )}
      {fetchingError && (
        <div className="ml-2 mr-2 mb-2 px-6 py-3 bg-[#f7e8e7] rounded mt-4 ">
          <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
            {fetchingError}
          </p>
        </div>
      )}
    </div>
  );
};
export default ImportWebPage;
