import ToggleSwitch from 'ToggleSwitch/ToggleSwitch';
import Edit from 'assets/Images/ListsegmentImages/edit.png';
import Delete from 'assets/Images/ListsegmentImages/delete.png';
import List from 'assets/Images/Workflow/list.png';
import Report from 'assets/Images/Dashboard/overview.png';
import { WorkflowType } from './index.type';
import { FC, useState } from 'react';
import {
  createWorkflow,
  deleteWorkflow,
} from 'services/apiHandlers/Workflows/Workflow';
import { z } from 'zod';
import { Toastify } from 'App';
import DeleteModal from 'components/Alert/DeleteModal';
import Duplicate from 'assets/Images/Workflow/copy.png';
import { Link } from 'react-router-dom';
import { logs, workflow, workflowReports } from 'services/constant/routes';
import React from 'react';

const responseSchema = z.object({ status: z.number() });
type Response = z.infer<typeof responseSchema>;

interface Props {
  row: WorkflowType;
  onStatusChange: (id: string, status: boolean) => void;
  index: number;
  logStatus: string
  getData: (notLoad: boolean) => void;
}

const WorkflowActions: FC<Props> = ({
  row,
  onStatusChange,
  index,
  getData,
  logStatus
}) => {
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);

  const onDuplicateHandler = async () => {
    const duplicateWorkflow = { ...row, name: 'Copy of ' + row.name };
    delete duplicateWorkflow.id;
    try {
      const duplicateWorkflowResponse = (await createWorkflow(
        duplicateWorkflow
      )) as Response;
      if (duplicateWorkflowResponse.status === 201) {
        Toastify('Duplicate Workflow Created', 'success', 'action1');
        getData(true);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const deleteHandler = async () => {
    try {
      const deleteWorkflowResponse = (await deleteWorkflow(
        row?.id || ''
      )) as Response;
      if (deleteWorkflowResponse.status === 200) {
        getData(true);
        Toastify('Workflow Deleted Successfully', 'success', 'action2');
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };
  return (
    <div className="whitespace-nowrap py-1.5 pl-3 pr-3 text-13 sm:align-top align-middle text-black-400 dark:text-white w-[270px] flex flex-wrap items-center pl-0">
      <ToggleSwitch
        onChange={() => onStatusChange(row.id || '', row.isEnabled || false)}
        checked={row.isEnabled}
        id={index}
      />
      <button
        onClick={() => onDuplicateHandler()}
        type="button"
        className="mr-4 py-3 p-0 dark:text-white"
      >
        <img
          src={Duplicate}
          alt="Duplicate"
          className="inline mr-1.5 dark:invert dark:brightness-0"
        />
        <span className="dark:text-white">Duplicate</span>
      </button>
      <Link to={`${workflow}/${row?.id}`}
      >
        <img
          src={Edit}
          alt="Edit"
          className="inline mr-1.5 dark:invert dark:brightness-0"
        />
        <span className="dark:text-white">Edit</span>
      </Link>
      
      <Link
        to={`${workflow}/${logs}/${row?.id}?status=${logStatus}`}
        type="button"
        className="flex items-center mr-4 py-3 p-0 dark:text-white"

      >
        <img
          className="mr-2 dark:invert dark:brightness-0"
          src={List}
          alt="List"
        />
        <span className="dark:text-white">Logs</span>
      </Link>
      <Link
        to={`${workflowReports}/${row.id}`}
        className="flex items-center mr-4 py-3 p-0 dark:text-white"
      >
        <img
          className="mr-2 dark:invert dark:brightness-0"
          src={Report}
          alt="List"
        />
        <span className="dark:text-white">Reports</span>
      </Link>
      <button
        onClick={() => setShowDeleteAlert(true)}
        type="button"
        className="flex items-center py-2 p-0 dark:text-white"
      >
        <img
          className="mr-2 dark:invert dark:brightness-0"
          src={Delete}
          alt="Delete"
        />
        <span className="dark:text-white">Delete</span>
      </button>
      {showDeleteAlert && (
        <div
          onClick={() => setShowDeleteAlert(false)}
          className="fixed left-0 top-0 w-full h-full z-10 bg-black-200 duration-300"
        >
          <DeleteModal
            onDelete={() => deleteHandler()}
            onCancel={() => setShowDeleteAlert(false)}
          />
        </div>
      )}
    </div>
  );
};
export default WorkflowActions;
