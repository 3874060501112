import Orders from './Orders';
import AllWorkflows from './AllWorkflows';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';

const StoreReport = () => {
  return (
    <ErrorBoundary>
      <div>
        <div className="md:px-6 px-3 py-5 font-inter bg-gray-400 dark:bg-slate-800 h-full">
          <div className="md:px-1.5  mb-16">
            <Orders />
            <AllWorkflows />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default StoreReport;
