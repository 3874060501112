import { FC, useContext, useEffect, useState } from 'react';
import { getcampaignEmails } from 'services/apiHandlers/Campaigns/Emails';
import Dropdown from 'components/Dropdown/SearchValueDropdown';
import { z } from 'zod';
import { CampaignContext } from 'store/CampaignContext';
import { Link, useParams } from 'react-router-dom';
import { previewEmail } from 'services/constant/routes';
import React from 'react';
import uuid from 'react-uuid';

interface Props {
  onEmailChange: (id: string) => void;
}

const options = z.object({
  name: z.string(),
  value: z.string(),
});

type option = z.infer<typeof options>;

const emailTriggerSchema = z.object({ name: z.string(), id: z.number() });

const campaignEmailSchema = z.object({
  id: z.number(),
  name: z.string(),
  created_at: z.string(),
  html_part_present: z.boolean(),
  html_part_blank: z.boolean(),
  include_event: z.boolean(),
  needs_widgetization: z.boolean(),
  widgetization_error: z.boolean(),
  new_editor_compatible: z.boolean(),
  text_part_present: z.boolean(),
  text_part_blank: z.boolean(),
  email_triggers: z.array(emailTriggerSchema),
});
type CampaignEmail = z.infer<typeof campaignEmailSchema>;

const campaignResponseSchema = z.object({
  data: z.object({
    emails: z.array(campaignEmailSchema),
    readonly_mode: z.boolean(),
    total_entries: z.number(),
  }),
  status: z.number(),
});

type CampaignResponse = z.infer<typeof campaignResponseSchema>;

const EmailTemplates: FC<Props> = ({ onEmailChange }) => {
  const [emails, setEmails] = useState<Array<option>>([]);
  const [campaigns, setCampaigns] = useState<Array<CampaignEmail>>([]);
  const [selectedCampaign, setSelectedCampiagn] =
    useState<string>('Select an email');
  const [showError, setShowError] = useState<boolean>(false);
  const campaignCtx = useContext(CampaignContext);
  const { id } = useParams();

  const getData = async () => {
    try {
      const getEmailTemplateResponse =
        (await getcampaignEmails()) as CampaignResponse;
      if (getEmailTemplateResponse.status === 200) {
        let tempMails = [];
        tempMails = getEmailTemplateResponse.data.emails.map((mail) => {
          return { name: mail.name, value: mail.id.toString() };
        });
        setEmails(tempMails);
        setCampaigns(getEmailTemplateResponse.data.emails);
      }
    } catch (error) {
      console.log('error is: ', error);
    }
  };

  const onSelectHandler = (id: string) => {
    onEmailChange(id);
    const tempSelectedCampaign = campaigns.filter(
      (campaign) => campaign.id.toString() === id
    );
    setSelectedCampiagn(tempSelectedCampaign[0].name);
    if (campaignCtx?.campaignData) {
      campaignCtx.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        email_id: parseInt(id),
      });
    }
  };

  useEffect(() => {
    if (campaignCtx?.campaignFormErrors.emailTemplateError) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [campaignCtx?.campaignFormErrors]);

  useEffect(() => {
    campaignCtx?.onChangeFormValidity('emailTemplateError', false);
  }, [campaignCtx?.campaignData?.email_id]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (id) {
      const emailId = id;
      const filteredCampaign = campaigns?.filter(
        (campaign) => campaign.id.toString() === emailId
      );
      if (campaignCtx?.campaignData) {
        campaignCtx.onUpdateCampaignData({
          ...campaignCtx.campaignData,
          email_id: emailId ? parseInt(emailId) : 0,
        });
      }
      setSelectedCampiagn(filteredCampaign[0]?.name);
    }
  }, [campaigns]);

  return (
    <div className="p-4 pb-10 bg-gray-150 dark:bg-slate-800 rounded-md border border-gray-800 dark:border-black-400">
      <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white inline-block inline-block mb-3">
        Email Templates
      </label>
      <Dropdown
        options={emails}
        onSelect={onSelectHandler}
        value={selectedCampaign}
      />
      <Link
        target="_blank"
        onClick={(e) => {
          if (campaignCtx?.campaignData?.email_id === 0) {
            e.preventDefault();
          } else {
            window.open(
              `${previewEmail}/${campaignCtx?.campaignData?.email_id}`,
              `html${uuid()}`,
              `width=800,height=${window.screen.availHeight}`
            );
          }
        }}
        to={`${previewEmail}/${campaignCtx?.campaignData?.email_id}`}
        className="inline-flex items-center text-xs font-medium text-ryzeoBlue mt-3 float-right"
      >
        <span className="w-3 h-3 flex justify-center items-center inline-block rounded-full bg-primary text-white text-xs font-bold mr-1">
          +
        </span>
        <span className="cursor-pointer text-xs font-medium text-primary underline">
          Preview Email
        </span>
      </Link>
      {showError && (
        <div className="text-xs leading-4 text-red-400 mt-1.5">
          Select an email Template
        </div>
      )}
    </div>
  );
};
export default EmailTemplates;
