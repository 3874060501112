import { ChangeEvent, FC } from 'react';
import React from "react";

interface Props {
  onSelect: (e: ChangeEvent<HTMLSelectElement>) => void;
  groups: string[];
  options: string[][];
}

const GroupDropdown: FC<Props> = ({ onSelect, groups, options }) => {
  
  return (
    <div className="md:block hidden w-56 relative h-8 border border-bdbdbd rounded">
      <select
        onChange={onSelect}
        className="text-[13px] font-medium leading-3 text-bdbdbd absolute w-full h-full rounded pl-4 focus:outline-0"
      >
        {groups.map((group, index) => (
          <optgroup key={group} label={group}>
            {options[index].map((option) => (
              <option key={option[1]} value={option[1]}>
                {option[0]}
              </option>
            ))}
          </optgroup>
        ))}
      </select>
    </div>
  );
};
export default GroupDropdown;
