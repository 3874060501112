import { ListResponse } from 'components/List-Segments/Body/List';
import List from 'components/List';
import { useEffect, useRef, useState } from 'react';
import { getAllList } from 'services/apiHandlers/List-Segments/SegmentsList';
import {
  getEmailEvents,
  updateEmailSystemEvent,
} from 'services/apiHandlers/Integration/Miscellaneous';
import ToggleSwitch from 'ToggleSwitch/ToggleSwitch';
import { z } from 'zod';
import { Toastify } from 'App';
import ErrorBoundary from 'components/ErrorBoundary';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React from 'react';

const responseStatusSchema = z.object({ status: z.number() });
type ResponseStatus = z.infer<typeof responseStatusSchema>;

const responseSchema = z.object({
  data: z.object({
    data: z.object({
      system_event_configuration: z.object({
        anyList: z.boolean(),
        list_ids: z.array(z.number()),
      }),
    }),
  }),
});
type response = z.infer<typeof responseSchema>;

const getListResponseDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  audience_id: z.number(),
});
type ListType = z.infer<typeof getListResponseDataSchema>;

const getListResponseSchema = z.object({
  data: z.object({
    lists: z.array(getListResponseDataSchema),
  }),
  status: z.number(),
});
type GetListResponse = z.infer<typeof getListResponseSchema>;

const selectedListSchema = z.object({ id: z.number(), label: z.string() });
type SelectedList = z.infer<typeof selectedListSchema>;

const EmailEvents = () => {
  const [list, setList] = useState<Array<ListType>>([]);
  const [listItems, setListItems] = useState<Array<SelectedList>>([]);

  const [selectedLists, setSelectedLists] = useState<Array<number>>([]);
  const [showList, setShowList] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const getListData = async () => {
    try {
      const getListsResponse = (await getAllList()) as GetListResponse;
      if (getListsResponse.status === 200) {
        const tempLists = getListsResponse?.data?.lists?.map((list) => {
          return { id: list.id, label: list.name };
        });

        setListItems(tempLists);
        setList(getListsResponse?.data?.lists);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false);
  };

  useOutsideClick(dropdownRef, () => {
    setShowList(false);
  });

  const getList = async () => {
    (await getAllList()) as ListResponse;
    const getEmailEventResponse = (await getEmailEvents()) as response;
    setSelectedLists(
      getEmailEventResponse.data.data.system_event_configuration.list_ids
    );
  };
  const formSubmitHandler = async () => {
    const data = {
      data: {
        system_event_configuration: {
          any_list: false,
          list_ids: showList ? selectedLists : [],
        },
      },
    };

    try {
      const updateEmailEventResponse = (await updateEmailSystemEvent(
        data
      )) as ResponseStatus;
      if (updateEmailEventResponse.status === 200) {
        setShowList(false);
        Toastify(
          'Email System Event Updated Successfully',
          'success',
          'emailEvent2'
        );
      }
    } catch (error) {
      Toastify('Updating Email System Event Failed', 'error', 'emailEvent2');
    }
  };

  const listChangeHandler = (list_id: number) => {
    const updatedList = [...selectedLists];
    const idIndex = updatedList.indexOf(list_id);
    if (idIndex === -1) {
      updatedList.push(list_id);
    } else {
      updatedList.splice(idIndex, 1);
    }
    setSelectedLists(updatedList);
  };

  useEffect(() => {
    getList();
    getListData();
  }, []);

  return (
    <ErrorBoundary>
      <div
        ref={dropdownRef}
        className="p-4 bg-gray-150 dark:bg-slate-800 rounded border border-gray-800 dark:border-black-400"
      >
        <div className="flex justify-between items-center">
          <h3 className="text-13 font-semibold leading-4  text-black-700 dark:text-white mb-2">
            Email System Events
          </h3>
          <ToggleSwitch
            onChange={() => {
              setShowDropdown((prev) => !prev);
            }}
            checked={showDropdown}
          />
        </div>
        <p className="text-xs leading-4 text-black-400 dark:text-white dark:text-white">
          Publish ss_added_to_list and ss_email_sent system events for
          subscribers on specific lists
        </p>
        {showDropdown && (
          <div
            ref={dropdownRef}
            className="z-10 bg-white dark:bg-slate-800 border-gray-200 dark:border-black-400 border rounded-lg dropdown-show-hide relative sm:h-11 h-9 w-6/12"
          >
            <div
              onClick={() => setShowList((prev) => !prev)}
              className=" bg-transparentPrimary text-sm font-medium leading-4 text-primary dark:text-white dark:text-white flex items-center justify-between sm:h-11 py-2 px-4 focus:outline-none border-0 rounded-md dark:bg-slate-800 dark:border-black-400 cursor-pointer"
            >
              {selectedLists.length} items selected
            </div>
            {showList && (
              <List
                list={list}
                listItems={listItems}
                onListChange={listChangeHandler}
                selectedLists={selectedLists}
                loading={loading}
                getList={getListData}
              />
            )}
          </div>
        )}
        <div className="mt-4">
          <button
            type="button"
            disabled={!showList}
            className={` ${
              !showList
                ? 'bg-gray-800 text-black-400 dark:text-white font-nomral '
                : 'bg-primary text-white '
            }text-13 font-medium leading-5 rounded-md py-2 px-4 mr-4 mt-4 ease-in duration-300 hover:bg-primaryHover hover:scale-105 hover:text-white`}
            onClick={() => formSubmitHandler()}
          >
            Update
          </button>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default EmailEvents;
