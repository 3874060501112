import { FC } from 'react';
import { z } from 'zod';
import React from "react";

const googleAnalyticsDataSchema = z.object({
  campaign: z.string(),
  medium: z.string(),
  content: z.string(),
  source: z.string(),
  accountId: z.string(),
});
type GoogleData = z.infer<typeof googleAnalyticsDataSchema>;

interface Props {
  linkData: GoogleData;
}

const SideNote: FC<Props> = ({ linkData }) => {
  return (
    <div>
      <h3 className="text-base font-semibold leading-4 text-black-700 dark:text-white mb-3">
        Integrate Google Analytics URLs
      </h3>
      <div className="md:px-6 px-3 py-4 rounded bg-[#ECF8F6] border dark:border-black-400 rounded dark:bg-slate-800">
        <p className="text-sm leading-5 text-black-400 dark:text-white dark:text-white mb-2">
          Google Analytics Integration automatically modifies all links in your
          HTML email with campaign parameters.
          <span className="font-semibold">
            Your website must have Google Analytics Integrated.
          </span>
        </p>
        <p className="text-sm font-medium leading-4 text-black-400 dark:text-white dark:text-white">
          Sample Link URL:
        </p>
        <p className="text-sm leading-5 text-black-400 dark:text-white dark:text-white break-all mt-2">
          {`http://example.com/?utm_campaign=${linkData.source}workflow_name=${linkData.campaign}&utm_content=${linkData.content}subject&utm_medium=${linkData.medium}&utm_source=`}
        </p>
      </div>
    </div>
  );
};
export default SideNote;
