import { FC, useRef, useState } from 'react';
import DownArrow from 'assets/Images/Common/down-arrow.png';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React from 'react';

interface Props {
  options: string[] | undefined;
  onSelect?: (option: string) => void;
  value: string | undefined;
  disableOptions?: string[];
  id?: string;
}

const Dropdown: FC<Props> = ({
  options,
  onSelect,
  value,
  disableOptions,
  id,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideClick(dropdownRef, () => {
    setShowDropdown(false);
  });

  return (
    <div
      onClick={() => onSelect && setShowDropdown((prev) => !prev)}
      className="dark:shadow-none rounded-md w-full relative"
      ref={dropdownRef}
    >
      <div
        className={`${
          showDropdown ? 'rounded-b-none border-primary dark:border-white' : ''
        } bg-transparentPrimary text-sm font-medium leading-4 text-primary dark:text-white dark:text-white flex items-center justify-between sm:h-11 py-2 px-4 focus:outline-none border-0 rounded-md dark:bg-slate-800 dark:border-black-400 cursor-pointer`}
      >
        {value}
        {onSelect && <span>
          <img
            className="dark:brightness-0 dark:invert"
            src={DownArrow}
            alt="Arrow"
          />
        </span>}
      </div>

      {showDropdown && (
        <div className="max-h-40 overflow-auto bg-white dark:bg-slate-800 border-t-0 rounded-b-md border-gray-800 dark:border-black-400 shadow-sm shadow-gray-200 scrollbar-hide cursor-pointer absolute z-50 w-full">
          {options?.map((option, index) => (
            <button
              onClick={() => onSelect && onSelect(option)}
              className={`${
                disableOptions?.includes(
                  option
                )
                  ? 'bg-gray-800 text-black-400 cursor-not-allowed '
                  : 'dark:hover:bg-white hover:bg-dropdownHover cursor-pointer '
              } ${
                options.length === index + 1 ? ' border-b-0' : ''
              } p-3 text-sm font-medium block w-full text-left leading-4 text-black-400 dark:text-white dark:text-white dark:hover:text-black-400 border border-dropdownHover`}
              key={option}
              disabled={disableOptions?.includes(
                option
              )}
              data-pw={index === options.length - 1 ? 'last-option' : ''}
              id={index === options.length - 1 ? id : '0'}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
export default Dropdown;
