import EmailPerformance from './EmailPerformance';
import { FC, useContext, useEffect, useState } from 'react';
import averageDeliverabilityRate from '../../../../assets/Images/Dashboard/average-deliverability-rate.svg';

import { DashboardContext } from 'store/DashboardContext';
import React from 'react';
import { z } from 'zod';


const EmailDataSchema = z.object({
  title: z.string(),
  percent: z.string(),
  openings: z.string().optional(),
  id: z.string(),
});
type EmailData = z.infer<typeof EmailDataSchema>;

const AverageDeliverability: FC = () => {
  const [emailPerformanceData, setEmailPerformanceData] = useState<
    Array<EmailData>
  >([]);
  const dashboardCtx = useContext(DashboardContext);

  useEffect(() => {
    setEmailPerformanceData([
      {
        title: 'Average Deliverability',
        percent: `${
          dashboardCtx?.weeklyRoiData?.email_recipients
            ? dashboardCtx?.weeklyRoiData?.email_recipients
            : 0
        }`,
        id: '1',
      },
    ]);
  }, [dashboardCtx?.weeklyRoiData]);
  return (
    <EmailPerformance emailPerformance={emailPerformanceData} image={averageDeliverabilityRate}/>
  );
};
export default AverageDeliverability;
