import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { ThemeContext } from 'store/ThemeContext';
import React, { FC, useContext } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';

interface Props {
  columns?: number;
}

const CustomSkeleton: FC<Props> = ({ columns }) => {
  const themeCtx = useContext(ThemeContext);

  return (
    <SkeletonTheme
      baseColor={`${themeCtx?.theme === 'dark' ? 'rgb(30 41 59)' : ''}`}
    >
      <div className="flex justify-between w-full px-6 py-6">
        <p className="mt-2 w-full">
          <Skeleton count={columns} height={30} />
        </p>
      </div>
    </SkeletonTheme>
  );
};
export default CustomSkeleton;
