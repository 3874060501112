import { ChangeEvent } from 'react';
import React from "react";
interface Props {
  options?: Array<string>;
  onSelect?: (e: ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
  fieldName?: string;
  error?: boolean;
  className?: string;
  id?: string;
  ids?: string[];
}
const Dropdown = ({
  value,
  options,
  onSelect,
  fieldName = '',
  className,
  id,
  ids,
}: Props) => {
  return (
    <div>
      <select
        id={`${id}-dropdown`}
        data-pw={`${id}-dropdown`}
        className={className}
        onChange={onSelect}
        name={fieldName}
        value={value}
      >
        {options?.map((opt, index) => (
          <option
            id={`element-id-${id}`}
            data-pw={options.length - 1 === index ? `last-${id}` : ''}
            selected={value === opt}
            key={opt}
            value={ids && index>0 ? ids[index-1] : opt}
            label={opt}
          >
            {opt}
          </option>
        ))}
      </select>
    </div>
  );
};
export default Dropdown;
