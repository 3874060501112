import { FC, useContext, useEffect, useMemo, useState } from 'react';
import Edit from 'assets/Images/ListsegmentImages/edit-table-active.svg';
import SubscribersHeader, { SUBSCRIBER_STATUS } from './SubscribersHeader';
import { TableColumn } from 'react-data-table-component';
import { getSubscribers } from 'services/apiHandlers/List-Segments/Subscriber';
import Table from 'components/Table';
import { tableDateSort, tableSort } from 'utils/common';
import { Link, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import {
  SubscribersData,
  GetSubscribersDataType,
  getSubscribersResponse,
} from './Types';
import ErrorBoundary from 'components/ErrorBoundary';
import styled from 'styled-components';
import React from 'react';
import { edit, view } from 'services/constant/routes';
import moment from 'moment-timezone';
import { UserContext } from 'store/UserContext';

const StyleWrapper = styled.div`
  .rdt_TableRow {
    height: 50px;
  }

  .rdt_TableRow .rdt_TableCell {
    border-right: 1px solid #e0e0e0;
  }

  .rdt_TableRow .rdt_TableCell:last-child {
    border-right: 0;
  }

  .rdt_TableHeadRow .rdt_TableCol {
    border-right: 1px solid #e0e0e0;
  }
  .rdt_TableHeadRow .rdt_TableCol:last-child {
    border-right: 0;
  }
`;

const searchListSchema = z.object({
  list_id: z.number().nullable(),
  email_address: z.string(),
  opt_status: z.string(),
});
export type SearchData = z.infer<typeof searchListSchema>;

const PageDataSchema = z.object({
  page: z.number(),
  per_page: z.number(),
  list_id: z.number().nullable().optional(),
  opt_status: z.string().optional(),
  email_address: z.string().optional(),
});
export type PageData = z.infer<typeof PageDataSchema>;

const Subscribers: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [subscriberList, setSubscriberList] = useState<GetSubscribersDataType>([]);
  const [searchParams] = useSearchParams();

  const [perPage, setPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalSubscribers, setTotalSubscribers] = useState<number>();
  const [searchListData, setSearchListData] = useState<PageData>({
    list_id: null,
    email_address: '',
    opt_status: SUBSCRIBER_STATUS.SELECT,
    page: 1,
    per_page: 10,
  });
  const [searchStatus, setSearchStatus] = useState<SUBSCRIBER_STATUS>(
    SUBSCRIBER_STATUS.SELECT
  );
  const [searchQuery, setSearchQuery] = useState<string>('');
  const userCtx = useContext(UserContext);

  const subscriberStatusSort = (
    rowA: SubscribersData,
    rowB: SubscribersData
  ) => {
    return tableSort(rowA.person.opt_status, rowB.person.opt_status);
  };

  const subscriberDateSort = (rowA: SubscribersData, rowB: SubscribersData) => {
    return tableDateSort(rowA.person.subscribed_at, rowB.person.subscribed_at);
  };

  const columns: TableColumn<SubscribersData>[] = useMemo(
    () => [
      {
        name: 'Email',
        cell: (row, index) => {
          return (
            <Link
              to={`${view}/${row.person.id}`}
              data-pw={`${index === 0 ? 'first-edit-subscriber' : ''}`}
              className="cursor-pointer text-sm font-medium text-primary hover:text-blue-700 mt-1 flex gap-3 dark:text-white"
            >
              <div>{row.person.email_address}</div>
            </Link>
          );
        },
      },
      {
        name: 'Status',
        cell: (row) => (
          <div className="py-0.5 px-2.5 rounded-full text-[#209d7d] bg-[#d5f7eb]">
            {row.person.opt_status}
          </div>
        ),
        sortable: true,
        sortFunction: subscriberStatusSort,
      },
      {
        name: 'Subscribed',
        cell: (row) => {
          return (
            <div className="dark:text-white">
              {moment
                .tz(row.person.created_at, userCtx?.usersData?.timeZone || '')
                .format('MMM DD, YYYY')}
            </div>
          );
        },
        sortFunction: subscriberDateSort,
      },
      {
        name: 'Actions',
        cell: (row, index) => (
          <Link
            data-pw={`${index === 0 ? 'subscriber-edit' : ''}`}
            type="button"
            className="mr-4 hover:text-[#3575d5] dark:text-white"
            id={row.person.id.toString()}
            to={`${edit}/${row.person.id}`}
          >
            <img
              src={Edit}
              alt="Edit"
              className="inline mr-1.5 dark:invert dark:brightness-0"
            />
            <span className="text-[#3575d5]">Edit</span>
          </Link>
        ),
      },
    ],
    []
  );

  const responseData = async (pageData: PageData) => {
    setLoading(true);
    try {
      const getSubscribersResponse = (await getSubscribers(
        pageData
      )) as getSubscribersResponse;
      if (getSubscribersResponse.status === 200) {
        setTotalSubscribers(parseInt(getSubscribersResponse.data.data.total));
        setSubscriberList(getSubscribersResponse.data.data.subscribers);
        setPerPage(pageData.per_page);
      }
    } catch (error) {
      console.log('error is: ', error);
    }
    setLoading(false);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    setSearchListData({
      ...searchListData,
      page: page,
      per_page: newPerPage,
    });
  };

  const onListChangeHandler = async (listId: string) => {
    setSearchListData({ ...searchListData, list_id: parseInt(listId) });
    setLoading(true);

    const pageData = {
      page: 1,
      per_page: perPage,
      list_id: parseInt(listId) === -1 ? null : parseInt(listId),
      email_address: searchListData.email_address,
      opt_status: searchListData.opt_status,
    };

    responseData(pageData);
    setLoading(false);
  };

  useEffect(() => {
    setSearchListData({
      ...searchListData,
      opt_status: searchStatus.toLowerCase(),
      email_address: searchQuery,
    });
    const pageData = {
      page: 1,
      per_page: perPage,
      list_id: searchListData.list_id === -1 ? null : searchListData.list_id,
      email_address: searchQuery,
      opt_status: searchStatus.toLowerCase(),
    };
    responseData(pageData);
  }, [searchStatus, searchQuery]);

  useEffect(() => {
    if (
      currentPage !== searchListData.page ||
      perPage !== searchListData.per_page
    ) {
      setSearchListData({
        ...searchListData,
        page: currentPage,
        per_page: perPage,
      });
      responseData({ ...searchListData, page: currentPage, per_page: perPage });
    }
  }, [currentPage, perPage]);

  useEffect(() => {
    const pageData = {
      page: 1,
      per_page: perPage,
    };
    responseData(pageData);
  }, []);

  useEffect(() => {
    const id = parseInt(searchParams.get('list_id') || '');
    console.log(id);
    responseData({ ...searchListData, list_id: id });
  }, []);

  return (
    <ErrorBoundary>
      <SubscribersHeader
        onSearch={setSearchQuery}
        onListChange={onListChangeHandler}
        onStatusChange={setSearchStatus}
      />
      <StyleWrapper>
        <Table
          data={subscriberList}
          columns={columns}
          paginationServer
          paginationTotalRows={totalSubscribers}
          paginationPerPage={perPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          progressPending={loading}
        />
      </StyleWrapper>
    </ErrorBoundary>
  );
};
export default Subscribers;
