import Table from 'components/Table';
import React, { FC, useMemo } from 'react';
import { TableColumn } from 'react-data-table-component';
import { z } from 'zod';

const productDataSchema = z.array(z.unknown());
type ProductData = z.infer<typeof productDataSchema>;

interface Props {
  data: string;
}

const ProductTable: FC<Props> = ({ data }) => {
  const parsedData: ProductData = JSON.parse(data);

  const columns: TableColumn<unknown>[] = useMemo(() => {
    if (parsedData.length === 0) {
      return [];
    }

    const keys = Object.keys(parsedData[0] as Record<string, unknown>);

    return keys.map((key) => ({
      name: key,
      cell: (row) => {
        const value = (row as Record<string, unknown>)[key];
        return key === 'productImage' ? (
          <div>
            <img
              className="sm:mr-3 mr-1"
              src={value !== null && value !== undefined ? String(value) : ''}
              alt="Product Image"
            />
          </div>
        ) : (
          <div className="dark:text-white">
            <div>
              {value !== null && value !== undefined ? String(value) : ''}
            </div>
          </div>
        );
      },
    }));
  }, [parsedData]);

  return (
    <div>
      <Table
        className="scrollbar-hide"
        data={parsedData}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};
export default ProductTable;
