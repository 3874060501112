import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllLogs } from 'services/apiHandlers/Logs';
import { eventLogs } from 'services/constant/routes';
import { UserContext } from 'store/UserContext';
import { initialLetters } from 'utils/common';
import { z } from 'zod';

const resultDataSchema = z.object({
  accountId: z.number(),
  attributes: z.record(z.string()),
  id: z.string(),
  name: z.string(),
  timestamp: z.number(),
});

const getEventFiltersResponseSchema = z.object({
  data: z.object({
    next_start_token: z.string(),
    totalCount: z.string(),
    result: z.array(resultDataSchema),
  }),
  status: z.number(),
});

type GetEventResponse = z.infer<typeof getEventFiltersResponseSchema>;
type ResultData = z.infer<typeof resultDataSchema>;

const EventsLogs = () => {
  const userCtx = useContext(UserContext);
  const [allEvents, setAllEvents] = useState<Array<ResultData>>([]);


  const getEventLogs = async () => {
    try {
      const getEventLogsResponse = (await getAllLogs(
        moment
          .tz(
            moment().subtract(3, 'months').format('YYYY-MM-DD'),
            userCtx?.usersData?.timeZone || ''
          )
          .startOf('day')
          .format('YYYY-MM-DD[T11:59:00.000Z]'),
        moment
          .tz(moment().format('YYYY-MM-DD'), userCtx?.usersData?.timeZone || '')
          .startOf('day')
          .format('YYYY-MM-DD[T11:59:00.000Z]')
      )) as GetEventResponse;

      if (getEventLogsResponse.status === 200) {
        setAllEvents(getEventLogsResponse.data.result);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  useEffect(() => {
    getEventLogs();
  }, []);

  return (
    <div className="pb-4">
      <p className="text-darkText p-4 font-semibold">Events:</p>
      <div className="h-[358px] overflow-auto scrollbar-hide">
        <table className="m-0 w-full">
          {allEvents?.slice(0, 6).map((event) => {
            return (
              <tr key={event.id} className="border-t">
                <td className="flex p-3 w-[200px]">
                  <p className="flex items-center bg-[rgba(64,187,130,.18)] w-[38px] justify-center text-[rgb(64,187,130)] rounded-full">
                    {initialLetters(event.attributes.personIdentifier)}
                  </p>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-darkText">
                      {event.attributes.personIdentifier}
                    </p>
                    <p className="text-xs text-secondary">
                      {moment
                        .tz(event.timestamp, userCtx?.usersData?.timeZone || '')
                        .format('MMMM DD, YYYY ')}
                    </p>
                  </div>
                </td>

                <td className="p-3 w-[120px]">
                  <p className="text-xs text-secondary">{event.name}</p>
                </td>
              </tr>
            );
          })}
        </table>
      </div>
      <Link to={eventLogs} className="bg-white w-full h-6">
        <p className="text-xs absolute bottom-4 left-[41%] cursor-pointer text-secondary underline mt-3">
          View all Events
        </p>
      </Link>
    </div>
  );
};

export default EventsLogs;
