import Dropdown from 'components/Dropdown/ActionDropdown';
import 'assets/styles/common.css';
import { getEmailSenders } from 'services/apiHandlers/Integration/Miscellaneous';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import React from "react";

export const dataSchema = z.object({
  accountId: z.number().optional(),
  email: z.string().optional(),
  id: z.string().optional(),
  links: z.object({ triggers: z.union([z.array(z.string()), z.tuple([])]) }).optional(),
  name: z.string().optional(),
  replyTo: z.string().optional(),
});
export type data = z.infer<typeof dataSchema>;

const responseSchema = z.object({
  data: z.array(dataSchema),
  status: z.number(),
});
type response = z.infer<typeof responseSchema>;

const EmailSender = () => {
  const [emailSenderData, setEmailSenderData] = useState<Array<data>>();
  const getData = async () => {
    const getEmailSenderResponse = (await getEmailSenders()) as response;
    if (getEmailSenderResponse.status === 200) {
      setEmailSenderData(getEmailSenderResponse.data);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="p-4 bg-gray-150 dark:bg-slate-800 rounded border border-gray-800 dark:border-black-400 mt-8">
      <h3 className="text-13 font-semibold leading-4 text-black-700 dark:text-white mb-2">
        E-Mail Senders
      </h3>
      <div>
        <div className="min-h-[300px]">
          <Dropdown options={emailSenderData} getData={getData} />
        </div>
      </div>
    </div>
  );
};
export default EmailSender;
