import Cookies from 'js-cookie';
import { Navigate, Outlet } from 'react-router-dom';
import { login } from 'services/constant/routes';
import MainLayout from 'MainLayout';
import ErrorBoundary from 'components/ErrorBoundary';
import { ThemeContextProvider } from 'store/ThemeContext';
import React from 'react';
import { UserContextProvider } from 'store/UserContext';

const ProtectedRoute = () => {
  const { REACT_APP_LOGIN_COOKIE } = process.env;
  const cookie = Cookies.get(REACT_APP_LOGIN_COOKIE);

  return cookie ? (
    <ErrorBoundary>
      <UserContextProvider>
        <ThemeContextProvider>
          <MainLayout>
            <Outlet />
          </MainLayout>
        </ThemeContextProvider>
      </UserContextProvider>
    </ErrorBoundary>
  ) : (
    <Navigate to={login} />
  );
};
export default ProtectedRoute;
