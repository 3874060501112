import Table from 'components/Table';
import React, { FC, useMemo } from 'react';
import { TableColumn } from 'react-data-table-component';
import { z } from 'zod';
import Copy from 'assets/Images/Workflow/copy.png';

const eventAttributesSchema = z.object({
  name: z.string(),
  mailMerge: z.string(),
});

type EventAttributes = z.infer<typeof eventAttributesSchema>;

interface Props {
  eventAttributes: EventAttributes[];
  fields: EventAttributes[];
}

const EventAttributes: FC<Props> = ({ eventAttributes, fields }) => {
  const attributeColumns: TableColumn<EventAttributes>[] = useMemo(
    () => [
      {
        name: 'Event Attributes',
        cell: (row) => {
          return (
            <div className="dark:text-white">
              <div>{row.name}</div>
            </div>
          );
        },
      },
      {
        name: 'Mail Merge Code',
        cell: (row) => {
          return (
            <div className="dark:text-white">
              <div>{row.mailMerge}</div>
              <img className="w-5" src={Copy} />
            </div>
          );
        },
      },
    ],
    []
  );

  const fieldsColumns: TableColumn<EventAttributes>[] = useMemo(
    () => [
      {
        name: 'Subscriber Attributes',
        cell: (row) => {
          return (
            <div className="dark:text-white">
              <div>{row.name}</div>
            </div>
          );
        },
      },
      {
        name: 'Mail Merge Code',
        cell: (row) => {
          return (
            <div className="dark:text-white">
              <div>{row.mailMerge}</div>
              <img className="w-5" src={Copy} />
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="flex mt-10">
      <div className="ml-[180px]">
        <Table
          className="scrollbar-hide"
          data={eventAttributes}
          columns={attributeColumns}
          pagination={false}
        />
      </div>
      <div className="ml-[480px]">
        <Table
          className="scrollbar-hide"
          data={fields}
          columns={fieldsColumns}
          pagination={false}
        />
      </div>
    </div>
  );
};
export default EventAttributes;
