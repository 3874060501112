import DownArrow from 'assets/Images/Common/down-arrow.png';
import SenderSettings from './SenderSettings';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { getAllSenders } from 'services/apiHandlers/Campaigns/CampaignInformation';
import SenderForm from './SenderForm';
import ErrorBoundary from 'components/ErrorBoundary';
import { senderSchema } from './Types';
import { CampaignContext } from 'store/CampaignContext';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import {
  resendeEmailVerification,
  verifyEmail,
} from 'services/apiHandlers/Campaigns/Emails';
import { Toastify } from 'App';
import { z } from 'zod';
import countryList from 'react-select-country-list';
import React from 'react';

const senderResponseSchema = z.object({
  data: z.object({ blast_addresses: z.array(senderSchema) }),
  status: z.number(),
});
const verifyEmailResponseSchema = z.object({
  data: z.object({ activated: z.boolean() }),
  status: z.number(),
});
const resendEmailVerificationResponseSchema = z.object({ status: z.number() });

const countryDataSchema = z.object({ value: z.string(), label: z.string() });

type CountryData = z.infer<typeof countryDataSchema>;
type ResendEmailResponse = z.infer<
  typeof resendEmailVerificationResponseSchema
>;
export type senderResponse = z.infer<typeof senderResponseSchema>;
export type SenderType = z.infer<typeof senderSchema>;
export type VerifyEmailResponse = z.infer<typeof verifyEmailResponseSchema>;

interface Props {
  hideReplySection: (show: boolean) => void;
}

const Sender: FC<Props> = ({ hideReplySection }) => {
  const [showSender, setShowSender] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [selectedSender, setSelectedSender] = useState<string>(
    'Please Select or Add a New Sender'
  );
  const [senders, setSenders] = useState<Array<SenderType>>([]);
  const [editSender, setEditSender] = useState<SenderType>();
  const campaignCtx = useContext(CampaignContext);
  const [showError, setShowError] = useState<boolean>(false);
  const [emailActivated, setEmailActivated] = useState<boolean>(true);
  const [activatedSender, setActivatedSender] = useState<SenderType | null>();
  const [senderMail, setSenderMail] = useState<string>('');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [countries, setCountries] = useState<Array<string>>([]);

  useOutsideClick(dropdownRef, () => {
    setShowSender(false);
  });

  const getData = async () => {
    try {
      const getSendersresponse = (await getAllSenders()) as senderResponse;
      if (getSendersresponse.status === 200) {
        setSenders(getSendersresponse.data.blast_addresses);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const addSenderHandler = (show: boolean) => {
    hideReplySection(show);
    setShowForm(show);
    !show && setShowSender(false);
  };

  const editSenderHandler = (id: number) => {
    const sender = senders.filter((sender) => sender.id === id);
    setEditSender(sender[0]);
    setShowForm(true);
  };

  const emailVerificationHandler = async (sender: SenderType) => {
    setActivatedSender(sender);
    try {
      const verifyEmailResponse = (await verifyEmail(
        sender.from_email_address
      )) as VerifyEmailResponse;
      if (verifyEmailResponse.status === 200) {
        if (verifyEmailResponse.data.activated) {
          const senderData = {
            reply_to_name: sender.from_name,
            reply_to_email_address: sender.from_email_address,
          };
          campaignCtx?.onChangeSenderActivatedData(senderData);
          setActivatedSender(null);
        } else {
          const senderData = {
            reply_to_name: '',
            reply_to_email_address: '',
          };
          campaignCtx?.onChangeSenderActivatedData(senderData);
        }
        setEmailActivated(verifyEmailResponse.data.activated);
      }
    } catch (error) {
      console.log('verify error is : ', error);
    }
  };

  const onSenderClickHandler = async (sender: SenderType) => {
    setShowSender(false);
    setSenderMail(sender.from_email_address);
    emailVerificationHandler(sender);

    const fromData = { id: sender.id };

    if (campaignCtx?.campaignData) {
      campaignCtx?.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        from: fromData,
        from_id: sender.id,
        senderTriggerData: {
          from_email_address: sender.from_email_address,
          from_name: sender.from_name,
        },
      });
    }

    setSelectedSender(sender.from_name);
  };

  const resendEmailVerificationHandler = async () => {
    const data = { from_email_address: senderMail };
    try {
      const resendEmailResponse = (await resendeEmailVerification(
        data
      )) as ResendEmailResponse;
      if (resendEmailResponse.status === 200) {
        setEmailActivated(true);
        Toastify('Email Verification Sent', 'success', 'sender4');
      }
    } catch (error) {
      Toastify('Resend Email Verification Failed', 'error', 'sender2');
    }
  };

  useEffect(() => {
    if (campaignCtx?.campaignFormErrors.senderFormError) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [campaignCtx?.campaignFormErrors]);

  useEffect(() => {
    if (campaignCtx?.campaignData?.from?.id) {
      campaignCtx?.onChangeFormValidity('senderFormError', false);
    }
  }, [campaignCtx?.campaignData?.from]);

  useEffect(() => {
    if (activatedSender) {
      const interval = setInterval(
        () => emailVerificationHandler(activatedSender),
        3000
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, [activatedSender]);

  useEffect(() => {
    const countriesLabels = countryList()
      .getData()
      .map((country: CountryData) => country.label);
    setCountries(countriesLabels);
    getData();
  }, []);

  return (
    <ErrorBoundary>
      <div className="p-4 pb-10 bg-gray-150 dark:bg-slate-800 rounded-md border border-gray-800 dark:border-black-400 mt-8">
        {showForm ? (
          <SenderForm
            hideForm={() => addSenderHandler(false)}
            getData={getData}
            editSenderData={editSender}
            countries={countries}
          />
        ) : (
          <div className="relative" ref={dropdownRef}>
            <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white inline-block inline-block mb-3">
              Sender
            </label>
            <div
              onClick={() => setShowSender((prev) => !prev)}
              className="relative sm:h-11 h-9 border border-gray-800 dark:border-black-400 rounded-md"
            >
              <div className="text-13 text-black-400 dark:text-white w-full h-full py-2 px-4 focus:outline-none bg-white dark:bg-slate-800 dark:border-black-400 rounded-md flex items-center">
                {selectedSender}
              </div>
              <img
                className="absolute right-1.5 top-3.5"
                src={DownArrow}
                alt="Arrow"
              />
            </div>
            {showSender && (
              <div className="border border-gray-800 absolute z-10 w-full bg-gray-150 dark:bg-slate-800">
                <div className="h-48 overflow-y-auto">
                  <a
                    onClick={() => addSenderHandler(true)}
                    className="flex items-center text-13 font-normal leading-3 text-black-700 dark:text-white p-3 text-13 font-normal leading-3 text-black-700 dark:text-white border-b cursor-pointer hover:bg-primary hover:text-white dark:hover:bg-white dark:hover:text-black-400"
                  >
                    <span className="w-3 h-3 flex justify-center items-center inline-block rounded-full bg-primary text-white text-xs font-bold mr-1">
                      +
                    </span>
                    <span>Add New Sender</span>
                  </a>
                  {senders?.map((sender, index) => (
                    <a
                      key={sender.id}
                      className={`flex justify-between items-center text-13 font-normal leading-5 text-black-700 dark:text-white block p-3 border-b hover:bg-primaryHover hover:text-white ${
                        sender.confirmed === 'true'
                          ? 'cursor-pointer'
                          : 'cursor-not-allowed'
                      } dark:hover:bg-white dark:hover:text-black-400`}
                    >
                      <div
                        className="flex"
                        onClick={() => {
                          onSenderClickHandler(sender);
                        }}
                      >
                        <div>
                          <p>
                            <strong>{sender.from_name} </strong>
                            <span>{sender.from_email_address}</span>
                            <span
                              className={`${
                                sender.confirmed === 'true'
                                  ? 'text-green-600 bg-green-100'
                                  : 'bg-[#f7e8e7] text-[#CB5A5A]'
                              } text-xs font-medium leading-3 relative rounded-xl py-1 px-2.5 h-5 leading-2 ml-2`}
                            >
                              {sender.confirmed === 'true'
                                ? 'verified'
                                : 'unverified'}
                            </span>
                          </p>
                          <p>
                            {sender.street_address_1 +
                              ' , ' +
                              sender.city +
                              ' , ' +
                              sender.state +
                              ' ' +
                              sender.postal_code}
                          </p>
                        </div>
                      </div>
                      {sender.confirmed === 'true' && (
                        <SenderSettings
                          index={index}
                          senderId={sender.id}
                          onEdit={(id) => editSenderHandler(id)}
                          onDelete={getData}
                        />
                      )}
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        {showError && (
          <div className="text-xs leading-4 text-red-400 mt-1.5">
            Sender cannot be empty
          </div>
        )}
        {!emailActivated && !showSender && (
          <div className="ml-2 mr-2 mb-2 px-6 py-3 bg-[#f7e8e7] rounded mt-4">
            <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
              {
                "Sender's email address must be verified prior to scheduling your blast. We have sent a verification email, please click on the link to verify. "
              }
              <span
                onClick={resendEmailVerificationHandler}
                className="underline cursor-pointer"
              >
                Resend verification email?
              </span>
            </p>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};
export default Sender;
