import { TableColumn } from 'react-data-table-component';
import Table from 'components/Table';
import { useContext, useEffect, useMemo, useState } from 'react';
import { z } from 'zod';
import { WorkflowType, InitialConditionGroup } from './index.type';
import { tableSort } from 'utils/common';
import styled from 'styled-components';
import WorkflowActions from './WorkflowActions';
import { updateWorkflow } from 'services/apiHandlers/Workflows/Workflow';
import Skeleton from 'components/Skeleton/Skeleton';
import { workflow } from 'services/constant/routes';
import { Link } from 'react-router-dom';
import { UserContext } from 'store/UserContext';
import moment from 'moment-timezone';
import React from 'react';

const responseSchema = z.object({ status: z.number() });
type Response = z.infer<typeof responseSchema>;

const logsSchema = z.object({
  accountId: z.number(),
  executionId: z.string(),
  id: z.string(),
  message: z.string(),
  personIdentifier: z.string(),
  triggerId: z.string(),
  workflowNodeId: z.string(),
});

const executionDataSchema = z.object({
  logs: z.array(logsSchema),
  workflow: z.string(),
});

export type ExecutionData = z.infer<typeof executionDataSchema>;
export type ExecutionLog = z.infer<typeof logsSchema>;

const workflowLogSchema = z.object({
  accountId: z.number(),
  executionId: z.string(),
  id: z.string(),
  message: z.string(),
  personIdentifier: z.string(),
  triggerId: z.string(),
  workflowNodeId: z.string(),
});
export type WorkflowLogs = z.infer<typeof workflowLogSchema>;

export const checkTrigger = (conditionGroup: InitialConditionGroup) => {
  if ('tags' in conditionGroup) {
    return 'Subscriber Tag';
  }
  if ('eventName' in conditionGroup) {
    return conditionGroup.eventName;
  }
};

const StyledWrapper = styled.div`
  .rdt_TableRow .rdt_TableCell {
    border-right: 1px solid #e0e0e0;
  }

  .rdt_TableRow .rdt_TableCell:last-child {
    border-right: 0;
  }

  .rdt_TableHeadRow .rdt_TableCol {
    border-right: 1px solid #e0e0e0;
  }
  .rdt_TableHeadRow .rdt_TableCol:last-child {
    border-right: 0;
  }
  .rdt_TableCol:last-child {
    min-width: 300px;
  }
  .WQNxq {
    border-radius: 8px 8px 0 0 !important;
  }
  .rdt_TableRow .rdt_TableCell:last-child {
    min-width: 300px;
  }
  .efizJs {
    font-size: 16px;
  }
  .rdt_TableCol:nth-child(4) {
    max-width: 140px;
  }
  .rdt_TableRow .rdt_TableCell:nth-child(4) {
    max-width: 140px;
  }
  .rdt_TableCol:nth-child(5) {
    max-width: 160px;
  }
  .rdt_TableRow .rdt_TableCell:nth-child(5) {
    max-width: 160px;
  }
  .rdt_TableCol:nth-child(6) {
    max-width: 160px;
  }
  .rdt_TableRow .rdt_TableCell:nth-child(6) {
    max-width: 160px;
  }
`;

const Workflows = () => {
  const [workflows, setWorkflows] = useState<Array<WorkflowType>>([]);
  const [statusLoading, setStatusLoading] = useState<boolean>(false);
  const [loadingIndex, setLoadingIndex] = useState<number>();
  const userCtx = useContext(UserContext);

  const statusChangeHandler = async (
    id: string,
    status: boolean,
    index: number
  ) => {
    setStatusLoading(true);
    setLoadingIndex(index);
    const filteredWorkflow = workflows.filter((workflow) => workflow.id === id);
    const updatedWorkflow = { ...filteredWorkflow[0], isEnabled: !status };

    const tempWorkflows = [...workflows];

    workflows.map((workflow, index) => {
      if (workflow.id === id) {
        tempWorkflows[index].isEnabled = !status;
      }
    });

    setWorkflows(tempWorkflows);

    try {
      const updateWorkflowResponse = (await updateWorkflow(
        updatedWorkflow,
        id
      )) as Response;
      if (updateWorkflowResponse.status === 200) {
        setStatusLoading(false);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setStatusLoading(false);
  };

  const workflowSort = (rowA: WorkflowType, rowB: WorkflowType) => {
    return tableSort(rowA.name || '', rowB.name || '');
  };

  const columns: TableColumn<WorkflowType>[] = useMemo(
    () => [
      {
        name: 'Name',
        cell: (row) => {
          return (
            <Link
              to={`${workflow}/${row?.id?.toString()}`}
              className="dark:text-white text-primary cursor-pointer font-medium w-[600px]"
            >
              <div>{row.name}</div>
            </Link>
          );
        },
        sortable: true,
        sortFunction: workflowSort,
      },
      {
        name: 'Triggers on',
        cell: (row) => {
          return (
            <div className="dark:text-white">
              <div>{checkTrigger(row.initialConditionGroup)}</div>
            </div>
          );
        },
      },
      {
        name: 'Converts On',
        cell: (row) => {
          return (
            <div className="dark:text-white">
              {row.defaultConversionReportingEvent}
            </div>
          );
        },
      },
      {
        name: 'Status',
        cell: (row) => {
          return (
            <div
              className={`${
                row.expiresAt && row.isEnabled ? 'bg-gray-600' : ''
              } ${!row.isEnabled ? 'bg-red-300' : ''} ${
                row.isEnabled && !row.expiresAt ? 'bg-green-600' : ''
              } dark:text-white text-white px-4 py-2.5 rounded-full`}
            >
              {row.expiresAt && row.isEnabled
                ? 'Expired'
                : row.isEnabled && row.isTestMode
                ? 'Enabled(Test Mode)'
                : row.isEnabled
                ? 'Enabled'
                : 'Disabled'}
            </div>
          );
        },
      },
      {
        name: 'Date Modified',
        cell: (row) => {
          return row.lastModified ? (
            <div className="dark:text-white">
              {moment
                .tz(row.lastModified, userCtx?.usersData?.timeZone || '')
                .format('MMMM D, YYYY h:mm:ss A')}
            </div>
          ) : (
            <>-</>
          );
        },
      },
      {
        name: 'Last Triggered',
        cell: (row) => {
          return (
            <div className="dark:text-white">
              {row.lastTriggered
                ? moment
                    .tz(row.lastTriggered, userCtx?.usersData?.timeZone || '')
                    .format('MMMM D, YYYY h:mm:ss A')
                : '-'}
            </div>
          );
        },
      },
      {
        name: 'Actions',
        cell: (row, index) => {
          return loadingIndex === index && statusLoading ? (
            <Skeleton columns={2} />
          ) : (
            <WorkflowActions
              row={row}
              onStatusChange={(id: string, status: boolean) =>
                statusChangeHandler(id, status, index)
              }
              index={index}
              getData={(preventLoading: boolean) =>
                userCtx?.getWorkflows(preventLoading)
              }
              logStatus={row.isEnabled ? 'active' : 'inactive'}
            />
          );
        },
      },
    ],
    [loadingIndex, statusLoading, workflows, userCtx?.usersData?.timeZone]
  );

  useEffect(() => {
    if (userCtx?.workflows) {
      setWorkflows(userCtx?.workflows);
    }
  }, [userCtx?.workflows]);

  useEffect(() => {
    // getData();
  }, []);

  return (
    <div>
      <StyledWrapper>
        <Table
          className="scrollbar-hide"
          data={workflows}
          columns={columns}
          progressPending={userCtx?.workflowsLoading}
        />
      </StyledWrapper>
    </div>
  );
};
export default Workflows;
