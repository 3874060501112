import Plus from 'assets/Images/svg-images/plus.svg';
import Delete from 'assets/Images/ListsegmentImages/segment-delete.png';
import { FC, useEffect } from 'react';
import { PublishEventActionData } from './index.type';
import React from 'react';

interface Props {
  attributes: PublishEventActionData[];
  setAttributes: (attributes: PublishEventActionData[]) => void;
  invalidForm: boolean;
  setIsFormValid: (valid: boolean) => void;
}

const PublishAttributes: FC<Props> = ({
  attributes,
  setAttributes,
  invalidForm,
  setIsFormValid,
}) => {

  const inputChangeHandler = (value: string, index: number, field: string) => {
    const tempAttributes = [...attributes];

    const updatedAttributes = tempAttributes.map((data, i) => {
      if (i === index) {
        return {
          ...data,
          [field as keyof PublishEventActionData]: value,
        };
      }
      return data;
    });
    setAttributes(updatedAttributes);
  };

  const deleteAttributeHandler = (index: number) => {
    const tempAttributes = [...attributes];
    tempAttributes.splice(index, 1);
    setAttributes(tempAttributes);
  };

  const addAttributeHandler = () => {
    const tempAttributes = [...attributes];
    tempAttributes.push({ name: '', value: '' });
    setAttributes(tempAttributes);
  };

  useEffect(() => {
    let valid = true;

    attributes.map((attribute) => {
      if (attribute.name === '' || attribute.value === '') {
        valid = false;
      }
    });
    if (valid) {
      setIsFormValid(valid);
    }
  }, [attributes]);

  return (
    <>
      {attributes?.map((data, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className="flex items-center gap-3 mt-3"
        >
          <div className="w-5/12">
            <label className="text-xs font-semibold leading-3 block tracking-wide text-gray-500 uppercase mb-3 dark:text-white">
              Name
            </label>
            <input
              className="dark:bg-slate-800 dark:text-white text-13 font-medium leading-3 text-black-400 w-full py-3 px-4 rounded-md border border-gray-800 focus:outline-none focus:border-primary sm:h-11 h-9"
              type="text"
              value={data.name}
              onChange={(e) =>
                inputChangeHandler(e.target.value, index, 'name')
              }
            />
          </div>
          <div className="w-5/12">
            <label className="dark:text-white text-xs font-semibold leading-3 block tracking-wide text-gray-500 uppercase mb-3">
              Value
            </label>
            <input
              className="dark:bg-slate-800 dark:text-white text-13 font-medium leading-3 text-black-400 w-full py-3 px-4 rounded-md border border-gray-800"
              type="text"
              value={data.value}
              onChange={(e) =>
                inputChangeHandler(e.target.value, index, 'value')
              }
            />
          </div>
          <div className="flex items-center gap-2.5 w-2/12 pt-5">
            <div onClick={() => deleteAttributeHandler(index)}>
              <img
                className="dark:invert dark:brightness-0"
                src={Delete}
                alt="Delete"
              />
            </div>
            <div
              onClick={() => addAttributeHandler()}
              className="w-5 h-5 rounded-full bg-primary flex justify-center items-center"
            >
              <img src={Plus} alt="Plus" />
            </div>
          </div>
        </div>
      ))}
      {invalidForm && (
        <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4">
          <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
            Correct the errors above
          </p>
        </div>
      )}
    </>
  );
};
export default PublishAttributes;
