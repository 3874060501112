import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';
import EmailAlert from 'components/Alert/EmailAlert';
import { getMailMerge } from 'services/apiHandlers/Campaigns/CampaignInformation';
import Dropdown from 'components/Dropdown';
import { z } from 'zod';
import { CampaignContext } from 'store/CampaignContext';
import Modal from 'components/Alert/Modal';
import React from 'react';

export const mailMerge = z.object({
  name: z.string(),
  tag: z.string(),
  group: z.string(),
});

export type Mail = z.infer<typeof mailMerge>;

const mailMergeResponseSchema = z.object({
  data: z.object({ fields: z.array(mailMerge) }),
  status: z.number(),
});
export type mailMergeResponse = z.infer<typeof mailMergeResponseSchema>;

const SubjectForm = () => {
  const [showMergeMail, setShowMergeMail] = useState<boolean>(false);
  const [mails, setMails] = useState<Array<Mail>>([]);
  const [mergeOptions, setMergeOptions] = useState<Array<string>>([]);
  const [mergeMail, setMergeMail] = useState<string>();
  const [formError, setFormError] = useState<boolean>(false);
  const campaignCtx = useContext(CampaignContext);

  const getData = async () => {
    try {
      const getSubjectFormDataresponse =
        (await getMailMerge()) as mailMergeResponse;
      if (getSubjectFormDataresponse.status === 200) {
        setMails(getSubjectFormDataresponse.data.fields);
        setMergeMail(getSubjectFormDataresponse.data.fields[0].tag);
        const options = getSubjectFormDataresponse.data.fields.map(
          (mail) => mail.name
        );
        setMergeOptions(options);
      }
    } catch (error) {
      console.log('erorr is : ', error);
    }
  };

  const onSelectHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    const mail = mails.filter((mail) => {
      if (mail.name === e.target.value) {
        return mail.tag;
      }
    });
    setMergeMail(mail[0].tag);
  };

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      subject: '',
    },
    validationSchema: Yup.object({
      subject: Yup.string().required('Subject cannot be empty'),
    }),
    onSubmit: async (values) => {
      console.log('values subject', values);
    },
  });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (campaignCtx?.campaignData) {
      campaignCtx?.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        subject: formik.values.subject,
      });
    }

    if (
      campaignCtx?.campaignFormErrors.subjectFormError ||
      (formik.touched.subject && formik.values.subject.length === 0)
    ) {
      setFormError(true);
    } else {
      setFormError(false);
    }
  }, [formik.values, campaignCtx?.campaignFormErrors]);

  useEffect(() => {
    if (campaignCtx?.campaignData) {
      if (campaignCtx?.campaignData?.subject?.length > 0) {
        campaignCtx.onChangeFormValidity('subjectFormError', false);
      }
    }
  }, [campaignCtx?.campaignData?.subject]);

  return (
    <div className="p-4 pb-10 bg-gray-150 dark:bg-slate-800 rounded-md border border-gray-800 dark:border-black-400">
      <form onSubmit={formik.handleSubmit}>
        <div>
          <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white inline-block inline-block mb-3">
            Subject
          </label>
          <input
            className="w-full p-4 text-13 font-medium leading-3 dark:bg-slate-800 border border-gray-800 dark:border-black-400 rounded-md text-black-400 dark:text-white sm:h-11 h-9 focus:outline-none placeholder:text-gray-500 dark:placeholder:text-white"
            type="text"
            name="subject"
            data-pw="subject-input"
            onChange={formik.handleChange}
            value={formik.values.subject}
            onBlur={formik.handleBlur}
            placeholder="Enter Subject"
          />
          {formError && (
            <div className="text-xs leading-4 text-red-400 mt-1.5">
              Subject cannot be empty
            </div>
          )}
          <div className="flex justify-between items-center mt-6">
            <div className="z-10 bg-white dark:bg-slate-800 border-gray-200 dark:border-black-400 border rounded-md dropdown-show-hide sm:h-11 h-9 overflow-y-auto w-6/12">
              <Dropdown
                className="text-13 text-black-400 placeholder:text-gray-500 w-full h-full py-2 px-4 focus:outline-none bg-white dark:bg-slate-800 dark:border-black-400 dark:text-white cursor-pointer"
                options={mergeOptions}
                onSelect={onSelectHandler}
                id="subject"
              />
            </div>
            <a
              className=" cursor-pointer inline-flex items-center text-xs font-medium text-primary ml-3"
              onClick={() => setShowMergeMail((prev) => !prev)}
            >
              <span className="w-3 h-3 flex justify-center items-center inline-block rounded-full bg-primary text-white text-xs font-bold mr-1">
                +
              </span>
              <span className="underline">Add Mail Merge</span>
            </a>
          </div>
        </div>
        <Modal isOpen={showMergeMail}>
          <EmailAlert
            cancelClicked={() => setShowMergeMail(false)}
            createClicked={(mail) => {
              if (campaignCtx?.campaignData) {
                campaignCtx?.onUpdateCampaignData({
                  ...campaignCtx.campaignData,
                  mergeFields: mail,
                });
              }
              formik.setFieldValue('subject', mail);
              setShowMergeMail(false);
            }}
            mergeMail={mergeMail}
          />
        </Modal>
      </form>
    </div>
  );
};
export default SubjectForm;
