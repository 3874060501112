import { FC } from 'react';
import { z } from 'zod';
import React from 'react';


const EmailDataSchema = z.object({
    title: z.string(),
    percent: z.string(),
    openings: z.string().optional(),
    id: z.string(),
  });
type EmailData = z.infer<typeof EmailDataSchema>;

interface Props {
    emailPerformance?: EmailData[]
    image: string
  }

const EmailPerformance: FC<Props> = ({emailPerformance,image}) => {

  return (
    <div className="rounded-lg shadow-lg shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800 xl:p-4 px-2 py-3">
      {emailPerformance?.map((email) => (
        <div
          key={email.id}
          className='h-39'
        >
          <div>
            <div className='xl:flex block items-center'>
              <div className='h-12 w-12 flex items-center justify-center bg-cardBadge rounded-md'>
                <img src={image} alt="No" />
              </div>
              <p className="tracking-wide text-secondary dark:text-white text-sm leading-4 xl:px-2 xl:mt-0 mt-2 truncate">
                {email.title}
              </p>
            </div>
          </div>
          <div className="mt-6">
            <h3 className="font-semibold leading-7 text-3xl text-darkText dark:text-white">
                {email.openings || '-'}
            </h3>
          </div>
          <div className="mt-4 mb-0 text-secondary text-xs flex xl:items-center xl:flex-row flex-col">
            <div className="bg-danger text-danger rounded-md px-2 py-1 inline-flex w-12 items-center text-xxs mb-0"> 
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3" />
              </svg>
              {email.percent}
            </div> 
            <p className='ml-2 xl:mt-0 mt-1'>vs. previous month</p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default EmailPerformance;
