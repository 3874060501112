import React from 'react';
import Menu from 'assets/Images/svg-images/menu.svg';
import IconButton from 'components/Header/IconButton';
import { Switcher } from 'components/Switcher';
import ErrorBoundary from 'components/ErrorBoundary';


interface Props {
  toggleSidebar: () => void;
}

const Header: React.FC<Props> = ({ toggleSidebar }) => {

  return (
    <ErrorBoundary>
      <div>
        <header className="bg-white border-b md:px-8 px-4 dark:bg-slate-800 border-gray-800 dark:border-black-400">

          <div className="flex justify-between items-center w-full">
            <div className="w-10/12 sm:block flex items-center">

              <div className="sm:hidden mr-4 h-5">
                

                <IconButton className="w-5 h-5" onClick={toggleSidebar}>
                  <img className='dark:invert dark:brightness' src={Menu} alt="Menu" />
                </IconButton>
              </div>
            </div>
            <div className="flex items-center py-2 pl-4 border-l dark:border-black-400">
              <Switcher />
            </div>
          </div>
        </header>
      </div>
    </ErrorBoundary>
  );
};
export default Header;
