import { useContext } from 'react';
import { WorkflowContext } from 'store/WorkflowContext';
import { checkTrigger } from '.';
import React from 'react';
import moment from 'moment';

const WorkflowTriggerData = () => {
  const workflowCtx = useContext(WorkflowContext);
  return (
    <div className="flex md:justify-start justify-between md:flex-nowrap flex-wrap font-inter">
      <div className="lg:w-96 md:w-52 md:mr-0 mr-2.5 mt-6 md:mr-2.5">
        <div>
          <p className="text-xs dark:text-white font-medium leading-3 tracking-wide uppercase text-black-400 mb-2.5">
            Triggers on
          </p>
          <p className="text-13 dark:text-white font-semibold leading-4 text-black-400">
            {workflowCtx?.workflow?.initialConditionGroup
              ? checkTrigger(workflowCtx?.workflow?.initialConditionGroup)
              : ''}
          </p>
        </div>
        <div className="mt-6">
          <p className="text-xs dark:text-white font-medium leading-3 tracking-wide uppercase text-black-400 mb-2.5">
            Modified on
          </p>
          <p className="text-13 dark:text-white font-semibold leading-4 text-black-400">
            {moment(workflowCtx?.workflow?.lastModified).format(
              'ddd, MMM DD, YYYY [at] hh:mm A'
            )}
          </p>
        </div>
      </div>
      <div className="lg:w-96 md:w-52 md:mr-0 md:mr-2.5 mt-6">
        <div>
          <p className="text-xs dark:text-white font-medium leading-3 tracking-wide uppercase text-black-400 mb-2.5">
            Converts on
          </p>
          <p className="text-13 dark:text-white font-semibold leading-4 text-black-400">
            {workflowCtx?.workflow?.defaultConversionReportingEvent || '-'}
          </p>
        </div>
        <div className="mt-6">
          <p className="text-xs dark:text-white font-medium leading-3 tracking-wide uppercase text-black-400 mb-2.5">
            Rule
          </p>
          <p className="text-13 dark:text-white font-semibold leading-4 text-black-400">
            First e-mail is sent after 2 min after the customer views cart.
          </p>
        </div>
      </div>
    </div>
  );
};
export default WorkflowTriggerData;
