import { useContext, FC } from 'react';
import { TotalOrders } from 'staticdata/Dashboard/Data';
import StoreReport from 'components/DashboardReports/Body/Store-Report/Report';
import { storeReportOrder } from 'components/DashboardReports/Body/Store-Report/Types';
import { DashboardContext } from 'store/DashboardContext';
import React from 'react';



interface Props {
  orders: storeReportOrder | undefined;
}

const TotalOrder: FC<Props> = ({ orders }) => {

  const dashboardCtx = useContext(DashboardContext);

  return (
    <div>
      <StoreReport
        data={orders ? orders : TotalOrders}
        loading={dashboardCtx?.loading}
      />
    </div>
  );
};
export default TotalOrder;
