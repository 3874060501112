import React from "react";
const CreateWorkflowHeader = () => {

  
  return (
    <div className="">
      <div className="flex justify-between items-center">
        <h2 className="ml-5 text-xl font-medium leading-5 text-black-300 dark:text-white">
          Create Workflow
        </h2>
      </div>
      <div className="mt-7">
        <div className="border border-gray-100 rounded-lg boxShadow border-md bg-white py-6 px-4 flex justify-between items-center dark:bg-black-400">
          <div>
            <p className="text-sm font-medium leading-4 text-black-400 dark:text-white dark:text-white">
              Use workflows to trigger actions based on your subscriber
              activities and behavior. Build a flow to generate segmented lists,
              personalized email campaigns and pass subscriber data to your
              marketing toolkit.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateWorkflowHeader;
