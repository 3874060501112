import httpService from 'services/core/HttpService';
import {
  GET_CSV_EXPORT,
  GET_ORDER_REVENUE_REPORT,
  GET_REPORT_QOUTA,
} from 'services/constant/apiRoutes';

const { REACT_APP_BASE_URL } = process.env;

export const getOrderRevenueReport = async (
  startDate: string,
  endDate: string
) => {
  const orderUrl =
    GET_ORDER_REVENUE_REPORT + `endDate=${endDate}&startDate=${startDate}`;
  return new Promise((resolve, reject) => {
    httpService
      .get(orderUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getReportQouta = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_REPORT_QOUTA)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCsvExport = (startDate: string, endDate: string) => {
  const exportUrl =
    GET_CSV_EXPORT + `startDate=${startDate}&endDate=${endDate}`;
  window.open(REACT_APP_BASE_URL + exportUrl);
};
