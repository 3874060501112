import { FC, useEffect, useState } from 'react';
import { getPopularTags } from 'services/apiHandlers/Campaigns/Emails';
import { Tooltip } from 'react-tooltip';
import { z } from 'zod';
import React from 'react';

interface Props {
  onLabelClick?: (label: string) => void;
}

const pouplarDataSchema = z.object({
  tag: z.object({
    count: z.number(),
    id: z.number(),
    name: z.string(),
    taggings_count: z.number(),
  }),
});

const getPopularTagResponseSchema = z.object({
  data: z.array(pouplarDataSchema),
  status: z.number(),
});

const tagsInfoSchema = z.object({
  minimum: z.number(),
  maximum: z.number(),
  distribution: z.number(),
});

type TagsInfo = z.infer<typeof tagsInfoSchema>;
type PopularData = z.infer<typeof pouplarDataSchema>;
type GetPopularDataResponse = z.infer<typeof getPopularTagResponseSchema>;

const PopularLabels: FC<Props> = ({ onLabelClick }) => {
  const [popularTags, setPopularTags] = useState<Array<PopularData>>([]);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [showAllTags, setShowAllTags] = useState<boolean>(false);
  const [tagsInfo, setTagsInfo] = useState<TagsInfo>({
    distribution: 0,
    maximum: 0,
    minimum: 0,
  });

  const tagSize = (count: number) => {
    if (count === tagsInfo.minimum) {
      return 'text-xs';
    } else if (count === tagsInfo.maximum) {
      return 'text-2xl';
    } else if (count > tagsInfo.minimum + tagsInfo.distribution * 2) {
      return 'text-xl';
    } else if (count > tagsInfo.minimum + tagsInfo.distribution) {
      return 'text-lg';
    } else {
      return 'text-base';
    }
  };

  const getData = async () => {
    try {
      const getPopularTagResponse =
        (await getPopularTags()) as GetPopularDataResponse;
      if (getPopularTagResponse.status === 200) {
        const tags = getPopularTagResponse?.data?.map((tags) => {
          return tags;
        });
        const minimum = Math.min(...tags.map((tag) => tag.tag.count));

        const sortedTags = tags.sort(function (a, b) {
          const x = a.tag.name.toLowerCase();
          const y = b.tag.name.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        const maximum = Math.max(...tags.map((tag) => tag.tag.count));
        const distribution = (maximum - minimum) / 3;
        setTagsInfo({ distribution, maximum, minimum });
        setPopularTags(sortedTags);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="md:w-4/12 w-full rounded-lg bg-white md:block border border-gray-800 dark:border-black-400 rounded-md bg-white dark:bg-slate-800">
      <div className="flex items-center justify-between bg-gray-50 dark:bg-slate-700 py-3 px-3 rounded-t-md">
        <p className="text-13 font-medium leading-5 text-gray-700 dark:text-white">
          Popular Labels
        </p>
      </div>
      <div className="py-3 px-3">
        <ul className="flex flex-wrap">
          {popularTags?.map((tag, index) => {
            return popularTags.length > 7 && index < 7 && !showAllTags ? (
              <div key={tag.tag.id}>
                <li
                  onMouseEnter={() => {
                    setSelectedTag(tag.tag.name);
                  }}
                  onMouseLeave={() => {
                    setSelectedTag(null);
                  }}
                  id={`tooltip-anchor-children${index}`}
                  onClick={() => onLabelClick && onLabelClick(tag.tag.name)}
                  key={tag.tag.id}
                  className="inline-block my-1 mr-2"
                >
                  <div
                    className={`block font-light ${tagSize(
                      tag.tag.count
                    )} leading-3 text-white py-1 px-2.5 bg-primary rounded`}
                  >
                    {tag.tag.name}
                  </div>

                  <div>
                    <Tooltip
                      isOpen={selectedTag === tag.tag.name}
                      anchorId={`tooltip-anchor-children${index}`}
                    >
                      <div>
                        <h1>Used {tag.tag.count}</h1>
                      </div>
                    </Tooltip>
                  </div>
                </li>
                {index === 6 && (
                  <button
                    className="text-primary underline text-sm"
                    onClick={() => setShowAllTags(true)}
                  >
                    View All
                  </button>
                )}
              </div>
            ) : showAllTags ? (
              <div key={tag.tag.id}>
                <li
                  onMouseEnter={() => {
                    setSelectedTag(tag.tag.name);
                  }}
                  onMouseLeave={() => {
                    setSelectedTag(null);
                  }}
                  id={`tooltip-anchor-children${index}`}
                  onClick={() => onLabelClick && onLabelClick(tag.tag.name)}
                  key={tag.tag.id}
                  className="inline-block my-1 mr-2"
                >
                  <div
                    className={`block ${tagSize(
                      tag.tag.count
                    )} font-light leading-3 text-white py-1 px-2.5 bg-primary rounded`}
                  >
                    {tag.tag.name}
                  </div>

                  <div>
                    <Tooltip
                      isOpen={selectedTag === tag.tag.name}
                      anchorId={`tooltip-anchor-children${index}`}
                    >
                      <div>
                        <h1>Used {tag.tag.count}</h1>
                      </div>
                    </Tooltip>
                  </div>
                </li>
                {popularTags.length - 1 === index && (
                  <button
                    className="text-primary underline text-sm"
                    onClick={() => setShowAllTags(false)}
                  >
                    View Less
                  </button>
                )}
              </div>
            ) : (
              <></>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default PopularLabels;
