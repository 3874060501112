import { useContext, FC } from 'react';
import {
  AverageOrder,
} from 'staticdata/Dashboard/Data';
import StoreReport from 'components/DashboardReports/Body/Store-Report/Report';
import { storeReportOrder } from 'components/DashboardReports/Body/Store-Report/Types';
import { DashboardContext } from 'store/DashboardContext';
import React from 'react';

interface Props {
  averageRevenue: storeReportOrder| undefined;
}

const AverageRevenue: FC<Props> = ({ averageRevenue }) => {
  const dashboardCtx = useContext(DashboardContext);

  return (
    <div >
      <StoreReport
        data={averageRevenue ? averageRevenue : AverageOrder}
        loading={dashboardCtx?.loading}
      />
    </div>
  );
};
export default AverageRevenue;
