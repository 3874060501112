import { useMemo, useState, useEffect } from 'react';
import { getExecutionLogs } from 'services/apiHandlers/Workflows/Workflow';
import { TableColumn } from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';
import Table from 'components/Table';
import { z } from 'zod';
import React from 'react';
import moment from 'moment';
import { edit, listSegments, subscribers } from 'services/constant/routes';

const logsSchema = z.object({
  accountId: z.number(),
  executionId: z.string(),
  id: z.string(),
  message: z.string(),
  personIdentifier: z.string(),
  triggerId: z.string(),
  workflowNodeId: z.string(),
});
const executionDataSchema = z.object({
  logs: z.array(logsSchema),
  workflow: z.string(),
});
const executionResponseSchema = z.object({
  data: executionDataSchema,
  status: z.number(),
});

type ExecutionDataResponse = z.infer<typeof executionResponseSchema>;
export type ExecutionData = z.infer<typeof executionDataSchema>;
export type ExecutionLog = z.infer<typeof logsSchema>;

const formatDate = (id: string) => {
  const parts = id.split('-');
  const time =
    parseInt(parts[2].substring(1) + parts[1] + parts[0], 16) -
    122192928000000000;
  const date = moment(Math.floor(time / 10000));

  const formattedDate = date.format('MMM DD, YYYY hh:mm:ss A');
  return formattedDate;
};

const ExecutionLogs = () => {
  const { id, idNested } = useParams();
  const [logsLoading,setLogsLoading] = useState<boolean>(true);
  const [executionLogs, setExecutionLogs] = useState<Array<ExecutionLog>>();

  const getExecutionLogsHandler = async (id: string, logId: string) => {
    setLogsLoading(true)
    try {
      const executionLogResponse = (await getExecutionLogs(
        id,
        logId
      )) as ExecutionDataResponse;
      if (executionLogResponse.status === 200) {
        setExecutionLogs(executionLogResponse.data.logs);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLogsLoading(false)

  };

  const columns: TableColumn<ExecutionLog>[] = useMemo(
    () => [
      {
        name: 'Timestamp',
        cell: (row) => {
          return (
            <div className="dark:text-white cursor-pointer">
              <div>{formatDate(row.id)}</div>
            </div>
          );
        },
        sortable: true,
      },
      {
        name: 'Subscriber',
        cell: (row) => {
          return (
            <Link
              to={`${listSegments}/${subscribers}/${edit}/${row.personIdentifier}`}
              className="text-primary underline dark:text-white"
            >
              <div>{row.personIdentifier}</div>
            </Link>
          );
        },
      },
      {
        name: 'Message',
        cell: (row) => {
          return <div>{row.message}</div>;
        },
      },
    ],
    []
  );
  useEffect(() => {
    getExecutionLogsHandler(id ? id : '', idNested ? idNested : '');
  }, []);

  return (
    <div>
      <Table
        className="scrollbar-hide"
        data={executionLogs || []}
        columns={columns}
        pagination={false}
        progressPending={logsLoading}
      />
    </div>
  );
};
export default ExecutionLogs;
