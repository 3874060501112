import React, { useContext, useEffect, useMemo, useState } from 'react';
import DropDownIcon from 'assets/Images/svg-images/new-down-arrow.svg';
import { UserContext } from 'store/UserContext';
import {
  SingleWorkflow,
  Workflows,
} from 'components/DashboardReports/Body/Store-Report/Types';
import { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { workflowReports } from 'services/constant/routes';
import { calculatePercentage, initialLetters, tableSort } from 'utils/common';
import view from 'assets/Images/Dashboard/view.png';
import { DashboardContext } from 'store/DashboardContext';
import Table from 'components/Table';

interface StatusStyle {
  [key: string]: string;
  Enabled: string;
  Disabled: string;
  'Enabled(Test Mode)': string;
  Expired: string;
}

const statusStyle: StatusStyle = {
  Enabled: 'text-success bg-success',
  Expired: 'text-red-700 bg-red-100',
  Disabled: 'text-gray-500 bg-gray-100',
  'Enabled(Test Mode)': 'text-blue-500 bg-blue-100',
};

const workflowSort = (rowA: SingleWorkflow, rowB: SingleWorkflow) => {
  return tableSort(rowA.workflowName, rowB.workflowName);
};

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
const AllWokflows = () => {
  const userCtx = useContext(UserContext);
  const dashboardCtx = useContext(DashboardContext);
  const [tableData, setTableData] = useState<Workflows | undefined>();

  const customStyles = {
    rows: {
      style: {
        height: '60px',
        color: '#616161',
        fontSize: '14px',
        borderRight: '1px',
        backgroundColor: 'dark:',
        '&:hover': {
          backgroundColor: `${'#f9f9f9'} `,
        },
      },
    },

    cells: {
      style: {
        display: 'flex',
        paddingLeft: '4px',
        paddingRight: '4px',
      },
    },

    headCells: {
      style: {
        color: '#757575',
        fontSize: '12px',
        height: '44px',
        display: 'flex',
        paddingLeft: '4px',
        paddingRight: '4px',
      },
    },
  };

  const getWorlkflowsStatus = (id: string) => {
    const status = userCtx?.workflows?.filter((workflow) => workflow.id === id);
    if (status) {
      return status[0]?.expiresAt && status[0]?.isEnabled
        ? 'Expired'
        : status[0]?.isEnabled && status[0]?.isTestMode
        ? 'Enabled(Test Mode)'
        : status[0]?.isEnabled
        ? 'Enabled'
        : 'Disabled';
    }
    return '';
  };

  const columns: TableColumn<SingleWorkflow>[] = useMemo(
    () => [
      {
        name: <div className="pl-3">Workflow</div>,
        cell: (row) => {
          return (
            <div className="flex items-center pl-3">
              <Link
                to={`${workflowReports}/${row.workflowId}`}
                className="flex items-center w-[38px] h-[40px] justify-center text-white rounded-lg"
                style={{ backgroundColor: getRandomColor() }}
              >
                <p>{initialLetters(row.workflowName)}</p>
              </Link>
              <div className="ml-2 ">
                <p className="text-sm font-medium text-darkText truncate w-[110px]">
                  {row.workflowName}
                </p>
              </div>
            </div>
          );
        },
        sortable: true,
        sortFunction: workflowSort,
        // width: '200px',
        header: <div>Helllo</div>,
      },
      {
        name: 'Recipients',
        cell: (row) => {
          return (
            <p className="text-13 text-black-400 dark:text-white ">
              {row.uniquePeople}
            </p>
          );
        },
        width: '85px',
      },
      {
        name: 'Converted',
        cell: (row) => {
          return (
            <div>
              <p className="text-13 text-black-400 dark:text-white inline-block">
                {row.uniqueConversions}
              </p>
              <p>
                {row.uniquePeople > 0
                  ? `${parseInt(
                      calculatePercentage(
                        row.uniqueConversions,
                        row.uniquePeople
                      ).toFixed(2)
                    )}%`
                  : '-'}
              </p>
            </div>
          );
        },
        width: '75px',
      },
      {
        name: 'Delivered',
        cell: (row) => {
          return (
            <div>
              <p className="text-13 text-black-400 dark:text-white">
                {row.deliveries}
              </p>
              <p>
                {row.deliveries > 0
                  ? `${Math.floor(
                      calculatePercentage(row.deliveries, row.sends)
                    )}%`
                  : '-'}
              </p>
            </div>
          );
        },
        width: '80px',
      },
      {
        name: 'Clicked',
        cell: (row) => {
          return (
            <div>
              <p className="text-13 text-black-400 dark:text-white">
                {row.uniqueClicks}
              </p>
              <p>
                {row.uniquePeople > 0
                  ? `${parseInt(
                      calculatePercentage(
                        row.uniqueClicks,
                        row.uniquePeople
                      ).toFixed(2)
                    )}%`
                  : '-'}
              </p>
            </div>
          );
        },
        width: '65px',
      },
      {
        name: 'Unsubscribed',
        cell: (row) => {
          return (
            <p className="text-13 text-black-400 dark:text-white ">
              {row.unsubscribes}
            </p>
          );
        },
        width: '100px',
      },
      {
        name: 'Status',
        cell: (row) => {
          return (
            <p
              className={`text-xs px-2 ${
                statusStyle[getWorlkflowsStatus(row.workflowId)]
              } text-center rounded`}
            >
              {getWorlkflowsStatus(row.workflowId)}
            </p>
          );
        },
        width: '86px',
      },
      {
        name: 'Actions',
        cell: (row) => {
          return (
            <Link
              to={`${workflowReports}/${row.workflowId}`}
              className="flex items-center group cursor-pointer"
            >
              <img
                src={view}
                alt="view"
                className="inline mr-1.5 dark:invert dark:brightness-0"
              />
              <span className="text-primary dark:text-white">View</span>
            </Link>
          );
        },
        width:'100px',
        center: true,
      },
    ],
    [tableData]
  );

  useEffect(() => {
    if (dashboardCtx?.workflows) {
      setTableData(dashboardCtx.workflows);
    }
  }, [dashboardCtx?.workflows]);

  return (
    <>
      <div className="flex justify-between p-4 border-b items-center">
        <p className="text-base font-medium text-darkText">
          All Workflows Table
        </p>
        <div className="flex">
          <p className="font-semimedium text-darkText text-sm">Sort by:</p>
          <div className="flex ">
            <p className="ml-2 text-secondary text-sm">Today </p>
            <img src={DropDownIcon} alt="DropDownIcon" />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 font-inter pt-3 overflow-hidden rounded-xl allWorkFlows">
        <Table
          className="rounded-t-2xl scrollbar-hide overflow-auto p-0"
          data={tableData ? tableData : []}
          columns={columns}
          progressPending={dashboardCtx?.loading}
          pagination
          paginationPerPage={5}
          customStyles={customStyles}
        />
      </div>
    </>
  );
};

export default AllWokflows;
