import Swap from 'assets/Images/Workflow/swap.png';
import ConnectedServices from 'components/Integration/Body/Miscellaneous/ConnectedServices';
import {
  RefObject,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
} from 'react';
import React from 'react';

interface Props {
  mailRef: RefObject<HTMLDivElement>;
}

const SendEmailMailer: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { mailRef },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref
) => {
  useEffect(() => {
    const handleScroll = () => {
      // Add your scroll handling logic here
    };

    if (mailRef.current) {
      mailRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (mailRef.current) {
        mailRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [mailRef]);

  return (
    <div ref={mailRef}>
      <div className="flex items-center my-6 dark:invert dark:brightness-0">
        <span className="mr-1.5">
          <img src={Swap} alt="Swap" />
        </span>
        <p className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium dark:text-white">
          Set up your mailer
        </p>
      </div>
      <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 block my-4 dark:text-white">
        Service provider
      </label>
      <div className="z-10 bg-white dark:bg-slate-800 border-gray-200 dark:border-black-400 border rounded-lg dropdown-show-hide relative h-auto">
        <ConnectedServices workflow={true} />
      </div>
    </div>
  );
};
export default forwardRef(SendEmailMailer);
