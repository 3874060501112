// import Mail from 'assets/Images/Workflow/mail.png';
// import Tabs from 'components/Tabs/Tabs';
import HeaderData from './HeaderData';
import Breadcrumb from 'components/Breadcrumbs/Breadcrumbs';
import React from "react"


const Header = () => {
  return (
    <div className="md:px-6 px-3 pt-5 bg-white dark:bg-slate-800 border-b border-gray-800 dark:border-black-400 font-inter">
      <div>
        <Breadcrumb />
        <HeaderData />
      </div>
    </div>
  );
};
export default Header;
