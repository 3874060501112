import Header from 'components/Campaigns/Header';
// import Tabs from 'components/Tabs/Tabs';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import Body from 'components/Campaigns/Body';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
// import Overview from 'assets/Images/Dashboard/overview.png';
// import Mail from 'assets/Images/svg-images/mail.svg';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';

// const TabsData = [
//   { title: 'Overview', src: Overview, searchQuery: 'overview' },
//   // { title: 'campaign Emails', src: Mail, searchQuery: 'campaign_emails' },
// ];

enum TabsEnum {
  OVERVIEW = 'overview',
  EMAILS = 'campaign_emails',
}
const Campaigns = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // const onTabChangeHandler = (tab: string) => {
  //   setSearchParams(createSearchParams({ tab: tab }));
  // };
  //TODO: Need Discussion
  useEffect(() => {
    if (!searchParams.get('tab')) {
      setSearchParams(createSearchParams({ tab: TabsEnum.OVERVIEW }));
    }
  }, [searchParams]);
  const activeTab = searchParams.get('tab') || TabsEnum.OVERVIEW;
  return (
    <ErrorBoundary>
        <div>
          <div className="md:px-6 px-3 py-5 pb-0 font-inter border-b dark:border-black-400">
            <div className="md:px-1.5">
              <div>
                <Breadcrumbs />
              </div>
              <Header />
            </div>
          </div>
          <Body activeTab={activeTab} />
        </div>
    </ErrorBoundary>
  );
};
export default Campaigns;
