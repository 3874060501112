import { FC, useContext, useState } from 'react';
import { logout } from 'services/Authentication';
import Cookies from 'js-cookie';
import LogoInitial from 'assets/Images/svg-images/logoInitial.png';
import Logo from 'assets/Images/svg-images/whitelogo-svg.png';
import Help from 'assets/Images/svg-images/help.svg';
import SettingImg from 'assets/Images/svg-images/setting.svg';
import SidebarItem from './SidebarItems';
import BoxImage from 'assets/Images/svg-images/box.svg';
import CloudImg from 'assets/Images/svg-images/cloud.svg';
import MailImg from 'assets/Images/svg-images/mail.svg';
import NotificationImg from 'assets/Images/svg-images/notification.svg';
import TargetImg from 'assets/Images/svg-images/target.svg';
import UnionImg from 'assets/Images/svg-images/union.svg';
import UserImg from 'assets/Images/svg-images/user.svg';
import Hierarchy from 'assets/Images/svg-images/hierarchy.svg';
import {
  listSegments,
  home,
  integration,
  campaigns,
  emailTemplates,
  workflow,
  login,
  subscribers,
  profile,
  eventLogs,
  reports,
} from 'services/constant/routes';
import ErrorBoundary from 'components/ErrorBoundary';
import { useNavigate } from 'react-router-dom';
import { Toastify } from 'App';
import { UserContext } from 'store/UserContext';
import { z } from 'zod';
import React from 'react';

const responseResult = z.object({ status: z.number() });
type responseStatus = z.infer<typeof responseResult>;


interface Props {
  toggleSidebar?: () => void;
  showSidebar?: boolean;
  showFullSidebar?: boolean;
  setShowFullSidebar?: (show: boolean) => void;
}

const SideBarData = [
  {
    id: '1',
    text: 'Dashboard',
    image: BoxImage,
    link: home,
  },
  {
    id: '2',
    text: 'Campaigns',
    image: UnionImg,
    link: campaigns,
    dropdown : [{title: 'Calendar', link: `${reports}?tab=calendar`}]
  },
  {
    id: '3',
    text: 'Workflows',
    image: Hierarchy,
    link: workflow,
  },
  {
    id: '4',
    text: 'Email - Templates',
    image: MailImg,
    link: emailTemplates,
  },
  {
    id: '5',
    text: 'Integrations',
    image: CloudImg,
    link: integration,
  },
  {
    id: '6',
    text: 'List & Segments',
    image: TargetImg,
    link: listSegments,
  },
  {
    id: '7',
    text: 'Profiles',
    image: UserImg,
    link: `${listSegments}/${subscribers}?${profile}=true`,
  },
  {
    id: '8',
    text: 'Pop-Ups',
    image: NotificationImg,
    link: 'https://screenpopper.com/login',
  },
  {
    id: '9',
    text: 'Event Logs',
    image: TargetImg,
    link: `${eventLogs}`,
  },
  {
    id: "10",
    text: 'Reports',
    image: NotificationImg,
    link: `${reports}?tab=analytics`,
    dropdown: [{title: 'Store Report', link: `${reports}?tab=store_report`}]
  }
];

const SideBar: FC<Props> = ({
  toggleSidebar,
  showSidebar,
  showFullSidebar,
  setShowFullSidebar,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const userCtx = useContext(UserContext);
  const navigate = useNavigate();

  const getInitials = (name: string) => {
    const words = name.split(' ');
    let initials = '';

    if (words.length > 1) {
      initials = words[0].charAt(0) + words[1].charAt(0);
    } else {
      initials = words[0].charAt(0);
    }
    return initials.toUpperCase();
  };

  const logoutUser = async () => {
    try {
      const logoutResponse = (await logout()) as responseStatus;
      if (logoutResponse.status === 200) {
        Cookies.remove('_meta_session_id_production');
        navigate(login);
      }
    } catch (error) {
      Toastify('Logging out failed', 'error', 'dropdown1');
    }
  };

  return (
    <ErrorBoundary>
      <div>
        {showSidebar && (
          <div
            className="fixed top-0 w-full h-full z-10 bg-black-200 duration-300"
            onClick={toggleSidebar}
          />
        )}
        <div
          onClick={() =>
            setShowFullSidebar && setShowFullSidebar(!showFullSidebar)
          }
          className={`${
            showFullSidebar ? 'left-[240px]' : 'left-[80px] rotate-180'
          } fixed rounded-full flex items-center bg-btnPrimary w-8 h-8 z-20 top-12 bodyShadow cursor-pointer`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="mx-auto w-6 h-6 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
        <div
          id="sidebar-mob"
          className={`h-screen sm:block sm:ml-0 bg-primary mob-sidebar ${
            showFullSidebar ? 'lg:w-64 sm:w-16 w-54' : 'w-24'
          } ${
            showSidebar ? 'sidebarscreen:left-0 ' : 'sidebarscreen:-left-56'
          } boxshadow-gray-100 font-inter fixed overflow-auto scrollbar-hide top-0 z-10`}
        >
          <div className="px-5 py-3 lg:block sm:hidden">
            <div className="w-40 pl-0.5">
              <img src={showFullSidebar ? Logo : LogoInitial} alt="Logo" />
            </div>
          </div>

          <div className="flex flex-col justify-between h-sideBarContentHeight px-2 pb-6">
            <div>
              {SideBarData.map((item) => (
                <SidebarItem
                  key={item.id}
                  link={item.link}
                  text={item.text}
                  image={item.image}
                  dropdown={item.dropdown}
                  showFullSidebar={showFullSidebar}
                />
              ))}
            </div>
            <div>
              <div className="flex justify-between items-center rounded py-2.5 pr-2.5 pl-6 mt-3 group hover:bg-primaryHover dark:hover:bg-white group">
                <div className="flex items-center">
                  <span>
                    <img
                      className="w-5 h-5 brightness-0 invert group-hover:invert group-hover:brightness-0 dark:brightness-0 dark:invert dark:group-hover:invert-0 dark:group-hover:brightness-100"
                      src={SettingImg}
                      alt="Setting"
                    />
                  </span>
                  <span
                    className={`${showFullSidebar ? 'lg:block sm:hidden' : 'hidden'
                      } text-15 font-medium leading-3 text-foreground dark:text-white dark:group-hover:text-black-400 pl-3 group-hover:text-white`}
                  >
                    Settings
                  </span>
                </div>
              </div>

              <div>
                <a className="flex justify-between items-center rounded py-2.5 pr-2.5 pl-6 mt-3 group hover:bg-primaryHover dark:hover:bg-white group">
                  <div className="flex items-center">
                    <span>
                      <img
                        className="w-5 h-5 brightness-0 invert group-hover:invert group-hover:brightness-0 dark:brightness-0 dark:invert dark:group-hover:invert-0 dark:group-hover:brightness-100"
                        src={Help}
                        alt="Help"
                      />
                    </span>
                    <span
                      className={`${showFullSidebar ? 'lg:block sm:hidden' : 'hidden'
                        } text-15 font-medium leading-3 text-foreground dark:group-hover:text-black-400 dark:text-white pl-3 group-hover:text-white`}
                    >
                      Get Help
                    </span>
                  </div>
                </a>
              </div>

              <div className="flex items-center mt-6 mb-5 justify-between pr-4 cursor-pointer select-none">
                <div className="flex items-center gap-2 pr-4 ">
                  <div className="w-10 h-10 rounded-full flex items-center justify-center bg-primaryHover">
                    <p className="text-[17.5px] font-interMedium text-foreground">
                      {userCtx?.currentUserData &&
                        getInitials(
                          userCtx?.currentUserData?.first_name.concat(
                            ` ${userCtx?.currentUserData?.last_name}`
                          )
                        )}
                    </p>
                  </div>
                  <div
                    className={`${
                      showFullSidebar ? 'lg:block sm:hidden' : 'hidden'
                    }`}
                    onClick={() => setShowDropdown((prev) => !prev)}
                  >
                    <p className="text-xs font-medium text-foreground">
                      {userCtx?.currentUserData?.first_name +
                        ' ' +
                        userCtx?.currentUserData?.last_name}
                    </p>
                    <p className="text-foreground text-xxs">
                      {userCtx?.currentUserData?.email}
                    </p>
                  </div>
                </div>
                <div onClick={() => setShowDropdown((prev) => !prev)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-foreground"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
                {showDropdown && <div onClick={logoutUser} className='right-6 absolute bg-primaryHover h-8 mt-16 rounded-md pl-6 flex items-center w-32 text-white'>Logout</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default SideBar;
