import {
  WorkflowType,
  EventInitialConditionType,
  SubscriberConditionType,
  WorkflowAction,
  WorkflowCondition,
  AllActionType,
} from './index.type';
import { FC, useContext, useEffect, useState } from 'react';
import PreviewHeader from './PreviewHeader';
import Node from './Node';
import StartTrigger from './StartTrigger';
import { WorkflowContext } from 'store/WorkflowContext';
import Plus from 'assets/Images/svg-images/plus.svg';

import React from "react"
import { useLocation } from 'react-router-dom';

export const getConditionGroupName = (
  workflow: WorkflowCondition | WorkflowAction
) => {
  return getGroupName(workflow);
};

const getGroupName = (group: WorkflowCondition | WorkflowAction) => {
  if ('conditionGroup' in group) {
    if (group.conditionGroup) {
      if ('eventName' in group.conditionGroup) {
        return group.conditionGroup.eventName;
      } else if ('conditions' in group.conditionGroup) {
        return 'Subscriber Matched ? ';
      } else if ('tags' in group.conditionGroup) {
        return 'Tagged ?';
      } else if ('listCondition' in group.conditionGroup) {
        return 'List Membership ?';
      } else if ('webhookUrl' in group.conditionGroup) {
        return 'Webhook Response';
      }
    }
  }
};

//look this function again
export const getActionName = (workflow: WorkflowAction | undefined) => {
  if (workflow) {
    return getName(workflow.action);
  }
};

const getName = (action: AllActionType | undefined) => {
  if (action) {
    if ('subject' in action) {
      return action.subject;
    } else if ('length' in action) {
      return 'Delay' + ' ' + action.length + ' ' + action.delayUnit._type;
    }
  }
};

export const isFirstActionNode = (
  workflow: WorkflowAction | WorkflowCondition
) => {
  return isActionNode(workflow);
};

const isActionNode = (
  value: WorkflowAction | WorkflowCondition
): value is WorkflowAction => {
  return 'action' in value;
};

const getInitialConditionName = (
  initialGroup: EventInitialConditionType | SubscriberConditionType
) => {
  return isInitialSubscriber(initialGroup) ? initialGroup.eventName : 'Tagged';
};

const isInitialSubscriber = (
  value: EventInitialConditionType | SubscriberConditionType
): value is EventInitialConditionType => {
  return 'eventName' in value;
};

interface WorkflowActionType {
  [key: string]: string;
  AddToListActionNode: string;
  WebhookActionNode: string;
  RemoveFromListActionNode: string;
  PublishEventActionNode: string;
  UnsubscribeNode: string;
  ExecuteScriptNode: string;
  UpdateScoreActionNode: string;
  NotifyByEmailActionNode: string;
}

export const workflowActionType: WorkflowActionType = {
  AddToListActionNode: 'Add to List',
  WebhookActionNode: 'Send to Webhook',
  RemoveFromListActionNode: 'Remove From List',
  PublishEventActionNode: 'Publish Event',
  UnsubscribeNode: 'Unsubscribe',
  ExecuteScriptNode: 'Execute Script',
  UpdateScoreActionNode: 'Update Score',
  NotifyByEmailActionNode: 'Notify By Email',
};

interface Props{
 onSave: () => void
}

const WorkflowPreview:FC<Props> = ({onSave}) => {
  const [workflowData, setWorkflowData] = useState<WorkflowType | undefined>();
  const workflowCtx = useContext(WorkflowContext);
  const location = useLocation();
  const [isCreate, setIsCreate] = useState<boolean>(location.pathname.includes('/create'));
  const [zoomLevel, setZoomLevel] = useState<number>(100);

  const handleZoomIn = () => {
    setZoomLevel(prevZoomLevel => prevZoomLevel + 10);
  };

  const handleZoomOut = () => {
    setZoomLevel(prevZoomLevel => prevZoomLevel - 10); 
  };
  useEffect(() => {
    setWorkflowData(workflowCtx?.workflow);
  }, [workflowCtx?.workflow]);


  return (
    <div className="mt-8">
      { <PreviewHeader onSave={onSave}/>}
      {isCreate ? (
        <StartTrigger onSaveData={() => setIsCreate(false)} />
      ) : workflowData ? (
      <div className="border border-gray-100 rounded-lg boxShadow border-md bg-white py-6 px-4 h-full bg-dot-pattern overflow-auto">
        <div className='flex max-w-[200px] ml-auto'>
          <button
              onClick={handleZoomIn}
              className="transition ease-in-out delay-150 duration-300 fs-13 font-medium leading-5 text-white flex items-center ml-auto rounded-full py-3 sm:pl-4 px-3 sm:pr-3.5 font-inter bg-btnPrimary hover:bg-primary hover:scale-105 ease-in duration-300"
          >
            <img className="mr-2.5" src={Plus} alt="Plus" /> 
          </button>
          <button
              onClick={handleZoomOut}
              className="transition ease-in-out delay-150 duration-300 fs-13 font-medium leading-5 text-white flex items-center ml-auto rounded-full py-3 sm:pl-4 px-3 sm:pr-3.5 font-inter bg-btnPrimary hover:bg-primary hover:scale-105 ease-in duration-300"
          >
            <img className="mr-2.5" src={Plus} alt="Plus" /> 
          </button>
        </div> 
          <div className="w-[1125px] mx-auto px-1 pt-7" style={{ zoom: `${zoomLevel}%` }}>
            <Node
              nodeName={getInitialConditionName(
                workflowData.initialConditionGroup
              )}
              initialNode={true}
              workflow={workflowData.workflow}
            />
          </div>
      </div>
      ) : (
        <>
        </>
      )}
    </div>
  );
};
export default WorkflowPreview;
