import React, { FC, useEffect, useState } from 'react';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import styled from "styled-components";

interface Props {
  tagInputs: string[] | undefined;
  onChange: (tags: string[]) => void;
}

const StyledWrapper = styled.div`
  .react-tagsinput {
    overflow-y: auto;
    max-height: 44px;
    min-height: 44px;
    border-color: #bdbdbd;
    border-radius: 4px;
  }

  .react-tagsinput::-webkit-scrollbar {
    display: none;
}

  .react-tagsinput-tag {
    background-color: #3575d5;
    color: white;
    border: 0px;
    border-radius: 40px;
    padding: 4px 8px;
  }

  .react-tagsinput {
    height: 100%;
    width: 100%;
  }

`

const TagInput: FC<Props> = ({ tagInputs, onChange }) => {
  const [tags, setTags] = useState<Array<string>>([]);

  useEffect(() => {
    if (tagInputs) {
      setTags(tagInputs ? tagInputs : []);
    }
  }, [tagInputs]);
  
  return (
    <StyledWrapper>
    <div className="text-13 font-medium leading-3 text-gray-500 shadow-sm shadow-gray-200 rounded w-full h-full overflow-hidden">
      <TagsInput value={tags} onChange={onChange} addOnBlur={true} placholder="Add Tags"/>
    </div>
    </StyledWrapper>
  );
};
export default TagInput;
