import { FC, useContext, useEffect, useRef, useState } from 'react';
import SaveModal from './SaveModal';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createCampaign, emailTemplates } from 'services/constant/routes';
import BackImage from 'assets/Images/Header/back.png';
import { EmailContext } from 'store/EmailContext';
import { ThemeContext } from 'store/ThemeContext';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React from 'react';

interface Props {
  draft?: string;
  onSaveData?: (name: string) => void;
  name: string;
  labels: string;
  loading: boolean;
  onBackToCreation?: () => void;
  view?: string;
  createEmailSubmitted?: boolean;
  showEmailModal?: () => void;
}

const Editor: FC<Props> = ({
  onSaveData,
  name,
  labels,
  loading,
  onBackToCreation,
  view,
  draft,
  createEmailSubmitted,
  showEmailModal,
}) => {
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [savedEmail, setSavedEmail] = useState<string>('');
  const [emailCreateMode, setEmailCreateMode] = useState<boolean>(false);
  const emailCtx = useContext(EmailContext);
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx?.theme;
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);
  const { id } = useParams();

  useOutsideClick(modalRef, () => {
    setShowSaveModal(false);
  });

  const continueButtonClickHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (loading) {
      e.preventDefault();
    }
    if (
      (view === 'emailSelected' || view === 'create') &&
      !createEmailSubmitted
    ) {
      e.preventDefault();
      showEmailModal && showEmailModal();
    }
  };

  useEffect(() => {
    if (emailCtx?.htmlSubmitted) {
      setEmailCreateMode(true);
    }
  }, [emailCtx?.htmlSubmitted]);

  const mobileViewHandler = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.StripoApi.compileEmail(function (
      error: string,
      html: string,
      ampHtml: string
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.ExternalPreviewPopup.openPreviewPopup(html, ampHtml);
    });
  };

  const submitHtmlHandler = () => {
    if (!loading) {
      setShowSaveModal((prev) => !prev);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.StripoApi.getTemplate((email: string) => {
        setSavedEmail(email);
      });
    }
  };

  return (
    <div className="text-center h-auto bg-[#ddebff7a] dark:bg-slate-800 pb-8">
      <div className="max-w-7xl w-full mx-auto p-4">
        <div className="flex justify-end">
          <span id="changeHistoryContainer" style={{ display: 'none' }}>
            Last change: <a href="dev1.ryzeo.com" id="changeHistoryLink"></a>
          </span>
          <button type="button" id="undoButton" className="control-button">
            Undo
          </button>
          <button type="button" id="redoButton" className="control-button">
            Redo
          </button>
          <button
            id="codeEditor"
            className={`${
              loading
                ? 'ease-in duration-300 border border-gray-800 bg-gray-200 bg:bg-gray-300 text-black-400'
                : 'ease-in duration-300 text-white bg-primary hover:bg-primaryHover hover:scale-105'
            } ease-in duration-300 text-sm font-medium leading-6 rounded-md h-11 w-28 p-2`}
            disabled={loading}
          >
            Code editor
          </button>
          <div ref={modalRef} className="relative">
            <button
              id="template"
              onClick={submitHtmlHandler}
              className={` ${
                loading
                  ? 'ease-in duration-300 border border-gray-800 bg-gray-200 bg:bg-gray-300 text-black-400'
                  : 'ease-in duration-300 text-white bg-primary hover:bg-primaryHover hover:scale-105'
              } ml-4 ease-in duration-300 text-sm font-medium leading-6 rounded-md h-11 w-28 py-2 px-4`}
            >
              Save Html
            </button>
            {showSaveModal && (
              <div>
                <SaveModal
                  onClose={() => setShowSaveModal(false)}
                  email={savedEmail}
                  onSaveData={onSaveData}
                  name={name}
                  labels={labels}
                  view={view}
                />
              </div>
            )}
          </div>
          <button
            onClick={mobileViewHandler}
            className={`${
              loading
                ? 'border border-gray-800 bg-gray-200 text-black-400'
                : 'text-white bg-btnPrimary ease-in-in duration-300 hover:bg-primary hover:scale-105'
            } ml-4 text-sm font-medium leading-5 rounded-md py-2 px-4 `}
            disabled={loading}
          >
            Mobile View
          </button>
        </div>
      </div>
      <div className="max-w-7xl w-full mx-auto rounded-2xl flex flex-col p-8 bg-[#e4ebf3] dark:bg-slate-800">
        <div className={`flex justify-center ${loading ? 'block' : ''}`}>
          <div
            id="stripoSettingsContainer"
            style={{
              backgroundColor: `${theme === 'dark' ? '#1e293b' : '#ededed'}`,
            }}
          ></div>
          <div
            id="stripoPreviewContainer"
            style={{
              backgroundColor: `${theme === 'dark' ? '#1e293b' : '#ededed'}`,
            }}
          ></div>
        </div>
      </div>
      {draft === '' && (
        <div className="flex justify-between w-full my-5 max-w-7xl mx-auto px-4">
          <div
            onClick={() => {
              if (!loading) {
                if (emailCreateMode) {
                  onBackToCreation && onBackToCreation();
                } else {
                  navigate(emailTemplates);
                }
              }
            }}
            className={`${
              loading
                ? 'border border-gray-800 bg-gray-200 text-black-400'
                : 'hover:scale-105 hover:bg-primary text-primary'
            } inline-block flex items-center text-sm w-44 cursor-pointer dark:bg-white dark:hover:bg-primary hover:text-white text-sm font-medium leading-5  py-2.5 px-2 border-2 border-primary rounded-md mr-4  ease-in duration-300 h-11`}
          >
            <img className="mt-0.5 mr-2 p-2" src={BackImage} />
            <span>Go back a step</span>
          </div>
          {id && (
            <Link
              onClick={continueButtonClickHandler}
              className={`${
                loading
                  ? 'border border-gray-800 bg-gray-200 text-black-400'
                  : 'text-white bg-primary hover:bg-primaryHover'
              } text-sm font-medium leading-5 rounded-md py-1.5 px-4 w-28 h-11 ease-in duration-300 hover:scale-105 cursor-pointer`}
              to={`${createCampaign}/${id}`}
            >
              Continue
            </Link>
          )}
        </div>
      )}
    </div>
  );
};
export default Editor;
