import Header from 'components/DashboardReports/Header';
import Tabs from 'components/Tabs/Tabs';
import Body from 'components/DashboardReports';
import Breadcrumb from 'components/Breadcrumbs/Breadcrumbs';
import Overview from 'assets/Images/Dashboard/overview.png';
// import Activity from 'assets/Images/Dashboard/activity.png';
import Calendar from "assets/Images/Dashboard/calender.png"
import React, { useEffect } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';

export enum TabsEnum {
  ANALYTICS = 'analytics',
  STORE_REPORT = 'store_report',
  CALENDAR = 'calendar',
  ACTIVITY = 'activity',
}

export const TabsData = [
  { title: 'Analytics', searchQuery: TabsEnum.ANALYTICS, src: Overview },
  { title: 'Store Report', searchQuery: TabsEnum.STORE_REPORT, src: Overview },
  { title: 'Calendar', searchQuery: TabsEnum.CALENDAR, src: Calendar },
  //{ title: 'Activity', searchQuery: TabsEnum.ACTIVITY , src: Calendar },
];

const Reports = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  
  const onTabChangeHandler = (tabQuery: string) => {
    setSearchParams(createSearchParams({ tab: tabQuery }));
  };

  useEffect(() => {
    if (!searchParams.get('tab')) {
      setSearchParams(createSearchParams({ tab: TabsEnum.ANALYTICS }));
    }
  }, [searchParams]);
  
  const activeTab = searchParams.get('tab') || TabsEnum.ANALYTICS;

  return (
    <div>
      <div className="md:px-6 px-3 pt-5 bg-contentBg dark:bg-slate-800 font-inter">
        <div>
          <Breadcrumb />
          <Header />
          <Tabs
            tabs={TabsData}
            activeTab={activeTab}
            onTabChange={onTabChangeHandler}
          />
        </div>
      </div>
      <Body activeTab={activeTab} />
    </div>
  );
};
export default Reports;
