import Dropdown from 'components/Dropdown/SearchDropdown';
import {
  getBlastConversionEventOptions,
  getBlastConversionEvent,
  updateBlastConversionEvent,
} from 'services/apiHandlers/Integration/Miscellaneous';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { useFormik } from 'formik';
import Skeleton from 'react-loading-skeleton';
import { Toastify } from 'App';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';

const eventExtraOptions = [
  'insights_email_send',
  'insights_email_view',
  'insights_email_click',
  'ss_email_sent',
  'ss_added_to_list',
  'ss_unsubscribed',
  'ss_email_viewed',
  'ss_email_link_clicked',
  'ss_form_signup',
];

const conversionEventOptionsResponseSchema = z.object({
  data: z.array(z.string()),
  status: z.number(),
});
type conversionEventOptionsResponse = z.infer<
  typeof conversionEventOptionsResponseSchema
>;

const conversionEventResponseSchema = z.object({
  data: z.string(),
  status: z.number(),
});
type conversionEventResponse = z.infer<typeof conversionEventResponseSchema>;

const updatedConversionEventResponseSchema = z.object({ status: z.number() });
type updatedConversionEventResponse = z.infer<
  typeof updatedConversionEventResponseSchema
>;

const ConversionEvent = () => {
  const [conversionEventOptions, setConversionEventOptions] = useState<
    Array<string>
  >([]);
  const [conversionEvent, setConversionEvent] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const getData = async () => {
    const getConversionEventsResponse =
      (await getBlastConversionEvent()) as conversionEventResponse;
    if (getConversionEventsResponse.status === 200) {
      setConversionEvent(getConversionEventsResponse.data);
    }
    const conversionEventOptionsresponse =
      (await getBlastConversionEventOptions()) as conversionEventOptionsResponse;
    if (conversionEventOptionsresponse.status === 200) {
      setConversionEventOptions([
        ...conversionEventOptionsresponse.data,
        ...eventExtraOptions,
      ]);
    }
  };

  const dropdownChangeHandler = (option: string) => {
    formik.setFieldValue('conversionEvent', option);
    setConversionEvent(option);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      conversionEvent: conversionEvent,
    },

    onSubmit: async (values) => {
      setLoading(true);
      try {
        const data = { conversionEvent: values.conversionEvent };
        const updateConversionEventResponse = (await updateBlastConversionEvent(
          data
        )) as updatedConversionEventResponse;
        if (updateConversionEventResponse.status === 200) {
          Toastify('Conversion Event Updated Sucessfully', 'success', 'event1');
        }
      } catch (error) {
        Toastify('Updating Conversion Event Failed', 'error', 'event2');
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    getData();
  }, []);

  return (
    <ErrorBoundary>
      <form
        onSubmit={formik.handleSubmit}
        className="p-4 bg-gray-150 dark:bg-slate-800 rounded border border-gray-800 dark:border-black-400 mt-8"
      >
        <h3 className="text-13 font-semibold leading-4  text-black-700 dark:text-white mb-2">
          Conversion Event for Blasts
        </h3>
        {loading ? (
          <Skeleton />
        ) : (
          <Dropdown
            options={conversionEventOptions}
            onSelect={dropdownChangeHandler}
            value={conversionEvent}
          />
        )}
        <div className="mt-4">
          <button
            type="submit"
            className={`sm:py-2.5 py-1.5 sm:px-4 px-2 sm:text-13 text-xs font-medium leading-4 text-white rounded-md bg-btnPrimary ease-in-in duration-300 hover:bg-primary hover:scale-105 ${
              loading ? 'bg-gray-700' : 'bg-primary'
            } `}
            disabled={loading ? true : false}
            data-pw="update-conversion-event"
          >
            Update
          </button>
        </div>
      </form>
    </ErrorBoundary>
  );
};
export default ConversionEvent;
