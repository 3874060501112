import { FC } from "react";
import {z} from "zod";
import React from "react";

const ConversionSchema = z.object({
  workflows: z.number(),
  campaigns: z.number(),
});
type Conversion = z.infer<typeof ConversionSchema>;

interface Props{
  totalOrders: string|undefined,
  conversions: Conversion
}
const OrderStats:FC<Props> = ({totalOrders,conversions}) => {
  return (
    <div className="revenue-left w-full max-w-lg pt-6 xl:px-0 xl:pb-0 pb-6 sm:px-10 px-5">
      <div className="mb-14">
        <h3 className="text-sm text-headingColor dark:text-white font-semibold tracking-wide leading-4 mb-3">
          Total Orders
        </h3>
        <h2 className="font-medium text-26 text-lightParagraphColor dark:text-white leading-7">
          {totalOrders}
        </h2>
      </div>
      <div className="flex justify-between pt-5">
        <div>
          <h3 className="text-sm text-headingColor dark:text-white font-medium tracking-wide leading-4 pb-3">
            From Workflows
          </h3>
          <h2 className="font-medium text-26 text-lightParagraphColor dark:text-white leading-7">
            {conversions.workflows}
          </h2>
        </div>
        <div>
          <h3 className="text-sm text-headingColor dark:text-white font-medium tracking-wide leading-4 pb-3">
            From Campaigns
          </h3>
          <h2 className="font-medium text-26 text-lightParagraphColor dark:text-white leading-7 ">
            {conversions.campaigns}
          </h2>
        </div>
      </div>
    </div>
  );
};
export default OrderStats;
