import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { DashboardContext } from 'store/DashboardContext';
import Form from '../Common/Form';
import Table from 'components/Table';
import view from 'assets/Images/Dashboard/view.png';
import styled from 'styled-components';
import { calculatePercentage, tableSort } from 'utils/common';
import { ThemeContext } from 'store/ThemeContext';
import { SingleWorkflow, Workflows } from './Types';
import moment from 'moment';
import React from 'react';
import { getCsvExport } from 'services/apiHandlers/Dashboard/StoreReport';
import { Link } from 'react-router-dom';
import { workflowReports } from 'services/constant/routes';

const AllWorkflows: FC = () => {
  const dashboardCtx = useContext(DashboardContext);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [tableData, setTableData] = useState<Workflows | undefined>(
    dashboardCtx?.workflows
  );
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx?.theme;

  const workflowSort = (rowA: SingleWorkflow, rowB: SingleWorkflow) => {
    return tableSort(rowA.workflowName, rowB.workflowName);
  };

  const columns: TableColumn<SingleWorkflow>[] = useMemo(
    () => [
      {
        name: 'Workflow',
        cell: (row) => {
          return (
            <Link
              to={`${workflowReports}/${row.workflowId}`}
              className="flex items-center overflow-auto w-full min-w-[269px] w-[269px] py-2"
            >
              {row.workflowName}
            </Link>
          );
        },
        sortable: true,
        sortFunction: workflowSort,
      },
      {
        name: 'Recipients',
        cell: (row) => {
          return (
            <p className="text-13 text-black-400 dark:text-white py-4">
              {row.uniquePeople}
            </p>
          );
        },
      },
      {
        name: 'Converted',
        cell: (row) => {
          return (
            <div>
              <p className="text-13 text-black-400 dark:text-white py-4 inline-block">
                {row.uniqueConversions}
              </p>
              <p>
                {row.uniquePeople > 0
                  ? `${parseInt(
                      calculatePercentage(
                        row.uniqueConversions,
                        row.uniquePeople
                      ).toFixed(2)
                    )}%`
                  : '-'}
              </p>
            </div>
          );
        },
      },
      {
        name: 'Delivered',
        cell: (row) => {
          return (
            <div>
              <p className="text-13 text-black-400 dark:text-white py-4">
                {row.deliveries}
              </p>
              <p>
                {row.deliveries > 0
                  ? `${Math.floor(
                      calculatePercentage(row.deliveries, row.sends)
                    )}%`
                  : '-'}
              </p>
            </div>
          );
        },
      },
      {
        name: 'Clicked',
        cell: (row) => {
          return (
            <div>
              <p className="text-13 text-black-400 dark:text-white py-2">
                {row.uniqueClicks}
              </p>
              <p>
                {row.uniquePeople > 0
                  ? `${parseInt(
                      calculatePercentage(
                        row.uniqueClicks,
                        row.uniquePeople
                      ).toFixed(2)
                    )}%`
                  : '-'}
              </p>
            </div>
          );
        },
      },
      {
        name: 'Unsubscribed',
        cell: (row) => {
          return (
            <p className="text-13 text-black-400 dark:text-white py-2">
              {row.unsubscribes}
            </p>
          );
        },
      },
      {
        name: 'Actions',
        cell: (row) => {
          return (
            <Link
              to={`${workflowReports}/${row.workflowId}`}
              className="flex items-center mr-4 group cursor-pointer"
            >
              <img
                src={view}
                alt="view"
                className="inline mr-1.5 dark:invert dark:brightness-0"
              />
              <span className="text-primary dark:text-white">View Details</span>
            </Link>
          );
        },
      },
    ],
    []
  );

  const StyledWrapper = styled.div`
    .rdt_TableHeadRow .rdt_TableCol div {
      color: ${theme === 'dark' ? 'white !important' : ''};
    }

    .allWorkFlows div[data-column-id='1'] {
      min-width: 25.3%;
      width: 25.3%;
      max-width: 25.3%;
      font-size: 14px;
      font-weight: 500;
      color: ${theme === 'dark' ? 'white' : '#757575'};
      border-right: 1px solid rgb(229 231 235);
      overflow: unset;
    }

    .allWorkFlows div[data-column-id='1'] div {
      width: 100%;
      min-width: 100%;
      border: unset;
      justify-content: space-between;
    }

    .allWorkFlows div[data-column-id='1'] div div {
      width: auto;
      min-width: auto;
    }

    .allWorkFlows div[data-column-id='2'] {
      min-width: 13%;
      width: 13%;
      max-width: 13%;
      font-size: 13px;
      font-weight: 500;
      color: #757575;
      overflow: unset;
    }

    .allWorkFlows div[data-column-id='2'] div {
      width: 100%;
      min-width: 100%;
      border: unset;
      justify-content: space-between;
    }

    .allWorkFlows div[data-column-id='2'] div div {
      width: auto;
      min-width: auto;
    }

    .allWorkFlows div[data-column-id='3'] {
      min-width: 13%;
      width: 13%;
      max-width: 13%;
      font-size: 13px;
      font-weight: 500;
      color: #757575;
      overflow: unset;
    }

    .allWorkFlows div[data-column-id='3'] div {
      width: 100%;
      min-width: 100%;
      border: unset;
      justify-content: space-between;
    }

    .allWorkFlows div[data-column-id='3'] div div {
      width: auto;
      min-width: auto;
    }

    .allWorkFlows div[data-column-id='4'] {
      min-width: 10.4%;
      width: 10.4%;
      max-width: 10.4%;
      font-size: 13px;
      font-weight: 500;
      color: #757575;
      overflow: unset;
    }

    .allWorkFlows div[data-column-id='4'] div {
      width: 100%;
      min-width: 100%;
      border: unset;
      justify-content: space-between;
    }

    .allWorkFlows div[data-column-id='4'] div div {
      width: auto;
      min-width: auto;
    }

    .allWorkFlows div[data-column-id='5'] {
      min-width: 10.4%;
      width: 10.4%;
      max-width: 10.4%;
      font-size: 13px;
      font-weight: 500;
      color: #757575;
      overflow: unset;
    }

    .allWorkFlows div[data-column-id='5'] div {
      width: 100%;
      min-width: 100%;
      border: unset;
      justify-content: space-between;
    }

    .allWorkFlows div[data-column-id='5'] div div {
      width: auto;
      min-width: auto;
    }

    .allWorkFlows div[data-column-id='6'] {
      min-width: 13.3%;
      width: 13.3%;
      max-width: 13.3%;
      font-size: 13px;
      font-weight: 500;
      color: #757575;
      border-left: 1px solid rgb(229 231 235);
      overflow: unset;
    }

    .allWorkFlows div[data-column-id='6'] div {
      width: 100%;
      min-width: 100%;
      border: unset;
      justify-content: space-between;
    }

    .allWorkFlows div[data-column-id='6'] div div {
      width: auto;
      min-width: auto;
    }

    .allWorkFlows div[data-column-id='7'] {
      min-width: 14.6%;
      width: 14.6%;
      max-width: 14.6%;
      font-size: 13px;
      font-weight: 500;
      color: #757575;
      border-left: 1px solid rgb(229 231 235);
      overflow: unset;
    }

    @media (max-width: 1024px) {
      .allWorkFlows div[data-column-id='1'] {
        min-width: 21%;
        width: 21%;
        max-width: 21%;
      }

      .allWorkFlows div[data-column-id='2'] {
        min-width: 15%;
        width: 15%;
        max-width: 15%;
      }

      .allWorkFlows div[data-column-id='4'] {
        min-width: 12%;
        width: 12%;
        max-width: 12%;
      }

      .allWorkFlows div[data-column-id='5'] {
        min-width: 12%;
        width: 12%;
        max-width: 12%;
      }

      .allWorkFlows div[data-column-id='6'] {
        min-width: 130px;
        width: 130px;
        max-width: 130px;
      }

      .allWorkFlows div[data-column-id='7'] {
        min-width: 200px;
        width: 200px;
        max-width: 200px;
      }
    }
  `;

  const onExportClickHandler = async () => {
    const currentDate = moment().format('YYYY-MM-DD');
    const previousDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
    try {
      getCsvExport(previousDate, currentDate);
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  useEffect(() => {
    let tempWorkflows = dashboardCtx?.workflows;
    if (searchQuery) {
      tempWorkflows =
        tempWorkflows &&
        tempWorkflows.filter((workflow) =>
          workflow.workflowName.toLowerCase().includes(searchQuery)
        );
    }
    setTableData(tempWorkflows);
  }, [searchQuery, dashboardCtx?.workflows]);

  return (
    <>
      <Form
        heading="All Workflows"
        setSearchQuery={setSearchQuery}
        hideFilter={true}
        onSelectExport={onExportClickHandler}
      />
      <StyledWrapper>
        <div className="grid grid-cols-1 gap-4 font-inter pt-3 overflow-hidden rounded-xl allWorkFlows">
          <Table
            className="rounded-t-2xl scrollbar-hide overflow-auto"
            data={tableData ? tableData : []}
            columns={columns}
            progressPending={dashboardCtx?.loading}
          />
        </div>
      </StyledWrapper>
    </>
  );
};

export default AllWorkflows;
