import { FC, useContext, useState } from 'react';
import { WorkflowContext } from 'store/WorkflowContext';
import Modal from 'components/Alert/Modal';
import SaveModal from './Modals/SaveModal';
import React from "react"

interface Props{
  onSave: () => void
}

const PreviewHeader:FC<Props> = ({onSave}) => {
  const workflowCtx = useContext(WorkflowContext);
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <div className="flex justify-between items-center mb-4">
      <Modal isOpen={showModal}>
        <SaveModal onClose={() => setShowModal(false)} onSave={onSave}/>
      </Modal>
      <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black dark:text-white font-medium">
        Workflow Preview
      </h2>
      <button
        type="button"
        className={`text-sm font-medium leading-5 rounded-md px-4 py-2.5 w-28 h-11 border border-primary ease-in duration-300 ${
          !workflowCtx?.workflow?.workflow || !workflowCtx.workflow
            ? ' border-gray-800 bg-gray-200 text-black-400'
            : 'bg-btnPrimary hover:bg-primary text-white hover:scale-105'
        } `}
        onClick={() => setShowModal(true)}
        disabled={!workflowCtx?.workflow?.workflow || !workflowCtx.workflow}
      >
        Save
      </button>
    </div>
  );
};
export default PreviewHeader;
