import { FC } from 'react';
import { useLocation,Link } from 'react-router-dom';
import { z } from 'zod';
import React from 'react';
import { listSegments, segmentDefinition } from 'services/constant/routes';

export const SegmentSchema = z.object({
  segment_json: z.string(),
  elastic_json: z.string(),
  segment_name: z.string(),
  subscriber_count: z.number(),
  status: z.string(),
});
export type Segment = z.infer<typeof SegmentSchema>;

interface Props {
  //TODO: Check if we can use them in single function
  addSegment: () => void;
}

const Footer: FC<Props> = ({ addSegment }) => {
  const {pathname} = useLocation();
  let create;
  if(pathname.includes('/create')){
    create = true;
  }
  return (
    <div>
      <div onClick={addSegment} className="text-right mt-5">
        <button
          type="button"
          className="py-2.5 px-4 h-11 sm:text-13 text-xs font-medium leading-4 text-white rounded-md bg-btnPrimary ease-in-in duration-300 hover:bg-primary hover:scale-105"
        >
          Add More Group
        </button>
      </div>
      <div className="mt-6 flex justify-end items-center">
        <Link
        to={`${listSegments}/${segmentDefinition}`}
          className="py-2.5 px-4 h-11 w-28 sm:text-sm text-xs font-medium leading-5 text-primary border-2 border-primary rounded-md hover:bg-primary hover:text-white bg-white mr-3 ease-in duration-300 hover:scale-105"
          data-pw="cancel-segment"
        >
          Cancel
        </Link>
        <div>
          <button
            data-pw={create ? '' : 'edit-segment-button'}
            type="submit"
            className="py-2.5 px-4 h-11 sm:text-sm text-xs font-medium leading-4 text-white rounded-md bg-btnPrimary ease-in-in duration-300 hover:bg-primary hover:scale-105"
          >
            {create ? 'Save Segment' : 'Edit Segment'}
          </button>
        </div>
      </div>
    </div>
  );
};
export default Footer;
