import { FC, useContext, useEffect, useState } from 'react';
import EmailPerformance from './EmailPerformance';
import { DashboardContext } from 'store/DashboardContext';
import averageOpenRate from '../../../../assets/Images/Dashboard/average-open-rate.svg';
import {
  calculateNumberFromPercentage,
  convertStringToWholeNumber,
} from 'utils/common';
import React from 'react';
import { z } from 'zod';

const EmailDataSchema = z.object({
  title: z.string(),
  percent: z.string(),
  openings: z.string().optional(),
  id: z.string(),
});
type EmailData = z.infer<typeof EmailDataSchema>;

const AverageOpenRate: FC = () => {
  const [emailPerformanceData, setEmailPerformanceData] = useState<
    Array<EmailData>
  >([]);
  const dashboardCtx = useContext(DashboardContext);

  useEffect(() => {
    setEmailPerformanceData([
      {
        title: 'Average Open Rate',
        percent: `${
          dashboardCtx?.weeklyRoiData?.email_open
            ? convertStringToWholeNumber(dashboardCtx.weeklyRoiData.email_open)
            : 0
        }%`,
        openings: `${
          dashboardCtx?.weeklyRoiData?.email_open
            ? calculateNumberFromPercentage(
                parseFloat(dashboardCtx?.weeklyRoiData?.email_open),
                parseFloat(dashboardCtx?.weeklyRoiData?.email_recipients)
              )
            : 0
        }`,

        id: '1',
      },
    ]);
  }, [dashboardCtx?.weeklyRoiData]);
  return (
    <EmailPerformance emailPerformance={emailPerformanceData} image={averageOpenRate}/>
  );
};
export default AverageOpenRate;
