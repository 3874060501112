import Upload from 'assets/Images/Campaign/upload.png';
import UploadFileImg from 'assets/Images/Subscribers/upload2.png';
import Plus from 'assets/Images/svg-images/plus.svg';
import { FC, useContext, useState } from 'react';
import { uploadFile } from 'services/apiHandlers/Campaigns/Emails';
import { EmailContext } from 'store/EmailContext';
import { SubmitEmailResponse as EmailRespnse } from './CopyPasteHtml';
import React from "react";
import { useNavigate } from 'react-router-dom';
import { emailEditor, create } from 'services/constant/routes';
interface Props {
  showContent: boolean;
  onUploadFile: () => void;
}
const UploadFile: FC<Props> = ({ showContent, onUploadFile }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<string>();
  const emailCtx = useContext(EmailContext);
  const navigate = useNavigate()

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      setSelectedFile(fileList[0]);
    } else {
      setSelectedFile(null);
    }
  };

  const handleUploadFile = async () => {
    if (selectedFile === null) {
      setError('Please Specify a file to upload');
    }

    if (selectedFile) {
      if (selectedFile?.type !== 'text/html') {
        setError(
          'Only text or html files can be used to create your e-mail message'
        );
      } else {
        try {
          const uploadFileResponse = (await uploadFile(
            selectedFile
          )) as EmailRespnse;
          if (uploadFileResponse.status === 200) {
            emailCtx?.htmlSubmitHandler(uploadFileResponse.data.draft.email.html_part);
            navigate(`${emailEditor}?${create}=true`)
          }
        } catch (error) {
          setError('Error occurred while uploading file');
        }
      }
    }
  };

  return (
    <div>
      <div
        onClick={onUploadFile}
        className="ml-7 p-2 flex items-center mt-3 md:w-8/12 bg-f6f7f9"
      >
        <img className="w-5" src={Upload} />
        <a
          className="text-sm font-medium leading-3 ml-2 text-primary hover:text-blue-700 ease-in duration-300"
          
        >
          Upload from file
        </a>
      </div>
      {showContent && (
        <div className='pb-3'>
          <div className="md:w-8/12 ml-7 mt-4 pt-6 pb-8 flex justify-center items-center relative border border-dashed border-primary rounded bg-[#fbfcfd]">
            <div className="w-[194px] mx-auto">
              <div>
                <img className="mx-auto" src={UploadFileImg} />
              </div>
              <p className="text-sm font-medium leading-4 text-212121 mt-6 text-center">
                {selectedFile
                  ? selectedFile.name
                  : 'Drag and drop files to upload or '}
              </p>
              <div className="px-0.5">
                <button className="text-[13px] font-medium leading-4 text-white rounded flex items-center justify-center py-3 px-2 w-full mt-2.5 bg-primary">
                  <img src={Plus} />
                  <span className="ml-3"> Select files to upload</span>
                </button>
              </div>
            </div>
            <input
              className="absolute top-0 w-full h-full opacity-0 cursor-pointer"
              type="file"
              name="upload"
              onChange={handleFileChange}
            />
          </div>
          {error && (
            <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4">
              <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
                {error}
              </p>
            </div>
          )}
          <button
            className={
              'text-white bg-btnPrimary ease-in-in duration-300 hover:bg-primary hover:scale-105 text-sm font-medium leading-4 rounded-md flex items-center justify-center py-3 px-2 w-24 mt-2.5 ml-7'
            }
            onClick={handleUploadFile}
          >
            Upload
          </button>
        </div>
      )}
    </div>
  );
};
export default UploadFile;
