import React, { FC } from 'react';
import { ExpanderComponentProps } from 'react-data-table-component';
import { eventResultDataSchema } from './SubscriberEvents';
import { z } from 'zod';

type ExpandableRowData = z.infer<typeof eventResultDataSchema>;

const SubscriberEventAccordian: FC<
  ExpanderComponentProps<ExpandableRowData>
> = ({ data }) => {
  return (
    <div>
      <div className="md:flex block text-sm">
        {Object.entries(data.attributes).map(([key, value]) => (
          <div key={key} className="md:w-1/2 flex py-2">
            <p className="text-sm font-medium leading-5 text-212121 w-40">
              {key}
            </p>
            &nbsp;
            <p className="text-[13px] font-medium leading-5 text-616161 w-2/3">
              {value}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
export default SubscriberEventAccordian;
