import React, { FC, useContext } from 'react';
import Health from 'assets/Images/Workflow/health.png';
import Dropdown from 'components/Dropdown/valueDropdown';
import { WorkflowType } from 'components/Workflow/Body/Workflows/index.type';
import moment from 'moment-timezone';
import { UserContext } from 'store/UserContext';

interface Props {
  workflowOptions: { name: string; value: string }[];
  selectedWorkflow: string;
  onWorkflowSelect: (value: string) => void;
  workflowsLoading: boolean;
  selectedWorkflowData: WorkflowType | undefined;
}

const Header: FC<Props> = ({
  workflowOptions,
  selectedWorkflow,
  onWorkflowSelect,
  workflowsLoading,
  selectedWorkflowData,
}) => {
  const userCtx = useContext(UserContext);

  return (
    <div className="md:px-6 px-3 py-5 font-inter">
      <div className="md:px-1.5">
        <div className="flex items-center">
          <span>
            <img src={Health} alt="Health" />
          </span>
          <h2 className="text-xl leading-5 text-212121 font-medium ml-2.5 mr-3">
            Workflows
          </h2>
        </div>
        <div className="w-full max-w-xs h-11 flex items-center rounded relative  mt-5">
          <Dropdown
            options={workflowOptions}
            value={selectedWorkflow}
            onSelect={(value: string) => onWorkflowSelect(value)}
          />
        </div>
        <div className="flex md:justify-start justify-between md:flex-nowrap flex-wrap font-inter">
          <div className="md:w-5/12 sm:w-6/12 w-full sm:pr-2.5 pr-1.5 mt-6">
            <div className=" mb-6">
              <p className="text-xs font-medium leading-3 tracking-wide uppercase text-616161 mb-2">
                Conversion
              </p>
              <p className="text-[13px] font-semibold leading-4 text-616161">
                {workflowsLoading ? '--------' : selectedWorkflowData?.name}
              </p>
            </div>
            <div className="">
              <p className="text-xs font-medium leading-3 tracking-wide uppercase text-616161 mb-2">
                TRIGGER EVENT
              </p>
              <p className="text-[13px] font-semibold leading-4 text-616161">
                {workflowsLoading
                  ? '------'
                  : selectedWorkflowData?.defaultConversionReportingEvent}
              </p>
            </div>
          </div>
          <div className="md:w-4/12 sm:w-6/12 w-full sm:pr-2.5 pr-1.5 mt-6">
            <div className=" mb-6">
              <p className="text-xs font-medium leading-3 tracking-wide uppercase text-616161 mb-2">
                STATUS
              </p>
              <p className="text-[13px] font-semibold leading-4 text-616161">
                {workflowsLoading
                  ? '------'
                  : selectedWorkflowData?.expiresAt &&
                    selectedWorkflowData?.isEnabled
                  ? 'Expired'
                  : selectedWorkflowData?.isEnabled &&
                    selectedWorkflowData?.isTestMode
                  ? 'Enabled(Test Mode)'
                  : selectedWorkflowData?.isEnabled
                  ? 'Enabled'
                  : 'Disabled'}
              </p>
            </div>
            <div className="">
              <p className="text-xs font-medium leading-3 tracking-wide uppercase text-616161 mb-2">
                MODIFIED
              </p>
              <p className="text-[13px] font-semibold leading-4 text-616161">
                {workflowsLoading
                  ? '------'
                  : moment
                      .tz(
                        selectedWorkflowData?.lastModified,
                        userCtx?.usersData?.timeZone || ''
                      )
                      .format('MMMM D, YYYY h:mm:ss A')}
              </p>
            </div>
          </div>
          <div className="md:w-3/12 sm:w-6/12 w-full mt-6">
            <div className=" mb-6">
              <p className="text-xs font-medium leading-3 tracking-wide uppercase text-616161 mb-2">
                LAST TRIGGERED
              </p>
              <p className="text-[13px] font-semibold leading-4 text-616161">
                {workflowsLoading
                  ? '------'
                  : selectedWorkflowData?.lastTriggered
                  ? moment
                  .tz(
                    selectedWorkflowData?.lastTriggered,
                    userCtx?.usersData?.timeZone || ''
                  )
                  .format('MMMM D, YYYY h:mm:ss A')
                  : '-'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
