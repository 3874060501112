import { useFormik } from 'formik';
import { useContext, useEffect } from 'react';
import { CampaignContext } from 'store/CampaignContext';
import * as Yup from 'yup';
import React from "react"

const Reply = () => {
  const campaignCtx = useContext(CampaignContext);

  const formik = useFormik({
    initialValues: { reply_to_name: '', reply_to_email_address: '' },
    validationSchema: Yup.object({
      email: Yup.string().email('Email is invalid'),
    }),
    onSubmit: () => {
      console.log();
    },
  });

  useEffect(() => {
    if (campaignCtx?.senderActivatedData) {
      formik.setValues(campaignCtx.senderActivatedData);
    }
  }, [campaignCtx?.senderActivatedData]);

  useEffect(() => {
    if (campaignCtx?.campaignData) {
      campaignCtx?.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        ...formik.values,
      });
    }
  }, [formik.values]);

  return (
    <div className="p-4 pb-10 bg-gray-150 dark:bg-slate-800 rounded-md border border-gray-800 dark:border-black-400 mt-8">
      <div>
        <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white inline-block inline-block mb-3">
          Reply to
        </label>
        <div>
          <input
            className="w-full p-4 text-13 font-medium leading-3 dark:bg-slate-800 border border-gray-800 dark:border-black-400 rounded-md text-black-400 dark:text-white sm:h-11 h-9 focus:outline-none placeholder:text-gray-500 dark:placeholder:text-white"
            type="text"
            name="reply_to_name"
            placeholder="Full Name"
            value={formik.values.reply_to_name}
            onChange={formik.handleChange}
          />
          <input
            className="w-full p-4 text-13 font-medium leading-3 dark:bg-slate-800 border border-gray-800 dark:border-black-400 rounded-md text-black-400 dark:text-white sm:h-11 h-9 focus:outline-none placeholder:text-gray-500 mt-4 dark:placeholder:text-white"
            type="email"
            name="reply_to_email_address"
            placeholder="Email Address"
            value={formik.values.reply_to_email_address}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>
      </div>
    </div>
  );
};
export default Reply;
