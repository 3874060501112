import Edit from 'assets/Images/ListsegmentImages/edit.png';
import Delete from 'assets/Images/ListsegmentImages/delete.png';
import UnionImg from 'assets/Images/svg-images/union.svg';
import { Tooltip } from 'react-tooltip';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Link,useLocation } from 'react-router-dom';
import Warning from 'assets/Images/Campaign/warning.svg';
import Loader from 'components/Loader';
import 'react-tooltip/dist/react-tooltip.css';
import { createCampaign, edit, emailEditor } from 'services/constant/routes';
import { z } from 'zod';
import DeleteMdoal from 'components/Alert/DeleteModal';
import { Toastify } from 'App';
import { deleteEmail } from 'services/apiHandlers/Campaigns/Emails';
import React from 'react';

const emailTriggerSchema = z.object({ name: z.string(), id: z.number() });

const campaignEmailSchema = z.object({
  id: z.number(),
  name: z.string(),
  created_at: z.string(),
  html_part_present: z.boolean(),
  html_part_blank: z.boolean(),
  include_event: z.boolean(),
  needs_widgetization: z.boolean(),
  widgetization_error: z.boolean(),
  new_editor_compatible: z.boolean(),
  text_part_present: z.boolean(),
  text_part_blank: z.boolean(),
  email_triggers: z.array(emailTriggerSchema),
});
type SingleCampaignEmail = z.infer<typeof campaignEmailSchema>;

const deleteEmailResponseSchema = z.object({ status: z.number() });
type DeleteEmailResponse = z.infer<typeof deleteEmailResponseSchema>;

interface Props {
  row: SingleCampaignEmail;
  actionTooltipOpen: boolean;
  setActionTooltipOpen: Dispatch<SetStateAction<boolean>>;
  onDelete: () => void;
}

const CampaignActions: FC<Props> = ({
  row,
  actionTooltipOpen,
  setActionTooltipOpen,
  onDelete,
}) => {
  const [actionAnchorId, setActionAnchorId] = useState<string>('');
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const tab = searchParams.get('tab');

  const deleteEmailHandler = async (id: string) => {
    try {
      const deleteEmailResponse = (await deleteEmail(
        id
      )) as DeleteEmailResponse;
      if (deleteEmailResponse.status === 200) {
        Toastify('Email Deleted Successfully', 'success', 'actions1');
        onDelete();
      }
    } catch (error) {
      Toastify('Deleting Email Failed', 'error', 'actions2');
    }
  };

  return (
    <div className="flex justify-center items-center">
      {!row.needs_widgetization && !row.widgetization_error && (
        <Link
          to={`${emailEditor}/${edit}/${row.id}`}
          className="relative inline-block group"
        >
          <img
            className="w-3.5 dark:invert dark:brightness-0"
            src={Edit}
            alt="Edit"
          />
        </Link>
      )}
      {row.email_triggers.length === 0 && (
        <div
          onClick={() => setShowDeleteAlert(true)}
          className="ml-6 relative inline-block group"
        >
          <img
            className="w-3.5 dark:invert dark:brightness-0"
            src={Delete}
            alt="Delete"
          />
        </div>
      )}
      <div className="inline-flex relative">
        {row.needs_widgetization ? (
          <div
            onMouseEnter={() => {
              setActionAnchorId('tooltip-anchor-children2');
              setActionTooltipOpen(true);
            }}
            id="tooltip-anchor-children2"
            className="ml-6"
          >
            <Loader />
            <div onMouseLeave={() => setActionTooltipOpen(false)}>
              <Tooltip isOpen={actionTooltipOpen} anchorId={actionAnchorId}>
                <div>
                  <h1>Processing widgets, Please refresh in a few minutes</h1>
                </div>
              </Tooltip>
            </div>
          </div>
        ) : row.widgetization_error ? (
          <div>
            <img
              className="w-3.5 ml-6 dark:invert dark:brightness-0"
              src={Warning}
              alt="Error"
            />
          </div>
        ) : (
          // <div className="ml-6 relative inline-block group">
          //   <img
          //     className="w-3.5 dark:invert dark:brightness-0"
          //     src={Export}
          //     alt="Export"
          //   />
          // </div>
          <></>
        )}
        {!row.needs_widgetization &&
          !row.widgetization_error &&
          !row.include_event && (
            <Link
              to={`${createCampaign}/${row.id}?tab=${tab}`}
              className="ml-6 relative inline-block group"
            >
              <img
                className="w-3.5 dark:invert dark:brightness-0"
                src={UnionImg}
                alt="Union"
              />
            </Link>
          )}
      </div>
      {showDeleteAlert && (
        <div
          onClick={() => setShowDeleteAlert(false)}
          className="fixed left-0 top-0 w-full h-full z-10 bg-black-200 duration-300"
        >
          <DeleteMdoal
            onDelete={() => deleteEmailHandler(row.id.toString())}
            onCancel={() => setShowDeleteAlert(false)}
          />
        </div>
      )}
    </div>
  );
};
export default CampaignActions;
