import { DashboardContextProvider } from 'store/DashboardContext';
import ErrorBoundary from 'components/ErrorBoundary';
import Dashboards from 'components/DashboardReports/Reports';
import React from 'react';
//import Calendar from 'assets/Images/Dashboard/calender.png';
const Reports = () => {
  return (
    //NEW_UPDATE: Let's discuss the possibility of the
    // dashboard context optimization
    <ErrorBoundary>
      <DashboardContextProvider>
          <Dashboards />
      </DashboardContextProvider>
    </ErrorBoundary>
  );
};
export default Reports;