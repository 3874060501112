import SubscriberList from './SubscriberList';
import FilterList from './FilterList';
import ErrorBoundary from 'components/ErrorBoundary';
import { Outlet } from 'react-router-dom';
import React from 'react';

const AddList = () => {
  
  return (
    <ErrorBoundary>
      <div>
        <h1 className="sm:text-xl text-base font-medium leading-5 text-black-300 mr-2.5 dark:text-white">
          Add List Members
        </h1>
        <div className="md:flex justify-between items-center mt-4">
          <SubscriberList />
          <FilterList  />
        </div>
        <Outlet />
      </div>
    </ErrorBoundary>
  );
};
export default AddList;
