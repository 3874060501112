import { useFormik } from 'formik';
import { useContext, useEffect } from 'react';
import { CampaignContext } from 'store/CampaignContext';
import React from "react"

const ScheduleTime = () => {
  const campaignCtx = useContext(CampaignContext);

  const formik = useFormik({
    initialValues: { hour: '15', minute: '0' },
    onSubmit: () => console.log(),
  });

  useEffect(() => {
    if (campaignCtx?.campaignData?.schedule_later && campaignCtx.campaignData) {
      const tempCampaignData = {
        ...campaignCtx.campaignData,
        schedule_later: {
          ...campaignCtx.campaignData.schedule_later,
          hour: formik.values.hour.toString(),
          minute: formik.values.minute.toString(),
        },
      };
      campaignCtx?.onUpdateCampaignData(tempCampaignData);
    }
  }, [formik.values]);
  return (
    <div className="mt-4">
      <h3 className="text-base font-medium leading-5 text-black-400 dark:text-white">
        At what time?
      </h3>
      <div>
        <div className="flex flex-wrap items-center">
          <input
            className="block w-24 h-11 text-13 py-3 border border-gray-800 dark:border-black-400 focus:outline-none dark:bg-slate-800 rounded-md px-2 font-medium leading-3 text-black-400 dark:text-white w-full h-full bg-white dark:bg-slate-800 mt-4"
            type="number"
            name="hour"
            placeholder="00"
            max={24}
            min={0}
            value={formik.values.hour}
            onChange={(e) => {
              if (parseInt(e.target.value) < 25 || e.target.value === '') {
                formik.handleChange(e);
              }
            }}
          />
          <p className="text-sm mt-4 mx-3 dark:text-white">:</p>
          <input
            className="block w-24 h-11 text-13 py-3 border border-gray-800 dark:border-black-400 focus:outline-none dark:bg-slate-800 rounded-md px-2 font-medium leading-3 text-black-400 dark:text-white w-full h-full bg-white dark:bg-slate-800 mt-4"
            type="number"
            name="minute"
            placeholder="00"
            max={60}
            min={0}
            value={formik.values.minute}
            onChange={(e) => {
              if (parseInt(e.target.value) < 60 || e.target.value === '') {
                formik.handleChange(e);
              }
            }}
          />
          <p className="text-sm font-medium leading-4 text-black-400 dark:text-white mt-4 ml-3">
            America-Los Angeles
            <span className="text-gray-500 dark:text-white">(GMT-07:00)</span>
          </p>
        </div>
        <p className="text-sm leading-4 text-gray-500 dark:text-white mt-2">
          People added after this time on the scheduled date will not receive
          this message.
        </p>
      </div>
    </div>
  );
};
export default ScheduleTime;
