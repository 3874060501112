import ErrorBoundary from 'components/ErrorBoundary';
import CampaignInformation from 'components/NewCampaign';
import { CampaignContextProvider } from 'store/CampaignContext';
import React from "react"
const NewCampaign = () => {
  return (
    <ErrorBoundary>
      <CampaignContextProvider>
        <CampaignInformation />
      </CampaignContextProvider>
    </ErrorBoundary>
  );
};
export default NewCampaign;
