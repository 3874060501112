import Plus from 'assets/Images/svg-images/plus.svg';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import PopularLabels from './PopularLabels';
import { emailEditor } from 'services/constant/routes';
import { Link } from 'react-router-dom';
import React from 'react';
interface Props {
  setFilterValue: (filter: string) => void;
  onLabelClick: (value: string | undefined) => void;
}

const Header: FC<Props> = ({ setFilterValue, onLabelClick }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const formik = useFormik({
    initialValues: { search: '' },
    onSubmit: (values) => {
      setFilterValue(values.search);
      onLabelClick(undefined);
    },
  });


  const serachChangeHandler = (search: string) => {
    setFilterValue(search);
  };
  useEffect(() => {

    const timeoutId = setTimeout(() => {
      serachChangeHandler(searchValue);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    }
  }, [searchValue])

  return (
    <div className="flex">
      <div className="flex flex-col justify-between md:w-8/12 w-full lg:pr-5 md:pr-3">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-medium leading-5 text-black-300 dark:text-white w-28 m-0">
            All Emails
          </h1>
          <div className="flex justify-between items-center md:w-10/12">
            <form
              onSubmit={formik.handleSubmit}
              className="xl:w-9/12 w-8/12 md:block hidden"
            >
              <div className="relative flex items-center h-11 border rounded-md border-gray-200 bg-white dark:bg-slate-800 dark:border-black-400">
                <input
                  className="text-13 px-2 font-medium focus:outline-none placeholder:text-gray-500 leading-3 text-gray-900 dark:text-white py-2 w-full dark:bg-slate-800 dark:placeholder:text-white"
                  type="text"
                  name="search"
                  value={formik.values.search}
                  placeholder="Email/Content"
                  onChange={(e) => { formik.handleChange(e); 
                    setSearchValue(e.target.value);
                  }}
                />
              </div>
            </form>
            <Link to={`${emailEditor}`} className="w-32">
              <button className="w-full rounded-md border-md text-sm font-medium leading-4 font-inter flex items-center lg:py-2.5 lg:px-2 md:p-1 px-2 py-1.5 text-white bg-primary hover:bg-primaryHover hover:scale-105 ease-in duration-300 shadow h-11">
                <img className="mr-1" src={Plus} alt="Plus" />
                Create New
              </button>
            </Link>
          </div>
        </div>
        <div className="w-full md:hidden mt-4">
          <div className="relative flex items-center px-2 h-11 border rounded border-gray-200 bg-white">
            <input
              className="text-13 font-medium leading-3 text-gray-900 dark:text-white py-2 w-full"
              type="text"
              name="search"
              placeholder="Email/Content"
            />
          </div>
        </div>
      </div>
      <PopularLabels
        onLabelClick={(value: string) => {
          formik.setFieldValue('search', '');
          onLabelClick(value);
        }}
      />
    </div>
  );
};
export default Header;
