import OwnHtml from './OwnHtml';
import CustomTemplates from './CustomTemplate';
import Template from './Template';
import StatusBar from 'components/StatusBar';
import { FC,  useEffect, useState } from 'react';
import { getTemplate } from 'services/apiHandlers/Campaigns/Emails';
import { z } from 'zod';
import React from 'react';

const categoriesSchema = z.object({
  prebuilt_template_category: z.object({
    id: z.number(),
    library_id: z.number(),
    name: z.string(),
  }),
});
export type Categories = z.infer<typeof categoriesSchema>;

const customTemplateSchema = z.object({
  custom_template: z.object({
    account_id: z.number(),
    content: z.string(),
    created_at: z.string(),
    css: z.string().nullable(),
    id: z.number(),
    name: z.string(),
    status: z.string(),
    updated_at: z.string(),
  }),
});
export type CustomTemplate = z.infer<typeof customTemplateSchema>;

const collectionSchema = z.object({
  prebuilt_template: z.object({
    color_contrast: z.string().nullable(),
    column_count: z.number().nullable(),
    created_at: z.string(),
    css: z.string(),
    description: z.string().nullable(),
    editor_version: z.number(),
    has_images: z.boolean().nullable(),
    html_part: z.string(),
    id: z.number(),
    library_id: z.number(),
    name: z.string(),
    primary_color: z.string(),
    secondary_color: z.string(),
    status: z.string(),
    template_type: z.string(),
    updated_at: z.string(),
  }),
});
export type Collections = z.infer<typeof collectionSchema>;

const templateSchema = z.object({
  collection: z.array(collectionSchema),
  current_page: z.number(),
  per_page: z.number(),
  total_count: z.number(),
});

const getTemplateResponseSchema = z.object({
  data: z.object({
    categories: z.array(categoriesSchema),
    custom_templates: z.array(customTemplateSchema),
    show_html_option: z.boolean(),
    show_templates_option: z.boolean(),
    templates: templateSchema,
  }),
  status: z.number(),
});

type GetTemplateResponse = z.infer<typeof getTemplateResponseSchema>;

const creationSteps = [
  { id: 'html', label: 'Use Your Own HTML' },
  { id: 'custom', label: 'Use a Custom Template' },
  { id: 'template', label: 'Use a Template' },
];

const EmailCreationSteps: FC = () => {
  const [view, setView] = useState<string>('html');
  const [customTemplates, setCustomTemplates] = useState<Array<CustomTemplate>>(
    []
  );
  const [categories, setCategories] = useState<Array<Categories>>([]);
  const [emailCollections, setEmailCollections] = useState<Array<Collections>>(
    []
  );

  const getData = async () => {
    try {
      const getTemplateResponse = (await getTemplate()) as GetTemplateResponse;
      if (getTemplateResponse.status === 200) {
        setCustomTemplates(getTemplateResponse.data.custom_templates);
        setCategories(getTemplateResponse.data.categories);
        setEmailCollections(getTemplateResponse.data.templates.collection);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };


  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="pt-5 bg-gray-300 dark:bg-slate-800 h-full ml-6">
      <StatusBar />
      <div className="my-5">
        <h1 className="sm:text-xl text-lg font-medium leading-7">
          <span className="text-757575 dark:text-white">Step 1a:</span>
          <span className="text-primary">How should it be built?</span>
        </h1>
      </div>
      <div className="bg-white sm:px-5 px-3 py-6 rounded-lg boxShadow">
        <div>
          {creationSteps.map((step, index) => (
            <div key={step.id} className={`${index > 0 ? 'mt-5' : ''}`}>
              <div className="flex items-center">
                <input
                  className=""
                  type="radio"
                  name={step.id}
                  id={step.id}
                  checked={view === step.id}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setView(step.id);
                    }
                  }}
                />
                <label
                  htmlFor={step.id}
                  className="radio-btn block pl-4 relative fs-13 font-normal leading-3 text-616161"
                >
                  {step.label}
                </label>
              </div>
              {view === 'html' && step.id === 'html' && <OwnHtml />}
              {view === 'custom' && step.id === 'custom' && (
                <CustomTemplates templates={customTemplates} />
              )}
              {view === 'template' && step.id === 'template' && (
                <Template
                  categories={categories}
                  collections={emailCollections}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default EmailCreationSteps;
