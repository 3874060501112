import httpService from 'services/core/HttpService';
import {
  CREATE_ONLOAD_SCRIPT,
  CREATE_WORKFLOW_SCRIPT,
  DELETE_ONLOAD_SCRIPT,
  DELETE_WORKFLOW_SCRIPT,
  GET_ONLOAD_SCRIPTS,
  GET_WORKFLOW_SCRIPTS,
  UPDATE_ONLOAD_SCRIPT,
  UPDATE_WORKFLOW_SCRIPT,
} from 'services/constant/apiRoutes';
import {z} from "zod";

const createWorkflowScriptSchema = z.object({
    workflowScript: z.object({
      domains: z.array(z.string()),
      name: z.string(),
      javascript: z.string(),
    }),
  });
  export type createWorkflowScriptType = z.infer<typeof createWorkflowScriptSchema>;
  
  const createOnloadScriptSchema = z.object({
    onloadScript: z.object({
      domains: z.array(z.string()),
      name: z.string(),
      javascript: z.string(),
    }),
  });
  export type createOnload = z.infer<typeof createOnloadScriptSchema>;
  
  const updateWorkflowScriptSchema = z.object({
    workflowScript: z.object({
      domains: z.array(z.string()),
      name: z.string(),
      javascript: z.string(),
      id: z.number(),
    }),
  });
  export type updateWorkflow = z.infer<typeof updateWorkflowScriptSchema>;
  
  const updateOnloadScriptSchema = z.object({
    onloadScript: z.object({
      domains: z.array(z.string()),
      name: z.string(),
      javascript: z.string(),
      id: z.number(),
    }),
  });
  export type updateOnload = z.infer<typeof updateOnloadScriptSchema>;
  

export const getOnloadScripts = async () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_ONLOAD_SCRIPTS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateOnloadScript = (updateOnloadScriptData: updateOnload) => {
  const updateOnloadScriptUrl = UPDATE_ONLOAD_SCRIPT.replace(
    '{{ID}}',
    updateOnloadScriptData.onloadScript.id?.toString()
  );
  const updatedData = {
    onloadScript: {
      domains: updateOnloadScriptData.onloadScript.domains,
      name: updateOnloadScriptData.onloadScript.name,
      javascript: updateOnloadScriptData.onloadScript.javascript,
    },
  };
  return new Promise((resolve, reject) => {
    httpService
      .put(updateOnloadScriptUrl, updatedData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteOnloadScript = (id: string) => {
  const deleteOnloadScriptUrl = DELETE_ONLOAD_SCRIPT.replace('{{ID}}', id);
  return new Promise((resolve, reject) => {
    httpService
      .delete(deleteOnloadScriptUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createOnloadScript = (createOnloadData: createOnload) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(CREATE_ONLOAD_SCRIPT, createOnloadData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getWorkflowScripts = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_WORKFLOW_SCRIPTS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createWorkflowScript = (
  createWorkflowScriptData: createWorkflowScriptType
) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(CREATE_WORKFLOW_SCRIPT, createWorkflowScriptData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateWorkflowScript = (
  updateWorkflowScriptData: updateWorkflow
) => {
  const updateWorkflowUrl = UPDATE_WORKFLOW_SCRIPT.replace(
    '{{ID}}',
    updateWorkflowScriptData.workflowScript.id?.toString()
  );
  const updatedData = {
    workflowScript: {
      domains: updateWorkflowScriptData.workflowScript.domains,
      name: updateWorkflowScriptData.workflowScript.name,
      javascript: updateWorkflowScriptData.workflowScript.javascript,
    },
  };
  return new Promise((resolve, reject) => {
    httpService
      .put(updateWorkflowUrl, updatedData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteWorkflowScript = (id: string) => {
  const deleteWorkflowScriptUrl = DELETE_WORKFLOW_SCRIPT.replace('{{ID}}', id);
  return new Promise((resolve, reject) => {
    httpService
      .delete(deleteWorkflowScriptUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
