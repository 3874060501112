import ErrorBoundary from 'components/ErrorBoundary';
import { DashboardContextProvider } from 'store/DashboardContext';
import Dashboards from 'components/Dashboard/Body';
import React from 'react';

const Dashboard = () => {

    return (
        <ErrorBoundary>
            <DashboardContextProvider>
                <Dashboards />
            </DashboardContextProvider>
        </ErrorBoundary>
    );
};
export default Dashboard;
