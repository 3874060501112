import { useState, createContext } from 'react';
import { WorkflowType } from 'components/Workflow/Body/Workflows/index.type';
import React from "react"

interface ContextType {
  leftNodes: number;
  leftNodeChangeHandler: (nodes: number) => void;
  workflow: WorkflowType | undefined;
  workflowChangeHandler: (workflow: WorkflowType | undefined) => void;
  createWorkflowId: string | null;
  onChangeWorkflowId: (id: string | null) => void;
  copyWorkflowId: string | null;
  onChangeCopyWorkflowId: (id: string| null) => void;
  copyWorkflow: boolean;
  onCopyWorkflowChange: (copy : boolean) => void
}
export const WorkflowContext = createContext<ContextType | null>(null);

type Props = {
  children: JSX.Element;
};
export const WorkflowContextProvider = ({ children }: Props) => {
  const [leftNodes, setLeftNodes] = useState<number>(0);
  const [workflow, setWorkflow] = useState<WorkflowType>();
  const [createWorkflowId, setCreateWorkflowId] = useState<string | null>(null);
  const [copyWorkflowId, setCopyWorkflowId] = useState<string | null>(null);
  const [copyWorkflow,setCopyWorkflow] = useState<boolean>(false);

  const leftNodeChangeHandler = (nodes: number) => {
    setLeftNodes(nodes);
  };
  
  const workflowChangeHandler = (workflow: WorkflowType | undefined) => {
    setWorkflow(workflow);
  };
  
  const onChangeWorkflowId = (id: string | null) => {
    setCreateWorkflowId(id);
  };

  const onChangeCopyWorkflowId = (id: string | null) => {
    setCopyWorkflowId(id);
  };
  
  const onCopyWorkflowChange = (copy: boolean) => {
    setCopyWorkflow(copy);
  }

  return (
    <WorkflowContext.Provider
      value={{
        leftNodes,
        leftNodeChangeHandler,
        workflow,
        workflowChangeHandler,
        createWorkflowId,
        onChangeWorkflowId,
        copyWorkflowId,
        onChangeCopyWorkflowId,
        copyWorkflow,
        onCopyWorkflowChange
      }}
    >
      {children}
    </WorkflowContext.Provider>
  );
};
