import Search from 'assets/Images/ListsegmentImages/search.png';
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import Dropdown from 'components/Dropdown/Dropdown';
import ValueDropdown from 'components/Dropdown/valueDropdown';
import { z } from 'zod';
import { getAllList } from 'services/apiHandlers/List-Segments/SegmentsList';
import { ListSchema } from '../List/Types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React from 'react';
import { upload } from 'services/constant/routes';

const ListResponseSchema = z.object({
  data: z.object({ lists: z.array(ListSchema) }),
  status: z.number(),
});
export type ListResponse = z.infer<typeof ListResponseSchema>;

const optionsSchema = z.object({
  name: z.string(),
  value: z.string(),
});
type Options = z.infer<typeof optionsSchema>;

interface Props {
  onSearch: Dispatch<SetStateAction<string>>;
  onListChange: (id: string) => void;
  onStatusChange: Dispatch<SetStateAction<SUBSCRIBER_STATUS>>;
}

export enum SUBSCRIBER_STATUS {
  SELECT = 'Select',
  ACTIVE = 'Active',
  PENDING = 'Pending',
  INACTIVE = 'Inactive',
  UNSUBSCRIBED = 'Unsubscribed',
}

const Actions = [
  'Select an option',
  'Add/Import',
  // 'Bulk Unsubscribe',
  // 'Download/Export',
  'Fields',
];

const SubscribersHeader = ({
  onSearch,
  onListChange,
  onStatusChange,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedMode, setSelectedMode] = useState<string>(Actions[0]);
  const [selectedList, setSelectedList] = useState<string>('Select an option');
  const [options, setOptions] = useState<Array<Options>>([
    { name: 'Select an option', value: '' },
  ]);
  const [selectedStatus, setSelectedStatus] =
  useState<string>('Select an option');
  const [searchParams] = useSearchParams();
  const listId = searchParams.get('list_id'); 
  const navigate = useNavigate();

  const getLists = async () => {
    try {
      const getListsResponse = (await getAllList()) as ListResponse;
      if (getListsResponse.status === 200) {
        const options = getListsResponse?.data?.lists.map((list) => {
          return { name: list.name, value: list.id.toString() };
        });
        setOptions([{ name: 'Select an option', value: '' }, ...options]);
      }
    } catch (error) {
      console.log('error for getting list  is : ', error);
    }
  };

  const onChangeField = (value: string) => {
    setSelectedMode(value);
    if (value === 'Add/Import') {
      navigate(`${upload}`);
    } else if (value === 'Fields') {
      navigate('fields');
    }
  };

  const listChangeHandler = (value: string) => {
    onListChange(value);
    const filterOptions = options.filter((option) => option.value === value);
    setSelectedList(filterOptions[0]?.name);
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      onSearch(searchQuery);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [searchQuery]);

  useEffect(() => {
    getLists();
  }, []);

  useEffect(() => {
    if (listId) {
      listChangeHandler(listId);
    }
  }, [options]);

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="sm:w-6/12 md:pr-3">
          <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black dark:text-white font-medium">
            Subscribers Profile
          </h2>
        </div>
        <div className="sm:pl-3 relative">
          <Dropdown
            value={selectedMode}
            options={Actions}
            onSelect={onChangeField}
          />
        </div>
      </div>
      <div className="flex justify-between items-center mt-7">
        <div className="w-1/2 md:block hidden pr-4">
          <div className="relative flex items-center py-2 px-4 sm:h-11 h-9 border rounded-md border-gray-800 focus:border-primary dark:border-black-400 bg-white dark:bg-slate-800 shadow-sm shadow-gray-200">
            <input
              className="text-13 font-medium leading-3 placeholder:text-gray-500 text-gray-500 dark:bg-slate-800 w-full pl-8 h-6 focus:outline-none dark:text-white dark:placeholder:text-white"
              type="text"
              name="search"
              data-pw="search-subscriber"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <img
              className="absolute dark:invert dark:brightness-0"
              src={Search}
              alt="Search"
            />
          </div>
        </div>

        <div
          className="w-1/2 md:w-1/4 sm:mt-0 mt-5 relative sm:pr-2 pr-1"
          id="select-list-segment"
        >
          <ValueDropdown
            options={options}
            onSelect={(value) => listChangeHandler(value)}
            value={selectedList}
          />
        </div>

        <div
          className="w-1/2 md:w-1/4 sm:mt-0 mt-5 relative sm:pl-2 pl-1"
          id="status-list-segment"
        >
          <Dropdown
            options={[...Object.values(SUBSCRIBER_STATUS)]}
            onSelect={(value) => {
              setSelectedStatus(value);
              onStatusChange(value as SUBSCRIBER_STATUS);
            }}
            value={selectedStatus}
          />
        </div>
      </div>
      <div className="md:hidden mt-5">
        <div className="relative flex items-center py-2 px-4 h-9 border rounded border-gray-200 dark:border-black-400 bg-white dark:bg-slate-800">
          <input
            className="text-13 font-medium leading-3 text-gray-500 dark:bg-slate-800 w-full pl-8 h-6 focus:outline-none"
            type="text"
            name="search"
            placeholder="Search"
          />
          <img className="absolute" src={Search} alt="Search" />
        </div>
      </div>
    </>
  );
};
export default SubscribersHeader;
