import { useState, createContext } from 'react';
import React from 'react';
import {z} from "zod";

const templateInfoSchema = z.object({
  name : z.string(),
  type: z.string().optional()
})
type TemplateInfo  = z.infer<typeof templateInfoSchema>

interface ContextType {
  htmlSubmitted: string;
  htmlSubmitHandler: (submit: string) => void;
  templateInfo: TemplateInfo;
  templateInfoHandler: (id: TemplateInfo) => void;
}
export const EmailContext = createContext<ContextType | null>(null);

type Props = {
  children: JSX.Element;
};
export const EmailContextProvider = ({ children }: Props) => {
  const [htmlSubmitted, setHtmlSubmitted] = useState<string>('');
  const [templateInfo, setTemplateInfo] = useState<TemplateInfo>({name: "" });

  const htmlSubmitHandler = (submit: string) => {
    setHtmlSubmitted(submit);
  };

  const templateInfoHandler = (info: TemplateInfo) => {
    setTemplateInfo(info);
  };

  return (
    <EmailContext.Provider
      value={{
        htmlSubmitted,
        htmlSubmitHandler,
        templateInfo,
        templateInfoHandler,
      }}
    >
      {children}
    </EmailContext.Provider>
  );
};
