import { FC, useContext, useEffect, useState } from 'react';
import { DashboardContext } from 'store/DashboardContext';
import averageConversionRate from '../../../../assets/Images/Dashboard/average-conversion-rate.svg';
import {calculatePercentage} from 'utils/common';

import React from 'react';
import { z } from 'zod';
import EmailPerformance from './EmailPerformance';


const EmailDataSchema = z.object({
  title: z.string(),
  percent: z.string(),
  openings: z.string().optional(),
  id: z.string(),
});
type EmailData = z.infer<typeof EmailDataSchema>;

const AverageConversionRate: FC = () => {
  const [emailPerformanceData, setEmailPerformanceData] = useState<
    Array<EmailData>
  >([]);
  const dashboardCtx = useContext(DashboardContext);

  useEffect(() => {
    setEmailPerformanceData([
      {
        title: 'Average Conversion Rate',
        percent: `${
          dashboardCtx?.weeklyRoiData?.conversions
            ? calculatePercentage(
                parseInt(dashboardCtx?.weeklyRoiData?.conversions),
                parseInt(dashboardCtx?.weeklyRoiData?.email_recipients)
              )
            : 0
        }%`,
        openings: `${
          dashboardCtx?.weeklyRoiData?.conversions
            ? dashboardCtx?.weeklyRoiData?.conversions
            : 0
        }`,
        //contacts converted
        id: '1',
      },
    ]);
  }, [dashboardCtx?.weeklyRoiData]);
  return (
    <EmailPerformance emailPerformance={emailPerformanceData} image={averageConversionRate}/>
  );
};
export default AverageConversionRate;
