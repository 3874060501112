import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { getOrderRevenueReport } from 'services/apiHandlers/Dashboard/StoreReport';
import { BlastResponse, Blasts } from 'components/DashboardReports/Types';
import { z } from 'zod';
import MixGraph from 'components/Graph/MixGraph';
import { ChartData } from 'chart.js';
import {
  getBlastByStatus,
  getBlastMonetaryValue,
} from 'services/apiHandlers/Dashboard/Analytics';
const DateSchema = z.object({ date: z.string(), total: z.number() });
const OrderSchema = z.object({
  metric: z.string(),
  dates: z.array(DateSchema),
});
const OrderResponseSchema = z.object({
  data: z.array(OrderSchema),
  status: z.number(),
});
const datasetsSchema = z.object({
  backgroundColor: z.string(),
  borderColor: z.string(),
  data: z.array(z.string()),
  label: z.string(),
  type: z.string(),
  fill: z.boolean().optional(),
});
const getBlastMonetaryValueSchema = z.object({
  blastId: z.number(),
  conversions: z.number(),
  monetaryValue: z.number(),
});
const getBlastMonetaryValueResponseSchema = z.object({
  data: z.array(getBlastMonetaryValueSchema),
  status: z.number(),
});
const campaignFilterSchema = z.array(
  z.object({ value: z.string(), date: z.string() })
);
type CampaignData = z.infer<typeof campaignFilterSchema>;
type GetBlastMonetaryValueResponse = z.infer<
  typeof getBlastMonetaryValueResponseSchema
>;
type Dataset = z.infer<typeof datasetsSchema>;
type OrderResponse = z.infer<typeof OrderResponseSchema>;
const calculateMonthlySums = (data: CampaignData) => {
  const sums: number[] = [];
  const start = moment().month(moment().subtract(5, 'months').format('MMM'));
  for (let i = 0; i < 6; i++) {
    const currentMonth = moment(start).add(i, 'months').format('MMM');
    const monthData = data.filter((item) => item.date === currentMonth);
    const sum = monthData.reduce((acc, item) => acc + parseInt(item.value), 0);
    sums.push(sum);
  }
  return sums;
};
const Revenues = () => {
  const [labels, setLabels] = useState<Array<string>>([]);
  const [dataset, setDatasets] = useState<Array<Dataset>>();
  const getBlastDates = (id: number, blasts: Blasts[]) => {
    const filteredBlast = blasts.filter((blast) => blast.blast.id === id);
    return moment(filteredBlast[0].blast.created_at).format('MMMM');
  };
  const getData = async () => {
    const currentMonthDate = moment().format('YYYY-MM-DD');
    const previousSixMonthDate = moment()
      .subtract(5, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    const previousYearWorkflowOrderData: string[] = [];
    const previousYearWorkflowRevenueData: string[] = [];
    const previousYearCampaignOrders: CampaignData = [];
    const previousYearCampaignRevenues: CampaignData = [];
    try {
      const getCurrentOrderResponse = (await getOrderRevenueReport(
        previousSixMonthDate,
        currentMonthDate
      )) as OrderResponse;
      const getFilteredCampaignsResponse = (await getBlastByStatus(
        moment().subtract(1, 'month').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      )) as BlastResponse;
      if (getFilteredCampaignsResponse.status === 200) {
        const campaignIds = getFilteredCampaignsResponse.data.collection.map(
          (collection) => {
            return collection.blast.id;
          }
        );
        const getBlastMonetaryValueResponse = (await getBlastMonetaryValue(
          campaignIds.join(',')
        )) as GetBlastMonetaryValueResponse;
        if (getBlastMonetaryValueResponse.status === 200) {
          getBlastMonetaryValueResponse.data.map((blast) => {
            previousYearCampaignRevenues.push({
              value: blast.monetaryValue.toString(),
              date: getBlastDates(
                blast.blastId,
                getFilteredCampaignsResponse.data.collection
              ),
            });
            previousYearCampaignOrders.push({
              value: blast.conversions.toString(),
              date: getBlastDates(
                blast.blastId,
                getFilteredCampaignsResponse.data.collection
              ),
            });
          });
        }
      }
      console.log(
        'campaign filter data is : ',
        calculateMonthlySums(previousYearCampaignRevenues),
        calculateMonthlySums(previousYearCampaignOrders)
      );
      if (getCurrentOrderResponse.status === 200) {
        getCurrentOrderResponse.data.map((data) =>
          data.dates.map(
            (date) => (
              data.metric === 'Orders' &&
                previousYearWorkflowOrderData.push(date.total.toString()),
              data.metric === 'Revenue' &&
                previousYearWorkflowRevenueData.push(date.total.toString())
            )
          )
        );
      }
      const previousMonths = [];
      for (let i = 5; i >= 0; i--) {
        const month = moment().subtract(i, 'months').format('MMMM');
        previousMonths.push(month);
      }
      setLabels(previousMonths);
      setDatasets([
        {
          backgroundColor: '#6597fd8c',
          borderColor: '#6597FD',
          data: previousYearWorkflowRevenueData,
          label: 'Workflow Revenues',
          type: 'line',
          fill: true,
        },
        {
          backgroundColor: '#9c7bd491',
          borderColor: '#9C7BD4',
          data: ['1000', '200', '300', '900', '800', '1000'], // proper data to be added in future
          label: 'Campiagn Revenues',
          type: 'line',
          fill: true,
        },
        {
          backgroundColor: '#65c5959c',
          borderColor: '#65C595',
          data: previousYearWorkflowOrderData,
          label: 'Workflow Orders',
          type: 'bar',
        },
        {
          backgroundColor: '#fcf12194',
          borderColor: '#FCF121',
          data: ['900', '800', '700', '600', '500', '1000'], // proper data to be added in future
          label: 'Campaign Orders',
          type: 'bar',
        },
      ]);
    } catch (error) {
      console.log('error is: ', error);
    }
  };
  useEffect(() => {
    getData();
  }, []);


	return (
		<div className='rounded-lg shadow-lg overflow-hidden shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800 storeTable'>
			<div className="cardHeader">
				<h4 className="text-base text-darkText font-medium dark:text-white">Top Performing Worklflows</h4>
			</div>
			<div className='px-4 pt-0 pb-10'>
				{dataset && (
					<MixGraph
					labels={labels}
					dataset={dataset as unknown as ChartData<'line', string[], unknown>}
					/>
				)}
			</div>
		</div>
	);
};
export default Revenues;
