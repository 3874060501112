import { FC, useContext, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import Table from 'components/Table';
import { tableSort } from 'utils/common';
import 'react-tooltip/dist/react-tooltip.css';
import CampaignNames from 'components/CompaignColumns/CompaignNames';
import CampaignStatus from 'components/CompaignColumns/CompaignStatus';
import CampaignActions from 'components/CompaignColumns/CompaignActions';
import moment from 'moment-timezone';
import { UserContext } from 'store/UserContext';
import { z } from 'zod';
import React from 'react';

const emailTriggerSchema = z.object({ name: z.string(), id: z.number() });

const campaignEmailSchema = z.object({
  id: z.number(),
  name: z.string(),
  created_at: z.string(),
  html_part_present: z.boolean(),
  html_part_blank: z.boolean(),
  include_event: z.boolean(),
  needs_widgetization: z.boolean(),
  widgetization_error: z.boolean(),
  new_editor_compatible: z.boolean(),
  text_part_present: z.boolean(),
  text_part_blank: z.boolean(),
  email_triggers: z.array(emailTriggerSchema),
});
type SinglecampaignEmail = z.infer<typeof campaignEmailSchema>;

interface Props {
  insights: SinglecampaignEmail[];
  readonly: boolean;
  loading: boolean;
  onDelete: () => void;
}

const Insights: FC<Props> = ({ insights, readonly, loading, onDelete }) => {
  const [actionTooltipOpen, setActionTooltipOpen] = useState<boolean>(false);
  const userCtx = useContext(UserContext);
  const campaignSort = (
    rowA: SinglecampaignEmail,
    rowB: SinglecampaignEmail
  ) => {
    return tableSort(rowA.name, rowB.name);
  };

  const columns: TableColumn<SinglecampaignEmail>[] = useMemo(
    () => [
      {
        name: 'Name',
        cell: (row, index) => {
          return <CampaignNames row={row} index={index} />;
        },
        sortable: true,
        sortFunction: campaignSort,
      },
      {
        name: 'Created At',
        cell: (row) => {
          return (
            <div>
              {moment
                .tz(row.created_at, userCtx?.usersData?.timeZone || '')
                .format('MMMM D, YYYY [at] h:mm A')}
            </div>
          );
        },
      },
      {
        name: 'Status',
        cell: (row) => {
          return <CampaignStatus row={row} readonly={readonly} />;
        },
      },
      {
        name: 'Actions',
        omit: !!readonly,
        cell: (row) => {
          return (
            <CampaignActions
              row={row}
              actionTooltipOpen={actionTooltipOpen}
              setActionTooltipOpen={setActionTooltipOpen}
              onDelete={onDelete}
            />
          );
        },
      },
    ],
    [readonly]
  );

  return (
    <Table
      onRowMouseLeave={() => {
        setActionTooltipOpen(false);
      }}
      className="scrollbar-hide"
      data={insights}
      columns={columns}
      progressPending={loading}
    />
  );
};
export default Insights;
