import React, { useEffect, useState, FC, useCallback } from 'react';
import { getFields } from 'services/apiHandlers/List-Segments/Field';
import xmlToJSON from 'utils/xmlToJSON';
import Input from './Input';
import { useFormik } from 'formik';
import {
  createSubscriber,
  getSubscriberById,
  getSubscriberMembershipById,
  editSubscriber,
} from 'services/apiHandlers/List-Segments/Subscriber';
import * as Yup from 'yup';
import { getAllList } from 'services/apiHandlers/List-Segments/SegmentsList';
import { formatXmlJsonResponse } from 'utils/common';
import Skeleton from 'components/Skeleton/Skeleton';
import { z } from 'zod';
import List from 'components/List';
import { Toastify } from 'App';
import ValueDropdown from 'components/Dropdown/valueDropdown';
import Dropdown from 'components/Dropdown/Dropdown';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  listSegments,
  subscribers,
  upload,
  view,
} from 'services/constant/routes';

const getListResponseDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  audience_id: z.number(),
});
const selectedListSchema = z.object({ id: z.number(), label: z.string() });
type SelectedList = z.infer<typeof selectedListSchema>;
type ListType = z.infer<typeof getListResponseDataSchema>;
const getListResponseSchema = z.object({
  data: z.object({
    lists: z.array(getListResponseDataSchema),
  }),
  status: z.number(),
});
type GetListResponse = z.infer<typeof getListResponseSchema>;

interface SubscriberStatus {
  [key: string]: string;
  unsubscribed: string;
  active: string;
}

const status: SubscriberStatus = {
  unsubscribed: 'Unsubscribed',
  active: 'Active',
};

const breadCrumbs = z.object({
  title: z.string(),
  link: z.string().optional(),
});
const breadCrumbSchema = z.array(breadCrumbs);

const responseStatusSchema = z.object({ status: z.number() });

const FormSchema = z.object({
  ['allow-other']: z.boolean(),
  ['data-type']: z.string(),
  id: z.number(),
  ['include-blank']: z.boolean(),
  name: z.string(),
  slug: z.string(),
  'used-in-filter': z.string(),
});

const editedSubscriberSchema = z.object({
  person: z.record(z.union([z.string(), z.array(z.string())])),
});

export type Form = z.infer<typeof FormSchema>;
export type BreadCrumb = z.infer<typeof breadCrumbSchema>;
export type ResponseStatus = z.infer<typeof responseStatusSchema>;

export type EditedSubscriber = z.infer<typeof editedSubscriberSchema>;

const SubscriberForm: FC = () => {
  const [initialState, setInitialState] = useState<
    Record<string, string | string[]>
  >({});

  const [createFormData, setCreateFormData] = useState<Array<Form>>([]);
  const [selectedLists, setSelectedLists] = useState<Array<number>>([]);
  const [loadingFields, setLoadingFields] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [list, setList] = useState<Array<ListType>>([]);
  const [listItems, setListItems] = useState<Array<SelectedList>>([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const getListData = async () => {
    try {
      const getListsResponse = (await getAllList()) as GetListResponse;
      if (getListsResponse.status === 200) {
        const tempLists = getListsResponse?.data?.lists?.map((list) => {
          return { id: list.id, label: list.name };
        });
        setListItems(tempLists);
        setList(getListsResponse?.data?.lists);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false);
  };

  const getSubscriberFormFields = async () => {
    /* eslint-disable */
    const getFieldResponse: any = await getFields();
    const data: any = xmlToJSON.parseString(getFieldResponse.data);
    const fields = formatXmlJsonResponse(data?.fields[0]?.field);

    setLoadingFields(false);
    setCreateFormData(fields as any);
    /* eslint-enable  */
  };

  const textFormatValue = (format: string) => {
    if (format === 'text' || format === 'Text') {
      return 'Text';
    } else {
      return 'HTML';
    }
  };

  const getSubscriberData = useCallback(async () => {
    if (id) {
      /* eslint-disable */

      const getSubscriberDataresponse = await getSubscriberById(
        isNaN(parseInt(id)) ? id : parseInt(id)
      );
      const subscribedListResponse = await getSubscriberMembershipById(
        parseInt(id)
      );

      const subscribedListJson: any = xmlToJSON.parseString(
        subscribedListResponse.replace(
          '<?xml version="1.0" encoding="UTF-8"?>',
          ''
        )
      );

      const formatedSubscribedList = formatXmlJsonResponse(
        subscribedListJson.memberships[0].membership
      );
      const preSelectLists = formatedSubscribedList.map((list: any) =>
        Number(list['list-id'])
      );

      setInitialState(getSubscriberDataresponse);
      setSelectedLists(preSelectLists);

      /* eslint-enable  */
    }
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialState,
    },
    validationSchema: Yup.object({
      email_address: Yup.string().email('Invalid email').required('Required'),
    }),
    onSubmit: async () => {
      try {
        if (id) {
          const editSubscriberResponse = (await editSubscriber(
            typeof initialState.id === 'number' ? initialState.id : 0,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            { person: { ...initialState, lists: selectedLists } }
          )) as ResponseStatus;
          if (
            editSubscriberResponse.status === 201 ||
            editSubscriberResponse.status === 200
          ) {
            Toastify('Changes Made Successfully', 'success', 'subscriberform2');
            navigate(
              `${listSegments}/${subscribers}/${view}/${initialState.id}`
            );
          }
        } else {
          const createSubscriberResponse = (await createSubscriber(
            initialState,
            selectedLists
          )) as ResponseStatus;
          if (
            createSubscriberResponse.status === 201 ||
            createSubscriberResponse.status === 200
          ) {
            Toastify('Saved Successfully', 'success', 'subscriberform2');
            navigate(`${listSegments}/${subscribers}`);
          }
        }
      } catch (error) {
        Toastify(
          `Subscriber ${id ? 'Changes ' : 'Saving '}Failed`,
          'error',
          'subscribersform1'
        );
      }
    },
  });

  const membershipChangeHandler = (list_id: number) => {
    const updatedList = [...selectedLists];
    const idIndex = updatedList.indexOf(list_id);
    if (idIndex === -1) {
      updatedList.push(list_id);
    } else {
      updatedList.splice(idIndex, 1);
    }
    setSelectedLists(updatedList);
  };

  useEffect(() => {
    getSubscriberFormFields();
    getSubscriberData();
    getListData();
  }, []);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="md:flex">
          <div className="md:w-7/12 w-full md:pr-2.5">
            <div className="flex justify-between items-center pt-2 mb-4">
              <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 dark:text-white font-medium">
                {id ? 'Edit Subscriber' : 'Add Subscriber'}
              </h2>
              <div className="flex items-center">
                <Link
                  to={
                    !id
                      ? `${listSegments}/${subscribers}/${upload}`
                      : `${listSegments}/${subscribers}`
                  }
                  className="sm:text-13 text-xs py-2.5 px-4 h-11 w-28 font-medium leading-5 text-primary border-2 border-primary border-primary rounded-md border-2 bg-white mr-3 ease-in duration-300 hover:bg-primary hover:scale-105 hover:text-white"
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="sm:text-13 text-xs font-medium leading-5 text-white py-2.5 px-4 h-11 w-28 rounded-md bg-btnPrimary ease-in-in duration-300 hover:bg-primary hover:scale-105"
                >
                  Save
                </button>
              </div>
            </div>
            <div className="sm:px-6 px-4 sm:py-4 py-2.5 border dark:border-black-400 rounded-lg shadow-lg shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800">
              <>
                <div>
                  <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white">
                    Email
                    <sup className="text-sm text-red-400 font-medium">*</sup>
                  </label>
                  <input
                    name="email_address"
                    type="email"
                    className="w-full p-4 text-13 font-medium leading-3 dark:bg-slate-800 border border-gray-800 dark:border-black-400 rounded-md mt-2 text-black-400 dark:text-white sm:h-11 h-9"
                    onChange={(e) => {
                      setInitialState({
                        ...initialState,
                        email_address: e.target.value,
                      });

                      formik.handleChange(e);
                    }}
                    value={formik.values.email_address}
                    onBlur={formik.handleBlur}
                  />
                  {!formik.isValid ? (
                    <div
                      data-pw="subscriber-email-required"
                      className="text-xs leading-4 text-red-400 mt-1.5"
                    >
                      {formik.errors.email_address}
                    </div>
                  ) : null}
                </div>
                {loadingFields && <Skeleton />}
                {createFormData?.map((data) => (
                  <Input
                    key={data.id}
                    parentClass="mt-4"
                    fieldData={initialState}
                    field={data}
                    className="w-full p-4 text-13 font-medium leading-3 dark:bg-slate-800 border border-gray-800 dark:border-black-400 rounded-md mt-2 text-black-400 dark:text-white sm:h-11 h-9"
                    onFieldValueChange={(name, value, check) => {
                      if (check) {
                        if (initialState[name]) {
                          const filteredArray = initialState[name]?.includes(
                            value
                          )
                            ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              initialState[name]?.filter(
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                (name) => name !== value
                              )
                            : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              [...initialState[name], value];

                          setInitialState({
                            ...initialState,
                            [name]: filteredArray,
                          });
                        } else {
                          setInitialState({
                            ...initialState,
                            [name]: [value],
                          });
                        }
                      } else {
                        setInitialState({ ...initialState, [name]: value });
                      }
                    }}
                  />
                ))}
              </>
            </div>
          </div>
          <div className="md:w-5/12 w-full md:pl-2.5">
            {id && (
              <div className="sm:px-6 px-4 sm:py-4 py-2.5 border dark:border-black-400 rounded-lg shadow-lg shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800 ">
                <div className="">
                  <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-616161">
                    Opt-Status
                  </label>
                  <div className="mt-2">
                    <ValueDropdown
                      options={[
                        { name: 'Active', value: 'active' },
                        { name: 'Unsubscribed', value: 'unsubscribed' },
                      ]}
                      value={status[initialState.opt_status as string]}
                      onSelect={(value) =>
                        setInitialState({ ...initialState, opt_status: value })
                      }
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="sm:px-6 px-4 sm:py-4 py-2.5 border dark:border-black-400 rounded-lg shadow-lg shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800 mt-3">
              <div>
                <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white">
                  E-mail Format
                </label>
                <div className="border border-gray-800 dark:border-black-400 flex items-center rounded relative mt-2 sm:h-11 h-9">
                  <Dropdown
                    options={['HTML', 'Text']}
                    value={
                      typeof initialState?.email_content_format === 'string'
                        ? textFormatValue(initialState?.email_content_format)
                        : ''
                    }
                    onSelect={(value) =>
                      setInitialState({
                        ...initialState,
                        email_content_format: value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 sm:px-6 px-4 sm:py-4 py-2.5 border dark:border-black-400 rounded-lg shadow-lg shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800">
              <div>
                <>
                  <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white">
                    List Membership
                  </label>
                  <List
                    list={list}
                    listItems={listItems}
                    onListChange={membershipChangeHandler}
                    selectedLists={selectedLists}
                    loading={loading}
                    getList={getListData}
                  />
                </>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default SubscriberForm;
