import DownArrow from 'assets/Images/Common/down-arrow.png';
import { useContext, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import {
  createBlasts,
  getBlasts,
} from 'services/apiHandlers/Campaigns/CampaignInformation';
import * as Yup from 'yup';
import { Toastify } from 'App';
import { z } from 'zod';
import { CampaignContext } from 'store/CampaignContext';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React from "react"

const responseStatusSchema = z.object({ status: z.number() });
export type ResponseStatus = z.infer<typeof responseStatusSchema>;

const blastReseponseSchema = z.object({
  data: z.object({ tags: z.array(z.string()) }),
  status: z.number(),
});
export type blastResponse = z.infer<typeof blastReseponseSchema>;

const TagBlast = () => {
  const [showBlast, setShowBlast] = useState<boolean>(false);
  const [blast, setBlast] = useState<string>('Select a Blast');
  const [blasts, setBlasts] = useState<Array<string>>([]);
  const campaignCtx = useContext(CampaignContext);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideClick(dropdownRef, () => {
    setShowBlast(false);
  });

  const getData = async () => {
    try {
      const getTagBlastsResponse = (await getBlasts()) as blastResponse;
      if (getTagBlastsResponse.status === 200) {
        setBlasts(getTagBlastsResponse.data.tags);
      }
    } catch (error) {
      console.log('error is :', error);
    }
  };

  const formik = useFormik({
    initialValues: { search: '', blastTag: '' },
    validationSchema: Yup.object({
      blastTag: Yup.string().required('Enter a blast value'),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = { tag: values.blastTag };
      try {
        const createBlastResponse = (await createBlasts(
          data
        )) as ResponseStatus;
        if (createBlastResponse.status === 200) {
          Toastify('Blast Added Successfully', 'success', 'tagblast1');
          getData();
          resetForm();
        }
      } catch (error) {
        Toastify('Blast Not Added', 'error', 'tagblast2');
      }
    },
  });

  const onBlastClickHandler = (blast: string) => {
    if (campaignCtx?.campaignData) {
      campaignCtx?.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        tag_list: [blast],
      });
    }
    setBlast(blast);
    setShowBlast(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const filterBlasts = [...blasts].filter((blast) => {
      return blast.toLowerCase().includes(formik.values.search.toLowerCase());
    });
    setBlasts(filterBlasts);
  }, [formik.values.search]);

  return (
    <div className="p-4 pb-10 bg-gray-150 dark:bg-slate-800 rounded-md border border-gray-800 dark:border-black-400 mt-8">
      <h3 className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white inline-block inline-block mb-3">
        Tag Blast
      </h3>
      <div className='relative' ref={dropdownRef}>
        <div
          onClick={() => setShowBlast((prev) => !prev)}
          className="bg-white dark:bg-slate-800 border-gray-800 dark:border-black-400 border rounded-md dropdown-show-hide sm:h-11 h-9 overflow-y-auto flex items-center relative cursor-pointer w-full"
        >
          <div className="text-13 text-black-400 dark:text-white w-full h-full py-2 px-4 focus:outline-none bg-white flex items-center dark:bg-slate-800">
            {blast}
          </div>
          <img
            className="absolute right-1.5 top-3 "
            src={DownArrow}
            alt="Arrow"
          />
        </div>
        {showBlast && (
          <div className="border border-gray-800 p-2.5 absolute z-50 w-full bg-gray-150 dark:bg-slate-800">
            <div className="w-full max-w-[480px] overflow-auto max-h-[295px]">
              <div className="flex items-center">
                <input
                  className="w-full px-3 py-2.5 text-13 placeholder:text-gray-800 font-medium leading-3 text-gray-800 border border-gray-800 rounded dark:bg-slate-800 dark:text-white dark:focus:outline-none dark:placeholder:text-white mr-2.5"
                  type="search"
                  name="search"
                  value={formik.values.search}
                  placeholder="Filter Items"
                  onChange={formik.handleChange}
                />
              </div>
              {blasts.map((blast) => (
                <div
                  onClick={() => {
                    onBlastClickHandler(blast);
                  }}
                  key={blast}
                  className="py-1 px-3 mr-2.5 text-black-400 hover:bg-primaryHover hover:text-white dark:hover:text-black-400 dark:hover:bg-white dark:text-white cursor-pointer"
                >
                  <label className="text-sm font-normal leading-3 checkBox relative pl-1">
                    {blast}
                  </label>
                </div>
              ))}
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="mt-4 pt-4 border-t border-gray-800"
            >
              <label className="text-xs font-semibold leading-3 text-black-400 dark:text-white block">
                Create new tag
              </label>
              <div className="flex items-center mt-2.5">
                <input
                  className="border border-gray-800 placeholder:text-gray-500 rounded-md w-full max-w-md text-13 font-medium leading-3 sm:w-52 w-44 h-8 p-4 dark:bg-slate-800 dark:focus:outline-none dark:placeholder:text-white dark:text-white"
                  type="text"
                  placeholder="tag"
                  name="blastTag"
                  value={formik.values.blastTag}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <button
                  type="submit"
                  className="text-13 font-medium leading-5 rounded-md py-1.5 px-4 text-white bg-btnPrimary ml-5 ease-in duration-300 hover:bg-primary hover:scale-105"
                >
                  Add
                </button>
                {formik.errors.blastTag && formik.touched.blastTag ? (
                  <div className="text-xs leading-4 text-red-400 mt-1.5 ml-4">
                    {formik.errors.blastTag}
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};
export default TagBlast;
