import { FC, useEffect } from 'react';
import { useFormik } from 'formik';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import React from 'react';

interface Props {
  cancelClicked: () => void;
  createClicked: (mail: string) => void;
  mergeMail: string | undefined;
}

const EmailAlert: FC<Props> = ({ cancelClicked, createClicked, mergeMail }) => {
  const formik = useFormik({
    initialValues: { mail: '{{{email_address}}}' },
    onSubmit: () => console.log(),
  });

  useEffect(() => {
    formik.setFieldValue('mail', mergeMail);
  }, [mergeMail]);

  return (
    <ModalWrapper
      onClose={cancelClicked}
      buttonTxt="Ok"
      onSave={() => createClicked(formik.values.mail)}
    >
      <div className="w-full max-w-lg sm:px-0 px-3 m-auto fixed left-0 right-0 top-40 z-50">
        <div className="bg-white dark:bg-slate-800 p-5 rounded-lg border border-gray-800 dark:border-black-400">
          <p className="text-base font-normal leading-5 text-black-400 dark:text-white dark:text-white mb-8">
            {`The text ${formik.values.mail} will be replaced with a person's actual information`}
          </p>
          <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white inline-block inline-block mb-3">
            Click OK to insert this mail merge value.
          </label>
          <input
            className="w-full p-4 text-13 font-medium leading-3 dark:bg-slate-800 border border-gray-800 dark:border-black-400 rounded-md text-black-400 dark:text-white sm:h-11 h-9 focus:outline-none placeholder:text-gray-500"
            type="text"
            data-pw="merge-field"
            name="mail"
            value={formik.values.mail}
            placeholder="Email address"
            onChange={formik.handleChange}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};
export default EmailAlert;
