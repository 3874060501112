import { Form } from './SubscribersForm';
import { getField } from 'services/apiHandlers/List-Segments/Field';
import xmlToJSON from 'utils/xmlToJSON';
import { formatXmlJsonResponse } from 'utils/common';
import { z } from 'zod';
import ErrorBoundary from 'components/ErrorBoundary';
import FieldForm from './FieldForm';
import React, { useEffect, useState } from 'react';
import SelectFieldForm from './SelectFieldForm';
import { useParams } from 'react-router-dom';

const responseStatusSchema = z.object({ status: z.number() });
export type ResponseStatus = z.infer<typeof responseStatusSchema>;

const EditField: React.FC = () => {
  const [fieldData, setFieldData] = useState<Form>();
  const [loading, setLoading] = useState<boolean>(true);
  const {id} = useParams()
  
  const getSingleFieldData = async () => {
    setLoading(true);
    try {
      /* eslint-disable */
      const getFieldDataResponse: any = await getField(Number(id) || 0);
      const data: any = xmlToJSON.parseString(getFieldDataResponse.data);
      const fields: any = formatXmlJsonResponse(data.field);
      setFieldData(fields[0]);
      /* eslint-enable */
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getSingleFieldData();
  }, []);

  return (
    <ErrorBoundary>
      {!loading && (
        <div>
          {(fieldData?.['data-type'] === 'Select' ||
          fieldData?.['data-type'] === 'Checkbox' ||
          fieldData?.['data-type'] === 'Radio') && id ? (
            <SelectFieldForm
              fieldData={fieldData}
              id={parseInt(id)}
            />
          ) : (
            <FieldForm fieldData={fieldData}  />
          )}
        </div>
      )}
    </ErrorBoundary>
  );
};
export default EditField;
