import { z } from 'zod';

export const ListSchema = z.object({
  active_people_count: z.number(),
  audience_id: z.number(),
  channel_list: z.boolean(),
  checked_by_default: z.boolean().nullable(),
  created_at: z.string(),
  deleted_at: z.string().nullable(),
  description: z.string(),
  id: z.number(),
  inactive_people_count: z.number(),
  mutable: z.boolean(),
  name: z.string(),
  pending_people_count: z.number(),
  pretty_status: z.string(),
  public: z.string(),
  status: z.string(),
  trigger_name: z.string(),
  unsubscribed_people_count: z.number(),
  updated_at: z.string(),
});
