import { FC, SetStateAction, useEffect, useState } from 'react';
import { TimerActionData } from '../index.type';
import Dropdown from 'components/Dropdown/Dropdown';
import DelayDay from '../DelayDay';
import DelayTimeData from '../DelayTimeData';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import { z } from 'zod';
import React from 'react';

interface Props {
  data: TimerActionData | undefined;
  onClose: () => void;
  onSave: (data: TimerActionData) => void;
}

const checkDaySchema = z.object({
  day: z.string(),
  checked: z.boolean(),
});
type CheckDay = z.infer<typeof checkDaySchema>;

const timeOptions = ['minutes', 'hours', 'days'];
const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

interface TimeZoneType {
  [key: string]: string;
  Minutes: string;
  Days: string;
  Hours: string;
}

const timeData = z.object({
  resumeTime: z.boolean(),
  hours: z.number(),
  minutes: z.number(),
  timeZone: z.string(),
});
type TimeData = z.infer<typeof timeData>;

const DelayTime: FC<Props> = ({ data, onClose, onSave }) => {
  const [delayUnit, setDelayUnit] = useState<number>(1);
  const [delayType, setDelayType] = useState<string>('minutes');
  const [weekDay, setWeekDay] = useState<boolean>(false);
  const [checkedDays, setCheckedDays] = useState<Array<CheckDay>>([]);
  const [timeData, setTimeData] = useState<TimeData>({
    resumeTime: data?.useTimeOfDay || false,
    hours: data?.hour || 1,
    minutes: data?.minute || 1,
    timeZone: data?.ampm || 'AM',
  });
  const [error, setError] = useState<boolean>(false);
  // const [timeError, setTimeError] = useState<boolean>(false);
  const [formIsValid, setFormIsValid] = useState<boolean>(true);

  const checks = [
    data?.monday || false,
    data?.tuesday || false,
    data?.wednesday || false,
    data?.thursday || false,
    data?.friday || false,
    data?.saturday || false,
    data?.sunday || false,
  ];

  const timeZoneType: TimeZoneType = {
    Minutes: 'minutes',
    Hours: 'hours',
    Days: 'days',
  };

  const saveDataHandler = () => {
    if (weekDay && checkedDays.every((item) => !item.checked)) {
      setFormIsValid(false);
    } else {
      const timesData = {
        length: delayUnit,
        delayUnit: {
          _type: Object.keys(timeZoneType).find(
            (key) => timeZoneType[key] === delayType
          ) as string,
        },
        useDayOfWeek: weekDay,
        useTimeOfDay: timeData.resumeTime,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        hour: parseInt(timeData.hours),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        minute: parseInt(timeData.minutes),
        ampm: timeData.timeZone,
        timezone: data?.timezone || 'America/Los_Angeles',
        _type: 'DelayAction',
        sunday: checkedDays[6].checked || false,
        monday: checkedDays[0].checked || false,
        tuesday: checkedDays[1].checked || false,
        wednesday: checkedDays[2].checked || false,
        thursday: checkedDays[3].checked || false,
        friday: checkedDays[4].checked || false,
        saturday: checkedDays[5].checked || false,
      };
      onSave(timesData);
      onClose();
    }
  };

  useEffect(() => {
    const tempCheckDays = weekDays.map((day, index) => {
      return { day: day, checked: checks[index] };
    });
    if (data) {
      setDelayUnit(data.length);
      setDelayType(data.delayUnit._type.toLowerCase());
      setWeekDay(data.useDayOfWeek);
    }
    setCheckedDays(tempCheckDays as SetStateAction<CheckDay[]>);
  }, []);

  useEffect(() => {
    if (
      (timeData.hours && timeData.hours < 0) ||
      (timeData.minutes && timeData.minutes < 0)
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [timeData.minutes, timeData.hours]);

  return (
    <ModalWrapper
      disabled={!formIsValid}
      onSave={saveDataHandler}
      onClose={onClose}
    >
      <>
        <div className="bg-white dark:bg-black-400">
          <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium mb-8 dark:text-white">
            Action
          </h2>
          <div className="my-auto">
            <div>
              <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 block my-4 dark:text-white">
                Delay
              </label>
              <div className="flex items-center gap-3">
                <div className="relative flex items-center sm:h-11 h-9 bg-white w-3/12 dark:bg-black-400">
                  <input
                    className="text-13 font-medium leading-3 text-gray-500 py-2 px-3 w-full h-full border rounded-md border-gray-800 focus:outline-none focus:border-primary sm:h-11 h-9 dark:bg-slate-800 dark:text-white dark:border-black-400 dark:Placeholder:text-white"
                    type="number"
                    value={delayUnit}
                    onChange={(e) => setDelayUnit(parseInt(e.target.value))}
                    min={1}
                  />
                </div>
                <div className="z-10 bg-white dark:bg-slate-800 border-gray-200 dark:border-black-400 border rounded-lg dropdown-show-hide relative sm:h-11 h-9 w-6/12">
                  <Dropdown
                    options={timeOptions}
                    value={delayType}
                    onSelect={(value) => setDelayType(value)}
                  />
                </div>
              </div>
              <DelayDay
                week={weekDay}
                onWeekDayChange={(checked) => setWeekDay(checked)}
                checkedDays={checkedDays}
                onDaysChange={(days) => setCheckedDays(days)}
                error={!formIsValid}
                setError={(error) => setFormIsValid(error)}
              />
              <DelayTimeData
                timeData={timeData}
                onDataChange={(name, value) =>
                  setTimeData({ ...timeData, [name]: value })
                }
                error={error}
              />
            </div>
          </div>
        </div>
      </>
    </ModalWrapper>
  );
};
export default DelayTime;
