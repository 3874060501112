import React, { FC, useContext, useEffect, useState } from 'react';
import { z } from 'zod';
import { BlastSchema } from 'components/DashboardReports/Types';
import { ThemeContext } from 'store/ThemeContext';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import moment from 'moment';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { filters, listId, listSegments, subscribers, view,report,campaignId } from 'services/constant/routes';

type Blast = z.infer<typeof BlastSchema>;

interface Props {
  blastData: Blast | undefined;
  campaignsLoading: boolean;
}

const Header: FC<Props> = ({ blastData, campaignsLoading }) => {
  const [deliveryOptions, setDeliveryOptions] = useState<Array<string>>([]);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx?.theme;

  const listClickHandler = (id: string) => {
    searchParams.set('list_id', id);
    setSearchParams(searchParams);
    navigate(`${listSegments}?tab=subscriber&list_id=${id}`);
  };


  useEffect(() => {
    const tempDeliveryOptions = [];
    blastData?.blast.include_social_bar &&
      tempDeliveryOptions.push('Social Bar');
    blastData?.blast.track_clicks &&
      tempDeliveryOptions.push('Track Click-through Activity');
    blastData?.blast.google_analytics &&
      tempDeliveryOptions.push('Google Analytics Integrations');
    setDeliveryOptions(tempDeliveryOptions);
  }, [blastData]);

  return (
    <div className="md:px-6 px-3 pt-5 pb-8 font-inter">
      <div className="md:px-1.5">
        <div className="flex justify-between lg:items-center items-end mt-0.5 relative">
          <div className="lg:flex items-center">
            <h2 className="sm:text-xl text-base sm:leading-5 leading-5 sm:w-auto mr-3 text-212121 font-gilroyBold">
              {blastData?.blast.from_name}
            </h2>
          </div>
        </div>
        <div className="sm:flex md:justify-start justify-between md:flex-nowrap flex-wrap font-inter">
          <div className="lg:w-96 md:w-52 md:mr-0 mr-2.5 mt-6">
            <div className="">
              <p className="text-xs font-medium leading-3 tracking-wide uppercase text-616161 mb-2">
                sent by
              </p>
              <p className="text-xs font-semibold leading-4 text-616161">
                {campaignsLoading
                  ? '----------'
                  : blastData?.blast.from_email_address}
              </p>
            </div>
            {deliveryOptions.length > 0 && (
              <div className="mt-6 relative h-6">
                <div
                  onMouseLeave={() => setShowTooltip(false)}
                  className="group"
                >
                  <span
                    onMouseEnter={() => setShowTooltip(true)}
                    id="tooltip"
                    className="text-sm font-medium leading-4 text-ryzeoBlue underline"
                  >
                    Delivery Setting
                  </span>
                  <Tooltip
                    style={{
                      pointerEvents: 'auto',
                      backgroundColor: `${
                        theme === 'dark' ? '#1e293b' : 'white'
                      }`,
                      border: '1px solid #9e9e9e',
                      zIndex: '1',
                      opacity: '1',
                    }}
                    isOpen={showTooltip}
                    anchorId={'tooltip'}
                    classNameArrow="-bottom-[5px] border border-gray-500 border-t-0 border-l-0"
                  >
                    <div className="sm:px-6 px-3 py-4 w-11/12 mt-2 bg-white rounded boxShadow hidden group-hover:block">
                      <p className="text-sm font-medium leading-4 text-gray-700">
                        Delivery Setting
                      </p>
                      <ul className="list-disc pl-4 mt-1">
                        {deliveryOptions.map((option) => (
                          <li
                            key={option}
                            className="text-xs font-medium leading-4 text-gray-700 py-2"
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
          <div className="lg:w-96 md:w-64 md:mr-0 mr-2.5 mt-6">
            <div>
              <p className="text-xs font-medium leading-3 tracking-wide uppercase text-616161 mb-2">
                sent on
              </p>
              <p className="text-xs font-semibold leading-4 text-616161">
                {campaignsLoading
                  ? '----------'
                  : moment(blastData?.blast.updated_at).format('Do MMMM, YYYY')}
              </p>
            </div>
            <div className="mt-6 flex">
              <p className="text-xs font-medium leading-3 tracking-wide uppercase text-616161">
                Audience
              </p>
              <div className="flex flex-wrap items-center">
                <Link
                  to={`${listSegments}/${subscribers}?${campaignId}=${blastData?.blast.id}&${report}=true`}
                  className="text-xs font-semibold leading-4 text-616161 rounded-full py-2 sm:px-3.5 px-2.5 bg-[#f7f7f7] mr-3 mt-2"
                >    
                  {blastData?.blast.audience?.name} 
                </Link>
                <ul>
                  {blastData?.blast.audience?.included_list?.map((list) => (
                    <Link
                      to={`${listSegments}/${subscribers}?${campaignId}=${blastData?.blast.id}&${listId}=${list.id.toString()}&${report}=true`}
                      key={list.id}
                    >
                      <li className="text-xs font-semibold leading-4 text-616161 rounded-full py-2 sm:px-3.5 px-2.5 bg-[#f7f7f7] mt-2">
                        {list.name}
                      </li>
                    </Link>
                  ))}
                </ul>
                <ul>
                  {blastData?.blast.audience?.excluded_list?.map((list) => (
                    <div
                      onClick={() => listClickHandler(list.id.toString())}
                      key={list.id}
                    >
                      <li className="text-xs font-semibold leading-4 text-616161 rounded-full py-2 sm:px-3.5 px-2.5 bg-[#f7f7f7] mt-2">
                        {list.name}
                      </li>
                    </div>
                  ))}
                </ul>
                {blastData?.blast?.audience?.segment.name && (
                  <li>
                    <Link
                      to={`${listSegments}/${filters}/${view}/${blastData.blast.creator_id}?${campaignId}=${blastData?.blast.id}&${report}=true`}
                    >
                      {blastData.blast.audience.segment.name}
                    </Link>
                  </li>
                )}
              </div>
            </div>
          </div>
          <div className="mt-6">
            <p className="text-xs font-medium leading-3 tracking-wide uppercase text-616161 mb-2">
              created on
            </p>
            <p className="text-xs font-semibold leading-4 text-616161">
              {campaignsLoading
                ? '----------'
                : moment(blastData?.blast.created_at).format('Do MMMM, YYYY')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
