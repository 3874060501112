import DownArrow from 'assets/Images/Common/down-arrow.png';
import { useEffect, useState, FC } from 'react';
import {
  createIntegration,
  validateIntegration,
} from 'services/apiHandlers/Integration/CartIntegration';
import uuid from 'react-uuid';
import Description from './Description';
import * as Yup from 'yup';
import { useFormik, FormikValues } from 'formik';
import { Toastify } from 'App';
import { z } from 'zod';
import React from "react";

const validateBigCommerceSchema = z.object({
  accessToken: z.string(),
  id: z.string().optional(),
  clientId: z.string(),
  shouldGrabOrderHistory: z.boolean(),
  storeHash: z.string(),
  uniqueIdConstraint: z.string(),
  _type: z.string(),
});
type validateBigCommerce = z.infer<typeof validateBigCommerceSchema>;

const validateResponseSchema = z.object({
  status: z.number(),
});
type validateResponse = z.infer<typeof validateResponseSchema>;

interface Props {
  removeBigCommerce: () => void;
  getData: () => void;
  data?: validateBigCommerce | undefined;
}

const bigCommerceData = [
  { name: 'storeHash', placeholder: 'Store Hash' },
  { name: 'clientId', placeholder: 'Client ID' },
  { name: 'accessToken', placeholder: 'Access Token' },
];

const radioOptions = [
  {
    title: 'store + orderId combination',
    id: '1',
    defaultChecked: true,
    value: 'composite',
  },
  { title: 'orderId only', id: '2', value: 'single' },
];

const BigCommerceForm: FC<Props> = ({ removeBigCommerce, data, getData }) => {
  const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(true);
  const [isValidData, setIsValidData] = useState<boolean>(false);

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      clientId: '',
      accessToken: '',
      storeHash: '',
      uniqueIdConstraint: 'composite',
      shouldGrabOrderHistory: true,
    },
    validationSchema: Yup.object({
      clientId: Yup.string().required('Required'),
      accessToken: Yup.string().required('Required'),
      storeHash: Yup.string().required('Required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = {
        ...values,
        id: uuid(),
        _type: 'bigcommerce',
      };
      try {
        const createBigCommerceResponse = (await createIntegration(
          data
        )) as validateResponse;
        if (createBigCommerceResponse.status === 200) {
          setIsValidData(false);
          setInvalidCredentials(false);
          getData();
          Toastify(
            'Big Commerce Created Successfullly',
            'success',
            'bigCommerce1'
          );
          resetForm();
        }
      } catch (error) {
        Toastify('Big Commerce Creation Failed', 'error', 'bigCommerce2');
      }
    },
  });

  const checkValidation = async () => {
    {
      if (
        formik.values.accessToken.length > 0 &&
        formik.values.clientId.length > 0 &&
        formik.values.storeHash.length > 0
      ) {
        const data = {
          ...formik.values,
          id: '07a47b13-e168-4995-96da-f5d4abffae2f',
          _type: 'bigcommerce',
        };
        try {
          const validateBigCommerceResponse = (await validateIntegration(
            data
          )) as validateResponse;
          if (validateBigCommerceResponse.status === 200) {
            setIsValidData(true);
          } else {
            setInvalidCredentials(true);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  useEffect(() => {
    const checkInputValidation = setTimeout(() => {
      checkValidation();
    }, 3000);
    return () => clearTimeout(checkInputValidation);
  }, [formik.values]);

  useEffect(() => {
    data ? formik.setValues(data) : formik.setValues(formik.initialValues);
  }, []);

  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className="bg-white mt-6 p-4 bg-[#f5f5f5] border dark:border-black-400 rounded dark:bg-slate-800"
      >
        <h4 className="text-13 mt-5 font-semibold leading-4 text-black-700 dark:text-white mb-3">
          Big Commerce
        </h4>
        <Description title="Big Commerce" />
        {bigCommerceData.map((item) => (
          <div key={item.name}>
            <div>
              <input
                className="w-full text-13 focus:border-primary placeholder:text-gray-500 dark:focus:border-white focus:outline-none font-medium leading-3 text-gray-700 dark:text-white py-2 rounded-md px-4 bg-white dark:bg-slate-800 border border-gray-800 dark:border-black-400 sm:h-11 h-9 mt-4 focus:outline-none"
                type="text"
                placeholder={item.placeholder}
                name={item.name}
                value={formik.values[item.name]}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        ))}
        <div className="relative flex items-center cursor-pointer mt-4">
          <input
            id="checkBox1"
            className="cursor-pointer"
            name="shouldGrabOrderHistory"
            value="checked"
            type="checkbox"
            defaultChecked
            onChange={formik.handleChange}
          />
          <label
            className="checkBox relative cursor-pointer pl-2"
            htmlFor="checkBox1"
          >
            <span className="text-13 font-normal leading-3 text-black-700 dark:text-white">
              Grab Order History (Last 2 year Orders data)
            </span>
          </label>
        </div>
        <div>
          <div
            onClick={() => setShowOptions((prev) => !prev)}
            className="inline-flex items-center relative mt-4 cursor-pointer"
          >
            <p className="text-xs font-semibold leading-4 text-black-700 dark:text-white">
              Advance Configuration Options
            </p>
            <img className="pl-2" src={DownArrow} alt="Arrow" />
          </div>
          {showOptions && (
            <>
              <p className="text-xs font-medium leading-3 text-primary dark:text-white mt-4 mb-2">
                Unique OrderId Constraint:
              </p>
              {radioOptions.map((option) => (
                <div
                  key={option.id}
                  className="radio-btn block relative flex items-center text-13 font-normal leading-3 text-black-400 dark:text-white dark:text-white mt-4"
                >
                  <input
                    id={option.title}
                    type="radio"
                    value={option.value}
                    name="uniqueIdConstraint"
                    className="w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 mr-2"
                    defaultChecked={option.defaultChecked}
                    checked={formik.values.uniqueIdConstraint === option.value}
                    onChange={formik.handleChange}
                  />
                  <label
                    htmlFor={option.title}
                    className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white inline-block inline-block"
                  >
                    {option.title}
                  </label>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="flex flex-wrap items-center mt-4">
          <button
            type="submit"
            className={` ${
              !isValidData
                ? 'bg-gray-800 text-black-400 dark:text-white font-nomral '
                : 'bg-primary text-white '
            }text-13 font-medium leading-5 rounded py-2 px-4 dark:hover:bg-white dark:hover:text-black-400 dark:text-white mr-4 mt-4`}
            disabled={!isValidData}
          >
            Save
          </button>
          <button
            type="button"
            className="text-13 font-medium leading-5 rounded py-2 px-4 text-white bg-[#B31E33] dark:hover:bg-white dark:hover:text-[#b31e33] mr-4 mt-4"
            onClick={removeBigCommerce}
          >
            Remove
          </button>
          <a
         
            className="text-sm leading-4 text-[#cb5b57] dark:text-white mt-4"
          >
            {invalidCredentials
              ? 'invalid Credentials'
              : !isValidData
              ? 'All fields are required'
              : ''}
          </a>
        </div>
      </form>
    </div>
  );
};
export default BigCommerceForm;
