import upArrow from 'assets/Images/Dashboard/up-arrow.png';
import DownArrow from 'assets/Images/svg-images/down.svg';
import React, { FC, useEffect, useState } from 'react';
import { BlastSchema } from 'components/DashboardReports/Types';
import { z } from 'zod';
import { calculatePercentage } from 'utils/common';
import { getAllBlasts } from 'services/apiHandlers/Dashboard/Analytics';

const reachCampaignDataSchema = z.object({
  title: z.string(),
  totalNumber: z.number(),
  percentage: z.number(),
});

const getFilteredCampaignsDataSchema = z.object({
  data: z.object({ collection: z.array(BlastSchema) }),
  status: z.number(),
});

type ReachCampaignsData = z.infer<typeof reachCampaignDataSchema>;
type Blast = z.infer<typeof BlastSchema>;
type GetFilteredCampaignResponse = z.infer<
  typeof getFilteredCampaignsDataSchema
>;
interface Props {
  blastData: Blast | undefined;
}

const Reach: FC<Props> = ({ blastData }) => {
  const [reachedCampaignsData, setReachCampaignsData] =
    useState<Array<ReachCampaignsData>>();
  const [filteredCampaigns, setFilteredCampaigns] = useState<Array<Blast>>([]);

  const getLatestCampaigns = async () => {
    try {
      const getFilteredCampaignsResponse = (await getAllBlasts('sending', {
        page: 1,
        per_page: 10,
      })) as GetFilteredCampaignResponse;
      if (getFilteredCampaignsResponse.status === 200) {
        setFilteredCampaigns(getFilteredCampaignsResponse.data.collection);
      }
    } catch (error) {
      console.log('error is :', error);
    }
  };

  useEffect(() => {
    const tempReachCampaignData: ReachCampaignsData[] = [];

    const filteredSentMails = filteredCampaigns.reduce(
      (accumulator, campaign) => {
        if (campaign.blast.delivered) {
          return accumulator + campaign?.blast?.delivered;
        } else {
          return accumulator + 0;
        }
      },
      0
    );

    const filteredOpens = filteredCampaigns.reduce((accumulator, campaign) => {
      return accumulator + campaign.blast.unique_views_count;
    }, 0);

    const filteredClicks = filteredCampaigns.reduce((accumulator, campaign) => {
      return accumulator + campaign.blast.unique_clicks_count;
    }, 0);

    const sentMailPercentage = calculatePercentage(
      blastData?.blast.delivered || 0,
      filteredSentMails
    );

    const openCampaignPercentage = calculatePercentage(
      blastData?.blast.unique_views_count || 0,
      filteredOpens
    );

    const clickCampaignPercentage = calculatePercentage(
      blastData?.blast.unique_clicks_count || 0,
      filteredClicks
    );

    tempReachCampaignData.push(
      {
        title: 'Total Emails Sent',
        totalNumber: blastData?.blast.delivered || 0,
        percentage: sentMailPercentage,
      },
      {
        title: 'Opens',
        totalNumber: blastData?.blast.unique_views_count || 0,
        percentage: openCampaignPercentage,
      },
      {
        title: 'Clicks',
        totalNumber: blastData?.blast.unique_clicks_count || 0,
        percentage: clickCampaignPercentage,
      },
      {
        title: 'Deliverability',
        totalNumber: blastData?.blast.bounced || 0,
        percentage: calculatePercentage(
          blastData?.blast.bounced || 0,
          blastData?.blast.unique_views_count || 0
        ),
      }
    );
    setReachCampaignsData(tempReachCampaignData);
  }, [blastData, filteredCampaigns]);

  useEffect(() => {
    getLatestCampaigns();
  }, []);

  return (
    <div>
      <h1 className="sm:text-xl text-base sm:leading-5 leading-3 text-black dark:text-white font-medium mt-12">
        Reach
      </h1>
      <div className="grid md:grid-cols-4 md:grid-rows-1 xl:gap-5 md:gap-3 font-inter">
        {reachedCampaignsData?.map((reach, index) => (
          <div
            key={index}
            className="sm:w-auto w-full sm:max-w-none max-w-xs sm:mx-0 mx-auto mt-4"
          >
            <div className="border dark:border-black-400 border-gray-100 rounded-lg border-md shadow-lg shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800 pt-5 xl:pl-7 lg:pl-3.5 md:pl-2 pl-3.5 bg-white">
              <p className="text-sm font-medium leading-4 text-headingColor tracking-wide mb-2.5 text-gray-700 dark:text-white break-all">
                {reach.title}
              </p>
              <div className="flex justify-between relative">
                <div className="mb-6">
                  <h2 className="lg:text-28 text-lg leading-10 font-medium text-black-300 dark:text-white">
                    {reach.totalNumber}
                  </h2>
                  <div className="flex items-center mt-2.5">
                    <span>
                      <img
                        src={reach.percentage < 0 ? DownArrow : upArrow}
                        alt="Up Arrow"
                      />
                    </span>
                    <span
                      className={`${
                        reach.percentage < 0 ? 'text-red-400' : 'text-green-400'
                      } text-15 font-gilroySemibold leading-4 ml-1 `}
                    >
                      {Number.isNaN(reach.percentage) ? 0 : reach.percentage}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Reach;
