import React, { SetStateAction, useState, FC, useEffect } from 'react';
import Select, { components, InputProps, MenuProps } from 'react-select';
import { z } from 'zod';
import styled from 'styled-components';

const optionSchema = z.object({ value: z.string(), label: z.string() });
type Options = z.infer<typeof optionSchema>;

interface CustomInputProps extends InputProps {
  InputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  getMenuProps?: (arg0?: MenuProps) => void;
}

interface Props {
  selectedTags: string[];
  onTagsSelect: (tags: string[]) => void;
  tagOptions: Options[];
}

const StyleWrapper = styled.div`
  .css-1p3m7a8-multiValue {
    background-color: #3575d5;
    color: white;
    border-radius: 16px;
  }

  .css-wsp0cs-MultiValueGeneric {
    color: white;
    font-size: 12px;
    font-weight: 500;
  }

  .css-b62m3t-container {
    height: 44px;
  }

  .css-13cymwt-control {
    height: 44px;
  }

  .css-12a83d4-MultiValueRemove:hover {
    background-color: transparent;
    color: transparent;
  }

  .css-12a83d4-MultiValueRemove:hover svg {
    color: #fff;
  }
`;

const Dropdown: FC<Props> = ({ selectedTags, onTagsSelect, tagOptions }) => {
  const [selectedOptions, setSelectedOptions] = useState<Array<Options>>([]);
  const [options, setOptions] = useState<Array<Options>>([]);

  const handleAddOption = (option: string) => {
    const newOption = { value: option, label: option };
    const tempOptions = selectedOptions.map((option) => {
      return option.label;
    });
    tempOptions.push(option);
    onTagsSelect(tempOptions);
    setSelectedOptions([...selectedOptions, newOption]);
  };

  // const MenuListComponent = (props: any) => {
  //   const { selectProps } = props;
  //   const { menuIsOpen } = selectProps;
    
  //   console.log("Select props are : " , selectProps)

  //   // Render the menu only if `menuIsOpen` is true
  //   return menuIsOpen ? <components.MenuList {...props} /> : <div></div>;
  // };

  const InputComponent = (props: CustomInputProps) => {
    const { InputProps , selectProps } = props;
    const {onMenuClose} = selectProps
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
      onMenuClose();
    };

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!inputValue) return;
      if (e.key === 'Enter') {
        handleAddOption(inputValue);
        setInputValue('');
        e.preventDefault();
      }
    };

    return (
      <components.Input
        {...props}
        {...InputProps}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        onBlur={() => {
          if (inputValue !== '') {
            handleAddOption(inputValue);
            setInputValue('');
          }
        }}
      />
    );
  };

  useEffect(() => {
    setOptions(tagOptions);
    if (selectedTags) {
      const tempOptions = selectedTags.map((tag) => {
        return { value: tag, label: tag };
      });
      setSelectedOptions(tempOptions);
    }
  }, [selectedTags]);
  return (
    <div className="mt-4">
      <StyleWrapper>
        <Select
          options={options}
          value={selectedOptions}
          onChange={(option) => {
            setSelectedOptions(option as SetStateAction<Options[]>);
            const tempOptions = option.map((option) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return option.label;
            });
            onTagsSelect(tempOptions);
          }}
          isMulti
          placeholder="Add a tag"
          components={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Input: InputComponent,
            // MenuList: MenuListComponent,
          }}
        />
      </StyleWrapper>
    </div>
  );
};

export default Dropdown;
