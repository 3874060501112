import {
  RefObject,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';
import React from 'react';

interface Props {
  status: boolean;
  deliveryRef: RefObject<HTMLDivElement>;
  onChangeStatus: (value: boolean) => void;
}

const DeliveryOptions: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { status, deliveryRef, onChangeStatus },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref
) => {
  return (
    <div ref={deliveryRef}>
      <div className="flex items-center mt-4">
        <input
          className="w-[18px] h-[18px] rounded-sm accent-blue-600 mr-2"
          type="checkbox"
          id="unsubscriber"
          name="Unsubscriber"
          checked={status}
          onChange={(e) => onChangeStatus(e.target.checked)}
        />
        <label className="font-sm font-normal leading-4 text-black-400">
          Ignore Unsubscribe Status
        </label>
      </div>
    </div>
  );
};
export default forwardRef(DeliveryOptions);
