import Skeleton from 'components/Skeleton/Skeleton';
import React, { useContext } from 'react';
import DataTable, { TableProps } from 'react-data-table-component';
import { ThemeContext } from 'store/ThemeContext';
import Pagination from './Pagination';
import ErrorBoundary from 'components/ErrorBoundary';

const selectProps = {
  indeterminate: (isIndeterminate: boolean) => isIndeterminate,
};

function DataTableBase<T>(props: TableProps<T>): JSX.Element {
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx?.theme;
  const customStyles = {
    rows: {
      style: {
        color: '#616161',
        fontSize: '14px',
        borderRight: '1px',
        backgroundColor: 'dark:',
        '&:hover': {
          backgroundColor: `${theme === 'dark' ? '#505050' : '#f9f9f9'} `,
        },
      },
    },

    cells: {
      style: {
        display: 'flex',
      },
    },

    headCells: {
      style: {
        color: `${theme === 'dark' ? 'text-white' : '#757575'}`,
        fontSize: '14px',
        height: '44px',
        display: 'flex',
      },
    },
  };

  const options = {
    noRowsPerPage: true,
    rowsPerPageText: '100',
    rangeSeparatorText: '0',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'true',
  };

  return (
    <ErrorBoundary>
      <div className="z-50">
        <DataTable
          pagination
          selectableRowsComponentProps={selectProps}
          dense
          customStyles={customStyles}
          progressComponent={<Skeleton columns={props.columns.length} />}
          {...props}
          theme={theme ? theme : 'light'}
          paginationComponent={Pagination}
          paginationComponentOptions={options}
          defaultSortAsc={false}
        />
      </div>
    </ErrorBoundary>
  );
}

export default DataTableBase;
