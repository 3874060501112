import BarGraph from 'components/Graph/BarGraph';
import { ChartDataset } from 'chart.js';
import OrderStats from './OrderStats';
import { useContext, useEffect, useState } from 'react';
import { DashboardContext } from 'store/DashboardContext';
import { SingleWorkflow } from './Types';
import { z } from 'zod';
import React from 'react';
import moment from 'moment';
import Skeleton from 'components/Skeleton/Skeleton';

const groupedDataSchema = z.record(
  z.object({
    date: z.string(),
    conversions: z.number(),
  })
);

const ConversionSchema = z.object({
  workflows: z.number(),
  campaigns: z.number(),
});

const ChartDataSchema = z.object({
  barThickness: z.number(),
  barPercentage: z.number(),
  backgroundColor: z.string(),
  label: z.string(),
  data: z.array(z.string()),
});
const ChartSchema = z.array(ChartDataSchema);

type Conversion = z.infer<typeof ConversionSchema>;
type ChartData = z.infer<typeof ChartSchema>;
type GroupedData = z.infer<typeof groupedDataSchema>;

const Orders = () => {
  const dashboardCtx = useContext(DashboardContext);
  const [totalOrders, setTotalOrders] = useState<string>();
  const [chartData, setChartData] = useState<ChartData>([]);
  const [chartLabels, setChartLabels] = useState<Array<string | undefined>>();
  const [conversions, setConversions] = useState<Conversion>({
    workflows: 0,
    campaigns: 0,
  });
  const [ordersLoading, setOrdersLoading] = useState<boolean>(true);

  const getData = () => {
    setOrdersLoading(true);
    const tempChartData = [];
    const tempConversion = conversions;
    let tempChartLabels: string[] = [];
    let campaignConversions: string[] = [];

    const tempWorkflowDates = dashboardCtx?.orderDates.map((data) =>
      moment(data).format('MMM DD')
    );

    const tempDates = tempWorkflowDates?.filter(
      (item, i, ar) => ar.indexOf(item) === i
    );
    tempChartLabels = tempDates || [];

    setTotalOrders(dashboardCtx?.weeklyRoiData?.orders);

    const workflowConversions = dashboardCtx?.workflows.map(
      (data: SingleWorkflow) => String(data.uniqueConversions)
    );
    tempChartData.push({
      barThickness: 16,
      barPercentage: 0.5,
      backgroundColor: '#8FDBC0',
      label: 'Revenue from Workflows',
      data: workflowConversions ? workflowConversions : [],
    });
    tempConversion.workflows = dashboardCtx?.workflows
      ? dashboardCtx?.workflows.reduce(
          (accumulator: number, currentValue: SingleWorkflow) =>
            accumulator + currentValue.uniqueConversions,
          tempConversion.workflows
        )
      : 0;

    tempConversion.campaigns = dashboardCtx?.campaigns
      ? dashboardCtx?.campaigns.reduce(
          (accumulator, currentValue) =>
            accumulator +
            parseInt(currentValue.conversions ? currentValue.conversions : '0'),
          tempConversion.campaigns
        )
      : 0;

    const groupedCampaigns = dashboardCtx?.campaigns.reduce(
      (result: GroupedData, item) => {
        const { date, conversions } = item;
        if (date && conversions) {
          if (!result[date]) {
            result[date] = { date, conversions: 0 };
          }
          result[date].conversions += parseInt(conversions);
        }
        return result;
      },
      {}
    );

    if (groupedCampaigns) {
      tempChartLabels = tempChartLabels.concat(
        Object.values(groupedCampaigns).map((data) => String(data.date))
      );
      campaignConversions = Object.values(groupedCampaigns).map((data) =>
        String(data.conversions)
      );
    }

    tempChartData.push({
      barThickness: 16,
      barPercentage: 0.5,
      backgroundColor: '#2EA1F9',
      label: 'Revenue from campaigns',
      data: campaignConversions ? campaignConversions : [],
    });

    const sortedDates = tempChartLabels.sort((a, b) =>
      moment(a, 'MMM DD').diff(moment(b, 'MMM DD'))
    );

    setChartLabels(sortedDates);
    setChartData(tempChartData as ChartData);
    setConversions(tempConversion);
    setOrdersLoading(false);
  };
  useEffect(() => {
    getData();
    //need to dicusss in detail
  }, [dashboardCtx?.orderDates]);
  return (
    <div className="mt-11 ">
      <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black dark:text-white font-medium">
        Orders
      </h2>
      <div>
        <div className="flex md:flex-row justify-between flex-col font-inter mt-4">
          <div className="w-full">
            <div className="xl:px-7 lg:px-5 px-3.5 sm:py-4 py-2.5 rounded-lg shadow-lg shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800">
              <div className="flex xl:flex-row flex-col">
                <OrderStats
                  totalOrders={totalOrders}
                  conversions={conversions}
                />
                <div className="w-full max-w-8/12 xl:pl-10">
                  {ordersLoading || dashboardCtx?.loading ? (
                    <Skeleton columns={10} />
                  ) : (
                    <BarGraph
                      height={480}
                      stacked={true}
                      chartLabels={chartLabels}
                      datasets={
                        chartData as unknown as ChartDataset<
                          'bar',
                          (number | [number, number] | null)[]
                        >[]
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Orders;
