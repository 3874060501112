import Edit from 'assets/Images/ListsegmentImages/edit.png';
import Delete from 'assets/Images/ListsegmentImages/delete.png';
import Plus from 'assets/Images/ListsegmentImages/plus.png';
import { List } from '.';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { deleteList } from 'services/apiHandlers/List-Segments/SegmentsList';
import DeleteModal from 'components/Alert/DeleteModal';
import { z } from 'zod';
import { Toastify } from 'App';
import React from 'react';
import { add, list, listSegments } from 'services/constant/routes';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { ThemeContext } from 'store/ThemeContext';

interface Props {
  rowData: List;
  index: number | null;
  getData?: () => void;
}

const ResponseSchema = z.object({ status: z.number() });
type Response = z.infer<typeof ResponseSchema>;

const ListActions = ({ rowData, index, getData }: Props) => {
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [anchorId, setAnchorId] = useState<string>('');
  const [selectedName, setSelectedName] = useState<string | null>(null);
  const themeCtx = useContext(ThemeContext);

  const deleteHandler = async (id?: number, deleteListItem?: boolean) => {
    setShowDeleteAlert(true);

    if (deleteListItem) {
      try {
        const deleteListResponse = (await deleteList(id)) as Response;
        if (deleteListResponse.status === 200) {
          Toastify('List Deleted Successfully', 'success', 'ListActions1');
          getData && getData();
        }
      } catch (error) {
        Toastify('Error Deleting List', 'error', 'ListActions2');
      }
    }
  };

  return (
    <div
      onMouseLeave={() => setSelectedName(null)}
      className="whitespace-nowrap py-3 text-13 sm:align-top align-middle text-black-400 dark:text-white dark:text-white md:w-[400px] md:max-w-[400px] w-[200px] min-w-[200px] flex flex-wrap items-center dark:text-white"
    >
      <div className="flex items-center">
        <Link
          to={`${listSegments}/${list}/${add}/${rowData.id}`}
          data-pw={`${index === 0 ? 'first-add' : ''}`}
          id={index === 0 ? rowData.id.toString() : ''}
          className="mr-4 hover:text-[#3575d5]"
        >
          <img
            src={Plus}
            alt="Edit"
            className="inline mr-1.5 bg-[#3575d5] rounded-full h-4 w-4"
          />
          <span>Add</span>
        </Link>
        <Link
          to={`${listSegments}/${list}/${rowData.id}`}
          className="mr-4 hover:text-[#3575d5]"
          data-pw={`${index === 0 ? 'first-edit' : ''}`}
          id={index === 0 ? rowData.id.toString() : ''}
        >
          <img
            src={Edit}
            alt="Edit"
            className="inline mr-1.5 dark:invert dark:brightness-0"
          />
          <span>Edit</span>
        </Link>
        {rowData.trigger_name?.length > 1 ? (
          <div>
            <div className="flex items-center my-1.5 hover:text-[#3575d5]">
              Locked
            </div>
            <div className="inline-block relative">
              <div
                className="w-4 h-4  rounded-full bg-gray-600 flex items-center justify-center"
                id={`tooltip-anchor-children-${index}`}
                onMouseEnter={() => {
                  setAnchorId(`tooltip-anchor-children-${index}`);
                  setSelectedName(rowData.name);
                }}
              >
                ?
              </div>
              <Tooltip
                style={{
                  pointerEvents: 'auto',
                  backgroundColor: `${
                    themeCtx?.theme === 'dark' ? '#1e293b' : 'white'
                  }`,
                  border: '1px solid #9e9e9e',
                  zIndex: '1',
                  opacity: '1',
                }}
                isOpen={selectedName === rowData.name}
                anchorId={anchorId}
                classNameArrow="-bottom-[5px] border border-gray-500 border-t-0 border-l-0"
              >
                <div className="text-black-400 dark:text-white w-60">
                  <div className="flex gap-1 items-center">
                    <h1 className="font-bold text-lg py-2">
                      Used by a Trigger
                    </h1>
                    <span>
                      Filter cannot be deleted because it is being used by the
                      filter trigger:
                      <span className="font-bold">{rowData.trigger_name}</span>
                    </span>
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
        ) : (
          <button
            onClick={() => deleteHandler(rowData?.id)}
            type="button"
            className="flex items-center my-1.5 hover:text-[#3575d5]"
            id={rowData.id.toString()}
          >
            <img
              className="inline mr-1.5 dark:invert dark:brightness-0"
              src={Delete}
              alt="Delete"
            />
            <span>Delete</span>
          </button>
        )}
        {showDeleteAlert && (
          <div className="fixed left-0 top-0 w-full h-full z-10 bg-black-200 duration-300">
            <DeleteModal
              onDelete={() => deleteHandler(rowData?.id, true)}
              onCancel={() => setShowDeleteAlert(false)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default ListActions;
