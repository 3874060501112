import SearchImage from 'assets/Images/Common/search.png';
import { Link } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import React from 'react';
import {
  create,
  emailTemplates,
  filters,
  listSegments,
} from 'services/constant/routes';

interface Props {
  setSearch: Dispatch<SetStateAction<string>>;
  searchQuery: string;
}
const FilterHeader: React.FC<Props> = ({ setSearch, searchQuery }: Props) => {
  return (
    <div>
      <div>
        <div>
          <div className="flex justify-between items-center">
            <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black dark:text-white font-medium">
              Filters
            </h2>
            <Link
              to={`${listSegments}/${filters}/${create}`}
              className="text-sm font-medium leading-4 bg-primary px-4 py-2.5 rounded-md shadow-lg shadow-gray-200 dark:shadow-none ease-in duration-300 hover:bg-primaryHover h-11 w-32 text-white hover:scale-105"
            >
              Create Filter
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <div className="sm:px-6 px-4 sm:py-4 py-2.5 border dark:border-black-400 rounded-lg shadow-lg shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800">
          <div>
            <h4 className="text-sm font-semibold leading-4 text-black-700 dark:text-white mb-3">
              Find exactly who you want
            </h4>
            <p className="text-sm font-normal leading-5 text-gray-700 dark:text-white mb-1">
              A filter is a set of rules that describe a set of subscribers.
            </p>
            <p className="text-sm font-normal leading-5 text-gray-700 dark:text-white">
              You can use filters for anything; from
              <Link
                to={`${emailTemplates}`}
                className="text-primary font-medium px-1 cursor-pointer"
              >
                sending
              </Link>
              a blast and for creating a
              <Link
                to={`${listSegments}`}
                className="text-primary font-medium px-1 cursor-pointer"
              >
                list.
              </Link>
              {/* , to even
              <a className="text-primary font-medium px-1 cursor-pointer">
                removing people
              </a>
              from a database. */}
            </p>
          </div>
          <div className="flex justify-between items-center mt-7">
            <div className="w-full sm:h-11">
              <div className="relative flex items-center sm:py-2 py-1 px-4 border rounded-md border-gray-800 dark:border-black-400 bg-white dark:bg-slate-800 dark:border-black-400 sm:h-11 h-9">
                <input
                  className="w-full pl-6 text-13 font-medium leading-3 dark:bg-slate-800 text-black-400 dark:text-white focus:outline-none placeholder:text-gray-700 dark:placeholder:text-white"
                  type="text"
                  name="search"
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                  value={searchQuery}
                  data-pw="search-filter"
                />
                <img
                  className="absolute dark:invert dark:brightness-0"
                  src={SearchImage}
                  alt="Search"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FilterHeader;
