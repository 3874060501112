import Plus from 'assets/Images/svg-images/plus.svg';
import Modal from 'components/Alert/Modal';
import InitialTag from './Modals/InitialTag';
import InitialEvent from './Modals/InitialEvent';
import { FC, useContext, useState } from 'react';
import { InitialEventData, TagsConditionData } from './index.type';
import { WorkflowContext } from 'store/WorkflowContext';
import React from "react"

interface Props {
  onSaveData: () => void;
}

interface Modal {
  [key: string]: JSX.Element;
  event: JSX.Element;
  tag: JSX.Element;
}

const StartTrigger: FC<Props> = ({ onSaveData }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('event');
  const workflowCtx = useContext(WorkflowContext);

  const saveDataHandler = (data: TagsConditionData | InitialEventData) => {
    const savedData = {
      initialConditionGroup: data,
      workflow: null,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    workflowCtx?.workflowChangeHandler(savedData);
    onSaveData();
  };

  const selectedModal: Modal = {
    event: (
      <InitialEvent
        onClose={() => setShowModal(false)}
        onTagSelect={() => {
          setModalType('tag');
        }}
        onSave={(data) => saveDataHandler(data)}
      />
    ),
    tag: (
      <InitialTag
        onClose={() => setShowModal(false)}
        onEventSelect={() => {
          setModalType('event');
        }}
        onSave={(data) => saveDataHandler(data)}
      />
    ),
  };

  return (
    <div className="mt-8">
      <div className="flex justify-between items-center mb-6"></div>
      <div
        className="border border-gray-100 rounded-lg boxShadow border-md bg-white py-6 px-4"
        style={{ backgroundImage: 'url("images/dots-background.png")' }}
      >
        <div className="w-full mx-auto px-1 pt-7 text-center">
          <button
            onClick={() => setShowModal(true)}
            className="text-13 font-medium leading-5 mx-auto text-white flex items-center ml-auto rounded-md py-3 sm:pl-4 px-2 sm:pr-3.5 font-inter bg-primary hover:bg-primaryHover hover:scale-105 ease-in duration-300"
          >
            <img className="mr-2.5" src={Plus} alt="Plus" />
            Start Trigger
          </button>
          <Modal isOpen={showModal}>
            {selectedModal[modalType]}
          </Modal>
        </div>
      </div>
    </div>
  );
};
export default StartTrigger;
