import { useState, useEffect, createContext } from 'react';
import React from "react"

interface ContextType {
  theme: string | null;
  colorTheme: string;
  themeChangeHandler: (theme: string) => void;
}

export const ThemeContext = createContext<ContextType | null>(null);
type Props = {
  children: JSX.Element;
};
export const ThemeContextProvider = ({ children }: Props) => {

  const [theme, setTheme] = useState<string>('light');

  const colorTheme = theme === 'dark' ? 'light' : 'dark';

  const themeChangeHandler = (changedTheme: string) => {
    setTheme(changedTheme);
    localStorage.setItem('theme', changedTheme);
  };

  useEffect(() => {
    if (
      localStorage.theme === 'dark' ||
      (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  }, []);

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove(colorTheme);
    root.classList.add(theme);
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        colorTheme,
        themeChangeHandler
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
