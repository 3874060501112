import { FC, useContext, useEffect, useState } from 'react';
import { WebhookConditionData } from '../index.type';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { WorkflowContext } from 'store/WorkflowContext';
import {
  getWebhookEvents,
  sendWebhookRequest,
} from 'services/apiHandlers/Workflows/Workflow';
import { z } from 'zod';
import Dropdown from 'components/Dropdown/valueDropdown';
import ReactJson from 'react-json-view';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import styled from 'styled-components';
import React from 'react';
import Loader from 'components/Loader';

const attributeSchema = z.object({
  productCost: z.string(),
  ip: z.string(),
  path: z.string(),
  hostname: z.string(),
  customer_id: z.string(),
  url: z.string(),
  personIdentifier: z.string(),
  userAgent: z.string(),
  productTitle: z.string(),
  ga_client_id: z.string(),
  referrerHostname: z.string(),
  currencyType: z.string(),
  timestampOffset: z.string(),
  productURL: z.string(),
  referrer: z.string(),
  title: z.string(),
  anonymousId: z.string(),
  productImage: z.string(),
});

const webhookConditionsSchema = z.object({
  name: z.string(),
  accountId: z.number(),
  attributes: attributeSchema,
  timestamp: z.number(),
  id: z.string(),
});

const responseSchema = z.object({
  data: z.object({
    next_start_token: z.string(),
    result: z.array(webhookConditionsSchema),
    totalCount: z.string(),
  }),
  status: z.number(),
});
const eventSchema = z.array(webhookConditionsSchema);

const options = z.object({
  name: z.string(),
  value: z.string(),
});

const sendTestRequestSchema = z.object({
  event: z.union([webhookConditionsSchema, z.undefined()]),
  url: z.string(),
  regex: z.string(),
});

const webhookResponseDataSchema = z.object({
  path: z.boolean(),
  responseBody: z.string(),
});

const webhookResponseSchema = z.object({
  data: webhookResponseDataSchema,
  status: z.number(),
});

type WebhookResponse = z.infer<typeof webhookResponseSchema>;
export type SendTestRequest = z.infer<typeof sendTestRequestSchema>;
type Response = z.infer<typeof responseSchema>;
type Event = z.infer<typeof eventSchema>;
type Options = z.infer<typeof options>;
type RequestData = z.infer<typeof webhookConditionsSchema>;
interface Props {
  onClose: () => void;
  data: WebhookConditionData | undefined;
  onSave: (data: WebhookConditionData) => void;
}
const tabs = ['Request Data', 'Response Data'];

const StyleWrapper = styled.div`
  .react-json-view {
    background-color: #e5e7eb !important;
    padding: 12px;
  }
`;

const WebhookCondition: FC<Props> = ({ onClose, data, onSave }) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0]);
  const [allEvents, setAllEvents] = useState<Event>([]);
  const [events, setEvents] = useState<Array<Options>>([]);
  const [selectedEvent, setSelectedEvent] = useState<string>('Select an item');
  const [requestData, setRequestData] = useState<RequestData>();
  const [sendTestStatus, setSendTestStatus] = useState<string>();
  const [responseData, setResponseData] = useState('');
  const [testStatus, setTestStatus] = useState<string>('');
  const [formIsValid, setFormIsValid] = useState<boolean>(true);
  const [sendingTest, setSendingTest] = useState<boolean>(false);
  const workflowCtx = useContext(WorkflowContext);

  const getData = async () => {
    try {
      let name = '';
      if (
        workflowCtx?.workflow &&
        'eventName' in workflowCtx.workflow.initialConditionGroup
      ) {
        name = workflowCtx.workflow.initialConditionGroup.eventName.replaceAll(
          /\s+/g,
          '+'
        );
      }
      const getEventsResponse = (await getWebhookEvents(
        name,
        JSON.stringify(workflowCtx?.workflow?.initialConditionGroup)
      )) as Response;
      if (getEventsResponse.status === 200) {
        setAllEvents(getEventsResponse.data.result);
        const tempEvents: Options[] = [];
        getEventsResponse.data.result.map((event, index) => {
          if (index === 0) {
            setSelectedEvent(event.attributes.personIdentifier);
          }
          return tempEvents.push({
            name: event.attributes.personIdentifier,
            value: event.id,
          });
        });
        setRequestData(getEventsResponse.data.result[0]);
        if (tempEvents.length === 0) {
          setEvents([{ value: 'Select an Item', name: 'Select an item' }]);
        } else {
          setEvents(tempEvents);
        }
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      webhookUrl: '',
      regex: '',
    },
    validationSchema: Yup.object({
      webhookUrl: Yup.string().required('Required'),
      regex: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      onSave({ ...values, _type: 'WebhookResponseConditionGroup' });
      onClose();
    },
  });

  const selectEventHandler = (value: string) => {
    const filteredEvent = allEvents.filter((event) => {
      return event.id === value;
    });
    setSelectedEvent(filteredEvent[0].attributes.personIdentifier);
    setRequestData(filteredEvent[0]);
  };

  const sendTestRequestHandler = async () => {
    setSendingTest(true);
    const data = {
      event: requestData,
      url: formik.values.webhookUrl,
      regex: formik.values.regex,
    };
    try {
      const sendTestResponse = (await sendWebhookRequest(
        data
      )) as WebhookResponse;
      if (sendTestResponse.status === 200) {
        setTestStatus('pass');
        if (sendTestResponse.data.path) {
          setSendTestStatus('Regex matched with response');
        } else {
          setSendTestStatus('Regex not matched with Response');
        }
        setResponseData(sendTestResponse.data.responseBody);
      }
    } catch (error) {
      setTestStatus('fail');
      setResponseData('{}');
      setSendTestStatus(
        'We could not connect, please check your URL and try again.'
      );
    }
    setSendingTest(false);
  };

  useEffect(() => {
    if (formik.isValid) {
      setFormIsValid(formik.isValid);
    }
  }, [formik.values, formik.isValid]);

  useEffect(() => {
    if (data) {
      formik.setValues(data);
    }
    getData();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <ModalWrapper
        onClose={onClose}
        disabled={!formIsValid}
        onSave={() => {
          if (Object.keys(formik.errors).length > 0 || !formik.dirty) {
            setFormIsValid(false);
          }
        }}
      >
        <>
          <div className="bg-white dark:bg-black-400 max-h-[60vh] overflow-y-auto">
            <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium mb-8 dark:text-white">
              Condition
            </h2>
            <div className="w-full">
              <label className="text-xs font-semibold leading-3 block tracking-wide text-gray-500 uppercase mb-3 dark:text-white">
                Webhook Url:
              </label>
              <div className="shadow-sm shadow-gray-200 dark:shadow-none mr-4">
                <input
                  className="text-13 font-medium leading-3 text-black-400 w-full py-3 px-4 rounded border border-gray-800 focus:outline-none focus:border-primary dark:bg-slate-800 dark:text-white"
                  type="text"
                  name="webhookUrl"
                  value={formik.values.webhookUrl}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="w-full mt-5">
              <label className="text-xs font-semibold leading-3 block tracking-wide text-gray-500 uppercase mb-3 dark:text-white">
                Regex:
              </label>
              <div className="shadow-sm shadow-gray-200 dark:shadow-none mr-4">
                <input
                  className="text-13 font-medium leading-3 text-black-400 w-full py-3 px-4 rounded border border-gray-800 focus:outline-none focus:border-primary dark:bg-slate-800 dark:text-white"
                  type="text"
                  name="regex"
                  value={formik.values.regex}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="mt-5">
              <label className="text-xs font-semibold leading-3 block tracking-wide text-gray-500 uppercase mb-3 dark:text-white">
                Events
              </label>
              <div className="flex items-center gap-3">
                <div className="z-10 bg-white dark:bg-slate-800 border-gray-200 dark:border-black-400 border rounded-lg dropdown-show-hide relative sm:h-11 h-9 w-6/12">
                  <Dropdown
                    options={events || ['Select an item']}
                    value={selectedEvent}
                    onSelect={(value) => selectEventHandler(value)}
                    className="dark:bg-slate-800 dark:text-white bg-white h-full focus:outline-none rounded"
                  />
                </div>
                <button
                  type="button"
                  className={`${
                    !formik.isValid || !formik.dirty
                      ? 'border border-gray-800 bg-gray-200 text-black-400'
                      : sendingTest
                      ? 'text-white bg-primary'
                      : testStatus === 'pass'
                      ? 'bg-green-200 text-white'
                      : testStatus === 'fail'
                      ? 'bg-red-100 text-red-800'
                      : 'text-white bg-primary'
                  } flex items-center text-13 font-medium leading-5 rounded-md h-11 px-4 py-2.5 mr-7 ease-in duration-300 hover:bg-primary hover:text-white hover:scale-105`}
                  onClick={() => sendTestRequestHandler()}
                  disabled={!formik.isValid || !formik.dirty}
                >
                  {sendingTest && (
                    <div className="">
                      <Loader />
                    </div>
                  )}
                  {sendingTest
                    ? 'Sending Test Data'
                    : testStatus === 'pass'
                    ? 'Test Successfull'
                    : testStatus === 'fail'
                    ? '! Failed Try Again '
                    : 'Send Test Request'}
                </button>
              </div>
              {sendTestStatus && (
                <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4">
                  <p className="text-sm font-normal leading-4 text-black">
                    {sendTestStatus}
                  </p>
                </div>
              )}
            </div>
            <div className="items-center flex">
              {tabs.map((tab) => (
                <>
                  <div
                    key={tab}
                    onClick={() => setActiveTab(tab)}
                    className="pt-4"
                  >
                    <button
                      type="button"
                      className={`text-sm font-medium leading-3 text-darkParagraphColor dark:text-white dark:text-white sm:px-6 px-2 pb-4 hover:text-primary hover:border-b-2 border-primary dark:hover:border-white group hover:-mb-0.5 ${
                        tab === activeTab
                          ? ' border-b-2 border-primary dark:border-white text-primary -mb-0.5'
                          : ''
                      } flex justify-center items-center group`}
                    >
                      <span
                        className={
                          activeTab === tab
                            ? 'dark:text-white text-primary'
                            : ''
                        }
                      >
                        {tab}
                      </span>
                    </button>
                  </div>
                </>
              ))}
            </div>
            {(requestData || responseData) && (
              <StyleWrapper>
                <ReactJson
                  name={null}
                  src={
                    activeTab === 'Request Data'
                      ? requestData
                      : JSON.parse(responseData || '{}')
                  }
                />
              </StyleWrapper>
            )}
          </div>

          {!formIsValid && (
            <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4">
              <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
                Please correct the errors above
              </p>
            </div>
          )}
        </>
      </ModalWrapper>
    </form>
  );
};
export default WebhookCondition;
