import { FC, useEffect, useRef, useState } from 'react';
import DownArrow from 'assets/Images/Common/down-arrow.png';
import { z } from 'zod';
import React from 'react';
import { useOutsideClick } from 'app/hooks/useOutsideClick';

const options = z.object({
  name: z.string(),
  value: z.string(),
});

type option = z.infer<typeof options>;

interface Props {
  options: option[];
  onSelect: (option: string) => void;
  value: string | undefined;
}

const Dropdown: FC<Props> = ({ options, onSelect, value }) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [searchedValue, setSearchedValue] = useState<string>('');
  const [dropdownOptions, setDropdownOptions] =
    useState<Array<option>>(options);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideClick(dropdownRef, () => {
    setShowDropdown(false);
  });

  useEffect(() => {
    setDropdownOptions(options);
  }, [options]);

  useEffect(() => {
    let tempOptions: option[] = [...dropdownOptions];
    tempOptions =
      searchedValue && searchedValue?.length > 0
        ? options.filter((option) =>
            option.name.toLowerCase().includes(searchedValue.toLowerCase())
          )
        : options;
    setDropdownOptions(tempOptions);
  }, [searchedValue]);

  return (
    <div ref={dropdownRef}>
      <div
        onClick={() => setShowDropdown((prev) => !prev)}
        className={`${
          showDropdown ? 'rounded-b-none border-primary dark:border-white' : ''
        } text-sm font-medium leading-4 text-black-400 dark:text-white dark:bg-slate-800 flex items-center justify-between sm:h-11 py-2 px-4 focus:outline-none bg-white border border-gray-800 rounded-md dark:border-black-400`}
        data-pw="dropdown"
      >
        {value}
        <span>
          <img
            className="dark:brightness-0 dark:invert"
            src={DownArrow}
            alt="Arrow"
          />
        </span>
      </div>

      {showDropdown && (
        <div className="border border-t-0 rounded-b-md border-gray-800 dark:border-black-400 bg-white relative z-50">
          <input
            className="border-b border-gray-800 dark:border-black-400 dark:bg-slate-800 sm:h-11 h-9 p-4 text-13 font-medium leading-3 text-gray-700 placeholder:text-gray-500 dark:placeholder:text-white dark:text-white dark:bg-slate-800 rounded-t w-full"
            type="text"
            name="Search"
            placeholder="Filter Items"
            value={searchedValue}
            onChange={(e) => setSearchedValue(e.target.value)}
          />
          <div className="h-40 overflow-auto scrollbar-hide">
            {dropdownOptions.map((option, index) => (
              <div
                onClick={() => {
                  onSelect(option.value);
                  setShowDropdown(false);
                }}
                className={`${
                  dropdownOptions.length === index + 1 ? 'border-b-0' : ''
                } p-3 text-sm font-medium leading-4 text-black-400 dark:text-white border-b border-dropdownHover dark:border-black-400 hover:bg-dropdownHover dark:text-white dark:hover:bg-white dark:hover:text-black-400 dark:bg-slate-800 dark:text-white`}
                key={option.value}
                data-pw={
                  dropdownOptions.length - 1 === index ? 'last-option' : ''
                }
              >
                {option.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default Dropdown;
