import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Header from 'components/EmailTemplates/Header';
import Tabs from 'components/Tabs/Tabs';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Body from 'components/EmailTemplates/Body';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';

export enum TabsEnum {
  EMAIL = 'email',
  INSIGHT = 'insight_templates',
}

export const TabsData = [
  { title: 'Emails', searchQuery: TabsEnum.EMAIL },
  { title: 'Insight Templates', searchQuery: TabsEnum.INSIGHT },
];

const EmailTemplates = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterValue, setFilterValue] = useState<string>('');
  const [label, setLabel] = useState<string | undefined>('');
  const onTabChangeHandler = (tabQuery: string) => {
    setSearchParams(createSearchParams({ tab: tabQuery }));
  };

  useEffect(() => {
    if (!searchParams.get('tab')) {
      setSearchParams(createSearchParams({ tab: TabsEnum.EMAIL }));
    }
    if (searchParams.get('id') || searchParams.get('create')) {
      searchParams.delete('id');
      searchParams.delete('create');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const activeTab = searchParams.get('tab') || TabsEnum.EMAIL;
  return (
    <ErrorBoundary>
        <div>
          <div className="md:px-6 px-3 pt-5 bg-white dark:bg-slate-800 border-b border-gray-800 dark:border-black-400 font-inter">
            <div>
              <Breadcrumbs />
              <Header setFilterValue={setFilterValue} onLabelClick={setLabel} />
              <Tabs
                tabs={TabsData}
                activeTab={activeTab}
                onTabChange={onTabChangeHandler}
              />
            </div>
          </div>
          <Body activeTab={activeTab} filterValue={filterValue} label={label} />
        </div>
    </ErrorBoundary>
  );
};
export default EmailTemplates;
