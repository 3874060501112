import Workflows from './Workflows';
import Plus from 'assets/Images/svg-images/plus.svg';
import { Link } from 'react-router-dom';
import React from 'react';
import { create, workflow } from 'services/constant/routes';


const Body = () => {
  return (
    <>
      <div className="flex items-center relative mb-4 px-6 mt-4">
        <div className="sm:mt-0 mt-11 sm:relative absolute right-0 ml-auto">
          <Link
            to={`${workflow}/${create}`}
            className="transition ease-in-out delay-150 duration-300 fs-13 font-medium leading-5 text-white flex items-center ml-auto rounded-md py-3 sm:pl-4 px-2 sm:pr-3.5 font-inter bg-primary hover:bg-primaryHover hover:scale-105 ease-in duration-300"
          >
            <img className="mr-2.5" src={Plus} alt="Plus" /> Create Workflow
          </Link>
        </div>
      </div>
      <div className="md:px-6 px-3 py-5 font-inter bg-gray-400 dark:bg-slate-800 h-full">
        <div className="md:px-1.5  mb-16">
          <Workflows />
        </div>
      </div>
    </>
  );
};
export default Body;
