import httpService from 'services/core/HttpService';
import {
  GET_DEFAULT_CONVERSION_PERIOD,
  GET_FREQUENCY_LIMIT,
  GET_EMAIL_EVENTS,
  GET_CONNECTED_SERVICES,
  GET_EMAIL_SENDERS,
  GET_BLAST_CONVERSION_EVENT_NAMES,
  GET_BLAST_CONVERSION_EVENT,
  UPDATE_BLAST_CONVERSION_EVENT,
  UPDATE_CONVERSION_PERIOD,
  UPDATE_WORKFLOW_FREQUENCY,
  UPDATE_EMAIL_SYSTEM_EVENTS,
  CREATE_CONNECTED_SERVICES,
  CREATE_EMAIL_SENDER,
  DELETE_EMAIL_SENDER,
  EDIT_EMAIL_SENDER,
  EDIT_CONNECTED_SERVICES,
} from 'services/constant/apiRoutes';
import { z } from 'zod';

const conversionEventSchema = z.object({
  conversionEvent: z.union([z.string(), z.undefined()]),
});
type conversionEvent = z.infer<typeof conversionEventSchema>;

const conversionPeriodSchema = z.object({
  defaultConversionPeriod: z.union([z.number(), z.undefined()]),
});
type conversionPeriod = z.infer<typeof conversionPeriodSchema>;

const workflowFrequencySchema = z.object({
  frequencyLimit: z.union([z.number(), z.undefined()]),
});
type workflowFrequency = z.infer<typeof workflowFrequencySchema>;

const createConnectedServicesSchema = z.object({
  type: z.string(),
  name: z.string(),
  apiKey: z.string(),
});
type connectedServices = z.infer<typeof createConnectedServicesSchema>;

const editConnectedServicesSchema = z.object({
  accountId: z.number(),
  apiKey: z.string(),
  id: z.string(),
  links: z.object({
    triggers: z.array(z.string()),
  }),
  name: z.string(),
  type: z.string(),
});
type EditServices = z.infer<typeof editConnectedServicesSchema>;

const updateEmailEventSchema = z.object({
  data: z.object({
    system_event_configuration: z.object({
      any_list: z.boolean(),
      list_ids: z.array(z.number()),
    }),
  }),
});
type updateEmailEvent = z.infer<typeof updateEmailEventSchema>;

const createEmailSenderSchema = z.object({
  email: z.string(),
  id: z.string(),
  links: z.object({ triggers: z.array(z.never()) }),
  name: z.string(),
  replyTo: z.string(),
});
type createEmailSenderType = z.infer<typeof createEmailSenderSchema>;

const editEmailSenderSchema = z.object({
  accountId: z.number(),
  email: z.string(),
  replyTo: z.string(),
  id: z.string(),
  links: z.object({
    triggers: z.array(z.string()),
  }),
  name: z.string(),
});
type EditSender = z.infer<typeof editEmailSenderSchema>;

export const getDefaultConversionPeriod = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_DEFAULT_CONVERSION_PERIOD)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFrequencyLimit = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_FREQUENCY_LIMIT)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEmailEvents = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_EMAIL_EVENTS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getConnectedServices = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_CONNECTED_SERVICES)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editConnectedServices = (data: EditServices) => {
  return new Promise((resolve, reject) => {
    httpService
      .put(EDIT_CONNECTED_SERVICES, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEmailSenders = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_EMAIL_SENDERS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createEmailSender = (data: createEmailSenderType) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(CREATE_EMAIL_SENDER, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteEmailSender = (id: string) => {
  const deleteEmailSenderUrl = DELETE_EMAIL_SENDER.replace('{{ID}}', id);
  return new Promise((resolve, reject) => {
    httpService
      .delete(deleteEmailSenderUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editEmailSender = (data: EditSender) => {
  const editEmailSenderUrl = EDIT_EMAIL_SENDER + data.id;
  return new Promise((resolve, reject) => {
    httpService
      .put(editEmailSenderUrl, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getBlastConversionEventOptions = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_BLAST_CONVERSION_EVENT_NAMES)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getBlastConversionEvent = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_BLAST_CONVERSION_EVENT)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateBlastConversionEvent = (
  conversionEventData: conversionEvent
) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(UPDATE_BLAST_CONVERSION_EVENT, conversionEventData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateConversionPeriod = (
  conversionPeriodData: conversionPeriod
) => {
  return new Promise((resolve, reject) => {
    httpService
      .put(UPDATE_CONVERSION_PERIOD, conversionPeriodData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateWorkflowFrequency = (
  workflowFrequencyData: workflowFrequency
) => {
  return new Promise((resolve, reject) => {
    httpService
      .put(UPDATE_WORKFLOW_FREQUENCY, workflowFrequencyData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createConnectedServices = (servicesData: connectedServices) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(CREATE_CONNECTED_SERVICES, servicesData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateEmailSystemEvent = (updateEmailData: updateEmailEvent) => {
  return new Promise((resolve, reject) => {
    httpService
      .put(UPDATE_EMAIL_SYSTEM_EVENTS, updateEmailData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
