import List from 'assets/Images/Header/lists.png';
import TargetWhite from 'assets/Images/ListsegmentImages/target-white.svg';
import React from 'react';

const ListHeading = () => {
  return (
    <div className="sm:flex items-center relative">
      <div className="w-full">
        <div className="flex items-center">
          <span>
            <img
              className="sm:w-6 sm:h-6 w-5 h-5 inline-block dark:hidden"
              src={List}
              alt="List"
            />
          </span>
          <span>
            <img
              className="sm:w-6 sm:h-6 w-5 h-5 hidden dark:inline-block"
              src={TargetWhite}
              alt="List"
            />
          </span>
          <h2 className="sm:text-xl text-base leading-5 text-black-700 dark:text-white ml-2.5 font-medium">
            Lists & Segments
          </h2>
        </div>
      </div>
    </div>
  );
};
export default ListHeading;
