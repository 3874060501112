import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  login,
  listSegments,
  home,
  integration,
  campaigns,
  emailTemplates,
  createCampaign,
  emailEditor,
  workflow,
  previewEmail,
  workflowReports,
  campaignReports,
  create,
  id,
  nestedId,
  logs,
  subscribers,
  filters,
  segmentDefinition,
  view,
  matches,
  add,
  filter,
  edit,
  upload,
  fields,
  list,
  revenues,
  eventLogs,
  reports,
} from 'services/constant/routes';
import Dashboard from 'pages/Dashboards';
import EmailEditor from 'pages/Email';
import Editor from 'components/Email';
import Reports from 'pages/Reports';
import ProtectedRoute from 'components/ProtectedRoute';
import Login from 'pages/Login';
import ListSegment from 'pages/ListSegments';
import List from 'components/List-Segments/Body/List/index';
import Integration from 'pages/Integration';
import Campaigns from 'pages/Campaigns';
import EmailTemplates from 'pages/EmailTemplates';
import NewCampaign from 'pages/NewCampaign';
import EditWorkflows from 'components/Workflow/Body/Workflows/Workflow';
import CreateWorkflows from 'components/Workflow/Body/Workflows/Workflow';
import CreateSegment from 'components/List-Segments/Body/Segments/index';
import EditSegment from 'components/List-Segments/Body/Segments/index';
import ErrorBoundary from 'components/ErrorBoundary';
import Workflow from 'pages/Workflow';
import PreviewEmail from 'pages/PreviewEmail';
import WorkflowReports from 'pages/WorkflowReports';
import Workflows from 'components/Workflow/Body';
import CampaignReports from 'pages/CampaignReports';
import WorkflowLogs from 'components/Workflow/Body/Workflows/WorkflowLogs';
import ExecutionLogs from 'components/Workflow/Body/Workflows/ExecutionLogs';
import Subscribers from 'components/List-Segments/Body/Subscribers/Subscribers';
import Filter from 'components/List-Segments/Body/Filter/Filter';
import AddFilterForm from 'components/List-Segments/Body/Filter/AddFilterForm';
import EditFilters from 'components/List-Segments/Body/Filter/EditFilters';
import SegmentDefinition from 'components/List-Segments/Body/Segments/SegmentDefinition';
import ViewFilter from 'components/List-Segments/Body/Filter/ViewFilter';
import FilterSubscribers from 'components/List-Segments/Body/Filter/FilterSubscribers';
import NewList from 'components/List-Segments/Body/List/NewList';
import EditList from 'components/List-Segments/Body/List/NewList';
import AddList from 'components/List-Segments/Body/List/AddList';
import UploadFilter from 'components/List-Segments/Body/List/UploadFilter';
import EmailCreationSteps from 'components/Email/EmailCreationSteps';
import ViewSubscriber from 'components/List-Segments/Body/Subscribers/ViewSubscriber';
import SubscriberForm from 'components/List-Segments/Body/Subscribers/SubscribersForm';
import UploadSubscribers from 'components/List-Segments/Body/Subscribers/UploadSubscribers';
import Fields from 'components/List-Segments/Body/Subscribers/Fields';
import EditField from 'components/List-Segments/Body/Subscribers/EditField';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import { z } from 'zod';
import React from 'react';
import Revenue from 'pages/Revenue';
import EventLogs from 'pages/EventLogs';

const toastSchema = z.object({ success: z.boolean(), error: z.boolean() });
type ToastType = z.infer<typeof toastSchema>;

export const Toastify = (text: string, type: string, id: string) => {
  toast[type as keyof ToastType](text, { toastId: `${id}` });
};

export const showLoginModal = () => {
  console.log('modal is in function: ');
};

const App = () => {
  return (
    <ErrorBoundary>
      <ToastContainer />
        <Routes>
          <Route path={login} element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path={home}  >
              <Route index element={<Dashboard />}/>
            </Route>
            <Route path={reports} element={<Reports />} />
            <Route path={listSegments} element={<ListSegment />} >
              <Route index element={<List />} />
              <Route path={list}>
                <Route path={create} element={<NewList />} />
                <Route path={id} element={<EditList />} />
                <Route path={add}>
                  <Route path={id}>
                    <Route index element={<AddList />} />
                    <Route path={filter} element={<UploadFilter />} />
                  </Route>
                </Route>
              </Route>
              <Route path={subscribers}>
                <Route index element={<Subscribers />} />
                <Route path={view}>
                  <Route path={id} element={<ViewSubscriber />} />
                </Route>
                <Route path={edit}>
                  <Route path={id} element={<SubscriberForm />} />
                </Route>
                <Route path={upload}>
                  <Route index element={<UploadSubscribers />} />
                  <Route path={create} element={<SubscriberForm />} />
                </Route>
                <Route path={fields}>
                  <Route index element={<Fields />} />
                  <Route path={edit}>
                    <Route path={id} element={<EditField />} />
                  </Route>
                </Route>
              </Route>
              <Route path={filters}>
                <Route index element={<Filter />} />
                <Route path={create} element={<AddFilterForm />} />
                <Route path={id} element={<EditFilters />} />
                <Route path={view}>
                  <Route path={id} element={<ViewFilter />} />
                </Route>
                <Route path={matches}>
                  <Route path={id} element={<FilterSubscribers />} />
                </Route>
              </Route>
              <Route path={segmentDefinition}>
                <Route index element={<SegmentDefinition />} />
                <Route path={create} element={<CreateSegment />} />
                <Route path={id} element={<EditSegment />} />
              </Route>
            </Route>
            <Route path={integration} element={<Integration />} />
            <Route path={campaigns} element={<Campaigns />} />
            <Route path={emailTemplates} element={<EmailTemplates />} />
            <Route path={createCampaign} element={<NewCampaign />}>
              <Route path={id} element={<NewCampaign />} />
            </Route>
            <Route path={emailEditor} element={<EmailEditor />}>
              <Route index element={<Editor />} />
              <Route path={create} element={<EmailCreationSteps />} />
              <Route path={edit}>
                <Route path={id} element={<Editor />} />
              </Route>
            </Route>
            <Route path={workflow} element={<Workflow />}>
              <Route index element={<Workflows />} />
              <Route path={create} element={<CreateWorkflows />} />
              <Route path={id} element={<EditWorkflows />} />
              <Route path={logs}>
                <Route path={id}>
                  <Route index element={<WorkflowLogs />} />
                  <Route path={nestedId} element={<ExecutionLogs />} />
                </Route>
              </Route>
            </Route>
            <Route path={previewEmail}>
              <Route index path={id} element={<PreviewEmail />} />
            </Route>

            <Route path={workflowReports}>
              <Route path={id} element={<WorkflowReports />} />
            </Route>
            <Route path={campaignReports}>
              <Route path={id} element={<CampaignReports />} />
            </Route>
            <Route path={revenues} element={<Revenue />} />
            <Route path={eventLogs} element={<EventLogs />} />
          </Route>
        </Routes>
    </ErrorBoundary>
  );
};
export default App;
