import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import '@fullcalendar/daygrid/main.css';
import { DashboardContext } from 'store/DashboardContext';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'store/ThemeContext';
import { z } from 'zod';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { campaignReports } from 'services/constant/routes';

const CalendarSchema = z.object({
  title: z.string(),
  start: z.string(),
  allDay: z.boolean(),
  publicId: z.number(),
});
type CalendarEvent = z.infer<typeof CalendarSchema>;

const eventSchema = z.object({
  timeText: z.string(),
  event: z.object({
    title: z.string(),
    extendedProps: z.object({ publicId: z.string() }),
  }),
});
type Event = z.infer<typeof eventSchema>;

const   StoreCalendar = () => {
  const dashboardCtx = useContext(DashboardContext);
  const themeCtx = useContext(ThemeContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = themeCtx?.theme;
  const [events, setEvents] = useState<Array<CalendarEvent>>();

  const StyledWrapper = styled.div`
    .fc-daygrid-day {
      height: 130px;
      border-style: dashed;
    }
    .fc-media-screen {
      background: ${theme === 'dark' ? '#1E293B' : '#fff'};
      padding: 24px 28px;
      margin-top: 16px;
      border-radius: 8px;
      // box-shadow: 0px 2px 8px rgb(64 68 82 / 3%),
      //   0px 0px 0px 1px rgb(64 68 82 / 10%), 0px 2px 2px rgb(0 0 0 / 8%);
      border: 1px solid ${theme === 'dark' ? '#616161' : '#bdbdbd'};
    }

    .fc-toolbar-title {
      color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
      font-weight: medium;
      font-size: 20px;
    }
    .fc-col-header-cell-cushion {
      color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
    }
    .fc-daygrid-day-number {
      color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
    }
    .fc-daygrid-more-link {
      color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
    }

    .fc-scrollgrid-sync-inner {
      color: ${theme === 'dark' ? '#FFFFFF' : '#333333'};
      text-align: left;
      font-weight: 500;
      font-size: 14px;
      min-width: 100px;
    }

    .fc .fc-daygrid-day-top {
      justify-content: flex-end;
      color: ${theme === 'dark' ? '#FFFFFF' : '#a1a1a1'};
      text-align: left;
      font-weight: 500;
      font-size: 14px;
    }

    .fc .fc-daygrid-day-top a {
      color: ${theme === 'dark' ? '#FFFFFF' : '#a1a1a1'};
      text-align: left;
      font-weight: 500;
      font-size: 14px;
    }
    .fc-daygrid-day {
      height: 130px;
      border-style: dashed;
    }
    .fc-media-screen {
      background: ${theme === 'dark' ? '#1E293B' : '#fff'};
      padding: 24px 28px;
      margin-top: 16px;
      border-radius: 8px;
      // box-shadow: 0px 2px 8px rgb(64 68 82 / 3%),
      //   0px 0px 0px 1px rgb(64 68 82 / 10%), 0px 2px 2px rgb(0 0 0 / 8%);
      border: 1px solid ${theme === 'dark' ? '#616161' : '#bdbdbd'};
    }

    .fc-toolbar-title {
      color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
      font-weight: medium;
      font-size: 20px;
    }

    .fc-col-header-cell-cushion {
      color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
    }

    .fc-daygrid-day-number {
      color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
    }

    .fc-daygrid-more-link {
      color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
    }

    .fc-scrollgrid-sync-inner {
      color: ${theme === 'dark' ? '#FFFFFF' : '#333333'};
      text-align: left;
      font-weight: 500;
      font-size: 14px;
      min-width: 100px;
    }

    .fc .fc-daygrid-day-top {
      justify-content: flex-end;
      color: ${theme === 'dark' ? '#FFFFFF' : '#a1a1a1'};
      text-align: left;
      font-weight: 500;
      font-size: 14px;
    }
    .fc-daygrid-day {
      height: 130px;
      border-style: dashed;
    }
    .fc-media-screen {
      background: ${theme === 'dark' ? '#1E293B' : '#fff'};
      padding: 24px 28px;
      margin-top: 16px;
      border-radius: 8px;
      // box-shadow: 0px 2px 8px rgb(64 68 82 / 3%),
      //   0px 0px 0px 1px rgb(64 68 82 / 10%), 0px 2px 2px rgb(0 0 0 / 8%);
      border: 1px solid ${theme === 'dark' ? '#616161' : '#bdbdbd'};
    }

    .fc-toolbar-title {
      color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
      font-weight: medium;
      font-size: 20px;
    }

    .fc-col-header-cell-cushion {
      color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
    }

    .fc-daygrid-day-number {
      color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
    }

    .fc-daygrid-more-link {
      color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
    }

    .fc-scrollgrid-sync-inner {
      color: ${theme === 'dark' ? '#FFFFFF' : '#333333'};
      text-align: left;
      font-weight: 500;
      font-size: 14px;
      min-width: 100px;
    }

    .fc .fc-daygrid-day-top {
      justify-content: flex-end;
      color: ${theme === 'dark' ? '#FFFFFF' : '#a1a1a1'};
      text-align: left;
      font-weight: 500;
      font-size: 14px;
    }

    .fc .fc-daygrid-day-top a {
      color: ${theme === 'dark' ? '#FFFFFF' : '#a1a1a1'};
      text-align: left;
      font-weight: 500;
      font-size: 14px;
    }

    .fc-direction-ltr .fc-daygrid-event.fc-event-start .boxShadow-left {
      box-shadow: #5338a0 5px 0px 0px inset;
    }

    .fc .fc-view-harness {
      overflow: auto;
    }

    .fc .fc-view {
      min-width: 700px;
    }

    .fc-theme-standard .fc-popover-header {
      height: 35px;
    }

    @media (max-width: 767px) {
      .fc-media-screen {
        padding: 16px 8px;
      }
    }
    .fc .fc-daygrid-day-top a {
      color: ${theme === 'dark' ? '#FFFFFF' : '#a1a1a1'};
      text-align: left;
      font-weight: 500;
      font-size: 14px;
    }

    .fc-direction-ltr .fc-daygrid-event.fc-event-start .boxShadow-left {
      box-shadow: #5338a0 5px 0px 0px inset;
    }

    .fc .fc-view-harness {
      overflow: auto;
    }

    .fc .fc-view {
      min-width: 700px;
    }

    .fc .fc-daygrid-day.fc-day-today {
      background: #f8f5fe;
    }

    @media (max-width: 767px) {
      .fc-media-screen {
        padding: 16px 8px;
      }
    }

    .fc-direction-ltr .fc-daygrid-event.fc-event-start .boxShadow-left {
      box-shadow: #5338a0 5px 0px 0px inset;
    }

    .fc .fc-view-harness {
      overflow: auto;
    }

    .fc .fc-view {
      min-width: 700px;
    }

    .fc-toolbar-title {
      font-size: 16px;
      line-height: 28px;
      color: #212121;
    }

    .fc-prev-button,
    .fc-prev-button:hover {
      height: 44px;
      width: 44px;
      background-color: #f8f5fe;
      border: none;
      border-radius: 4px;
    }

    .fc-prev-button .fc-icon-chevron-left {
      color: #5338a0;
    }

    .fc-next-button,
    .fc-next-button:hover {
      height: 44px;
      width: 44px;
      background-color: #f8f5fe;
      border: none;
      margin-left: 16px !important;
      border-radius: 4px;
    }

    .fc-next-button .fc-icon-chevron-right {
      color: #5338a0;
    }

    a.fc-event,
    a.fc-event:hover {
      background: none;
    }

    @media (min-width: 640px) {
      .fc-toolbar-title {
        font-size: 20px;
      }
    }

    @media (max-width: 767px) {
      .fc-media-screen {
        padding: 16px 8px;
      }
    }
  `;

  const renderEventContent = (eventInfo: Event) => {
    //eventInfo.event.extendedProps.publicId;

    return (
      <div
        onClick={() => {
          searchParams.set(
            'campaignId',
            eventInfo.event.extendedProps.publicId
          );
          searchParams.set('calendar', 'true');
          setSearchParams(searchParams);
          navigate(
            `${campaignReports}/${eventInfo.event.extendedProps.publicId}?calendar=true`
          );
        }}
        className="w-full hover:shadow-none hover:border-none hover:outline-none"
      >
        <div className="pl-2 bg-transparentPrimary px-1.5 py-2 w-full boxShadow-left">
          <p className="text-sm leading-4 text-black-700 overflow-hidden overflow-ellipsis xl:w-28 w-8 whitespace-nowrap mb-1.5">
            {eventInfo.event.title}
          </p>
          <p className="text-xs font-medium leading-4 text-gray-700 mb-0">
            {eventInfo.timeText}
          </p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const campaignEvents = dashboardCtx?.campaigns.map((campaign) => ({
      title: campaign.campaignName,
      start: campaign.eventDate,
      allDay: false,
      publicId: campaign.id,
    }));
    setEvents(campaignEvents as CalendarEvent[]);
  }, [dashboardCtx?.campaigns]);

  return (
    <ErrorBoundary>
      <div className="bg-gray-400 dark:bg-slate-900 font-inter py-7 md:px-6 px-3 pb-8">
        <div className="md:px-1.5">
          <h1 className="sm:text-xl text-base sm:leading-5 leading-3 text-black dark:text-white font-medium">
            Calendar View
          </h1>
          <StyledWrapper>
            <FullCalendar
              customButtons={{
                customTitle: {
                  text: 'Dec',
                  classNames: 'bg-white',
                },
              }}
              headerToolbar={{
                left: 'title',
                center: '',
                right: 'prev,next',
              }}
              dayMaxEvents={2}
              theme={true}
              eventColor={'#378006'}
              themeSystem="lifc-media-screenght"
              navLinks={true}
              plugins={[interactionPlugin, dayGridPlugin]}
              editable={true}
              selectable={true}
              events={events}
              eventContent={renderEventContent}
            />
          </StyledWrapper>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default StoreCalendar;
