import { FC, useContext } from 'react';
import { DashboardContext } from 'store/DashboardContext';
import { z } from 'zod';
import React from 'react';
import { convertStringToWholeNumber } from 'utils/common';

const MonetaryValueSchema = z.object({
  workflows: z.number(),
  total: z.number(),
  campaigns: z.number(),
});
type MonetaryValue = z.infer<typeof MonetaryValueSchema>;
interface Props {
  monetaryValue: MonetaryValue;
}

const RevenueStats: FC<Props> = ({ monetaryValue }) => {
  const dashboardCtx = useContext(DashboardContext);
  return (
    <div className="revenue-left w-full xl:max-w-lg pt-4 xl:px-0 xl:pb-0 pb-6 sm:px-10 px-5">
      <div className="flex justify-between pt-5 sm:flex-nowrap flex-wrap">
        <div className="sm:pr-0 pr-4 sm:pb-0 pb-10">
          <h3 className="text-sm text-headingColor dark:text-white font-medium tracking-wide leading-4 pb-3">
            Total Revenue
          </h3>
          {dashboardCtx?.weeklyRoiData && <h2 className="font-medium text-26 text-lightParagraphColor dark:text-white leading-7 ">
            ${convertStringToWholeNumber(dashboardCtx.weeklyRoiData.revenue) }
          </h2>}
        </div>
        <div className="w-36">
          <h3 className="text-sm text-headingColor dark:text-white tracking-wide font-medium leading-4 pb-3">
            Revenue by Ryzeo
          </h3>
          {dashboardCtx?.weeklyRoiData?.conversions_value && <h2 className="font-medium text-26 text-lightParagraphColor dark:text-white leading-7 ">
            ${convertStringToWholeNumber(dashboardCtx.weeklyRoiData.conversions_value)}
          </h2>}
        </div>
      </div>
      <div className="flex justify-between sm:mt-10 mt-5 sm:flex-nowrap flex-wrap">
        <div className="sm:pr-0 pr-4 sm:pb-0 pb-10">
          <h3 className="text-sm text-headingColor dark:text-white font-medium tracking-wide leading-4 pb-3">
            From Workflows
          </h3>
          <h2 className="font-medium text-26 text-lightParagraphColor dark:text-white leading-7 ">
            ${Math.floor(monetaryValue.workflows)}
          </h2>
        </div>
        <div className="w-36">
          <h3 className="text-sm text-headingColor dark:text-white font-medium tracking-wide leading-4 pb-3">
            From Campaigns
          </h3>
          <h2 className="font-medium text-26 text-lightParagraphColor dark:text-white leading-7 ">
            ${Math.floor(monetaryValue.campaigns)}
          </h2>
        </div>
      </div>
      {dashboardCtx?.weeklyRoiData?.weekly_fee && (
        <div className="sm:mt-10 mt-5 mb-4">
          <h3 className="text-sm text-headingColor dark:text-white font-medium tracking-wide leading-4 pb-3">
            Spent on Ryzeo
          </h3>
          <h2 className="font-medium text-26 text-lightParagraphColor dark:text-white leading-7 ">
            ${parseInt(dashboardCtx?.weeklyRoiData?.weekly_fee)}
          </h2>
        </div>
      )}
    </div>
  );
};
export default RevenueStats;
