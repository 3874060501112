import React, { useState, createContext } from 'react';
import {
  InitialData,
  CreateCampaign,
  CampaignError,
  SenderData,
} from 'components/NewCampaign/index.type';
import { useParams } from 'react-router-dom';

const defaultInitialData = {
  campaignData: {
    utm_campaign: '',
    utm_content: '{{{blast_id}}}',
    utm_medium: 'email',
    utm_source: '',
  },
  description: '',
  socialBar: false,
  googleAnalytics: false,
  trackClick: false,
};
interface ContextType {
  campaignData: CreateCampaign | undefined;
  campaignFormErrors: CampaignError;
  onChangeFormValidity: (name: string, value: boolean) => void;
  onFormSubmit: () => boolean;
  initialData: InitialData;
  onChangeInitialData: (data: InitialData) => void;
  senderActivatedData: SenderData;
  onChangeSenderActivatedData: (senderData: SenderData) => void;
  onUpdateCampaignData: (campiagnsData: CreateCampaign) => void;
}
export const CampaignContext = createContext<ContextType | null>(null);

type Props = {
  children: JSX.Element;
};
export const CampaignContextProvider = ({ children }: Props) => {
  // const [searchParams] = useSearchParams();
  const {id} = useParams()

  const defaultCampaign = {
    email_id: parseInt(id || '0'),
    exclude_lists: [],
    filter_id: '',
    finder_id: 0,
    from: {
      id: 0,
    },
    from_id: 0,
    google_analytics: false,
    include_lists: [],
    options: {
      include_social_bar: false,
      track_clicks: false,
    },
    reply_to_email_address: '',
    reply_to_name: '',
    schedule_type: 'now',
    share_description: '',
    share_title: '',
    subject: '',
    utm_campaign: '',
    utm_content: '{{{blast_id}}}',
    utm_medium: 'email',
    utm_source: '',
    mergeFields: '{{{email_address}}}',
  };

  const [campaignData, setCampaignData] =
    useState<CreateCampaign>(defaultCampaign);
  const [campaignFormErrors, setCampaignFormErrors] = useState<CampaignError>({
    subjectFormError: false,
    emailTemplateError: false,
    senderFormError: false,
    triggerError: false,
  });
  const [initialData, setInitialData] =
    useState<InitialData>(defaultInitialData);
  const [senderActivatedData, setSenderActivatedData] = useState<SenderData>({
    reply_to_email_address: '',
    reply_to_name: '',
  });

  const onChangeFormValidity = (name: string, value: boolean) => {
    setCampaignFormErrors({ ...campaignFormErrors, [name]: value });
  };

  const onChangeInitialData = (data: InitialData) => {
    setInitialData(data);
  };

  const onFormSubmit = () => {
    const { subject, email_id, from, triggerName, schedule_type } =
      campaignData;
    if (
      subject.length === 0 ||
      !email_id ||
      !from?.id ||
      (schedule_type !== 'now' && triggerName === '')
    ) {
      setCampaignFormErrors({
        subjectFormError: subject.length === 0,
        emailTemplateError: !email_id,
        senderFormError: !from?.id,
        triggerError: schedule_type !== 'now' && triggerName === '',
      });
      return false;
    } else {
      return true;
    }
  };

  const onChangeSenderActivatedData = (senderData: SenderData) => {
    setSenderActivatedData(senderData);
  };

  const onUpdateCampaignData = (campaignsData: CreateCampaign) => {
    setCampaignData(campaignsData);
  };

  return (
    <CampaignContext.Provider
      value={{
        campaignData: campaignData,
        campaignFormErrors,
        onChangeFormValidity,
        onFormSubmit,
        initialData,
        onChangeInitialData,
        senderActivatedData,
        onChangeSenderActivatedData,
        onUpdateCampaignData,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};
