import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import React from "react";
interface Props {
  lastMonth: string;
  last90Days: string;
  last14Days: string;
}
const StoreReportStats: FC<Props> = ({ lastMonth, last90Days, last14Days }) => {
  const { pathname } = useLocation();

  return (
    <div className="mt-20 py-6 lg:px-7 px-3.5 border-t border-gray-800 dark:border-black-400 bg-gray-400 dark:bg-slate-800 rounded-b-lg">
      <h4 className="text-base font-semibold leading-5 mb-2.5 text-black-700 dark:text-white">
        Stats
      </h4>
      <div className="flex justify-between items-center font-inter">
        <div>
          <p className="uppercase text-11 font-medium leading-4 mb-1.5 mt-0 text-black-400 dark:text-white dark:text-white">
            {pathname === '/campaigns' ? 'Last 7 Days' : 'LAST MONTH'}
          </p>
          <p className="xl:text-sm lg:text-xs text-sm font-semibold leading-5 mb-1.5 mt-0 text-black-400 dark:text-white dark:text-white">
            {lastMonth}
          </p>
        </div>
        <div>
          <p className="uppercase text-11 font-medium leading-4 mb-1.5 mt-0 text-black-400 dark:text-white dark:text-white">
            {pathname === '/campaigns' ? 'LAST MONTH' : 'LAST 90 days'}
          </p>
          <p className="xl:text-sm lg:text-xs text-sm font-semibold leading-5 mb-1.5 mt-0 text-black-400 dark:text-white dark:text-white">
            {last90Days}
          </p>
        </div>
        <div>
          <p className="uppercase text-11 font-medium leading-4 mb-1.5 mt-0 text-black-400 dark:text-white dark:text-white">
            {pathname === '/campaigns' ? 'LAST 90 days' : 'Last 6 Months'}
          </p>
          <p className="xl:text-sm lg:text-xs text-sm font-semibold leading-5 mb-1.5 mt-0 text-black-400 dark:text-white dark:text-white">
            {last14Days}
          </p>
        </div>
      </div>
    </div>
  );
};
export default StoreReportStats;
