import ModalWrapper from 'components/Workflow/ModalWrapper';
import React, { FC } from 'react';

interface Props {
  onCancel: () => void;
  onDelete: () => void;
}

const DeleteModal: FC<Props> = ({ onCancel, onDelete }) => {
  return (
    <ModalWrapper buttonTxt="Delete" onClose={onCancel} onSave={onDelete}>
      <div className="bg-white dark:bg-slate-800 p-5 rounded-lg  dark:border-black-400">
        <h3 className="sm:text-lg text-sm font-semibold leading-4 text-black-400 dark:text-white dark:text-white">
          Delete
        </h3>
        <p className="sm:text-sm text-xs leading-5 text-gray-700 datk:text-white dark:text-white">
          Are you sure you want to Delete?
        </p>
      </div>
    </ModalWrapper>
  );
};
export default DeleteModal;
