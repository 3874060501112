import { FC, useState } from 'react';
import Setting from 'assets/Images/Campaign/setting-gray.png';
import Edit from 'assets/Images/ListsegmentImages/edit.png';
import Delete from 'assets/Images/ListsegmentImages/delete.png';
import Close from 'assets/Images/Campaign/close.svg';
import React from 'react';
import DeleteModal from 'components/Alert/DeleteModal';
import Modal from 'components/Alert/Modal';
import { deleteSender } from 'services/apiHandlers/Campaigns/CampaignInformation';
import { z } from 'zod';
import { Toastify } from 'App';
interface Props {
  senderId: number;
  onEdit: (id: number) => void;
  index: number;
  onDelete: () => void;
}

const deleteSenderResponseSchema = z.object({ status: z.number() });
type DeleteSenderResponse = z.infer<typeof deleteSenderResponseSchema>;

const SenderSettings: FC<Props> = ({ senderId, onEdit, index, onDelete }) => {
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>('');

  const deleteSenderHandler = async () => {
    setShowDeleteModal(false);
    try {
      const deleteSenderResponse = (await deleteSender(
        deleteId
      )) as DeleteSenderResponse;
      if (deleteSenderResponse.status === 200) {
        Toastify('Sender Deleted Successfully', 'success', 'sender1');
        onDelete();
      }
    } catch (error) {
      console.log('error is  : ', error);
      Toastify('Error Occured While Deleting List', 'error', 'sender2');
    }
  };

  return (
    <div>
      {showSettings ? (
        <div className="flex items-start">
          <span
            data-pw={index === 0 ? 'first-sender-close' : ''}
            onClick={() => setShowSettings(false)}
            className="mr-3 text-xl leading-5 font-light text-black py-1.5 px-2 cursor-pointer"
          >
            <img
              src={Close}
              className="dark:invert dark:brightness-0 w-18 h-18"
              alt="Close"
            />
          </span>

          <span
            onClick={() => {
              setDeleteId(senderId.toString());
              setShowDeleteModal(true);
            }}
            data-pw={index === 0 ? 'first-sender-delete' : ''}
            className="mr-3 text-xl leading-5 font-light text-black py-1.5 px-2 cursor-pointer"
          >
            <img
              src={Delete}
              className="dark:invert dark:brightness-0"
              alt="Delete"
            />
          </span>
          <span
            data-pw={index === 0 ? 'first-sender-edit' : ''}
            onClick={() => onEdit(senderId)}
            className="mr-3 text-xl leading-5 font-light text-black py-1.5 px-2 cursor-pointer"
          >
            <img
              src={Edit}
              className="dark:invert dark:brightness-0"
              alt="Edit"
            />
          </span>
          <Modal isOpen={showDeleteModal}>
            <DeleteModal
              onDelete={() => deleteSenderHandler()}
              onCancel={() => setShowDeleteModal(false)}
            />
          </Modal>
        </div>
      ) : (
        <img
          data-pw={index === 0 ? 'first-sender-setting' : ''}
          className="cursor-pointer"
          onClick={() => setShowSettings(true)}
          src={Setting}
          alt="Setting"
        />
      )}
    </div>
  );
};
export default SenderSettings;
