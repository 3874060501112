import React, { FC, useEffect, useState, DragEvent } from 'react';
import Dots from 'assets/Images/ListsegmentImages/segments-dots.png';
import { Form } from './SubscribersForm';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';
import {
  addFieldOption,
  deleteFieldOption,
  editField,
  getFieldOptions,
  sortFieldOptions,
  updateFieldOption,
} from 'services/apiHandlers/List-Segments/Field';
import { z } from 'zod';
import { Toastify } from 'App';
import DeleteModal from 'components/Alert/DeleteModal';

const fieldOptionDataSchema = z.object({
  person_attribute_column_option: z.object({
    account_id: z.number(),
    code: z.number().nullable(),
    created_at: z.string(),
    custom_question: z.string().nullable(),
    id: z.number(),
    name: z.string(),
    person_attribute_column_id: z.number(),
    position: z.number(),
    updated_at: z.string(),
  }),
});

const getFieldOptionResponseSchema = z.object({
  data: z.array(fieldOptionDataSchema),
  status: z.number(),
});

const fieldActionResponseSchema = z.object({ status: z.number() });

type SortFieldsResponse = z.infer<typeof fieldActionResponseSchema>;
type DeleteFieldResponse = z.infer<typeof fieldActionResponseSchema>;
type UpdateFieldResponse = z.infer<typeof fieldActionResponseSchema>;
type AddFieldResponse = z.infer<typeof fieldActionResponseSchema>;
type FieldOptionData = z.infer<typeof fieldOptionDataSchema>;
type GetFieldOptionsResponse = z.infer<typeof getFieldOptionResponseSchema>;

interface Props {
  fieldData: Form | undefined;
  id: number | undefined;
 
}

const SelectFieldForm: FC<Props> = ({ fieldData, id }) => {
  const [fieldOptions, setFieldOptions] = useState<Array<FieldOptionData>>([]);
  const [options, setOptions] = useState<Array<FieldOptionData>>([]);
  const [showInputOptions, setShowInputOptions] = useState<Array<boolean>>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [showError, setShowError] = useState<boolean>(false);

  const formik: FormikValues = useFormik({
    initialValues: {
      name: '',
      ['include-blank']: false,
      ['allow-other']: false,
      addField: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name Cannot be Empty'),
    }),
    onSubmit: async (values) => {
      try {
        const data = {
          name: values.name,
          ['allow-other']: values['allow-other'] ? 1 : 0,
          ['include-blank']: values['include-blank'] ? 1 : 0,
          id: fieldData?.id || 0,
        };
        const updateFieldResponse = (await editField(
          data
        )) as UpdateFieldResponse;
        if (updateFieldResponse.status === 200) {
          Toastify('Field Updated Successfully ', 'success', 'select8');
         
        }
      } catch (error) {
        console.log('error is : ', error);
        Toastify('Error occured while updating field', 'error', 'select7');
      }
    },
  });

  const getFieldOptionsHandler = async () => {
    try {
      const getFieldOptionsResponse = (await getFieldOptions(
        id || 0
      )) as GetFieldOptionsResponse;
      if (getFieldOptionsResponse.status === 200) {
        const tempShowInputOptions = getFieldOptionsResponse.data.map(() => {
          return false;
        });
        setShowInputOptions(tempShowInputOptions);
        setFieldOptions(getFieldOptionsResponse.data);
        setOptions(getFieldOptionsResponse.data);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const addOptionHandler = async () => {
    if (formik.values.addField === '') {
      setShowError(true);
    } else {
      try {
        const addFieldOptionResponse = (await addFieldOption(
          formik.values.addField,
          id || 0
        )) as AddFieldResponse;
        if (addFieldOptionResponse.status === 200) {
          getFieldOptionsHandler();
          Toastify('Field Added Successfully', 'success', 'select1');
          formik.resetForm();
        }
      } catch (error) {
        Toastify('Error Occured While Adding Field', 'error', 'select2');
        console.log('error is : ', error);
      }
    }
  };

  const onUpdateField = async (name: string, id: number, index: number) => {
    try {
      const updateFieldOptionResponse = (await updateFieldOption(
        name,
        id
      )) as UpdateFieldResponse;
      if (updateFieldOptionResponse.status === 200) {
        const tempShowInputOptions = [...showInputOptions];
        tempShowInputOptions[index] = false;
        setShowInputOptions(tempShowInputOptions);
        Toastify('Field Updated Successfully ', 'success', 'select3');
        getFieldOptionsHandler();
      }
    } catch (error) {
      Toastify('Some Error Occured While Updating Field', 'error', 'select4');
      console.log('error is : ', error);
    }
  };

  const deleteOptionHandler = async () => {
    try {
      const deleteFieldOptionResponse = (await deleteFieldOption(
        deleteId
      )) as DeleteFieldResponse;
      if (deleteFieldOptionResponse.status === 200) {
        Toastify('Field Deleted Successfully ', 'success', 'select5');
        getFieldOptionsHandler();
        setShowDeleteModal(false);
      }
    } catch (error) {
      Toastify('Some Error Occured While Deleting Field', 'error', 'select6');
      console.log('error is : ', error);
    }
  };

  const sortFieldsHandler = async (sortedFields: number[]) => {
    try {
      const sortFieldsResponse = (await sortFieldOptions(
        fieldData?.id || 0,
        sortedFields
      )) as SortFieldsResponse;
      if (sortFieldsResponse.status === 200) {
        getFieldOptionsHandler();
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const fieldOptionChangeHandler = (value: string, index: number) => {
    const tempFieldOptions = [...fieldOptions];
    const tempShowInputOptions = [...showInputOptions];
    const updatedOption = {
      ...tempFieldOptions[index],
      person_attribute_column_option: {
        ...tempFieldOptions[index].person_attribute_column_option,
        name: value,
      },
    };
    tempFieldOptions[index] = updatedOption;
    tempShowInputOptions[index] = true;
    setFieldOptions(tempFieldOptions);
    setShowInputOptions(tempShowInputOptions);
  };

  const onCancelField = (index: number) => {
    const tempShowInputOptions = [...showInputOptions];
    const tempFieldOptions = [...fieldOptions];
    tempShowInputOptions[index] = false;
    tempFieldOptions[index].person_attribute_column_option.name =
      options[index].person_attribute_column_option.name;
    setShowInputOptions(tempShowInputOptions);
    setFieldOptions(tempFieldOptions);
  };

  const handleDragStart = (e: DragEvent<HTMLUListElement>, index: number) => {
    e.dataTransfer.setData('text/plain', index.toString());
  };
  const handleDragOver = (e: DragEvent<HTMLUListElement>) => {
    e.preventDefault();
  };
  const handleDrop = (e: DragEvent<HTMLUListElement>, index: number) => {
    const draggedIndex: string = e.dataTransfer.getData('text/plain');
    const draggedItem = fieldOptions[parseInt(draggedIndex)];
    const sortedFields = [...fieldOptions];
    sortedFields.splice(parseInt(draggedIndex), 1);
    sortedFields.splice(index, 0, draggedItem);
    const sortedOptionsIds = sortedFields.map((item) => {
      return item.person_attribute_column_option.id;
    });
    sortFieldsHandler(sortedOptionsIds);
    setFieldOptions(sortedFields);
  };

  useEffect(() => {
    getFieldOptionsHandler();
  }, []);

  useEffect(() => {
    if (fieldData) {
      formik.setValues({...fieldData, addField: ''});
    }
  }, [fieldData]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="md:px-6 px-3 py-5 font-inter bg-f6f7f9 h-screen"
    >
      <div className="md:px-1.5">
        <div className="flex justify-between items-center mb-4">
          <button
            type="submit"
            className="sm:py-2.5 py-1.5 sm:px-4 px-2 sm:text-13 text-xs font-medium leading-4 text-white dark:hover:text-black-400 dark:text-white rounded bg-primary dark:hover:bg-white"
          >
            Save Changes
          </button>
        </div>
        <div className="md:flex mt-4">
          {showDeleteModal && (
            <DeleteModal
              onCancel={() => setShowDeleteModal(false)}
              onDelete={() => deleteOptionHandler()}
            />
          )}
          <div className="w-full max-w-full">
            <div className="flex justify-between items-center mb-4">
              <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-424242 font-gilroyBold">
                Edit Field
              </h2>
              <button className="text-[13px] font-medium leading-5 rounded py-1.5 px-4 text-white ryeo-blue-bg">
                Save Changes
              </button>
            </div>
            <form>
              <div className="md:px-6 px-3 py-6 bg-white rounded-lg boxShadow">
                <div>
                  <p className="text-xs font-semibold leading-3 tracking-wide uppercase text-9e9e9e mb-3">
                    Name
                  </p>
                  <input
                    className="border border-9e9e9e h-11 p-4 text-[13px] font-medium leading-3 text-616161 rounded w-full"
                    type="text"
                    name="name"
                    value={formik.values.name}
                    placeholder="Name"
                    onChange={formik.handleChange}
                  />
                </div>
                
                <div className="mt-3">
                  <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-9e9e9e">
                    Data type
                  </label>
                  <div>
                    <div className="mt-2">
                      <label className="checkBox relative cursor-pointer pl-7">
                        <span className="text-xs font-normal leading-5 text-212121">
                          include blank option
                        </span>
                        <input
                          className=""
                          type="checkbox"
                          name="include-blank"
                          checked={formik.values['include-blank']}
                          onChange={formik.handleChange}
                        />
                      </label>
                    </div>
                    <div className="mt-2">
                      <label className="checkBox relative cursor-pointer pl-7">
                        <span className="text-xs font-normal leading-5 text-212121">
                          allow other responses
                        </span>
                        <input
                          className=""
                          type="checkbox"
                          name="allow-other"
                          checked={formik.values['allow-other']}
                          onChange={formik.handleChange}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="md:w-9/12 w-full md:pl-6 md:mt-0 mt-8">
            <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-424242 font-gilroyBold mb-7">
              Edit Option
            </h2>
            <div className="py-4 bg-white rounded-lg boxShadow">
              <div className="h-48 overflow-auto scroll-bar">
                {fieldOptions.map((option, index) => (
                  <ul
                    draggable={!showInputOptions[index]}
                    key={option.person_attribute_column_option.id}
                    className="flex justify-between items-center md:px-6 px-3 py-2 border-b"
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragOver={(e) => handleDragOver(e)}
                    onDrop={(e) => handleDrop(e, index)}
                  >
                    <div className="w-6 h-6">
                      <img className="w-full" src={Dots} alt="Dots" />
                    </div>
                    <input
                      value={option.person_attribute_column_option.name}
                      className="sm:text-base text-sm sm:leading-5 leading-3 text-212121 font-medium"
                      onClick={() =>
                        fieldOptionChangeHandler(
                          option.person_attribute_column_option.name,
                          index
                        )
                      }
                      onChange={(e) =>
                        fieldOptionChangeHandler(e.target.value, index)
                      }
                    />
                    {showInputOptions[index] && (
                      <div>
                        <div
                          onClick={() =>
                            onUpdateField(
                              option.person_attribute_column_option.name,
                              option.person_attribute_column_option.id,
                              index
                            )
                          }
                        >
                          Update
                        </div>
                        <div onClick={() => onCancelField(index)}>Cancel</div>
                      </div>
                    )}
                    <div
                      onClick={() => {
                        setShowDeleteModal(true);
                        setDeleteId(option.person_attribute_column_option.id);
                      }}
                      className="text-2xl font-normal text-white flex justify-center items-center w-3.5 h-3.5 rounded-full bg-red-400"
                    >
                      -
                    </div>
                  </ul>
                ))}
              </div>
              <div className="md:px-6 px-3 pt-2 flex flex-wrap items-center">
                <span className="text-xs font-medim text-ryzeoBlue mr-2">
                  Add Option
                </span>
                <input
                  className="border border-bdbdbd rounded w-full max-w-md text-[13px] font-medium leading-3 sm:w-52 w-full h-8 p-4 mt-2 mr-2"
                  type="text"
                  placeholder=""
                  name="addField"
                  value={formik.values.addField}
                  onChange={formik.handleChange}
                />
                <button
                  type="button"
                  className="text-[13px] font-medium leading-5 rounded py-1.5 px-4 text-white bg-blue-600 mt-2"
                  onClick={addOptionHandler}
                >
                  Add
                </button>
              </div>
              {showError && formik.values.addField === '' && (
                <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4">
                  <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
                    Option Cannot be Empty
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export default SelectFieldForm;
