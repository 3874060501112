import Search from 'assets/Images/ListsegmentImages/search.png';
import { Dispatch, SetStateAction} from 'react';
import { Link } from 'react-router-dom';
import React from 'react';
import { listSegments, create, list } from 'services/constant/routes';

interface Props {
  onSearch: Dispatch<SetStateAction<string>>;
}
const ListSegmentHeader = ({  onSearch }: Props) => {

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="sm:text-xl text-base font-medium leading-5 lg:w-52 md:w-56 w-48 text-black-300 dark:text-white">
          All Lists & Segments
        </h1>
        <div className="xl:w-8/12 lg:w-6/12 lg:block hidden">
          <div className="relative flex items-center py-2 px-4 border rounded-md border-gray-200 dark:border-black-400 bg-white dark:bg-slate-800 sm:h-11 h-9">
            <input
              className="text-13 font-medium leading-3 text-gray-500 dark:bg-slate-800 w-full h-full pl-8 h-6 focus:outline-none placeholder:text-gray-700 dark:text-white dark:placeholder:text-white rounded-md"
              type="text"
              data-pw="list-search"
              name="search"
              placeholder="Search Lists & Segments"
              onChange={(e) => onSearch(e.target.value)}
            />
            <img
              className="absolute dark:invert dark:brightness-0"
              src={Search}
              alt="Search"
            />
          </div>
        </div>
        <div className="relative" id="create-list-segment">
          <Link
            to={`${listSegments}/${list}/${create}`}
            className="sm:text-sm text-10 font-medium leading-5 text-white flex items-center ml-auto rounded-md py-2.5 px-4 w-28 h-11 font-inter bg-primary group ease-in duration-300 hover:bg-primaryHover hover:scale-105 justify-center"
          >
            Create List
          </Link>
        </div>
      </div>
      <div className="mt-5 lg:hidden sm:h-11 h-9">
        <div className="relative flex items-center sm:py-2 py-1 sm:px-4 px-2.5 border rounded border-gray-200 dark:border-black-400 bg-white dark:bg-slate-800">
          <input
            className="sm:text-13 text-xs font-medium leading-3 text-gray-500 dark:bg-slate-800 w-full h-full sm:pl-8 pl-5 h-6 focus:outline-none"
            type="text"
            name="search"
            placeholder="Search Lists & Segments"
          />
          <img className="absolute" src={Search} alt="Search" />
        </div>
      </div>
    </div>
  );
};
export default ListSegmentHeader;
