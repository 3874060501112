import FilterHeader from './FilterHeader';
import {
  getAllFilters,
  deleteFilter as deleteFilterItem,
} from 'services/apiHandlers/List-Segments/Filter';
import DeleteModal from 'components/Alert/DeleteModal';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import Table from 'components/Table';
import { z } from 'zod';
import { Toastify } from 'App';
import { tableSort } from 'utils/common';
import { Link } from 'react-router-dom';
import { FilterDataSchema } from './Types';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { ThemeContext } from 'store/ThemeContext';
import styled from 'styled-components';
import {
  listSegments,
  filters as filterTab,
  view as filterView,
} from 'services/constant/routes';
import React from 'react';
import { UserContext } from 'store/UserContext';

const StyleWrapper = styled.div`
  .rdt_TableRow {
    height: 50px;
  }

  .rdt_TableRow .rdt_TableCell {
    border-right: 1px solid #e0e0e0;
  }

  .rdt_TableRow .rdt_TableCell:last-child {
    border-right: 0;
  }

  .rdt_TableHeadRow .rdt_TableCol {
    border-right: 1px solid #e0e0e0;
  }
  .rdt_TableHeadRow .rdt_TableCol:last-child {
    border-right: 0;
  }
`;

const responseStatusSchema = z.object({ status: z.number() });
type ResponseStatus = z.infer<typeof responseStatusSchema>;

const RuleSchema = z.object({ operator: z.string(), value: z.string() });

export const BlockSchema = z.object({
  field_name: z.string(),
  field_source: z.string(),
  field_type: z.string(),
  logic_operator: z.string(),
  rules: z.object({ rule: z.union([z.array(RuleSchema), RuleSchema]) }),
});
export type Block = z.infer<typeof BlockSchema>;

export type FilterData = z.infer<typeof FilterDataSchema>;

const FilterSchema = z.object({
  data: z.object({ filters: z.array(FilterDataSchema) }),
  status: z.number(),
});

export type FilterResponseData = z.infer<typeof FilterSchema>;

const Filter = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<Array<FilterData>>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [deleteId, setDeleteid] = useState<number>(0);
  const themeCtx = useContext(ThemeContext);
  const [anchorId, setAnchorId] = useState<string>('');
  const [selectedName, setSelectedName] = useState<string | null>(null);
  const userCtx = useContext(UserContext);
  const theme = themeCtx?.theme;

  const getData = async (preventLoading?: boolean) => {
    !preventLoading && setLoading(true);
    try {
      const getFiltersResponse = (await getAllFilters()) as FilterResponseData;
      if (getFiltersResponse.status === 200) {
        const tempFilters = getFiltersResponse?.data?.filters.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setFilters(tempFilters);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false);
  };

  const deleteHandler = async (id: number) => {
    try {
      const deleteFilterResponse = (await deleteFilterItem(
        id
      )) as ResponseStatus;
      if (deleteFilterResponse.status === 200) {
        Toastify('Filter Deleted Successfully', 'success', 'filter1');
        getData(true);
      }
    } catch (error) {
      Toastify('Deleting Filter Failed', 'error', 'filter2');
    }
  };

  const filterSort = (rowA: FilterData, rowB: FilterData) => {
    return tableSort(rowA.name, rowB.name);
  };

  const columns: TableColumn<FilterData>[] = useMemo(
    () => [
      {
        name: 'Filter',
        cell: (row, index) => {
          return (
            <Link
              to={`${listSegments}/${filterTab}/${filterView}/${row.id}`}
              id={`${index === 0 ? row.id : ''}`}
              className="dark:text-white hover:underline text-blue-700 cursor-pointer text-black-400 text-xs font-medium mt-1"
              data-pw={`${index === 0 ? 'first-filter' : ''}`}
            >
              <div data-pw={`${index === 0 ? 'first-filter-name' : ''}`}>
                {row.name}
              </div>
            </Link>
          );
        },
        sortable: true,
        sortFunction: filterSort,
      },
      {
        name: 'Status',
        cell: (row) => {
          return (
            <div className="dark:text-white">
              <div>{row.description}</div>
            </div>
          );
        },
      },
      {
        name: 'Actions',
        cell: (row, index) => {
          return (
            <td
              onMouseLeave={() => setSelectedName(null)}
              className="whitespace-nowrap py-3 pl-3 pr-3 text-13 sm:align-top align-middle  dark:text-white text-black-400 dark:text-white dark:text-white md:w-[400px] md:min-w-[400px] w-[200px] min-w-[200px] flex flex-wrap items-center "
            >
              {!userCtx?.currentUserData?.readonly_mode && (
                <div className="flex items-center">
                  {row.mutable === 'true' && (
                    <div className="flex items-center">
                      <Link
                        to={`${listSegments}/${filterTab}/${row.id}`}
                        className="mr-4 hover:text-[#3575d5]"
                      >
                        <span className="text-primary dark:text-white">
                          Edit
                        </span>
                      </Link>
                      {row.used_by_trigger ? (
                        <>
                          <div className="flex items-center my-1.5 hover:text-[#3575d5]">
                            Locked
                          </div>
                          <div className="inline-block relative ml-2">
                            <div
                              className="w-4 h-4  rounded-full bg-gray-600 flex items-center justify-center"
                              id={`tooltip-anchor-children-${index}`}
                              onMouseEnter={() => {
                                setAnchorId(`tooltip-anchor-children-${index}`);
                                setSelectedName(row.name);
                              }}
                            >
                              ?
                            </div>
                            <Tooltip
                              style={{
                                pointerEvents: 'auto',
                                backgroundColor: `${
                                  theme === 'dark' ? '#1e293b' : 'white'
                                }`,
                                border: '1px solid #9e9e9e',
                                zIndex: '1',
                                opacity: '1',
                              }}
                              isOpen={selectedName === row.name}
                              anchorId={anchorId}
                              classNameArrow="-bottom-[5px] border border-gray-500 border-t-0 border-l-0"
                            >
                              <div className="text-black-400 dark:text-white w-60">
                                <div className="flex gap-1 items-center">
                                  <h1 className="font-bold text-lg py-2">
                                    Used by a Trigger
                                  </h1>
                                  <span>
                                    Filter cannot be deleted because it is being
                                    used by the filter trigger:
                                    <span className="font-bold">
                                      {row.used_by_trigger}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </Tooltip>
                          </div>
                        </>
                      ) : (
                        <button
                          onClick={() => {
                            setDeleteid(row.id);
                            setShowDeleteAlert(true);
                          }}
                          type="button"
                          className="flex items-center my-1.5 hover:text-[#3575d5]"
                          data-pw={`${
                            index === 0 ? 'first-filter-delete' : ''
                          }`}
                          id={`${index === 0 ? row.id.toString() : ''}`}
                        >
                          <span>Delete</span>
                        </button>
                      )}
                    </div>
                  )}

                  {row.mutable === 'false' && (
                    <div>
                      <div className="flex items-center my-1.5 hover:text-[#3575d5]">
                        Locked
                      </div>
                    </div>
                  )}
                </div>
              )}
            </td>
          );
        },
      },
    ],
    [selectedName]
  );

  const filterList =
    searchQuery.length > 0
      ? filters.filter((l) =>
          l.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : filters;

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {showDeleteAlert && (
        <div
          onClick={() => setShowDeleteAlert(false)}
          className="fixed left-0 top-0 w-full h-full z-10 bg-black-200 duration-300"
        >
          <DeleteModal
            onDelete={() => deleteHandler(deleteId)}
            onCancel={() => setShowDeleteAlert(false)}
          />
        </div>
      )}
      <div>
        <FilterHeader setSearch={setSearchQuery} searchQuery={searchQuery} />
        <StyleWrapper>
          <Table
            className="scrollbar-hide"
            data={filterList}
            columns={columns}
            progressPending={loading}
          />
        </StyleWrapper>
      </div>
    </div>
  );
};
export default Filter;
