import { FC, useState } from 'react';
import Check from 'assets/Images/Filter/check-icon.png';
import { z } from 'zod';
import ValueDropdown from 'components/Dropdown/valueDropdown';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import Dropdown from 'components/Dropdown/Dropdown';
import { segmentDataSchema, ruleDataSchema } from './Types';
import React from 'react';
import {
  addRule,
  deleteRule,
  swapBlock,
  swapRule,
} from 'services/apiHandlers/List-Segments/Filter';
import Modal from 'components/Alert/Modal';
import DeleteModal from 'components/Alert/DeleteModal';
import DatePicker from 'components/Datepicker';

interface FilterOption {
  [key: string]: string;

  '>': string;
  '>=': string;
  '<': string;
  '<=': string;
  '%': string;
  '!%': string;
  's%': string;
  '%s': string;
  '!s%': string;
  '!%s': string;
  '==': string;
  '!=': string;
}

const filterOptions: FilterOption = {
  '>': 'is greater than',
  '>=': 'is greater than or equal to',
  '<': 'is less than',
  '<=': 'is less than or equal to',
  '%': 'contains',
  '!%': 'does not contain',
  's%': 'begins with',
  '%s': 'ends with',
  '!s%': 'does not begin with',
  '!%s': 'does not end with',
  '==': 'is equal to',
  '!=': 'is not equal to',
};
const crudResponseSchema = z.object({ status: z.number() });
const addRuleResponseSchema = z.object({
  data: z.object({ segment_rule: ruleDataSchema }),
  status: z.number(),
});

type RuleData = z.infer<typeof ruleDataSchema>;
type AddRuleResponse = z.infer<typeof addRuleResponseSchema>;
type SegmentData = z.infer<typeof segmentDataSchema>;
type CrudResponse = z.infer<typeof crudResponseSchema>;

interface Props {
  segment: SegmentData;
  operator: string;
  onBlockSwap: () => void;
  onSwapRule: (id: number) => void;
  onRuleRemove: (segmentId: number, ruleId: number) => void;
  onAddRule: (id: number, ruleData: RuleData) => void;
  onOperatorChange: (
    id: number,
    ruleId: number,
    value: string,
    field: string
  ) => void;
}
const Segments: FC<Props> = ({
  segment,
  operator,
  onBlockSwap,
  onSwapRule,
  onRuleRemove,
  onAddRule,
  onOperatorChange,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteRuleId, setDeleteRuleid] = useState<number>(0);
  const [anchorId, setAnchorId] = useState<string>('');
  const [ruleTooltip, setRuleTooltip] = useState<number | null>(0);
  const [conditionAnchorId,setConditionAnchorId] = useState<string>('');
  const [conditionRuleTooltip, setConditionRuleTooltip] = useState<number | null>(0); 

  const getFilterOptions = (options: string[][]) => {
    const tempOptions = options.map((option) => {
      return { name: option[0], value: option[1] };
    });
    return tempOptions;
  };

  const swapBlockHandler = async () => {
    try {
      const swapBlockResponse = (await swapBlock(
        segment.id.toString()
      )) as CrudResponse;
      if (swapBlockResponse.status === 200) {
        onBlockSwap();
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const swapRuleHandler = async () => {
    try {
      const swapRuleResponse = (await swapRule(
        segment.id.toString()
      )) as CrudResponse;
      if (swapRuleResponse.status === 200) {
        onSwapRule(segment.id);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const deleteRuleHandler = async () => {
    try {
      const deleteRuleResponse = (await deleteRule(
        deleteRuleId
      )) as CrudResponse;
      if (deleteRuleResponse.status === 200) {
        onRuleRemove(segment.id, deleteRuleId);
        setShowDeleteModal(false);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const addRuleHandler = async () => {
    try {
      const addRuleResponse = (await addRule(segment.id)) as AddRuleResponse;
      if (addRuleResponse.status === 200) {
        onAddRule(segment.id, addRuleResponse.data.segment_rule);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  return (
    <div className="mt-4">
      <div className="w-full">
        <div>
          <div
            className={`sm:px-6 px-4 sm:py-4 py-2.5 border dark:border-black-400 rounded-lg shadow-lg shadow-gray-200 dark:shadow-none bg-white dark:bg-slate-800`}
          >
            <h3 className="md:text-base text-sm font-medium leading-6 text-black-300 dark:text-white">
              {segment.name}
              <span className="font-light ml-2">({segment.type_label})</span>
            </h3>
            <div>
              <div>
                {segment.rules.map((rule, index) => (
                  <div
                    key={rule.id}
                    className="flex items-center flex-wrap mt-4 "
                  >
                    <div className="w-52 w- border border-gray-800 dark:border-black-400 flex items-center rounded-md relative sm:h-11 h-9 mr-3">
                      <ValueDropdown
                        options={getFilterOptions(
                          rule.comparison_operators.options
                        )}
                        value={
                          filterOptions[rule.comparison_operators.selected]
                        }
                        onSelect={(value) =>
                          onOperatorChange(
                            segment.id,
                            rule.id,
                            value,
                            'operator'
                          )
                        }
                      />
                    </div>
                    <div>
                      {rule.data_type?.table?.ruby_class === 'datetime' ? (
                        <div>
                          <div className='relative'>
                          <DatePicker
                            date={rule.comparison_value}
                            onDateChange={(date: string) =>
                              onOperatorChange(segment.id, rule.id, date, '')
                            }
                            showTime={true}
                          />
                          </div>
                          <div className="inline-block relative">
                            <img
                              src={Check}
                              alt="computer"
                              className="w-4 h-4"
                              id={`Tooltip${rule.id}`}
                              onMouseEnter={() => {
                                setAnchorId(`Tooltip${rule.id}`);
                                setRuleTooltip(rule.id);
                              }}
                              onMouseLeave={() => {
                                setRuleTooltip(null);
                              }}
                            />

                            <Tooltip
                              style={{
                                pointerEvents: 'auto',
                                backgroundColor: 'white',
                                border: '1px solid #9e9e9e',
                                zIndex: '1',
                                opacity: '1',
                              }}
                              isOpen={rule.id === ruleTooltip}
                              anchorId={anchorId}
                              classNameArrow="-bottom-[5px] border border-gray-500 border-t-0 border-l-0"
                            >
                              <div className="text-black-400 dark:text-white w-60">
                                <div className="flex gap-1 items-center">
                                  <h1 className="font-bold text-lg py-2">
                                    Help
                                  </h1>

                                  <p className="flat">
                                    Date and DateTime fields can accept a static
                                    time such as 1970-01-01, or the relative
                                    date of <b>today</b>. <br />
                                    <br />
                                    When using <b>today</b>, an offset can be
                                    provided for additional flexibility.
                                    <br />
                                    <br />
                                  </p>

                                  <p className="flat">
                                    (e.g. yesterday: today - 1)
                                    <br />
                                    (e.g. tomorrow: today + 1)
                                    <br />
                                  </p>
                                </div>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      ) : rule.data_type?.table?.name === 'Opt Status' ? (
                        <Dropdown
                          options={rule.data_type.table.default_options}
                          value={
                            rule.comparison_value === ''
                              ? 'active'
                              : rule.comparison_value
                          }
                          onSelect={(value) =>
                            onOperatorChange(segment.id, rule.id, value, '')
                          }
                        />
                      ) : (
                        <input
                          className="w-full p-4 text-13 font-medium leading-3 dark:bg-slate-800 border border-gray-800 dark:border-black-400 rounded-md text-black-400 dark:text-white sm:h-11 h-9 focus:outline-none"
                          type="text"
                          placeholder=""
                          value={rule.comparison_value}
                          onChange={(e) =>
                            onOperatorChange(
                              segment.id,
                              rule.id,
                              e.target.value,
                              ''
                            )
                          }
                        />
                      )}
                    </div>

                    <div
                      onClick={() => {
                        setDeleteRuleid(rule.id);
                        setShowDeleteModal(true);
                      }}
                      className="ml-2"
                    >
                      <button
                        type="button"
                        className="w-4 h-4 rounded-full bg-red-500 flex justify-center items-center text-3xl font-light text-white pb-0.5"
                      >
                        -
                      </button>
                    </div>
                    {segment.rules.length - 1 !== index && (
                      <div>
                        <p className="text-sm font-medium leading-5 text-black-700 dark:text-white ml-2">
                          {segment.logical_operator}
                        </p>
                        {rule.data_type?.table?.ruby_class === 'datetime' && (
                          <div>
                            <div className="inline-block relative">
                              <img
                                src={Check}
                                alt="computer"
                                className="w-4 h-4"
                                id={`ConditionTooltip${rule.id}`}
                                onMouseEnter={() => {
                                  setConditionAnchorId(`ConditionTooltip${rule.id}`)
                                  setConditionRuleTooltip(rule.id)
                                }}
                                onMouseLeave={() => {
                                  setConditionRuleTooltip(null)  
                                }}
                              />

                              <Tooltip
                                style={{
                                  pointerEvents: 'auto',
                                  backgroundColor: 'white',
                                  border: '1px solid #9e9e9e',
                                  zIndex: '1',
                                  opacity: '1',
                                }}
                                isOpen={conditionRuleTooltip === rule.id}
                                anchorId={conditionAnchorId}
                                classNameArrow="-bottom-[5px] border border-gray-500 border-t-0 border-l-0"
                              >
                                <div className="text-black-400 dark:text-white w-60">
                                  <div className="flex gap-1 items-center">
                                    <h1 className="font-bold text-lg py-2">
                                      Help
                                    </h1>
                                    <div>
                                      <p className="flat">
                                        You can use the asterisk (*) wildcard
                                        character with <strong>contains</strong>{' '}
                                        operators to search for a string of
                                        characters.
                                        <br />
                                        <br />
                                        For example,{' '}
                                        <strong>s*@gmail.com</strong> will match{' '}
                                        <strong>steve@gmail.com</strong> and{' '}
                                        <strong>sandy@gmail.com</strong>.
                                        <br />
                                        <br />
                                        To escape an asterisk, use a backslash:{' '}
                                        <strong>searchterm\*</strong>
                                      </p>
                                      <img alt="Help" src={Check} />
                                      help
                                    </div>
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}

                <div className="mt-3 flex items-center">
                  <a
                    onClick={addRuleHandler}
                    className="flex items-center text-xs font-medium text-primary mr-2 cursor-pointer"
                  >
                    <span className="flex justify-center items-center inline-block rounded-full text-primary text-xs font-bold mr-1">
                      +
                    </span>
                    <span className="font-semibold mr-1">Add</span>
                    another condition
                  </a>
                  {segment.rules.length > 1 && (
                    <a
                      onClick={() => swapRuleHandler()}
                      className="flex items-center text-xs font-medium text-primary mr-4 underline cursor-pointer"
                    >
                      Toggle AND / OR
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={showDeleteModal}>
        <DeleteModal
          onCancel={() => setShowDeleteModal(false)}
          onDelete={deleteRuleHandler}
        />
      </Modal>
      {operator.length > 0 && (
        <h2
          onClick={() => swapBlockHandler()}
          className="sm:text-xl text-base sm:leading-5 leading-3 text-primary dark:text-white text-center my-4"
        >
          {operator}
        </h2>
      )}
    </div>
  );
};
export default Segments;
