import httpService from 'services/core/HttpService';
import { GET_ALL_LOGS } from 'services/constant/apiRoutes';

const { REACT_APP_BASE_URL } = process.env;

export const getAllLogs = (
  startDate: string,
  endDate: string,
  filter?: string,
  startToken?: string,
  selectedEvent?: string
) => {
  return new Promise((resolve, reject) => {
    let url =
      GET_ALL_LOGS.replace(
        '{{FILTER}}',
        filter && filter?.length > 0 ? `&filter=${filter}` : ''
      )
        .replace('{{START}}', startDate)
        .replace('{{END}}', endDate) +
      (startToken ? `&startToken=${startToken}` : '');

    if (selectedEvent) {
      if (selectedEvent !== 'All Events') {
        url = url
          .replace('_by_timestamp', '')
          .replace('{{NAME}}', `&name=${selectedEvent}`);
      } else {
        url = url.replace('{{NAME}}', '');
      }
    } else {
      url = url.replace('{{NAME}}', '');
    }

    httpService
      .get(url)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const downloadLogReport = (
  selectedEvent: string,
  filter: string,
  startDate: string,
  endDate: string,
  startToken: string
) => {
  console.log('start token is : ', startToken);
  const url =
    GET_ALL_LOGS.replace('_by_timestamp', '')
      .replace('{{NAME}}', `&name=${selectedEvent}`)
      .replace('{{FILTER}}', filter ? `&filter=${filter}` : '')
      .replace('{{START}}', startDate)
      .replace('{{END}}', endDate) +
    `&startToken=${startToken}` +
    `&format=csv`;
  window.open(REACT_APP_BASE_URL + url);
};
