import PurpleLogoInitial from 'assets/Images/Login/purpleLogoInitial.png';
import ErrorBoundary from 'components/ErrorBoundary';
import LoginForm from './LoginForm';
import React from 'react';

const Login = () => {
  return (
    <ErrorBoundary>
      <div className="h-screen flex items-center px-4 bg-cover bg-dot-pattern">
        <div className="shadow-2xl w-4/5 mx-auto rounded-3xl h-[90vh] bg-white flex">
          <div
            className="w-3/6 bg-primary rounded-tl-3xl rounded-bl-3xl overflow-hidden flex justify-center items-center"
            style={{
              backgroundImage: 'radial-gradient(white -60%, #5338a0 27%)',
            }}
          >
            <img
              src="https://www.pngarts.com/files/1/Email-Marketing-PNG-Image-Background.png"
              alt="login"
              className="w-8/12	mx-auto"
            />
          </div>
          <div className="w-3/6 relative">
            <img
              src={PurpleLogoInitial}
              alt="rzyeo"
              className="mx-auto mt-12"
            />
            <h2 className="text-center text-xl md:text-4xl font-medium text-headingColor mt-4 mb-0">
              Hello Again!
            </h2>
            <p className="text-center max-w-md mx-auto text-lightParagraphColor my-3 w-11/12">
              Maximize Your Ecommerce Sales With Our Email Agency And Software.
            </p>
            <div className="px-4 md:px-8 font-inter">
              <LoginForm />
            </div>
            <h5 className="text-darkParagraphColor absolute left-0 right-0 mx-auto text-center bottom-8">
              Already have an account?
              <a href="#" className="text-primary">
                Sign Up
              </a>
            </h5>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default Login;
