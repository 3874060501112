import { FC } from 'react';
import { SubscriberActionData } from '../index.type';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import React from "react"

interface Props {
  onClose: () => void;
  onSave: (data: SubscriberActionData) => void;
}
const UnsubscribeAction: FC<Props> = ({ onClose, onSave }) => {
  
  const saveDataHandler = () => {
    const data = {
      unsubscribeActionEnabled: true,
      _type: 'UnsubscribeAction',
    };
    onSave(data);
    onClose();
  }
  
  return (
    <ModalWrapper
      disabled={false}
      onSave={saveDataHandler}
      onClose={onClose}
    >
      <div className="bg-white dark:bg-black-400">
        <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium mb-8 dark:text-white">
          Action
        </h2>
        <p className="text-13 font-medium leading-4 text-black-400 mt-4 dark:text-white">
          This node will unsubscribe the person associated with the event from
          your account.
        </p>
      </div>
    </ModalWrapper>
  );
};
export default UnsubscribeAction;
