import DownArrow from 'assets/Images/Common/down-arrow.png';
import Edit from 'assets/Images/ListsegmentImages/edit.png';
import Delete from 'assets/Images/ListsegmentImages/delete.png';
import { useFormik } from 'formik';
import {
  getConnectedServices,
  createConnectedServices,
  editConnectedServices,
} from 'services/apiHandlers/Integration/Miscellaneous';
import { FC, useEffect, useState } from 'react';
import { z } from 'zod';
import * as Yup from 'yup';
import { Toastify } from 'App';
import ErrorBoundary from 'components/ErrorBoundary';
import uuid from 'react-uuid';
import React from "react";

interface Props {
  workflow?: boolean;
  mailer?: string;
}

export const mailerDropdownOptions = ['Mandrill', 'Sendgrid'];

const postResponseSchema = z.object({ status: z.number() });
type postResponse = z.infer<typeof postResponseSchema>;

const dataSchema = z.object({
  accountId: z.string(),
  id: z.string(),
  links: z.object({ triggers: z.array(z.string()) }),
  name: z.string(),
  type: z.string(),
});
type data = z.infer<typeof dataSchema>;

const responseSchema = z.object({
  data: z.array(dataSchema),
  status: z.number(),
});
type response = z.infer<typeof responseSchema>;

const ConnectedServices: FC<Props> = ({ workflow }) => {
  const [showDefaultMailer, setShowDefaultMailer] = useState(false);
  const [showMailingServices, setShowMailingServices] = useState(false);
  const [editServices, setEditServices] = useState(false);
  const [servicesData, setServicesData] = useState<data>();
  const getData = async () => {
    const getConnectedServicesResponse =
      (await getConnectedServices()) as response;
    if (getConnectedServicesResponse.status === 200) {
      setServicesData(getConnectedServicesResponse.data[0]);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      apiKey: '',
      type: 'Mandrill',
      accountId: 2,
      id: 'c2b6e48a-700e-4411-8eaa-2b6ae6d8a53e',
      links: { triggers: [] },
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      apiKey: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      if (editServices) {
        try {
          const editServicesResponse = (await editConnectedServices(
            values
          )) as postResponse;
          if (editServicesResponse.status === 200) {
            Toastify('Editing Connected Services Failed', 'error', 'services3');
          }
        } catch (error) {
          Toastify('Editing Connected Services Failed', 'error', 'services3');
        }
      } else {
        const createData = {
          type: values.type,
          name: values.name,
          apiKey: values.apiKey,
        };
        try {
          const createServicesResponse = (await createConnectedServices(
            createData
          )) as postResponse;
          if (createServicesResponse.status === 200) {
            Toastify(
              'Connected Services Created Successfully',
              'success',
              'services1'
            );
          }
        } catch (error) {
          Toastify('Creating Connected Services Failed', 'error', 'services2');
        }
      }
    },
  });

  const editMailerHandler = () => {
    setEditServices(true);
    setShowMailingServices(true);
    formik.setFieldValue('name', servicesData?.name);
    formik.setFieldValue('apiKey', uuid());
    formik.setFieldValue(
      'type',
      servicesData?.type ? servicesData.type : 'Mandrill'
    );
  };

  const mailingServiceHandler = () => {
    setShowMailingServices((prev) => !prev);
    formik.setFieldValue('name', '');
    formik.setFieldValue('type', 'Mandrill');
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ErrorBoundary>
      <form
        onSubmit={formik.handleSubmit}
        className="p-4 pb-10 bg-gray-150 dark:bg-slate-800 rounded-md border border-gray-800 dark:border-black-400"
      >
        {!workflow && (
          <h3 className="text-13 font-semibold leading-4  text-black-700 dark:text-white mb-2">
            Connected Services
          </h3>
        )}
        <div
          onClick={() => setShowDefaultMailer((prev) => !prev)}
          className="relative border border-gray-800 dark:border-black-400 rounded-md sm:h-11 h-9 cursor-pointer px-3 py-2.5 flex justify-between items-center"
        >
          <p className="text-13 font-medium hiddenleading-5 text-gray-700 dark:text-white">
            {servicesData?.name ? servicesData.name : 'Default Mailer'}
          </p>
          <img src={DownArrow} alt="Arrow" />
        </div>
        {showDefaultMailer && (
          <div className="bg-white dark:bg-slate-800 mt-4 border border-gray-800 dark:border-black-400 rounded-md boxShadow w-full">
            <div className="flex justify-between item rounded-t bg-gray-200 p-3">
              <p className="text-sm font-medium leading-5 text-gray-700">
                Default Mailer
              </p>
              <div className="flex items-center">
                <button
                  onClick={editMailerHandler}
                  type="button"
                  className="w-3.5 h-4 mr-2"
                >
                  <img className="w-full" src={Edit} alt="Edit" />
                </button>
                <button type="button" className="w-3.5 h-4">
                  <img className="w-full" src={Delete} alt="Delete" />
                </button>
              </div>
            </div>
            <div className="p-3">
              <div
                className="relative  rounded h-11 cursor-pointer px-3 py-1.5"
                onClick={mailingServiceHandler}
              >
                <p className="text-sm font-medium leading-5  text-black-700 dark:text-white">
                  Connect New Mailing Service
                </p>
                <img
                  className="absolute right-1.5 top-2"
                  src={DownArrow}
                  alt="Arrow"
                />
              </div>
              {showMailingServices && (
                <>
                  <div className="flex justify-between items-center border h-11 border-gray-800 dark:border-black-400 rounded-md relative md:w-7/12 mt-2.5">
                    <select
                      className="h-full rounded-md w-full px-3.5 text-13 font-medium leading-3 text-gray-700 dark:text-white focus:outline-none dark:bg-slate-800"
                      value={formik.values.type}
                      name="type"
                      defaultValue={formik.values.type}
                      onChange={formik.handleChange}
                    >
                      {mailerDropdownOptions.map((options) => (
                        <option key={options} value={options} label={options}>
                          {options}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mt-4 md:w-7/12">
                    <input
                      className="text-13 focus:border-primary dark:focus:border-white focus:outline-none font-medium leading-3 text-gray-700 dark:text-white py-2 rounded-md px-4 bg-white border border-gray-800 dark:border-black-400 w-full sm:h-11 h-9 dark:bg-slate-800"
                      type="text"
                      placeholder="Name for this account"
                      value={formik.values.name}
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.name && formik.touched.name ? (
                      <div className="text-xs leading-4 text-red-400 mt-1.5">
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </div>
                  <div className="mt-4 md:w-7/12">
                    <input
                      className="text-13 focus:border-primary dark:focus:border-white focus:outline-none font-medium leading-3 text-gray-700 dark:text-white py-2 rounded-md px-4 bg-white border border-gray-800 dark:border-black-400 w-full dark:bg-slate-800 sm:h-11 h-9"
                      type="text"
                      name="apiKey"
                      value={formik.values.apiKey}
                      placeholder="API key"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.apiKey && formik.touched.apiKey ? (
                      <div className="text-xs leading-4 text-red-400 mt-1.5">
                        {formik.errors.apiKey}
                      </div>
                    ) : null}
                  </div>
                  {editServices ? (
                    <div className="mt-4 flex">
                      <div>
                        <button
                          type="submit"
                          className="text-13 font-medium leading-5 rounded py-2 px-4 text-white bg-primary dark:hover:bg-white dark:hover:text-black-400"
                        >
                          Update
                        </button>
                      </div>
                      <div className="ml-4">
                        <button
                          type="button"
                          className="py-2.5 px-4 w-28 h-11 sm:text-13 text-xs font-medium leading-5 text-black-400 dark:text-white border border-gray-800 dark:border-black-400 rounded-md bg-white dark:bg-slate-800 dark:hover:bg-white hover:text-primary dark:text-white dark:hover:text-black-400 dark:text-white mr-3"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-4">
                      <button
                        type="submit"
                        className="text-13 font-medium leading-5 rounded-md py-2 px-4 text-white bg-btnPrimary ease-in-in duration-300 hover:bg-primary hover:scale-105"
                      >
                        Connect
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </form>
    </ErrorBoundary>
  );
};
export default ConnectedServices;
