
import listMenu from 'assets/Images/ListsegmentImages/list-menu.png';
import notificationBell from 'assets/Images/ListsegmentImages/notification-bell.png';
import filter from 'assets/Images/ListsegmentImages/filter.png';
import lists from 'assets/Images/ListsegmentImages/lists.png';
import Breadcrumb from 'components/Breadcrumbs/Breadcrumbs';
import ListHeading from 'components/List-Segments/ListHeading';
import Tabs from 'components/Tabs/Tabs';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import { filters, listSegments,list, segmentDefinition, subscribers } from 'services/constant/routes';

export enum TabsEnum {
  LIST = 'list-segment',
  SUBSCRIBERS = 'subscriber',
  FILTER = 'filter',
  SEGMENT = 'segment',
}

export const tabs = [
  {
    src: listMenu,
    alt: 'List',
    title: 'Lists & Segments',
    link: listSegments,
    active: list
  },
  {
    src: notificationBell,
    alt: 'notification-bell',
    title: 'Subscribers Profile',
    link: `${listSegments}/${subscribers}`, 
    active: subscribers
  },
  {
    src: filter,
    alt: 'Filter',
    title: 'Filters',
    link: `${listSegments}/${filters}`,
    active: filters
  },
  {
    src: lists,
    alt: 'Lists',
    title: 'Segments Definition',
    link: `${listSegments}/${segmentDefinition}`,
    active: segmentDefinition
  },
];

  const ListSegmentHeader = () => {
  return (
    <ErrorBoundary>
      <div className="md:px-6 px-3 pt-5 border-b dark:border-black-400 font-inter">
        <div className="md:px-1.5">
          <Breadcrumb />
          <ListHeading />
          <Tabs tabs={tabs}  />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ListSegmentHeader;
