import { useEffect, useState } from 'react';
import { getFields } from 'services/apiHandlers/List-Segments/Field';
import xmlToJSON from 'utils/xmlToJSON';
import { Form } from './SubscribersForm';
import FieldData from './FieldData';
import { formatXmlJsonResponse } from 'utils/common';
import AddFieldForm from './AddFieldForm';
import Skeleton from 'components/Skeleton/Skeleton';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';

const Fields: React.FC = () => {
  const [fieldData, setFieldData] = useState<Array<Form>>([]);
  const [loadingFields, setLoadingFields] = useState<boolean>(true);

  const getData = async () => {
    /* eslint-disable */
    const getFieldsResponse: any = await getFields();
    const data: any = xmlToJSON.parseString(getFieldsResponse.data);
    const fields = formatXmlJsonResponse(data?.fields[0]?.field);
    setLoadingFields(false);
    setFieldData(fields as any);
    /* eslint-enable */
  };

  useEffect(() => {
    getData();
  }, []);
  
  return (
    <ErrorBoundary>
      <div>
        <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black dark:text-white font-medium mb-6">
          Subscriber Fields
        </h2>
        <div>
          <div>
            <AddFieldForm getData={getData} />
            <div className="bg-white dark:bg-slate-800 border border-gray-800 dark:border-black-400 shadow-lg shadow-gray-200 dark:shadow-none rounded-lg mt-6">
              <div className="md:px-6 px-3 py-4 bg-gray-50 dark:bg-slate-700 rounded-t-lg">
                <div className="flex items-center">
                  <h3 className="sm:text-base text-sm sm:leading-5 leading-3 text-black-700 dark:text-white font-medium mr-2">
                    E-Mail Address
                  </h3>
                  <span className="text-sm font-medium leading-4 text-gray-500 dark:text-white">
                    (Text Field)
                  </span>
                </div>
                {loadingFields ? (
                  <Skeleton />
                ) : (
                  fieldData.map((field, index) => (
                    <FieldData
                      key={field.id}
                      data={field}
                      getData={getData}
                      index={fieldData.length - 1 === index ? index : 0}
                      first={index === 0}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default Fields;
