import Mail from 'assets/Images/svg-images/mail.svg';
import Swap from 'assets/Images/Workflow/swap.png';
import { NotifyEmailData } from '../index.type';
import { FC, useEffect, useState } from 'react';
import { useFormik, FormikValues } from 'formik';
import ConnectedServices from 'components/Integration/Body/Miscellaneous/ConnectedServices';
import * as Yup from 'yup';
import uuid from 'react-uuid';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import React from 'react';

interface Props {
  emailData: NotifyEmailData | undefined;
  onClose: () => void;
  onSave: (data: NotifyEmailData) => void;
}

const notifyEmailFormData = [
  { name: 'emailName', placeholder: 'Email Name', label: 'From Name' },
  { name: 'emailAddress', placeholder: 'Email Address', label: 'From Email' },
  { name: 'sendTo', placeholder: 'Send To', label: 'Send To' },
  { name: 'subject', placeholder: 'Subject', label: 'Subject' },
  { name: 'message', placeholder: 'Message', label: 'message' },
];

const NotifyEmail: FC<Props> = ({ emailData, onClose, onSave }) => {
  const [formIsValid, setFormIsValid] = useState<boolean>(true);

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      emailName: '',
      emailAddress: '',
      sendTo: '',
      subject: '',
      message: '',
    },
    validationSchema: Yup.object({
      emailName: Yup.string().required('Required'),
      emailAddress: Yup.string().required('Required'),
      sendTo: Yup.string().required('Required'),
      subject: Yup.string().required('Required'),
      message: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      const data = {
        ...values,
        _type: 'NotifyByEmailAction',
        emailProviderId: uuid(),
      };
      onSave(data);
      onClose();
    },
  });

  useEffect(() => {
    if (formik.dirty && formik.isValid) {
      setFormIsValid(true);
    }
  }, [formik.isValid, formik.values]);

  useEffect(() => {
    if (emailData) {
      formik.setValues(emailData);
    }
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <ModalWrapper
        onClose={onClose}
        disabled={!formIsValid}
        onSave={() => {
          if (!formik.dirty || !formik.isValid) {
            setFormIsValid(false);
          }
        }}
      >
        <div className="bg-white dark:bg-black-400">
          <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium mb-8 px-4 dark:text-white">
            Action
          </h2>
          <div className="h-[65vh] my-auto overflow-y-auto px-4 ">
            <div>
              <div className="flex items-center mb-4">
                <span className="mr-1.5 dark:invert dark:brightness-0">
                  <img src={Swap} alt="Swap" />
                </span>
                <p className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium dark:text-white">
                  Set up your mailer
                </p>
              </div>
              <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 block my-4 dark:text-white">
                Service provider
              </label>
              <div className="z-10 bg-white dark:bg-slate-800 border-gray-200 dark:border-black-400 border rounded-lg dropdown-show-hide relative">
                <ConnectedServices workflow={true} />
              </div>
              <div className="flex items-center my-6">
                <span className="mr-1.5 dark:invert dark:brightness-0">
                  <img src={Mail} alt="User" />
                </span>
                <p className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium dark:text-white">
                  Setup your email notification
                </p>
              </div>
              <div className="flex flex-wrap items-center gap-y-4">
                {notifyEmailFormData.map((data) => (
                  <div key={data.name} className="w-1/2 pr-1.5">
                    <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 block my-4 dark:text-white">
                      {data.label}
                    </label>
                    <div>
                      <input
                        className="text-13 font-medium leading-3 text-gray-700 py-2 rounded-md px-4 bg-white border border-gray-800 focus:outline-none focus:border-primary sm:h-11 h-9 w-full placeholder:text-gray-500 dark:bg-slate-800 dark:placeholder:text-white dark:text-white"
                        type="text"
                        placeholder={data.placeholder}
                        name={data.name}
                        value={formik.values[data.name]}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {!formIsValid && (
                <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4">
                  <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
                    You must fill in all fields.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalWrapper>
    </form>
  );
};
export default NotifyEmail;
