import { FC, useEffect, useState } from 'react';
import { TagsConditionData } from '../index.type';
import Dropdown from 'components/Dropdown/Dropdown';
import TagDropdown from '../TagDropdown';
import { getTags } from 'services/apiHandlers/Workflows/Workflow';
import { z } from 'zod';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import React from 'react';

const responseSchema = z.object({
  status: z.number(),
  data: z.object({ tags: z.array(z.string()) }),
});
type Response = z.infer<typeof responseSchema>;

const optionSchema = z.object({ value: z.string(), label: z.string() });
type Options = z.infer<typeof optionSchema>;

interface Props {
  onClose: () => void;
  tagData?: TagsConditionData | undefined;
  onEventSelect?: () => void;
  condition?: boolean;
  onSave: (data: TagsConditionData) => void;
}

interface Tags {
  [key: string]: string;
  AnyOf: string;
  AllOf: string;
  NoneOf: string;
}

const tagOptions = ['Event Has Occured', 'Subscriber was tagged'];
const subscriberTagOptions = ['Any of', 'All of', 'None of'];

const selectedTag: Tags = {
  AnyOf: 'Any of',
  AllOf: 'All of',
  NoneOf: 'None of',
};

const InitialTag: FC<Props> = ({
  onClose,
  tagData,
  onEventSelect,
  condition,
  onSave,
}) => {
  const [subscriberTagValue, setSubscriberTagValue] =
    useState<string>('Any of');
  const [tags, setTags] = useState<Array<string>>([]);
  const [options, setOptions] = useState<Array<Options>>([]);
  const [formIsValid, setFormIsValid] = useState<boolean>(true);

  const getData = async () => {
    try {
      const response = (await getTags()) as Response;
      if (response.status === 200) {
        const tempOptions = response.data.tags.map((option) => {
          return { value: option, label: option };
        });
        setOptions(tempOptions);
      }
    } catch (error) {
      console.log('error is: ', error);
    }
  };

  const saveDataHandler = () => {
    if (tags.length === 0) {
      setFormIsValid(false);
    } else {
      const data = {
        _type: 'TagInitialConditionGroup',
        tagCondition: {
          _type: Object.keys(selectedTag).find(
            (key) => selectedTag[key] === subscriberTagValue
          ) as string,
        },
        tags: tags,
      };
      onClose();
      onSave(data);
    }
  };

  useEffect(() => {
    getData();
    if (tagData) {
      setSubscriberTagValue(selectedTag[tagData.tagCondition._type]);
      setTags(tagData.tags);
    }
  }, []);
  return (
    <ModalWrapper
      onClose={onClose}
      disabled={!formIsValid}
      onSave={saveDataHandler}
    >
      <>
        <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium mb-8 dark:text-white">
          {condition ? 'Condition' : 'Initial Condition'}
        </h2>
        {!condition && (
          <>
            <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 block my-4">
              Execute trigger when
            </label>
            <div className="flex items-center gap-4">
              <div className="z-11 bg-white dark:bg-slate-800 border-gray-200 dark:border-black-400 border rounded-lg dropdown-show-hide relative sm:h-11 h-9 w-6/12">
                <Dropdown
                  options={tagOptions}
                  value={'Subscriber was tagged'}
                  onSelect={(value) => {
                    if (value === 'Event Has Occured') {
                      onEventSelect && onEventSelect();
                    }
                  }}
                />
              </div>
            </div>
          </>
        )}
        <div className="mt-7">
          <div className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 block my-4">
            Subscriber Tagged with
          </div>
          <div className="z-10 bg-white dark:bg-slate-800 border-gray-200 dark:border-black-400 border rounded-lg dropdown-show-hide relative sm:h-11 h-9 w-full">
            <Dropdown
              options={subscriberTagOptions}
              value={subscriberTagValue}
              onSelect={(value) => setSubscriberTagValue(value)}
            />
          </div>
        </div>
        <div>
          <TagDropdown
            selectedTags={tags}
            onTagsSelect={(tags) => {
              setFormIsValid(true)
              setTags(tags);
            }}
            tagOptions={options}
          />
        </div>
        {!formIsValid && (
          <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4">
            <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
              You must select atleast one tag
            </p>
          </div>
        )}
      </>
    </ModalWrapper>
  );
};
export default InitialTag;
