import BoxImage from 'assets/Images/svg-images/box.svg';
import Dropdown from 'components/Dropdown/Dropdown';
import { FC, useState } from 'react';
import Plus from 'assets/Images/svg-images/plus.svg';
import { Link } from 'react-router-dom';
import { createCampaign } from 'services/constant/routes';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';

const HeaderDropdownOptions = ['7 Days', '30 Days', '90 Days', 'Custom'];

const Header: FC = () => {
  const [date] = useState('7 Days');

  return (
    <ErrorBoundary>
      <div className="flex items-center relative">
        <div className="sm:flex items-center w-full relative">
          <div className="flex items-center">
            <span>
              <img
                className="dark:brightness-0 dark:invert"
                src={BoxImage}
                alt="Health"
              />
            </span>
            <h2 className="sm:text-xl text-base leading-5 text-black-700 dark:text-white ml-2.5 font-medium">
              Reports
            </h2>
          </div>
          <div className="sm:ml-3 sm:w-auto sm:mt-0 mt-5 absolute sm:left-32 left-7 sm:-top-2.5 top-1">
            <Dropdown value={date} options={HeaderDropdownOptions} />
          </div>
        </div>
        <Link
          to={`${createCampaign}?tab=dashboard`}
          className="sm:mt-0 sm:relative absolute right-0 sm:w-full"
        >
          <button className="sm:text-sm text-xs font-medium leading-5 text-white flex items-center ml-auto rounded-md py-3 sm:pl-4 px-2 sm:pr-3.5 font-inter bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105">
            <img className="sm:mr-3 mr-1" src={Plus} alt="Plus" />
            Create Campaign
          </button>
        </Link>
      </div>
    </ErrorBoundary>
  );
};
export default Header;
