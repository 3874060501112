import { TableColumn } from 'react-data-table-component';
import { FC, useContext, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import StoreTable from '../Common/StoreTable';
import Table from 'components/Table';
import { DashboardContext } from 'store/DashboardContext';
import { topStrategies, tableSort } from 'utils/common';
import { Workflows, campaigns, SingleWorkflow, Singlecampaign } from './Types';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { campaignReports, workflowReports } from 'services/constant/routes';

const StyledWrapper = styled.div`
  .table1 .sc-dkrFOg {
    border: none;
  }
  .table1 .storeTable .border {
    border: none;
    border-radius: 0px;
  }

  .table1 .sc-jrcTuL {
    border: 0;
  }

  .table1 .storeTable .sc-eDWCr {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: ${localStorage.theme === 'dark' ? 'white' : '#757575'};
  }

  .rdt_TableCol_Sortable {
    color: #292d38;
  }

  .jbGVAn:hover,
  .jbGVAn:focus {
    opacity: 1;
  }

  @media screen and (prefers-color-scheme: dark) {
    .table1 .storeTable .sc-eDWCr {
      color: white;
    }
  }

  .table1 .storeTable .sc-jrcTuL {
    padding: 20px 0px;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: #616161;
  }

  .table1 .storeTable .sc-jrcTuL .bQdYbR {
    color: #60a5fa;
  }
`;

const AnalyticsTable: FC = () => {
  const [workflows, setWorkflows] = useState<Workflows>([]);
  const [campaigns, setcampaigns] = useState<campaigns>([]);
  const dashboardCtx = useContext(DashboardContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const workflowSort = (rowA: SingleWorkflow, rowB: SingleWorkflow) => {
    return tableSort(rowA.workflowName, rowB.workflowName);
  };

  const campaignSort = (rowA: Singlecampaign, rowB: Singlecampaign) => {
    return tableSort(rowA.campaignName, rowB.campaignName);
  };

  const workflowRevenueSort = (rowA: SingleWorkflow, rowB: SingleWorkflow) => {
    return tableSort(rowA.monetaryValue, rowB.monetaryValue);
  };

  const campaignRevenueSort = (rowA: Singlecampaign, rowB: Singlecampaign) => {
    return tableSort(rowA.monetaryValue, rowB.monetaryValue);
  };

  const showWorkflowDetails = (id: string) => {
    searchParams.set('analytics', 'true');
    searchParams.set('workflowId', id);
    setSearchParams(searchParams);
  };

  const workflowColumns: TableColumn<SingleWorkflow>[] = useMemo(
    () => [
      {
        name: 'Workflow Name',
        cell: (row) => {
          return (
            <Link
            to={`${workflowReports}/${row.workflowId}?analytics=true`}
              onClick={() => {
                showWorkflowDetails(row.workflowId);
              }}
              className="dark:text-white cursor-pointer"
            >
              <div>{row.workflowName}</div>
            </Link>
          );
        },
        sortable: true,
        sortFunction: workflowSort,
      },
      {
        name: 'Revenue',
        right: true,
        cell: (row) => {
          return (
            <div className="dark:text-white justify-end w-full text-right ">
              <div>{Math.floor(row.monetaryValue)}</div>
            </div>
          );
        },
        sortFunction: workflowRevenueSort,
      },
    ],
    []
  );
  const campaignColumns: TableColumn<Singlecampaign>[] = useMemo(
    () => [
      {
        name: 'campaign Name',
        cell: (row) => {
          return (
            <Link
              to={`${campaignReports}/${row.id}`}
              className="dark:text-white cursor-pointer"
            >
              <div>{row.campaignName}</div>
            </Link>
          );
        },
        sortFunction: campaignSort,
        sortable: true,
      },
      {
        name: 'Revenue',
        right: true,
        cell: (row) => {
          return (
            <div className="dark:text-white justify-end w-full text-right">
              <div>{Math.floor(row.monetaryValue)}</div>
            </div>
          );
        },
        sortFunction: campaignRevenueSort,
      },
    ],
    []
  );

  useEffect(() => {
    const tempWorkflows = topStrategies(
      dashboardCtx?.workflows ? dashboardCtx.workflows : [],
      3,
      'monetaryValue'
    );

    const tempcampaigns = topStrategies(
      dashboardCtx?.campaigns ? dashboardCtx.campaigns : [],
      3,
      'monetaryValue'
    );

    dashboardCtx?.workflows && setWorkflows(tempWorkflows as Workflows);
    dashboardCtx?.campaigns && setcampaigns(tempcampaigns as campaigns);
  }, [dashboardCtx?.workflows, dashboardCtx?.campaigns, dashboardCtx?.loading]);

  return (
    <StyledWrapper>
      <div className="flex md:flex-row flex-col font-inter mt-4 table1">
        <div className="md:w-6/12 w-full md:pr-2.5">
          <StoreTable
            heading1="Top Performing Worklflows"
            heading2="View Reports"
            type="workflows"
          >
            <Table
              className="scrollbar-hide border-none"
              data={workflows}
              columns={workflowColumns}
              pagination={false}
              progressPending={dashboardCtx?.loading}
            />
          </StoreTable>
        </div>
        <div>
          <StoreTable
            heading1="Top Performing campaigns"
            heading2="View Reports"
            type="campaigns"
          >
            <Table
              className="scrollbar-hide border-none"
              data={campaigns}
              columns={campaignColumns}
              pagination={false}
              progressPending={dashboardCtx?.loading}
            />
          </StoreTable>
        </div>
      </div>
    </StyledWrapper>
  );
};
export default AnalyticsTable;
