import { FC, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { home } from 'services/constant/routes';
import { z } from 'zod'
import React from 'react';


const dropdownDataSchema = z.object({ title: z.string(), link: z.string() });
type Dropdown = z.infer<typeof dropdownDataSchema>;

interface Props {
  link: string | undefined;
  text: string;
  image: string;
  showFullSidebar: boolean | undefined;
  dropdown?: Dropdown[];
}

const SidebarItem: FC<Props> = ({
  link,
  text,
  image,
  showFullSidebar,
  dropdown,
}) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const checkActiveLink = () => {
    if (text === 'Profiles' && searchParams.get('profile')) {
      return true;
    } else {
      if (
        pathname.includes(link || '') &&
        !searchParams.get('profile') &&
        text !== 'Profiles' &&
        link !== home
      ) {
        return true;
      } else if (
        pathname === home &&
        link === home &&
        !searchParams.get('profile') &&
        text !== 'Profiles'
      ) {
        return true;
      } else if ( pathname.includes('workflow') && text === 'Workflows') {
        return true;
      } else if ( pathname.includes('campaign') && text === 'Campaigns') {
        return true;
      } else if ( pathname.includes('email') && text === 'Email - Templates') {
        return true;
      }
    }
  };
  return (
    <>

      <Link
        to={link || ''}
        target={text === 'Pop-Ups' ? "_blank" : '_self'}
      >
        <div
          className={`${checkActiveLink() ? 'bg-primaryHover ' : ''
            } flex justify-between items-center rounded py-2.5 pl-6 pr-2.5 mt-3 group hover:bg-primaryHover dark:hover:bg-white group cursor-pointer`}
        >
          <div className="flex items-center">
            <span>
              <img
                className={`${checkActiveLink()
                  ? ' brightness-0 invert '
                  : 'brightness-0 invert '
                  } w-5 h-5 group-hover:invert group-hover:brightness-0 dark:brightness-0 dark:invert dark:group-hover:invert-0 dark:group-hover:brightness-100`}
                src={image}
                alt="Box"
              />
            </span>
            <span
              className={`${checkActiveLink()
                ? 'text-white '
                : ' text-foreground dark:text-white '
                } ${showFullSidebar ? 'lg:block sm:hidden' : 'hidden'
                } text-15 font-medium leading-3 dark:text-white dark:group-hover:text-black-400 dark:text-white pl-3 sidebar-links group-hover:text-white`}
            >
              {text}
            </span>
          </div>
        </div>
      </Link>
      {dropdown && <button onClick={() => { setShowDropdown((prev) => !prev) }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4 text-foreground"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </button>}
      {showDropdown &&
        <div>{dropdown?.map((item) => {
          return (
          <ul key={item.title}><li >
            <Link
              to={item.link}>
             <div
              className={`flex justify-between items-center rounded py-2.5 pl-6 pr-2.5 mt-3 group hover:bg-primaryHover dark:hover:bg-white group cursor-pointer`}
            >
          <div className="flex items-center">
            <span
              className={`${showFullSidebar ? 'lg:block sm:hidden' : 'hidden'
                } text-15 font-medium leading-3 dark:text-white dark:group-hover:text-black-400 dark:text-white pl-3 sidebar-links group-hover:text-white`}
            >
              {item.title}
            </span>
          </div>
        </div>
            </Link>
          </li></ul>)
        })}</div>
      }
    </>
  );
};
export default SidebarItem;
