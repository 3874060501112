import React from "react";
const WooCommerceDescription = () => {
  return (
    <>
      <p className="text-sm font-medium leading-5 text-black-400 dark:text-white dark:text-white">
        <span>If you are using a WooCommerce version before 2.3.13</span>,these
        credentials must match an api account at your WooCommerce store under
        <span>
          WooCommerce {'>'} Settings {'>'} API {'>'} Keys/Apps
        </span>
        Also, make sure the {'"'}Enable REST Api{'"'} checkbox under the
        WooCommerce {'>'} Settings {'>'} API tab is checked.
      </p>
      <p className="text-sm font-medium leading-5 text-black-400 dark:text-white dark:text-white mt-3">
        If you are using a WooCommerce version before 2.3.13 or greater, these
        credentials must match an api account at your WooCommerce store
        WooCommerce API Keys section under Users {'>'} Profile.Also, make sure
        the {'"'}Enable REST Api{'"'} checkbox under the WooCommerce {'>'}{' '}
        Settings {'>'} General is checked.
      </p>
    </>
  );
};
export default WooCommerceDescription;
