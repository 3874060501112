import httpService from 'services/core/HttpService';
import {
  GET_ALL_FILTERS,
  CREATE_FILTER,
  DELETE_FILTER,
  UPDATE_FILTER,
  GET_FILTER,
  GET_FILTER_BY_ID,
  SWAP_BLOCK,
  SWAP_RULE,
  DELETE_RULE,
  ADD_RULE,
  ADD_BLOCK,
  GET_COUNT_FILTER,
  GET_FILTER_SUBSCRIBERS,
  EDIT_FILTER,
} from 'services/constant/apiRoutes';
import axios from 'services/core/HttpService';
import { z } from 'zod';

const ruleSchema = z.object({
  comparison_operator: z.string(),
  comparison_value: z.string(),
});

const rulesSchema = z.record(ruleSchema);

const ruleDataSchema = z.object({
  rules: rulesSchema,
});

export const createFilterSchema = z.object({
  name: z.string(),
  description: z.string(),
});

export type RuleData = z.infer<typeof ruleDataSchema>;
export type CreateFilter = z.infer<typeof createFilterSchema>;

const { REACT_APP_BASE_URL } = process.env;

const xmlConfig = {
  headers: { 'Content-Type': 'text/xml' },
};

export const getAllFilters = async () => {
  try {
    const response = await httpService.get(GET_ALL_FILTERS);

    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createFilter = async (data: CreateFilter) => {
  try {
    const response = await httpService.post(CREATE_FILTER, {
      segment: {
        name: data.name,
        description: data.description,
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateFilter = async (id: number, data: CreateFilter) => {
  const editFilterData = `<filter>
  <name>${data.name}</name>
  <description>${data.description}</description>
</filter>`;

  try {
    const response = await httpService.put(
      EDIT_FILTER.replace('{{ID}}', id.toString()),
      editFilterData,
      xmlConfig
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteFilter = async (id: number) => {
  try {
    const deleteUrl = DELETE_FILTER.replace('{{ID}}', id.toString());
    const response = await httpService.delete(deleteUrl);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFilter = (id: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(GET_FILTER + `${id}.xml`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFilterById = (id: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(GET_FILTER_BY_ID + id)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const swapBlock = (id: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(SWAP_BLOCK + id)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const swapRule = (id: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(SWAP_RULE + id)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteRule = async (id: number) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(DELETE_RULE + id.toString())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addRule = async (id: number) => {
  return new Promise((resolve, reject) => {
    axios
      .post(ADD_RULE + id.toString())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addBlock = async (id: number, field: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(ADD_BLOCK + id.toString(), {
        block: {
          field: field,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editFilter = (id: number, data: RuleData) => {
  return new Promise((resolve, reject) => {
    axios
      .put(UPDATE_FILTER + id.toString(), data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFilterCount = (id: number, type: string, data: RuleData) => {
  return new Promise((resolve, reject) => {
    axios
      .put(GET_COUNT_FILTER.replace('{{ID}}', id.toString()) + type, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFilterSubscribers = (id: number, page: number) => {
  return new Promise((resolve, reject) => {
    axios
      .get(GET_FILTER_SUBSCRIBERS + id.toString() + `?page=${page.toString()}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const exportFilter = (id: number) => {
  window.open(
    REACT_APP_BASE_URL + GET_FILTER_SUBSCRIBERS + id + '?export=true'
  );
};
