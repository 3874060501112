import Header from './Header';
import Forms from './Forms';
import ListSegments from './ListSegments';
import Schedule from './Schedule';
import DeliveryOptions from './DeliveryOptions';
import { FC, useContext, useEffect } from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import { useParams } from 'react-router-dom';
import { getNewCampaignData } from 'services/apiHandlers/Campaigns/Emails';
import { z } from 'zod';
import { CampaignContext } from 'store/CampaignContext';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import React from 'react';

const newCampaignDataSchema = z.object({
  finder_id: z.number(),
  hide_continue_button: z.boolean(),
  campaign_source: z.string(),
  draft_changed: z.boolean(),
  franchise_enabled: z.boolean(),
  no_subscribers: z.boolean(),
  personDbId: z.number(),
  resold: z.boolean(),
  rss_enabled: z.boolean(),
  rss_included: z.boolean(),
  buy_more_quota_link: z.string(),
  upgrade_account_link: z.string(),
  preview_link: z.string(),
  account_tracking_hash: z.string(),
  default_owner: z.object({
    owner: z.object({
      account_id: z.number(),
      city: z.string(),
      country: z.string(),
      created_at: z.string(),
      custom_field_values: z.string(),
      deleted_at: z.string(),
      franchise_code: z.string(),
      franchise_id: z.number(),
      from_email_address: z.string(),
      from_name: z.string(),
      id: z.number(),
      name: z.string(),
      owner_type: z.string(),
      state: z.string(),
      street_address_1: z.string(),
      street_address_2: z.string(),
      tracking_hash: z.string(),
      updated_at: z.string(),
      zip: z.string(),
    }),
  }),
  default_owner_valid: z.boolean(),
  include_social_bar: z.boolean(),
  share_description: z.string(),
  use_google_analytics: z.boolean(),
});

const getNewCampaignResponseSchema = z.object({
  data: newCampaignDataSchema,
  status: z.number(),
});

type GetNewCampaignDataResponse = z.infer<typeof getNewCampaignResponseSchema>;

const CampaignInformation: FC = () => {
  const { id } = useParams();
  // const [searchParams] = useSearchParams();
  const campaignCtx = useContext(CampaignContext);

  const getNewCampaignDataHandler = async (id: string) => {
    try {
      const getNewCampaignDataResponse = (await getNewCampaignData(
        id
      )) as GetNewCampaignDataResponse;
      if (getNewCampaignDataResponse.status === 200) {
        const {
          finder_id,
          campaign_source,
          share_description,
          include_social_bar,
          use_google_analytics,
        } = getNewCampaignDataResponse.data;

        if (campaignCtx?.campaignData) {
          campaignCtx?.onUpdateCampaignData({
            ...campaignCtx.campaignData,
            finder_id: finder_id,
            email_id: parseInt(id),
          });
        }
        campaignCtx?.onChangeInitialData({
          campaignData: {
            utm_campaign: campaignCtx.campaignData?.utm_campaign || '',
            utm_content: campaignCtx.campaignData?.utm_content || '',
            utm_medium: campaignCtx.campaignData?.utm_medium || '',
            utm_source: campaign_source,
          },
          description: share_description,
          socialBar: include_social_bar,
          googleAnalytics: use_google_analytics,
          trackClick: false,
        });
      }
    } catch (error) {
      console.log('erorr is  : ', error);
    }
  };

  useEffect(() => {
    const emailId = id;

    if (emailId) {
      getNewCampaignDataHandler(emailId);
    }
  }, []);

  return (
    <ErrorBoundary>
      <div className="md:px-6 px-3 py-5 font-inter bg-gray-400 dark:bg-slate-800 h-full">
        <div className="md:px-1.5  mb-16">
          <Breadcrumbs />
          <Header />
          <Forms
            onEmailChange={(id: string) => getNewCampaignDataHandler(id)}
          />
          <ListSegments />
          <Schedule />
          <DeliveryOptions />
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default CampaignInformation;
