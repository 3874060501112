import React from "react";
import { Link } from "react-router-dom";
const sideNoteListData = [
  { text: 'Download the latest extension here' },
  { text: 'Log-In to Magento v1 Admin panel' },
  {
    text: 'Navigate to',
    strongText: 'System > Magento Connect > Magento Connect Manager',
  },
  { text: 'Sign into Magento Connect Manager with Magento admin credentials' },
  {
    text: 'Under',
    strongText: 'Direct package file upload',
    afterText: 'Click Choose File',
  },
  {
    text: 'Choose the ',
    emText: 'Insights_MarketingAutomation-[version].tgz',
    afterText: 'you downloaded',
  },
  { text: 'Click', strongText: 'Upload' },
  { text: 'Once installed click ', strongText: 'Return to Admin' },
  { text: 'Navigate to ', strongText: 'System > Configuration' },
  {
    text: 'Under ',
    strongText: 'INSIGHT MARKETING AUTOMATION',
    afterText: ' Click on General',
  },
  {
    text: ' Enter your Account Id -- ngIf: $ctrl.accountId --',
    strongText: '(500221)',
    afterText: '-- end ngIf:  $ctrl.accountId -- and clickSave Config',
  },
];
const CartNote = () => {
  return (
    <div className="lg:w-6/12 lg:pl-5 lg:mt-0 mt-8">
      <div className="md:px-6 px-3 py-4 rounded bg-[#ECF8F6] border dark:border-black-400 rounded dark:bg-slate-800">
        <h3 className="text-base font-semibold leading-5 text-black-700 dark:text-white mb-4">
          Insights Marketing Automation Magento v1 Extension
        </h3>
        <p className="text-sm font-medium leading-5 text-black-700 dark:text-white">
          Note: We support Magento 1.x on PHP Versions &gt;=5.4.0 &amp;
          &lt;=5.6.x same as <span></span>
          <Link
            target="popup"
            to="http://devdocs.magento.com/guides/m1x/system-requirements.html"
            className="text-primary dark:text-white"
          >
            magento 1.xrequirements
          </Link>
        </p>
        <p className="text-sm font-normal leading-4 text-black-400 dark:text-white dark:text-white my-2">
          To install follow the steps bellow:
        </p>
        <ol className="list-decimal pl-5">
          {sideNoteListData.map((li) => (
            <li
              key={li.text}
              className="text-sm leading-5 text-black-400 dark:text-white dark:text-white mb-2"
            >
              {li.text} <em>{li.emText}</em>
              <strong className="font-semibold">{li.strongText}</strong>
              {li.afterText}
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};
export default CartNote;
