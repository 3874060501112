import { Toastify } from 'App';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import xmlToJSON from 'utils/xmlToJSON';
import { formatXmlJsonResponse } from 'utils/common';
import {
  createList,
  getSingleList,
  updateList,
} from 'services/apiHandlers/List-Segments/SegmentsList';
import * as Yup from 'yup';
import { z } from 'zod';
import { useParams, Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { listSegments } from 'services/constant/routes';

const responseStatusSchema = z.object({ status: z.number() });
type ResponseStatus = z.infer<typeof responseStatusSchema>;

const NewList = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const getSingleListHandler = async () => {
    try {
      /* eslint-disable */
      const getListsResponse: any = await getSingleList(id);
      const data: any = xmlToJSON.parseString(getListsResponse.data);
      const list: any = formatXmlJsonResponse(data.list);
      /* eslint-enable  */
      formik.setFieldValue('name', list[0].name);
      formik.setFieldValue('description', list[0].description);
    } catch (error) {
      console.log('error is: ', error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      const data = {
        name: values.name,
        description: values.description,
        id: Number(id),
      };
      const list = {
        list: { name: values.name, description: values.description },
      };
      try {
        const createListResponse = id
          ? ((await updateList(data)) as ResponseStatus)
          : ((await createList(list)) as ResponseStatus);
        if (
          createListResponse.status === 200 ||
          createListResponse.status === 201
        ) {
          Toastify(
            `${id ? 'Changes Done' : 'Created'} Successfully`,
            'success',
            'NewList1'
          );
          navigate(-1);
        }
      } catch (error) {
        Toastify('Changing List Failed', 'error', 'NewList2');
      }
    },
  });

  useEffect(() => {
    if (id) {
      getSingleListHandler();
    }
  }, [id]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex items-center">
          <div className="flex justify-between items-center w-full">
            <h2 className="sm:text-xl text-base font-medium leading-5 text-black-300 dark:text-white">
              {id ? 'Edit List' : 'Create New List'}
            </h2>
            <div className="flex items-center text-13 font-medium leading-5">
              <Link
                to={`${listSegments}`}
                className="py-2.5 w-28 px-4 sm:text-sm text-xs font-medium leading-5 text-primary border-2 border-primary rounded-md bg-white hover:text-primary ease-in duration-300 hover:bg-primary hover:scale-105 hover:text-white mr-3"
              >
                Cancel
              </Link>
              <button
                data-pw="Create-List"
                type="submit"
                className="text-sm font-medium leading-5 rounded-md px-4 py-2.5 w-28 h-11 bg-btnPrimary hover:bg-primary text-white hover:scale-105 ease-in duration-300"
              >
                {id ? 'Save' : 'Create'}
              </button>
            </div>
          </div>
        </div>
        <div className="mt-4 py-6 sm:px-6 px-4 bg-white dark:bg-slate-800 border border-gray-800 dark:border-black-400 shadow-lg shadow-gray-200 dark:shadow-none rounded-lg">
          <div className="sm:flex justify-between">
            <div className="sm:w-6/12 w-full sm:pr-3">
              <p className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white mb-3">
                Segment Name
              </p>
              <input
                className="border border-gray-800 dark:border-black-400 dark:bg-slate-800 sm:h-11 h-9 p-4 text-13 font-medium leading-3 text-gray-700 placeholder:text-gray-500 dark:text-white dark:bg-slate-800 rounded-md w-full focus:outline-none dark:placeholder:text-white"
                type="text"
                name="name"
                placeholder="Segment name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name && formik.values.name?.length === 0 ? (
                <div
                  data-pw="Segment-Required"
                  className="text-xs leading-4 text-red-400 mt-1.5"
                >
                  {formik.errors.name ? formik.errors.name : null}
                </div>
              ) : null}
            </div>
            <div className="sm:w-6/12 w-full sm:pl-3 sm:mt-0 mt-4">
              <p className="text-xs font-semibold leading-3 tracking-wide uppercase text-black-400 dark:text-white dark:text-white mb-3">
                Description
              </p>
              <input
                className="border border-gray-800 dark:border-black-400 dark:bg-slate-800 sm:h-11 h-9 p-4 text-13 font-medium leading-3 text-gray-700 placeholder:text-gray-500 dark:text-white dark:bg-slate-800 rounded-md w-full focus:outline-none dark:placeholder:text-white"
                type="Text"
                name="description"
                placeholder="Segment description"
                value={formik.values.description}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default NewList;
