import ErrorBoundary from 'components/ErrorBoundary';
import PreviewEditor from 'components/PreviewEditor';
import React from "react"

const PreviewEmail = () => {
  return (
    <ErrorBoundary>
      <PreviewEditor />
    </ErrorBoundary>
  );
};
export default PreviewEmail;
