import { FC, useEffect, useRef, useState } from 'react';
import DownArrow from 'assets/Images/Common/down-arrow.png';
interface Props {
  options: string[]  ;
  onSelect: (option: string) => void;
  value: string | undefined;
}
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React from "react";

const Dropdown: FC<Props> = ({ options, onSelect, value }) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [searchedValue, setSearchedValue] = useState<string>("");
  const [dropdownOptions, setDropdownOptions] =
    useState<Array<string>>(options);
    const dropdownRef = useRef<HTMLDivElement>(null);
  
  useOutsideClick(dropdownRef, () => setShowDropdown(false))

  useEffect(() => {
    setDropdownOptions(options);
  }, [options]);

  useEffect(() => {
    let tempOptions: string[] = [...dropdownOptions];
    tempOptions =
      searchedValue && searchedValue?.length > 0
        ? options.filter((option) =>
            option.toLowerCase().includes(searchedValue.toLowerCase())
          )
        : options;
    setDropdownOptions(tempOptions);
  }, [searchedValue]);

  return (
    <div className='shadow-sm shadow-gray-200 rounded-md overflow-hidden dark:shadow-none' ref={dropdownRef}>
      <div
        onClick={() => setShowDropdown((prev) => !prev)}
        className={`${
          showDropdown ? 'rounded-md border-primary dark:border-white' : ''
        } text-sm font-medium rounded-md leading-4 text-black-400 dark:text-white flex items-center justify-between sm:h-11 py-2 px-4 focus:outline-none bg-white border border-gray-800 dark:bg-slate-800 dark:border-black-400`}
        data-pw="dropdown"
      >
        {value}
        <span>
          <img
            className="dark:brightness-0 dark:invert"
            src={DownArrow}
            alt="Arrow"
          />
        </span>
      </div>

      {showDropdown && (
        <div className="border border-t-0 rounded-b-md border-gray-800 dark:border-black-400 relative z-50 overflow-hidden">
          <input
            className="border-b border-gray-800 focus:outline-none dark:border-black-400 dark:bg-slate-800 sm:h-11 h-9 p-4 text-13 font-medium leading-3 text-gray-700 placeholder:text-gray-500 dark:text-white dark:placeholder:text-white rounded-t w-full"
            type="text"
            name="Search"
            placeholder="Filter Items"
            value={searchedValue}
            onChange={(e) => setSearchedValue(e.target.value)}
          />
          <div className="max-h-40 overflow-auto bg-white scrollbar-hide">
            {dropdownOptions.map((option, index) => (
              <div
                onClick={() => {
                  onSelect(option);
                  setShowDropdown(false);
                }}
                className={`${
                  dropdownOptions.length === index + 1 ? 'border-b-0' : ''
                } p-3 text-sm font-medium block w-full text-left leading-4 text-black-400 dark:text-white hover:bg-dropdownHover dark:hover:bg-white focus:bg-primary focus:text-white dark:text-white dark:hover:text-black-400 border border-dropdownHover bg-white dark:bg-slate-800 dark:text-white`}
                key={option}
                data-pw={
                  dropdownOptions.length - 1 === index ? 'last-option' : ''
                }
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default Dropdown;
