import Editor from 'components/Email/Editor';
import Header from 'components/Email/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { z } from 'zod';
import {
  getEmail,
  autosaveEmail,
  createNewEmail,
} from 'services/apiHandlers/Campaigns/Emails';
import { initPlugin } from 'utils/common';
import CreateHtmlHeader from 'components/Email/CreateHtmlHeader';
import Modal from 'components/Alert/Modal';
import DiscardModal from 'components/Alert/DiscardAlert';
import { EmailContext } from 'store/EmailContext';
import SubmitEmailAlert from 'components/Alert/SubmitEmailAlert';
import React from 'react';
import { create, emailEditor } from 'services/constant/routes';
import moment from 'moment';

export const emailDataSchema = z.object({
  account_id: z.number(),
  agreed_to_canspam_address_for_html: z.number().nullable(),
  agreed_to_canspam_address_for_text: z.number().nullable(),
  content_type: z.string(),
  created_at: z.string(),
  creator_id: z.number(),
  creator_type: z.string(),
  css: z.string(),
  description: z.string().nullable(),
  html_part: z.string(),
  id: z.number(),
  last_saved: z.string(),
  name: z.string(),
  process: z.string(),
  recovered_draft: z.string(),
  save_type: z.string(),
  size_in_bytes: z.number(),
  source_email_id: z.number(),
  subject: z.string().nullable(),
  tag_list: z.union([z.array(z.string()), z.tuple([])]),
  template_id: z.number(),
  template_name: z.string(),
  template_type: z.string(),
  template_category_name: z.string(),
  text_part: z.string(),
  updated_at: z.string(),
  widgetization_error: z.string().nullable(),
});
export type EmailData = z.infer<typeof emailDataSchema>;

const emaiLResponseSchema = z.object({
  data: z.object({ email: emailDataSchema }),
  status: z.number(),
});
type EmailResponse = z.infer<typeof emaiLResponseSchema>;

const getEmailDataSchema = z.object({
  data: z.object({
    draft: z.object({ email: emailDataSchema }),
    recovered_draft: z.string().optional(),
    redirect_to: z.string(),
  }),
  status: z.number(),
});
export type EmailDataResponse = z.infer<typeof getEmailDataSchema>;

const EmailEditor = () => {
  const [emailData, setEmailData] = useState<EmailData>();
  const [name, setName] = useState<string>('');
  const [emailId, setEmailId] = useState<string>('');
  const [labels, setLabels] = useState<string>('');
  const [lastUpdated, setLastUpdated] = useState<string>('');
  const [draftUpdate, setDraftUpdate] = useState<string>('');
  const [editorLoading, setEditorLoading] = useState<boolean>(true);
  const [showDiscardModal, setShowDiscardModal] = useState<boolean>(false);
  const [createEmailSubmitted, setCreateEmailSubmitted] =
    useState<boolean>(false);
  const emailCtx = useContext(EmailContext);
  const { id } = useParams();
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
  const [view, setView] = useState<string>('');
  const navigate = useNavigate();

  const codeChanged = async () => {
    const saveDraftEmail = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.StripoApi.getTemplate(async (email: string) => {
        const data = {
          draft: {
            html_part: email,
            updated_at: moment().format('DD-MM-YYYY HH:mm:ss'),
          },
        };
        try {
          await autosaveEmail(data);
        } catch (error) {
          console.log('error is : ', error);
        }
      });
    }, 5000);
    return () => clearTimeout(saveDraftEmail);
  };

  const getData = async (id: string, force?: boolean) => {
    try {
      const getEmailResponse = (await getEmail(id, force)) as EmailResponse;
      if (getEmailResponse.status === 200) {        
        emailCtx?.templateInfoHandler(
          {name: getEmailResponse.data.email.template_name ,type: getEmailResponse.data.email.template_category_name}
        );
        
        setEmailData(getEmailResponse.data.email);
        if (getEmailResponse.data.email.recovered_draft) {
          setDraftUpdate(getEmailResponse.data.email.recovered_draft);
        }
      }
    } catch (error) {
      console.log('error is : ', error);
      setEditorLoading(false);
    }
  };

  const getNewEmailData = async (newMail: boolean) => {
    try {
      const getNewMailDataResponse = (await createNewEmail(
        newMail
      )) as EmailDataResponse;
      if (getNewMailDataResponse.status === 200) {
        if (newMail) {
          if (getNewMailDataResponse.data.redirect_to === 'template') {
            navigate(`${emailEditor}/${create}`);
          }
        } else {
          if (getNewMailDataResponse.data.draft?.email) {
            setView('create');
            initPlugin(
              getNewMailDataResponse.data.draft.email.html_part,
              codeChanged,
              false,
              () => setEditorLoading(false)
            );
            setName(getNewMailDataResponse.data.draft.email.name);
            setDraftUpdate(getNewMailDataResponse.data.recovered_draft || '');
          } else {
            navigate(`${emailEditor}/${create}`);
          }
        }

      }
    } catch (error) {
      console.log('error is: ', error);
    }
  };

  useEffect(() => {
    if (emailCtx && emailCtx?.htmlSubmitted?.length > 0) {
      initPlugin(emailCtx.htmlSubmitted, codeChanged, false, () =>
        setEditorLoading(false)
      );
      setView('create');
    } else if (id) {
      setView('edit');
      const editEmailId = id;
      if (editEmailId) {
        getData(editEmailId);
        setEmailId(editEmailId);
      }
    } else {
      getNewEmailData(false);
    }
  }, []);

  useEffect(() => {
    if (emailData) {
      const email = emailData.html_part;
      setName(emailData.name);
      setLastUpdated(emailData.last_saved);
      setLabels(emailData.tag_list.join(','));
      initPlugin(email, codeChanged, false, () => setEditorLoading(false));
    }
  }, [emailData]);

  return (
    <div className="pt-5 bg-gray-300 dark:bg-slate-800 h-full">
      <div className="bg-[#ddebff7a]">
        <div className="md:px-6 px-3 pt-5 bg-white dark:bg-slate-800 border-b border-gray-800 dark:border-black-400 font-inter">
          <div>
            {view === 'create' && (
              <CreateHtmlHeader
                draftUpdate={draftUpdate}
                onYes={() => {
                  setDraftUpdate('');
                  setView('emailSelected');
                }}
                onNo={() => {
                  getNewEmailData(true);
                  navigate(`${emailEditor}/${create}`);
                }}
              />
            )}

            {(view === 'edit' ||
              view === 'emailSelected' ||
              createEmailSubmitted ||
              id) && (
              <>
                <Header
                  name={name}
                  lastUpdated={lastUpdated}
                  id={emailId}
                  loading={editorLoading}
                  view={view}
                  draftUpdate={draftUpdate}
                  onYes={() => setDraftUpdate('')}
                  onNo={() => {
                    getData(emailId, true);
                    setDraftUpdate('');
                  }}
                  showEmailModal={() => setShowEmailModal(true)}
                />
              </>
            )}
          </div>
        </div>

        <Editor
          draft={draftUpdate}
          onSaveData={(name: string) => {
            setName(name);
            setCreateEmailSubmitted(true);
          }}
          name={name}
          labels={labels}
          loading={editorLoading}
          onBackToCreation={() => {
            setShowDiscardModal(true);
          }}
          view={view}
          createEmailSubmitted={createEmailSubmitted}
          showEmailModal={() => setShowEmailModal(true)}
        />

        <Modal isOpen={showDiscardModal || showEmailModal}>
          {showDiscardModal ? (
            <DiscardModal
              onClose={() => setShowDiscardModal(false)}
              onOk={() => {
                navigate(`${emailEditor}/${create}`);
              }}
            />
          ) : showEmailModal ? (
            <SubmitEmailAlert
              onClose={() => setShowEmailModal(false)}
              onOk={() => setShowEmailModal(false)}
            />
          ) : (
            <></>
          )}
        </Modal>
      </div>
    </div>
  );
};
export default EmailEditor;
