import { useEffect, useMemo, useState } from 'react';
import { WorkflowLogs as Logs } from '.';
import { TableColumn } from 'react-data-table-component';
import { getWorkflowLogs } from 'services/apiHandlers/Workflows/Workflow';
import Table from 'components/Table';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Loader from 'components/Loader';
import styled from 'styled-components';
import { z } from 'zod';
import { workflow, logs as workflowLogs, listSegments, edit, subscribers } from 'services/constant/routes';
import React from 'react';
import moment from 'moment';

const workflowLogSchema = z.object({
  accountId: z.number(),
  executionId: z.string(),
  id: z.string(),
  message: z.string(),
  personIdentifier: z.string(),
  triggerId: z.string(),
  workflowNodeId: z.string(),
});
export type WorkflowLogs = z.infer<typeof workflowLogSchema>;

const workflowLogResponseSchema = z.object({
  data: z.array(workflowLogSchema),
  status: z.number(),
});
type WorkflowLogResponse = z.infer<typeof workflowLogResponseSchema>;

const StyleWrapper = styled.div`
  .rdt_TableRow .rdt_TableCell {
    border-right: 1px solid #e0e0e0;
  }

  .rdt_TableRow .rdt_TableCell:last-child {
    border-right: 0;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .rdt_TableHeadRow .rdt_TableCol {
    border-right: 1px solid #e0e0e0;
  }
  .rdt_TableHeadRow .rdt_TableCol:last-child {
    border-right: 0;
  }
  .rdt_TableHeadRow .rdt_TableCol:nth-child(3) {
    max-width: 20%;
  }
  .rdt_TableRow .rdt_TableCell:nth-child(3) {
    max-width: 20%;
  }
`;

const formatDate = (id: string) => {
  const parts = id.split('-');
  const time =
    parseInt(parts[2].substring(1) + parts[1] + parts[0], 16) -
    122192928000000000;
  const date = moment(Math.floor(time / 10000));

  const formattedDate = date.format('MMM DD, YYYY hh:mm:ss A');
  return formattedDate;
};

const WorkflowLogs = () => {
  const [searchParams] = useSearchParams();
  const [showMoreButton, setShowMoreButton] = useState<boolean>(true);
  const [logs, setLogs] = useState<Array<WorkflowLogs>>([]);
  const [moreLoading, setMoreLoading] = useState<boolean>(false);
  const [logsLoading, setLogsLoading] = useState<boolean>(false);
  const { id } = useParams();
  const status = searchParams.get('status');

  const columns: TableColumn<Logs>[] = useMemo(
    () => [
      {
        name: 'Timestamp',
        cell: (row) => {
          return (
            <div className="dark:text-white cursor-pointer">
              <div>{formatDate(row.id)}</div>
            </div>
          );
        },
        sortable: true,
      },
      {
        name: 'Subscriber',
        cell: (row) => {
          return (
            <Link
              to={`${listSegments}/${subscribers}/${edit}/${row.personIdentifier}`}
              className="text-primary underline dark:text-white"
            >
              <div>{row.personIdentifier}</div>
            </Link>
          );
        },
      },
      {
        name: 'Execution Id',
        cell: (row) => {
          return (
            <Link
              to={`${workflow}/${workflowLogs}/${row.triggerId}/${row?.executionId}`}
              className="text-primary underline dark:text-white"
            >
              {row.executionId}
            </Link>
          );
        },
      },
      {
        name: 'Message',
        cell: (row) => {
          return <div className="dark:text-white">{row.message}</div>;
        },
      },
    ],
    []
  );

  const getWorkflowLogsHandler = async (id: string, logId?: string) => {
    if (logId) {
      setMoreLoading(true);
    } else {
      setLogsLoading(true);
    }

    try {
      const workflowLogResponse = (await getWorkflowLogs(
        id,
        logId
      )) as WorkflowLogResponse;
      if (workflowLogResponse.status === 200) {
        if (logId && workflowLogResponse.data.length === 0) {
          setLogs(logs);
          setShowMoreButton(false);
        } else {
          setLogs(workflowLogResponse.data);
        }
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLogsLoading(false);
    setMoreLoading(false);
  };
  const showMore = () => {
    getWorkflowLogsHandler(id ? id : '', logs[logs.length-1].id);
    console.log('id: ', id);
  };

  useEffect(() => {
    getWorkflowLogsHandler(id ? id : '');
  }, []);

  console.log('Logs ', logs);
  return (
    <div>
      {logs.length === 0 && !logsLoading ? (
        <div>
          This workflow is {status} and nothing has been logged for it yet
        </div>
      ) : (
        <>
          <StyleWrapper>
            <Table
              className="scrollbar-hide"
              data={logs}
              columns={columns}
              pagination={false}
              progressPending={logsLoading}
            />
          </StyleWrapper>
          {showMoreButton && (
            <button
              disabled={moreLoading}
              onClick={showMore}
              className="fs-13 font-medium leading-5 text-white flex items-center ml-auto rounded-md py-3 sm:pl-4 px-2 sm:pr-3.5 font-inter bg-primary mt-5 ease-in duration-300 hover:bg-primaryHover hover:scale-105"
            >
              {moreLoading && (
                <div className="">
                  <Loader />
                </div>
              )}
              {moreLoading ? 'Loading' : 'Show More'}
            </button>
          )}
        </>
      )}
    </div>
  );
};
export default WorkflowLogs;
