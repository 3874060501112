import Tabs from 'components/Tabs/Tabs';
import React, { useContext, useEffect, useState } from 'react';
import Header from 'components/Events/Header';
import {
  getEventAttributes,
  getEventNames,
} from 'services/apiHandlers/Workflows/Workflow';
import { z } from 'zod';
import AllEvents from 'components/Events/AllEvents';
import { downloadLogReport, getAllLogs } from 'services/apiHandlers/Logs';
import moment from 'moment-timezone';
import EventFilters from 'components/Events/EventFilters';
import EventAttributes from 'components/Events/EventAttributes';
import xmlToJSON from 'utils/xmlToJSON';
import { formatXmlJsonResponse } from 'utils/common';
import { getFields } from 'services/apiHandlers/List-Segments/Field';
import { UserContext } from 'store/UserContext';

const tabsData = [
  { title: 'Event Logs', searchQuery: 'Event Logs' },
  { title: 'Attributes', searchQuery: 'Attributes' },
];

const eventExtraOptions = [
  'insights_email_send',
  'insights_email_view',
  'insights_email_click',
  'ss_email_sent',
  'ss_added_to_list',
  'ss_unsubscribed',
  'ss_email_viewed',
  'ss_email_link_clicked',
  'ss_form_signup',
];

const getEventsResponseSchema = z.object({
  data: z.array(z.string()),
  status: z.number(),
});

const resultDataSchema = z.object({
  accountId: z.number(),
  attributes: z.record(z.string()),
  id: z.string(),
  name: z.string(),
  timestamp: z.number(),
});

const getEventFiltersResponseSchema = z.object({
  data: z.object({
    next_start_token: z.string(),
    totalCount: z.string(),
    result: z.array(resultDataSchema),
  }),
  status: z.number(),
});

const eventAttributesSchema = z.object({
  name: z.string(),
  mailMerge: z.string(),
});

const eventAttributesResponseSchema = z.object({
  data: z.array(z.string()),
  status: z.number(),
});

type EventAttributeResponse = z.infer<typeof eventAttributesResponseSchema>;
type EventAttribute = z.infer<typeof eventAttributesSchema>;
type ResultData = z.infer<typeof resultDataSchema>;
type GetEventResponse = z.infer<typeof getEventFiltersResponseSchema>;
type GetEvents = z.infer<typeof getEventsResponseSchema>;

const EventLogs = () => {
  const [events, setEvents] = useState<Array<string>>([]);
  const [selectedEvent, setSelectedEvent] = useState<string>('All Events');
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(3, 'months').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [allEvents, setAllEvents] = useState<Array<ResultData>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [nextStartToken, setNextStartToken] = useState<string>('');
  const [showMoreButton, setShowMoreButton] = useState<boolean>(true);
  const [moreLoading, setMoreLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [dateApplied, setDateApplied] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>(tabsData[0].title);
  const [eventAttributes, setEventAttributes] = useState<Array<EventAttribute>>(
    []
  );
  const [fields, setFields] = useState<Array<EventAttribute>>([]);
  const userCtx = useContext(UserContext);

  const getEventLogs = async (
    startDate: string,
    endDate: string,
    filter?: string,
    startToken?: string,
    more?: boolean,
    event?: string
  ) => {
    if (!more) {
      setLoading(true);
    }
    setMoreLoading(true);
    setShowMoreButton(true);
    try {
      const getAllEventResponse = (await getAllLogs(
        moment
          .tz(startDate, userCtx?.usersData?.timeZone || '')
          .startOf('day')
          .format('YYYY-MM-DD[T11:59:00.000Z]'),
        moment
          .tz(endDate, userCtx?.usersData?.timeZone || '')
          .startOf('day')
          .format('YYYY-MM-DD[T11:59:00.000Z]'),
        filter,
        startToken,
        event || selectedEvent
      )) as GetEventResponse;
      if (getAllEventResponse.status === 200) {
        if (getAllEventResponse.data.result.length === 0) {
          if (more) {
            setAllEvents(allEvents);
          } else {
            setAllEvents(getAllEventResponse.data.result);
          }
          setShowMoreButton(false);
        } else {
          if (event !== 'All Events') {
            if (more) {
              if (getAllEventResponse.data.result.length === 0) {
                setAllEvents(allEvents);
                setShowMoreButton(false);
              } else {
                setAllEvents([
                  ...allEvents,
                  ...getAllEventResponse.data.result,
                ]);
              }
            } else {
              setAllEvents(getAllEventResponse.data.result);
            }
          } else if (event === 'All Events') {
            setActiveTab(tabsData[0].title);
            setAllEvents(getAllEventResponse.data.result);
          } else if (filter && filter?.length > 0) {
            if (more) {
              // setAllEvents(getAllEventResponse.data.result);
              if (getAllEventResponse.data.result.length === 0) {
                setAllEvents(allEvents);
                setShowMoreButton(false);
              } else {
                setAllEvents([
                  ...allEvents,
                  ...getAllEventResponse.data.result,
                ]);
              }
            } else {
              setAllEvents(getAllEventResponse.data.result);
            }
          } else if (dateApplied) {
            if (more) {
              if (getAllEventResponse.data.result.length === 0) {
                setShowMoreButton(false);
                setAllEvents(allEvents);
              } else {
                setAllEvents([
                  ...allEvents,
                  ...getAllEventResponse.data.result,
                ]);
              }
            } else {
              setAllEvents(getAllEventResponse.data.result);
            }
          } else {
            setAllEvents([...allEvents, ...getAllEventResponse.data.result]);
          }
        }
        setNextStartToken(getAllEventResponse.data.next_start_token);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setMoreLoading(false);
    setLoading(false);
  };

  const getEventsData = async () => {
    try {
      const getEventsResponse = (await getEventNames()) as GetEvents;

      /* eslint-disable */
      const getFieldsResponse: any = await getFields();
      if (getFieldsResponse.status === 200) {
        const data: any = xmlToJSON.parseString(getFieldsResponse.data);
        const fields = formatXmlJsonResponse(data?.fields[0]?.field);
        const tempFields = fields.map((field) => {
          return { name: field.name, mailMerge: `{{${field.slug}}}` };
        });
        setFields(tempFields);
      }
      /* eslint-enable */

      if (getEventsResponse.status === 200) {
        setEvents([
          'All Events',
          ...getEventsResponse.data,
          ...eventExtraOptions,
        ]);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const getEventAttributesHandler = async () => {
    try {
      const getEventAttributesResponse = (await getEventAttributes(
        selectedEvent
      )) as EventAttributeResponse;
      if (getEventAttributesResponse.status === 200) {
        const tempAttributes = getEventAttributesResponse.data.map(
          (attribute) => {
            return {
              name: attribute,
              mailMerge: `{{event.attributes.${attribute}}}`,
            };
          }
        );
        setEventAttributes(tempAttributes);
      }
    } catch (error) {
      console.log('eror is : ', error);
    }
  };

  useEffect(() => {
    if (selectedEvent !== 'All Events') {
      getEventAttributesHandler();
    }
  }, [selectedEvent]);

  useEffect(() => {
    getEventsData();
    getEventLogs(startDate, endDate);
  }, []);

  const filterData = { startDate, endDate, searchValue, selectedEvent };

  return (
    <div>
      <Header />
      <div className="md:px-6 px-3 py-5 font-inter bg-gray-400 dark:bg-slate-800 h-full">
        <div className="md:px-1.5 mb-16">
          <EventFilters
            events={events}
            onSelect={(event) => {
              setSelectedEvent(event);
              getEventLogs(
                startDate,
                endDate,
                searchValue,
                undefined,
                undefined,
                event
              );
            }}
            selectedEvent={selectedEvent}
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            onApplyFilter={() => {
              setDateApplied(true);
              getEventLogs(startDate, endDate, searchValue);
            }}
            searchValue={searchValue}
            onSearchValueChange={setSearchValue}
            onApplySearch={() => getEventLogs(startDate, endDate, searchValue)}
            activeTab={activeTab}
            onDownloadLog={() =>
              downloadLogReport(
                selectedEvent,
                searchValue,
                moment
                  .utc(startDate)
                  .startOf('day')
                  .format('YYYY-MM-DD[T07:00:00.000Z]'),
                moment
                  .utc(endDate)
                  .startOf('day')
                  .format('YYYY-MM-DD[T07:00:00.000Z]'),
                nextStartToken
              )
            }
          />
        </div>
      </div>

      {selectedEvent !== 'All Events' && (
        <div>
          <Tabs
            tabs={tabsData}
            activeTab={activeTab}
            onTabChange={setActiveTab}
          />
        </div>
      )}

      {(selectedEvent === 'All Events' || activeTab === tabsData[0].title) && (
        <AllEvents
          events={allEvents}
          loading={loading}
          showMore={() =>
            getEventLogs(startDate, endDate, searchValue, nextStartToken, true)
          }
          showMoreButton={showMoreButton}
          moreLoading={moreLoading}
          filterData={filterData}
        />
      )}
      {activeTab === tabsData[1].title && !loading && (
        <EventAttributes eventAttributes={eventAttributes} fields={fields} />
      )}
    </div>
  );
};
export default EventLogs;
