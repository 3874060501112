import httpService from 'services/core/HttpService';
import {
  GET_GOOGLE_ANALYTICS,
  UPDATE_GOOGLE_ANALYTICS,
} from 'services/constant/apiRoutes';
import { z } from 'zod';

const updateGoogleAnalyticsSettingSchema = z.object({
  accountId: z.string().optional(),
  campaign: z.string().optional(),
  content: z.string().optional(),
  enabled: z.boolean().optional(),
  medium: z.string().optional(),
  source: z.string().optional(),
});

export type updateSetting = z.infer<typeof updateGoogleAnalyticsSettingSchema>;

export const getGoogleSettings = async () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_GOOGLE_ANALYTICS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateGoogleAnalyticsSetting = (
  updateGoogleAnalyticSettingData: updateSetting
) => {
  return new Promise((resolve, reject) => {
    httpService
      .put(UPDATE_GOOGLE_ANALYTICS, updateGoogleAnalyticSettingData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
