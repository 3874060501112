import createForm from 'form-data';
import axios from 'services/core/HttpService';
import { ACCOUNT_LOGIN, ACCOUNT_LOGOUT } from 'services/constant/apiRoutes';
import { z } from 'zod';

export const LoginResponseSchema = z.object({
  success: z.boolean(),
  message: z.string(),
  data: z.object({ success: z.boolean(), message: z.string() }),
});
export type LoginResponse = z.infer<typeof LoginResponseSchema>

export const login = (username: string, password: string , expire: boolean) => {
  return new Promise((resolve, reject) => {
    const data = new createForm();
    data.append('user_email_address', username);
    data.append('user_password', password);
    data.append("never_expires",expire)

    axios
      .post<LoginResponse>(ACCOUNT_LOGIN, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const logout = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(ACCOUNT_LOGOUT)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
