import httpService from 'services/core/HttpService';
import axios from 'services/core/HttpService';
import {
  CREATE_FIELD,
  GET_FIELDS,
  DELETE_FIELD,
  UPDATE_FIELD,
  GET_SINGLE_FIELD,
  GET_FIELD_OPTIONS,
  ADD_FIELD_OPTION,
  UPDATE_FIELD_OPTION,
  DELETE_FIELD_OPTION,
  SORT_FIELD_OPTIONS,
} from 'services/constant/apiRoutes';
import { z } from 'zod';

export const FieldSchema = z.object({ name: z.string(), id: z.number() });
export type FIELD = z.infer<typeof FieldSchema>;

export const EditFieldSchema = z.object({
  name: z.string(),
  id: z.number(),
  ['allow-other']: z.number().optional(),
  ['include-blank']: z.number().optional(),
});
export type EDIT_FIELD = z.infer<typeof EditFieldSchema>;

const xmlConfig = {
  headers: { 'Content-Type': 'text/xml' },
};

export const createField = async (fieldData: FIELD) => {
  try {
    const response = await httpService.post(CREATE_FIELD, {
      field: {
        name: fieldData.name,
        data_type_id: fieldData.id,
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFields = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(GET_FIELDS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteField = async (id: number) => {
  try {
    const deleteFieldUrl = DELETE_FIELD.replace('{{ID}}', id.toString());
    const response = await httpService.delete(deleteFieldUrl);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const editField = async (data: EDIT_FIELD) => {
  const updateData = `<field>
             <name>${data.name}</name>
             ${
               'include-blank' in data || 'allow-other' in data
                 ? `<include_blank>${data['include-blank']}</include_blank><allow_other>${data['allow-other']}</allow_other>`
                 : ''
             }
                     </field>`;
  const updateFieldUrl = UPDATE_FIELD.replace('{{ID}}', data.id.toString());
  try {
    const response = await httpService.put(
      updateFieldUrl,
      updateData,
      xmlConfig
    );

    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getField = (id: number) => {
  return new Promise((resolve, reject) => {
    axios
      .get(GET_SINGLE_FIELD.replace('{{ID}}', id.toString()))
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFieldOptions = (id: number) => {
  return new Promise((resolve, reject) => {
    axios
      .get(GET_FIELD_OPTIONS.replace('{{ID}}', id.toString()))
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addFieldOption = (name: string, id: number) => {
  return new Promise((resolve, reject) => {
    axios
      .post(ADD_FIELD_OPTION + id, {
        option: {
          name: name,
          field_id: id,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateFieldOption = (name: string, id: number) => {
  return new Promise((resolve, reject) => {
    axios
      .post(UPDATE_FIELD_OPTION + id, {
        value: name,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteFieldOption = (id: number) => {
  return new Promise((resolve, reject) => {
    axios
      .post(DELETE_FIELD_OPTION + id.toString())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const sortFieldOptions = (id: number, options: number[]) => {
  return new Promise((resolve, reject) => {
    axios
      .post(SORT_FIELD_OPTIONS + id.toString(), { options: options })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
