import Edit from 'assets/Images/ListsegmentImages/edit.png';
import DeleteModal from 'components/Alert/DeleteModal';
import Delete from 'assets/Images/ListsegmentImages/delete.png';
import DownArrow from 'assets/Images/Common/down-arrow.png';
import { useFormik, FormikValues } from 'formik';
import { FC, useEffect, useRef, useState } from 'react';
import uuid from 'react-uuid';
import {
  createEmailSender,
  deleteEmailSender,
  editEmailSender as editEmailHandler,
} from 'services/apiHandlers/Integration/Miscellaneous';
import { data } from 'components/Integration/Body/Miscellaneous/EmailSender';
import { z } from 'zod';
import * as Yup from 'yup';
import { Toastify } from 'App';
import ErrorBoundary from 'components/ErrorBoundary';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React from 'react';
interface Props {
  options: data[] | undefined;
  getData?: () => void;
  workflow?: boolean;
  onSelect?: (select: boolean, id: string | undefined) => void;
  selectedSender?: string | undefined;
  senderId?: string;
}

const emailSenderForm = [
  { name: 'name', placeholder: 'name' },
  { name: 'email', placeholder: 'E-mail' },
  { name: 'replyTo', placeholder: 'Reply-To-Email' },
];

const EmailSenderResponseSchema = z.object({ status: z.number() });
type EmailSenderResponse = z.infer<typeof EmailSenderResponseSchema>;

const Dropdown: FC<Props> = ({
  options,
  getData,
  workflow,
  onSelect,
  selectedSender,
  senderId,
}) => {
  const [deleteId, setDeleteId] = useState<string>('');
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectedMail, setSelectedMail] = useState<string>();
  const [editEmailSender, setEditEmailSender] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideClick(dropdownRef, () => {
    setShowDropdown(false);
  });

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
      replyTo: '',
      accountId: 0,
      id: '',
      links: { triggers: [] },
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name Cannot be Empty'),
      email: Yup.string()
        .required('Email Cannot be Empty')
        .email('Email is not valid'),
      replyTo: Yup.string()
        .required('Reply Email Cannot be Empty')
        .email('Email is not valid'),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (editEmailSender) {
        try {
          const editEmailSenderResponse = (await editEmailHandler(
            values
          )) as EmailSenderResponse;
          if (editEmailSenderResponse.status === 204) {
            Toastify('Email Sender Edited Successfully', 'success', 'action3');
            getData && getData();
          }
        } catch (error) {
          Toastify('Editing Email Sender Failed', 'error', 'action2');
        }
      } else {
        try {
          const data = {
            ...values,
            id: uuid(),
            links: {
              triggers: [],
            },
          };
          const createEmailSenderResponse = (await createEmailSender(
            data
          )) as EmailSenderResponse;
          if (createEmailSenderResponse.status === 201) {
            getData && getData();
            Toastify('Email Sender Created Successfully', 'success', 'action1');
            resetForm();
          }
        } catch (error) {
          Toastify('Email Creation Failed', 'error', 'action2');
        }
      }
    },
  });

  const dropDownChangeHandler = (
    email: string | undefined,
    id: string | undefined
  ) => {
    if (email === 'Custom') {
      onSelect && onSelect(true, id);
    } else {
      onSelect && onSelect(false, id);
    }
    setSelectedMail(email);
    setShowDropdown(false);
  };
  const editSenderHandler = (sender: data) => {
    setEditEmailSender(true);
    formik.setValues(sender);
  };

  const deleteEmailSenderHandler = async (id: string) => {
    try {
      const deleteEmailSenderResponse = (await deleteEmailSender(
        id
      )) as EmailSenderResponse;
      if (deleteEmailSenderResponse.status === 200) {
        Toastify('Email sender deleted successfully', 'success', 'dropdown1');
        getData && getData();
      }
    } catch (error) {
      Toastify('Deleting Email Sender Failed', 'error', 'dropdown2');
    }
  };

  useEffect(() => {
    if (selectedSender && senderId) {
      setSelectedMail(selectedSender);
      onSelect && onSelect(false, senderId);
    }
  }, [selectedSender]);

  return (
    <ErrorBoundary>
      <div ref={dropdownRef}>
        <div
          className={` ${
            showDropdown ? 'border-primary dark:border-white' : ''
          }  relative dark:text-white dark:bg-slate-800 rounded-t cursor-pointer px-3 py-2.5 w-full flex justify-between items-center`}
          onClick={() => setShowDropdown((prev) => !prev)}
        >
          <span>
            {options && !selectedMail ? options[0]?.email : selectedMail}
          </span>
          <span>
            <img src={DownArrow} alt="Arrow" />
          </span>
        </div>
        <div className="w-full overflow-auto max-h-60 bg-white scrollbar-hide">
          {showDropdown &&
            options?.map((option, index) => (
              <div
                key={option.id}
                className="dark:bg-slate-800 dark:text-white flex justify-between items-center text-gray-700 border border-t-0 border-gray-800 dark:border-black-400 hover:bg-dropdownHover py-3.5 px-3 group"
              >
                <div
                  data-pw="sender-list"
                  onClick={() =>
                    dropDownChangeHandler(option?.email, option?.id)
                  }
                >
                  <div className="flex items-center">
                    <p
                      data-pw={index === 0 ? 'first-sender-mail' : ''}
                      className="text-sm font-medium leading-5  dark:text-white dark:group-hover:text-gray-700"
                    >
                      {option.email}
                    </p>
                    <p className="text-xs leading-5 dark:text-white dark:group-hover:text-gray-700 pl-1">
                      ({option.name})
                    </p>
                  </div>
                  <p className="text-xs leading-3 dark:text-white dark:group-hover:text-gray-700 pt-0.5">
                    {option.replyTo}
                  </p>
                </div>

                {!(workflow && index === 0) && (
                  <div className="items-center flex">
                    <button type="button" className="w-4 h-4 mr-2">
                      <img
                        className="w-full dark:brightness-0 dark:invert"
                        onClick={() => editSenderHandler(option)}
                        src={Edit}
                        alt="Edit"
                        data-pw={index === 0 ? 'first-sender-edit' : ''}
                        id={index === 0 ? option.id : ''}
                      />
                    </button>
                    <button
                      type="button"
                      className="w-4 h-4"
                      data-pw={
                        options.length - 1 === index ? 'last-sender' : ''
                      }
                      id={options.length - 1 === index ? option.id : ''}
                    >
                      <img
                        className="w-full dark:brightness-0 dark:invert"
                        onClick={() => {
                          setDeleteId(option.id ? option.id : '');
                          setShowDeleteAlert(true);
                        }}
                        src={Delete}
                        alt="Delete"
                      />
                    </button>
                  </div>
                )}
              </div>
            ))}
        </div>
        {showDropdown && (
          <form
            className="border border-t-0 border-gray-800 dark:border-black-400 py-4 px-6 bg-gray-400 rounded-b-md dark:bg-slate-800 dark:text-white"
            onSubmit={formik.handleSubmit}
          >
            <h4>{editEmailSender ? 'Edit Sender' : 'Add'}</h4>
            {emailSenderForm.map((input) => (
              <div key={input.name}>
                <div className="mt-4">
                  <input
                    className="w-full placeholder:text-gray-500 focus:border-primary dark:focus:border-white focus:outline-none text-13 font-medium leading-3 text-gray-700 dark:text-white py-2 rounded-md px-4 bg-white dark:bg-black-400 dark:placeholder:text-white border border-gray-800 dark:border-black-400 sm:h-11 h-9"
                    type="text"
                    placeholder={input.placeholder}
                    name={input.name}
                    value={formik.values[input.name]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.errors[input.name] &&
                  formik.touched[input.name] &&
                  !formik.isValid && (
                    <div>
                      <div className="text-xs leading-4 text-red-400 mt-1.5">
                        {formik.errors[input.name]}
                      </div>
                    </div>
                  )}
              </div>
            ))}
            <div className="flex flex-wrap items-center">
              <button
                type="submit"
                className="text-13 font-medium leading-5 rounded-md py-2 px-4 text-white bg-btnPrimary ease-in-in duration-300 hover:bg-primary hover:scale-105 mr-4 mt-4"
                data-pw="submit-sender"
              >
                {editEmailSender ? 'Save' : 'Add'}
              </button>
              {editEmailSender && (
                <button
                  data-pw="cancel-sender"
                  type="button"
                  onClick={() => {
                    setEditEmailSender(false);
                    formik.resetForm()
                  }}
                  className="text-13 font-medium leading-5 rounded py-2 px-4 text-white bg-primary dark:hover:bg-white dark:hover:text-black-400 dark:text-white mr-4 mt-4"
                >
                  Cancel
                </button>
              )}
            </div>
            {showDeleteAlert && (
              <div
                onClick={() => setShowDeleteAlert(false)}
                className="fixed left-0 top-0 w-full h-full z-10 bg-black-200 duration-300"
              >
                <DeleteModal
                  onDelete={() => deleteEmailSenderHandler(deleteId)}
                  onCancel={() => setShowDeleteAlert(false)}
                />
              </div>
            )}
          </form>
        )}
      </div>
    </ErrorBoundary>
  );
};
export default Dropdown;
