import { EmailContextProvider } from 'store/EmailContext';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

const Email = () => {
  return (
    <ErrorBoundary>
      <EmailContextProvider>
      <>
        <div className="pt-5 bg-gray-300 dark:bg-slate-800 h-full">
        <div className="ml-2">
          <Breadcrumbs />
          <Outlet />
        </div>
        </div>
      </>
      </EmailContextProvider>
    </ErrorBoundary>
  );
};
export default Email;
