import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from 'services/Authentication';
import { home } from 'services/constant/routes';
import * as Yup from 'yup';
import { Toastify } from 'App';
import { z } from 'zod';
import React from 'react';

export const LoginResponseSchema = z.object({
  success: z.boolean(),
  message: z.string(),
  data: z.object({ success: z.boolean(), message: z.string() }),
});
export type LoginResponse = z.infer<typeof LoginResponseSchema>;

interface Props {
  hideButton?: boolean;
  submitted?: boolean;
  setSubmit?: (submit: boolean) => void;
  onClose?: () => void;
}

const LoginForm: FC<Props> = ({
  hideButton,
  submitted,
  setSubmit,
  onClose,
}) => {
  const [responseError, setResponseError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordField, setPasswordField] = useState<boolean>(true);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      check: [],
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Required'),
      username: Yup.string().email('Invalid email').required('Email Required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const loginResponse = (await login(
          values.username,
          values.password,
          values.check.length > 0 ? true : false
        )) as LoginResponse;
        if (loginResponse.data.success) {
          setResponseError('');
          if (!hideButton) {
            navigate(home);
          }
          onClose && onClose();
        } else {
          Toastify('Wrong Credentials', 'error', 'login1');
        }
      } catch (error) {
        Toastify('Login Failed', 'error', 'login1');
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (submitted) {
      formik.handleSubmit();
    }
  }, [submitted]);

  useEffect(() => {
    setSubmit && setSubmit(false);
  }, [formik.errors, submitted]);

  return (
    <div className={`${hideButton ? 'px-5' : 'p-4 md:p-8'}  font-inter`}>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-4">
          <input
            id="username"
            name="username"
            type="text"
            className={`hover:border-primary ${
              formik.errors.username ? 'border-red-300' : ''
            } placeholder:text-gray-500 focus:border-primary focus:outline-none focus:ring focus:ring-violet-300 border block w-full rounded-lg pl-4 h-14 text-sm`}
            placeholder="Username"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.username}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className="text-xs leading-4 text-red-400 mt-1.5">
              {formik.errors.username}
            </div>
          ) : null}
        </div>
        <div className="mb-4">
          <input
            id="password"
            name="password"
            type={passwordField ? 'password' : 'text'}
            className={`hover:border-primary ${
              formik.errors.password ? 'border-red-300' : ''
            } placeholder:text-gray-500 focus:border-primary focus:outline-none focus:ring focus:ring-violet-300 border block w-full rounded-lg pl-4 h-14 text-sm`}
            placeholder="Password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />

          {!passwordField ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setPasswordField((prev) => !prev)}
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 right-2 -top-9 float-right cursor-pointer relative"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setPasswordField((prev) => !prev)}
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 right-2 -top-9 float-right cursor-pointer relative"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          )}

          {formik.touched.password && formik.errors.password ? (
            <div className="text-xs leading-4 text-red-400 mt-1.5">
              {formik.errors.password}
            </div>
          ) : null}
          {responseError.length > 0 && (
            <div className="text-sm leading-4 text-red-400 mt-1.5">
              {responseError}
            </div>
          )}
        </div>

        <div className="flex justify-between font-inter">
          <div className="flex items-center">
            <input
              type="checkbox"
              id="remember-me"
              name="check"
              onChange={formik.handleChange}
              value="check"
            />
            <label className="sm:text-sm  text-xs leading-3 text-black-700 ml-2">
              Remember me.
            </label>
          </div>
          <div>
            <a className="sm:text-sm text-xs leading-3 text-primary">
              Forgot Password?
            </a>
          </div>
        </div>
        {!hideButton && (
          <button
            type="submit"
            className={` ${
              loading
                ? 'bg-gray-800 text-black-400 dark:text-white font-nomral '
                : 'bg-primary hover:bg-primaryHover text-white '
            } rounded-lg text-md font-medium block mx-auto my-6 w-full h-14 ease-in duration-300 `}
            disabled={loading}
          >
            Login
          </button>
        )}
      </form>
    </div>
  );
};
export default LoginForm;
